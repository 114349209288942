import { css, Theme } from '@emotion/react';
import { TinyColor } from '@ctrl/tinycolor';

export const container = (theme: Theme) => css`
  color: ${theme.colors.gray[700]};

  &.isCorrected {
    color: ${theme.colors.gray[300]};

    &.isSuccess {
      color: ${theme.colors.success[500]};
    }

    &.isError {
      color: ${theme.colors.danger[500]};
    }
  }

  > label {
    display: flex;
    align-items: center;
    gap: 10px;

    height: 100%;

    cursor: pointer;

    border: 1px solid ${theme.colors.gray[300]};
    border-radius: 4px;

    padding: 8px 15px;

    transition: ${theme.transitions.default('border-color', 'box-shadow')};

    > div {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &.media {
        justify-content: start;
        height: 100%;
        width: 100%;
        padding-left: 20px;
        border-left: 1px solid ${theme.colors.gray[100]};
      }
    }
  }

  &.isFocused > label {
    box-shadow: 0 0 0 4px
      ${new TinyColor(theme.colors.primary[500]).setAlpha(0.15).toRgbString()};
  }

  &.isCorrected > label {
    cursor: initial;
  }

  &.isDisabled > label {
    cursor: not-allowed;
  }

  &.hasIcon > label {
    padding: 7px 15px;
  }

  &.isSelected,
  &.isCorrected {
    > label {
      border-width: 2px;
      padding: 7px 14px;
    }

    &.hasIcon > label {
      padding: 6px 14px;
    }
  }

  &:not(.isCorrected).isSelected > label {
    border-color: ${theme.colors.primary[500]};
  }

  &.isCorrected {
    > label {
      border-color: ${theme.colors.gray[100]};
    }

    &.isSuccess > label {
      border-color: ${theme.colors.success[500]};
    }

    &.isError > label {
      border-color: ${theme.colors.danger[500]};
    }
  }
`;
