import {
  FocusEventHandler,
  memo,
  MemoExoticComponent,
  MouseEventHandler,
  ReactNode,
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Drag } from '@icon-park/react';
import { css, useTheme } from '@emotion/react';

import { AvatarShape } from '@eversity/types/web';
import { useFocus } from '@eversity/ui/utils';

import { ICON_SIZES } from '../../general/icon/constants';
import { avatarShape } from '../../../types/avatar';
import { removeButtonAppearance } from '../../../utils/style';

import { AvatarInline } from '../avatar-inline/AvatarInline';

import * as styles from './AvatarBox.styles';

const DRAGGABLE_CLASSNAME = 'DRAGGABLE';

export type AvatarBoxProps = {
  user: AvatarShape;
  status?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isSelected?: boolean;
  action?: ReactNode;
  isDraggable?: boolean;
  onFocus?: FocusEventHandler<HTMLButtonElement>;
  onBlur?: FocusEventHandler<HTMLButtonElement>;
};

export const AvatarBoxBase = ({
  user,
  status = null,
  onClick = null,
  isSelected = false,
  action = null,
  isDraggable = false,
  ...props
}: AvatarBoxProps) => {
  const { isFocused, ...focusProps } = useFocus<HTMLButtonElement>(props);
  const theme = useTheme();

  const avatarBox = (
    <div
      className={cn({ isSelected, isFocused })}
      css={styles.container}
    >
      {isDraggable && (
        <Drag
          className={DRAGGABLE_CLASSNAME}
          size={ICON_SIZES.MEDIUM}
          fill={[theme.colors.gray[300]]}
        />
      )}

      <AvatarInline
        size={AvatarInline.SIZES.MEDIUM}
        user={user}
        status={status}
        css={css`
          flex: 1;
        `}
      />

      {!onClick && action}
    </div>
  );

  return onClick ? (
    <button
      {...focusProps}
      type="button"
      css={[removeButtonAppearance, styles.button]}
      onClick={onClick}
    >
      {avatarBox}
    </button>
  ) : (
    avatarBox
  );
};

AvatarBoxBase.displayName = 'AvatarBox';

AvatarBoxBase.propTypes = {
  /** User to whom the avatar belongs. */
  user: avatarShape.isRequired,
  /** Status of the user. */
  status: PropTypes.node,
  /** Callback when clicking on the box itself. Do not use together with action. */
  onClick: PropTypes.func,
  /** Show component as selected. */
  isSelected: PropTypes.bool,
  /** Icon for the right button. */
  action: PropTypes.node,
  /** Show component as draggable. */
  isDraggable: PropTypes.bool,
};

export const AvatarBox: MemoExoticComponent<typeof AvatarBoxBase> & {
  DRAGGABLE_CLASSNAME?: typeof DRAGGABLE_CLASSNAME;
} = memo(AvatarBoxBase);

AvatarBox.DRAGGABLE_CLASSNAME = DRAGGABLE_CLASSNAME;
