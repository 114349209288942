import { css, Theme } from '@emotion/react';

import { removeButtonAppearance } from '../../../utils/style';

export const container = css`
  width: 100%;
`;

export const swiperContainer = (theme: Theme) => css`
  position: relative;

  width: 100%;

  overflow: hidden;

  &.hasPrev {
    border-left: 1px solid ${theme.colors.gray[100]};
  }

  &.hasNext {
    border-right: 1px solid ${theme.colors.gray[100]};
  }
`;

export const swiper = css`
  padding: 20px 0;

  /* Need to set auto width on slides, otherwise auto slidesPerView does not work. */
  .swiper-slide {
    width: auto;
  }
`;

const makeNavigationButton = (isNext: boolean) => (theme: Theme) => css`
  ${removeButtonAppearance};

  position: absolute;
  ${isNext ? 'right' : 'left'}: 0;
  top: 0;

  height: 100%;
  width: 50px;

  > div {
    position: absolute;
    ${isNext ? 'right' : 'left'}: 0;
    top: 50%;

    display: flex;
    justify-content: ${isNext ? 'flex-start' : 'flex-end'};
    align-items: center;

    transform: translateX(${isNext ? 50 : -50}%) translateY(-50%);

    width: 50px;
    height: 50px;

    background-color: ${theme.colors.gray[900]};

    border-radius: 50%;

    z-index: 1;

    transition: ${theme.transitions.default('transform')};
  }

  &:hover div {
    transform: translateX(${isNext ? 50 : -50}%) translateY(-50%) scale(1.5);
  }
`;

export const prevButton = makeNavigationButton(false);
export const nextButton = makeNavigationButton(true);
