import { memo, ReactElement, useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { TabKey, TabShape } from './types';
import { tabKeyPropTypes, tabPropTypes } from '../../../types';
import { useSortableList } from '../../../utils/hooks/useSortableList';
import { Sortable } from '../../general/sortable/Sortable';

import { Tab } from './Tab';

import * as styles from './Tabs.styles';

export type TabGroupProps<TTabKey extends TabKey = TabKey> = {
  tabs: TabShape<TTabKey>[];
  index: number;
  tabGroupsCount: number;
  value?: TTabKey;
  onChange?: (key: TTabKey) => void;
  onChangeOrder?: (newOrder: TabShape<TTabKey>[], index: number) => void;
  isDraggable?: boolean;
  isDragDisabled?: boolean;
  vertical?: boolean;
};

const getKey = <TTabKey extends TabKey = TabKey>(obj: TabShape<TTabKey>) =>
  obj.key;

export const TabGroupBase = <TTabKey extends TabKey = TabKey>({
  tabs,
  index,
  tabGroupsCount,
  value = null,
  onChange = noop,
  onChangeOrder = noop,
  isDraggable = false,
  isDragDisabled = false,
  vertical = false,
}: TabGroupProps<TTabKey>) => {
  const onChangeOrderProxy = useCallback(
    (newOrder: TabShape<TTabKey>[]) => onChangeOrder(newOrder, index),
    [onChangeOrder, index],
  );

  const [draggableTabs, onChangeDraggableTabs, onDrop] = useSortableList(
    tabs,
    onChangeOrderProxy,
    { getKey },
  );

  return (
    <Sortable
      tag="div"
      list={draggableTabs}
      setList={onChangeDraggableTabs}
      onEnd={onDrop}
      disabled={!isDraggable || isDragDisabled}
      // handle={`.${Tab.DRAGGABLE_CLASSNAME}`}
      css={(theme) =>
        vertical
          ? styles.verticalTab(theme)
          : styles.horizontalTab(theme, tabGroupsCount > 1)
      }
    >
      {draggableTabs.map(({ data: tab }) => (
        <Tab<TTabKey>
          key={tab.key}
          tab={tab}
          isActive={tab.key === value}
          isDraggable={isDraggable}
          onClick={onChange}
          vertical={vertical}
        />
      ))}
    </Sortable>
  );
};

TabGroupBase.displayName = 'TabGroup';

TabGroupBase.propTypes = {
  tabs: PropTypes.arrayOf(tabPropTypes).isRequired,
  index: PropTypes.number.isRequired,
  tabGroupsCount: PropTypes.number.isRequired,
  value: tabKeyPropTypes,
  onChange: PropTypes.func,
  onChangeOrder: PropTypes.func,
  isDraggable: PropTypes.bool,
  isDragDisabled: PropTypes.bool,
  vertical: PropTypes.bool,
};

export const TabGroup = memo(TabGroupBase) as <TTabKey extends TabKey = TabKey>(
  props: TabGroupProps<TTabKey>,
) => ReactElement | null;
