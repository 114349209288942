import { memo, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { gradeMessages } from '@eversity/ui/intl';

export type FormattedGradeProps = {
  grade: number;
  gradingScale: number;
  renderGrade?: (chunks: ReactNode[]) => ReactNode;
  renderGradingScale?: (chunks: ReactNode[]) => ReactNode;
};

const DEFAULT_RENDER_GRADE = (chunks: ReactNode[]) => chunks;
const DEFAULT_RENDER_GRADING_SCALE = (chunks: ReactNode[]) => chunks;

export const FormattedGradeBase = ({
  grade,
  gradingScale,
  renderGrade = DEFAULT_RENDER_GRADE,
  renderGradingScale = DEFAULT_RENDER_GRADING_SCALE,
}: FormattedGradeProps) => (
  <FormattedMessage
    {...gradeMessages.GRADE}
    values={{
      grade,
      gradingScale,
      Grade: renderGrade,
      GradingScale: renderGradingScale,
    }}
  />
);

FormattedGradeBase.displayName = 'FormattedGrade';

FormattedGradeBase.propTypes = {
  /** Grade of the student. */
  grade: PropTypes.number.isRequired,
  /** Max grade of the assignment. */
  gradingScale: PropTypes.number.isRequired,
  /** Component to render the grade. Passed to FormattedMessage. */
  renderGrade: PropTypes.func,
  /** Component to render the gradingScale. Passed to FormattedMessage. */
  renderGradingScale: PropTypes.func,
};

export const FormattedGrade = memo(FormattedGradeBase);
