import { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import cn from 'classnames';

import { FormattedMessage } from 'react-intl';
import { CheckOne, ImageFiles } from '@icon-park/react';
import { Tag, Typography, uploadPropTypes } from '@eversity/ui/design-system';
import { lessonsMessages, sequencesMessages } from '@eversity/ui/intl';
import { TeachingUnitLessonViewStudent, Upload } from '@eversity/types/domain';

import { schoolObjectBasePropTypes } from '../../../types/common';

import messages from './LessonCard.messages';
import * as styles from './LessonCard.styles';

export type LessonCardProps = {
  lesson: TeachingUnitLessonViewStudent['lesson'];
  thumbnail?: Upload;
  completionPercentage?: number;
  isDisabled?: boolean;
};

export const LessonCardBase = ({
  lesson,
  thumbnail = null,
  completionPercentage = null,
  isDisabled = false,
}: LessonCardProps) => {
  const theme = useTheme();
  const isCompleted = completionPercentage === 100;

  return (
    <div css={styles.container}>
      <div
        css={styles.card}
        className={cn({ isDisabled })}
      >
        {thumbnail?.href ? (
          <div
            css={styles.progressImage(
              theme,
              thumbnail?.href,
              completionPercentage,
            )}
          />
        ) : (
          <div css={styles.placeholderImage}>
            <ImageFiles
              size={48}
              theme="outline"
              fill={theme.colors.primary[500]}
              strokeWidth={1}
            />
          </div>
        )}

        {completionPercentage > 0 && completionPercentage < 100 && (
          <Fragment>
            <Typography
              variant={Typography.VARIANTS.HEADING_4}
              element="span"
              css={styles.progressTextBackground}
            >
              <FormattedMessage
                {...messages.PROGRESS}
                values={{
                  progress: (
                    <Typography
                      variant={Typography.VARIANTS.HEADING_1}
                      element="span"
                    >
                      {completionPercentage}
                    </Typography>
                  ),
                }}
              />
            </Typography>

            <Typography
              variant={Typography.VARIANTS.HEADING_4}
              element="span"
              css={styles.progressText}
            >
              <FormattedMessage
                {...messages.PROGRESS}
                values={{
                  progress: (
                    <Typography
                      variant={Typography.VARIANTS.HEADING_1}
                      element="span"
                    >
                      {completionPercentage}
                    </Typography>
                  ),
                }}
              />
            </Typography>
          </Fragment>
        )}
      </div>

      <div
        css={styles.subText}
        className={cn({ isDisabled })}
      >
        {isCompleted && (
          <CheckOne
            size={30}
            fill={[theme.colors.success[500], theme.colors.success[25]]}
          />
        )}

        <div>
          <div css={styles.tags}>
            <Tag label={<FormattedMessage {...lessonsMessages.LESSON} />} />
          </div>

          <Typography
            variant={Typography.VARIANTS.BODY_MEDIUM_BOLD}
            element="span"
          >
            {lesson.title}
          </Typography>

          <div css={styles.info}>
            {!!lesson?.sequencesCount && (
              <Typography
                variant={Typography.VARIANTS.BODY_SMALL_REGULAR}
                element="span"
              >
                <FormattedMessage
                  {...sequencesMessages.SEQUENCES_COUNT}
                  values={{ count: lesson.sequencesCount }}
                />
              </Typography>
            )}

            {/* When estimatedCompletionTime is implemented on lessons. */}
            {/* !!lesson?.estimatedCompletionTime && (
              <div css={styles.completionTime}>
                <Typography
                  variant={Typography.VARIANTS.BODY_SMALL_REGULAR}
                  element="span"
                >
                  <FormattedDuration
                    value={lesson.estimatedCompletionTime}
                  />
                </Typography>
              </div>
            ) */}
          </div>
        </div>
      </div>
    </div>
  );
};

LessonCardBase.displayName = 'LessonCard';

LessonCardBase.propTypes = {
  lesson: schoolObjectBasePropTypes.isRequired,
  thumbnail: uploadPropTypes,
  /** Completion of the lesson in %. */
  completionPercentage: PropTypes.number,
  /** Card can not be triggered. */
  isDisabled: PropTypes.bool,
};

export const LessonCard = memo(LessonCardBase);
