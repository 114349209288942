import PropTypes from 'prop-types';

import {
  LESSON_EDITION_STATUSES,
  LESSON_EXERCISE_STYLES,
  LESSON_SEQUENCE_TYPES,
} from '@eversity/domain/constants';

import { questionPropTypes } from './mcq';
import { contentOutlinePropTypes } from './common';
import { commonUserPropTypes } from './users';

export const exercisePropTypes = PropTypes.shape({
  /** Unique id. */
  id: PropTypes.string.isRequired,
  /** Key used to compare two exercises. Same key is considered the same exercise. */
  exerciseVersionId: PropTypes.string.isRequired,
  /** Exercise style. */
  style: PropTypes.oneOf(Object.values(LESSON_EXERCISE_STYLES)).isRequired,
  /** List of questions. */
  questions: PropTypes.arrayOf(questionPropTypes).isRequired,
});

export const lessonVersionSequenceViewMinimalPropTypes = PropTypes.shape({
  /** Unique id. */
  id: PropTypes.string.isRequired,
  /** Key used to match sequences between multiple versions of the same lesson. */
  sequenceVersionId: PropTypes.string.isRequired,
  /** Is the sequence numbered. */
  isNumbered: PropTypes.bool.isRequired,
  /** Sequence type. */
  type: PropTypes.oneOf(Object.values(LESSON_SEQUENCE_TYPES)).isRequired,
  /** Tree of H3/H4/H5 titles. */
  outline: contentOutlinePropTypes.isRequired,
});

export const lessonVersionSequenceViewFullPropTypes = PropTypes.shape({
  /** Unique id. */
  id: PropTypes.string.isRequired,
  /** Key used to match sequences between multiple versions of the same lesson. */
  sequenceVersionId: PropTypes.string.isRequired,
  /** Is the sequence numbered. */
  isNumbered: PropTypes.bool.isRequired,
  /** Sequence type. */
  type: PropTypes.oneOf(Object.values(LESSON_SEQUENCE_TYPES)).isRequired,
  /** Content as HTML string. */
  content: PropTypes.string.isRequired,
  /** Tree of H3/H4/H5 titles. */
  outline: contentOutlinePropTypes.isRequired,
  /** Sequence exercise. */
  exercise: exercisePropTypes,
});

export const lessonVersionViewFullPropTypes = PropTypes.shape({
  /** Unique id. */
  id: PropTypes.string.isRequired,
  /** List of sequences. */
  sequences: PropTypes.arrayOf(lessonVersionSequenceViewFullPropTypes)
    .isRequired,
  /** X.y version. */
  versionMajor: PropTypes.number.isRequired,
  /** x.Y version */
  versionMinor: PropTypes.number.isRequired,
});

export const lessonVersionWithSequencesViewMinimalPropTypes = PropTypes.shape({
  /** Unique id. */
  id: PropTypes.string.isRequired,
  /** List of sequences without the content. */
  sequences: PropTypes.arrayOf(lessonVersionSequenceViewMinimalPropTypes)
    .isRequired,
  /** X.y version. */
  versionMajor: PropTypes.number.isRequired,
  /** x.Y version */
  versionMinor: PropTypes.number.isRequired,
});

export const lessonVersionViewMinimalPropTypes = PropTypes.shape({
  /** Unique id. */
  id: PropTypes.string,
  /** Major number of version. */
  versionMajor: PropTypes.number.isRequired,
  /** Minor number of version. */
  versionMinor: PropTypes.number.isRequired,
});

export const lessonEditableVersionSequencePropTypes = PropTypes.shape({
  /** Unique id. */
  id: PropTypes.string.isRequired,
  /** Key used to match sequences between multiple versions of the same lesson. */
  sequenceVersionId: PropTypes.string.isRequired,
  /** Is the sequence numbered. */
  isNumbered: PropTypes.bool.isRequired,
  /** Sequence type. */
  type: PropTypes.oneOf(Object.values(LESSON_SEQUENCE_TYPES)).isRequired,
  /** Content as HTML string. */
  content: PropTypes.string.isRequired,
  /** Sequence exercise. */
  exercise: exercisePropTypes,
  /** Tree of H3/H4/H5 titles. */
  outline: contentOutlinePropTypes.isRequired,
  /** Id of the user who has a lock the sequence. */
  lockedBy: PropTypes.string,
  /** After this date, ignore lockedBy. */
  lockExpirationDate: PropTypes.string,
});

export const lessonEditableVersionViewInternalPropTypes = PropTypes.shape({
  /** Unique id. */
  id: PropTypes.string.isRequired,
  /** X.y version. */
  versionMajor: PropTypes.number.isRequired,
  /** x.Y version. */
  versionMinor: PropTypes.number.isRequired,
  /** List of allowed editor ids on the editable version. */
  editors: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** Edition status. */
  status: PropTypes.oneOf(Object.values(LESSON_EDITION_STATUSES)).isRequired,
});

export const lessonEditableVersionViewFullPropTypes = PropTypes.shape({
  /** Unique id. */
  id: PropTypes.string.isRequired,
  /** X.y version of the origin version (for comparisons). */
  fromVersionMajor: PropTypes.number,
  /** x.Y version of the origin version (for comparisons). */
  fromVersionMinor: PropTypes.number,
  /** X.y version of the editable version when it is published. */
  versionMajor: PropTypes.number.isRequired,
  /** x.Y version of the editable version when it is published. */
  versionMinor: PropTypes.number.isRequired,
  /** List of allowed editors on the editable version. */
  editors: PropTypes.arrayOf(commonUserPropTypes).isRequired,
  /** Edition status. */
  status: PropTypes.oneOf(Object.values(LESSON_EDITION_STATUSES)).isRequired,
  /** Sequences of the editable version. */
  sequences: PropTypes.arrayOf(lessonEditableVersionSequencePropTypes)
    .isRequired,
});

export const lessonViewMinimalPropTypes = PropTypes.shape({
  /** Unique id. */
  id: PropTypes.string.isRequired,
  /** Lesson title. */
  title: PropTypes.string.isRequired,
  /** Lesson code (unique). */
  code: PropTypes.string.isRequired,
  /** Lesson creation date. */
  createdAt: PropTypes.string.isRequired,
  /** Lesson last update. */
  updatedAt: PropTypes.string.isRequired,
});

export const lessonViewInternalPropTypes = PropTypes.shape({
  /** Unique id. */
  id: PropTypes.string.isRequired,
  /** Lesson title. */
  title: PropTypes.string.isRequired,
  /** Lesson code (unique). */
  code: PropTypes.string.isRequired,
  /** Metadata of versions. */
  versions: PropTypes.arrayOf(lessonVersionViewMinimalPropTypes).isRequired,
  /** Metadata of editable versions. */
  editableVersions: PropTypes.arrayOf(
    lessonEditableVersionViewInternalPropTypes,
  ).isRequired,
  /** Lesson creation date. */
  createdAt: PropTypes.string.isRequired,
  /** Lesson last update. */
  updatedAt: PropTypes.string.isRequired,
});
