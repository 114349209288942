/**
 * @description This method verifies that the date in parameter is later than the present time.
 * @param time
 * @returns boolean
 */
export const isDateInFuture = (time: Date) => {
  const currentDate = new Date();
  const selectedDate = new Date(time);

  return currentDate.getTime() < selectedDate.getTime();
};
