import { cloneElement, memo, ReactElement, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { css, useTheme } from '@emotion/react';
import { IconProps, toast } from 'react-toastify';
import { Attention, CheckOne, CloseOne, Info } from '@icon-park/react';

import { ICON_SIZES } from '../../general/icon/constants';
import { Typography } from '../../general/typography/Typography';

import * as styles from './Toast.styles';

const TOAST_TYPE_ICON_MAPPING = {
  [toast.TYPE.ERROR]: <CloseOne />,
  [toast.TYPE.INFO]: <Info />,
  [toast.TYPE.WARNING]: <Attention />,
  [toast.TYPE.SUCCESS]: <CheckOne />,
};

export type ToastProps = {
  icon?: ReactElement;
  toastProps?: IconProps;
  children?: ReactNode;
};

export const ToastBase = ({
  toastProps,
  icon = undefined,
  children = undefined,
}: ToastProps) => {
  const theme = useTheme();

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 10px;
      `}
    >
      {
        // Show a default icon if icon is undefined,
        // but don't show anything if icon is null or default icon does not exist.
        icon !== null &&
          (icon !== undefined || !!TOAST_TYPE_ICON_MAPPING[toastProps.type]) &&
          cloneElement(icon || TOAST_TYPE_ICON_MAPPING[toastProps.type], {
            size: ICON_SIZES.MEDIUM,
            fill: styles.getIconFills(theme, toastProps.type),
          })
      }

      <Typography
        variant={Typography.VARIANTS.BODY_SMALL_REGULAR}
        element="div"
        css={css`
          overflow-wrap: anywhere;
        `}
      >
        {children}
      </Typography>
    </div>
  );
};

ToastBase.displayName = 'Toast';

ToastBase.propTypes = {
  /** Toast icon. */
  icon: PropTypes.node,
  /** Toast text. */
  children: PropTypes.node,
  /** Injected automatically by react-toastify. */
  toastProps: PropTypes.shape({
    /** Type of toast (default, info, success, error, warning). */
    type: PropTypes.oneOf(Object.values(toast.TYPE)).isRequired,
  }).isRequired,
};

export const Toast = memo(ToastBase);
