import { ForwardedRef, forwardRef, memo, MemoExoticComponent } from 'react';
import { css } from '@emotion/react';
import { PreviewOpen, PreviewCloseOne } from '@icon-park/react';

import { useBoolState } from '@eversity/ui/utils';

import { Input, InputProps } from '../input/Input';

export type InputPasswordProps = InputProps;

export const InputPasswordBase = forwardRef(
  (props: InputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const [showPassword, onShowPassword, onHidePassword] = useBoolState(false);

    return (
      <Input
        {...props}
        ref={ref}
        type={showPassword ? 'text' : 'password'}
        iconRight={
          showPassword ? (
            <PreviewCloseOne
              onClick={onHidePassword}
              css={css`
                cursor: pointer;
              `}
            />
          ) : (
            <PreviewOpen
              onClick={onShowPassword}
              css={css`
                cursor: pointer;
              `}
            />
          )
        }
      />
    );
  },
);

InputPasswordBase.displayName = 'InputPassword';

InputPasswordBase.propTypes = {};

InputPasswordBase.defaultProps = {};

export const InputPassword: MemoExoticComponent<typeof InputPasswordBase> & {
  SIZES?: typeof Input.SIZES;
  MAX_LENGTH_SIZE_MAPPING?: typeof Input.MAX_LENGTH_SIZE_MAPPING;
} = memo(InputPasswordBase);

InputPassword.SIZES = Input.SIZES;
InputPassword.MAX_LENGTH_SIZE_MAPPING = Input.MAX_LENGTH_SIZE_MAPPING;
