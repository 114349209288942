import { defineMessages } from 'react-intl';

import {
  ASSIGNMENT_GRADING_TYPES,
  ASSIGNMENT_TYPES,
  ASSIGNMENT_CORRECTOR_ROLES,
  ASSIGNMENT_STYLES,
  MCQ_ANSWER_TYPES,
  ASSIGNMENT_TAGS,
} from '@eversity/domain/constants';

export const gradingValidationMessages = defineMessages({
  INVALID_GRADE: {
    defaultMessage: 'La note doit être comprise entre 0 et {maxGrade}.',
  },
} as const);

export const assignmentTagsMessages = defineMessages<ASSIGNMENT_TAGS>({
  [ASSIGNMENT_TAGS.CONTINUOUS_ASSESSMENT]: {
    defaultMessage: 'Contrôle continu',
  },
  [ASSIGNMENT_TAGS.PROFESSIONAL_FILE]: {
    defaultMessage: 'Dossier professionnel',
  },
} as const);

export const assignmentTypesMessages = defineMessages<ASSIGNMENT_TYPES>({
  [ASSIGNMENT_TYPES.EXAM]: {
    defaultMessage: "Devoir associé à une unité d'enseignement",
  },

  [ASSIGNMENT_TYPES.HOMEWORK]: {
    defaultMessage: 'Devoir associé à un cours',
  },
} as const);

export const assignmentTypesShortMessages = defineMessages<ASSIGNMENT_TYPES>({
  [ASSIGNMENT_TYPES.EXAM]: {
    defaultMessage: "Associé à une unité d'enseignement",
  },

  [ASSIGNMENT_TYPES.HOMEWORK]: {
    defaultMessage: 'Associé à un cours',
  },
} as const);

export const assignmentStylesMessages = defineMessages<ASSIGNMENT_STYLES>({
  [ASSIGNMENT_STYLES.FILE]: {
    defaultMessage: 'Fichier à rendre',
  },

  [ASSIGNMENT_STYLES.MCQ]: {
    defaultMessage: 'QCM',
  },
} as const);

export const gradingTypeMessages = defineMessages<ASSIGNMENT_GRADING_TYPES>({
  [ASSIGNMENT_GRADING_TYPES.ASSESSMENT]: {
    defaultMessage: 'Appréciation',
  },

  [ASSIGNMENT_GRADING_TYPES.GRADE]: {
    defaultMessage: 'Note',
  },
} as const);

export const gradingTypeStudentMessages =
  defineMessages<ASSIGNMENT_GRADING_TYPES>({
    [ASSIGNMENT_GRADING_TYPES.GRADE]: {
      defaultMessage: 'Note sur {gradingScale}',
    },

    [ASSIGNMENT_GRADING_TYPES.ASSESSMENT]: {
      defaultMessage: 'Appréciation',
    },
  } as const);

export const correctorMessages = defineMessages<ASSIGNMENT_CORRECTOR_ROLES>({
  [ASSIGNMENT_CORRECTOR_ROLES.MAIN]: {
    defaultMessage: 'Correcteur principal',
  },
  [ASSIGNMENT_CORRECTOR_ROLES.SECONDARY]: {
    defaultMessage: 'Correcteur secondaire',
  },
} as const);

export const gradeMessages = defineMessages({
  GRADE: {
    defaultMessage:
      '<Grade>{grade, number, ::.##}</Grade><GradingScale> / {gradingScale, number}</GradingScale>',
  },

  COEFFICIENT: {
    defaultMessage: 'Coef. {coefficient, number}',
  },

  GRADED_ASSIGNMENTS_COUNT: {
    defaultMessage:
      '<b>{count, number}</b>/{total, plural, one {# évaluation notée} other {# évaluations notées}}',
  },
} as const);

export const assignmentStatusMessages = defineMessages({
  IN_PROGRESS: {
    defaultMessage: 'En cours',
  },
  AWAITING_GRADE: {
    defaultMessage: 'En attente de notation',
  },
  AVAILABLE: {
    defaultMessage: 'Disponible',
  },
} as const);

export const academicTranscriptMessages = defineMessages({
  TITLE: { defaultMessage: "Relevé de notes et d'assiduité" },
  OVERALL_AVERAGE: { defaultMessage: 'Moyenne générale' },
} as const);

export const questionnaireMessages = defineMessages({
  [MCQ_ANSWER_TYPES.SINGLE]: {
    defaultMessage: 'Question à choix unique',
  },

  [MCQ_ANSWER_TYPES.MULTIPLE]: {
    defaultMessage: 'Question à choix multiple',
  },

  QUESTION_NUMBER: {
    defaultMessage: 'Question {questionNumber, number}',
  },

  QUESTION_POINTS: {
    defaultMessage: `{questionPoints, number} {questionPoints, plural,
      one {point}
      other {points}
    }`,
  },
} as const);

export const assignmentsMessages = defineMessages({
  AVAILABLE_IN: {
    defaultMessage: 'Devoir disponible dans :',
  },
} as const);
