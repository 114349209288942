import {
  cloneElement,
  ForwardedRef,
  forwardRef,
  HTMLProps,
  memo,
  MemoExoticComponent,
  ReactElement,
  ReactNode,
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { useTheme } from '@emotion/react';
import { useHover, useActive } from '@eversity/ui/utils';

import {
  BUTTON_HUES,
  BUTTON_ICON_POSITIONS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from './constants';
import { ICON_SIZES } from '../icon/constants';

import { Typography } from '../typography/Typography';

import { getIconFills, buttonStyle, badgeStyle } from './Button.styles';

const TYPOGRAPHY_SIZE_MAPPING = {
  [BUTTON_SIZES.SMALL]: Typography.VARIANTS.BUTTON_SMALL_REGULAR,
  [BUTTON_SIZES.MEDIUM]: Typography.VARIANTS.BUTTON_MEDIUM_REGULAR,
  [BUTTON_SIZES.LARGE]: Typography.VARIANTS.BUTTON_LARGE_REGULAR,
};

const ICON_SIZE_MAPPING = {
  [BUTTON_SIZES.SMALL]: ICON_SIZES.SMALL,
  [BUTTON_SIZES.MEDIUM]: ICON_SIZES.MEDIUM,
  [BUTTON_SIZES.LARGE]: ICON_SIZES.LARGE,
};

export type ButtonProps = Omit<HTMLProps<HTMLButtonElement>, 'size' | 'ref'> & {
  type?: 'button' | 'submit' | 'reset';
  variant?: BUTTON_VARIANTS;
  hue?: BUTTON_HUES;
  size?: BUTTON_SIZES;
  outline?: boolean;
  isActive?: boolean;
  icon?: ReactElement;
  iconPosition?: BUTTON_ICON_POSITIONS;
  badge?: ReactElement;
  children?: ReactNode;
};

export const ButtonBase = forwardRef(
  (
    {
      variant,
      hue,
      size,
      outline,
      isActive: forceActive,
      icon,
      iconPosition,
      badge,
      className,
      children,
      ...props
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const theme = useTheme();

    const { isHovered, ...hoverProps } = useHover(props);
    const { isActive, ...activeProps } = useActive(props);

    const isActuallyActive = isActive || forceActive;

    const onlyIcon = !!icon && !children;

    const iconComponent =
      !!icon &&
      cloneElement(icon, {
        style: {
          marginRight:
            onlyIcon || iconPosition === BUTTON_ICON_POSITIONS.RIGHT ? 0 : 8,
          marginLeft:
            onlyIcon || iconPosition === BUTTON_ICON_POSITIONS.LEFT ? 0 : 8,
        },
        size: ICON_SIZE_MAPPING[size],
        fill: getIconFills[hue || variant](
          theme,
          outline,
          isActuallyActive,
          isHovered,
          props.disabled,
        ),
      });

    return (
      // eslint-disable-next-line react/button-has-type -- Type is passed as props.
      <button
        {...props}
        {...hoverProps}
        {...activeProps}
        ref={ref}
        css={buttonStyle}
        className={cn(className, size, variant, hue, iconPosition, {
          outline,
          onlyIcon,
          isActive: isActuallyActive,
        })}
      >
        {!!icon && iconPosition === BUTTON_ICON_POSITIONS.LEFT && iconComponent}

        {!!children && (
          <Typography variant={TYPOGRAPHY_SIZE_MAPPING[size]}>
            {children}
          </Typography>
        )}

        {!!icon &&
          iconPosition === BUTTON_ICON_POSITIONS.RIGHT &&
          iconComponent}

        {!!badge && <div css={badgeStyle}>{badge}</div>}
      </button>
    );
  },
);

ButtonBase.displayName = 'Button';

ButtonBase.propTypes = {
  /** Button HTML type. HTML default is "submit" but we override it to default to "button". */
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  /** Button color variant. */
  variant: PropTypes.oneOf(Object.values(BUTTON_VARIANTS)),
  /** Button color variant independent from theme. Do not use variant and hue at the same time. */
  hue: PropTypes.oneOf(Object.values(BUTTON_HUES)),
  /** Button size. */
  size: PropTypes.oneOf(Object.values(BUTTON_SIZES)),
  /** Show a border instead of a filled background. */
  outline: PropTypes.bool,
  /** Icon from IconPark. */
  icon: PropTypes.element,
  /** Icon position (left or right). */
  iconPosition: PropTypes.oneOf(Object.values(BUTTON_ICON_POSITIONS)),
  /** Badge if necessary. */
  badge: PropTypes.element,
  /** Is the button disabled. */
  disabled: PropTypes.bool,
  /** Style applied to the component. */
  className: PropTypes.string,
  /** Force active state. Does not work if disabled. */
  isActive: PropTypes.bool,
};

ButtonBase.defaultProps = {
  type: 'button',
  variant: BUTTON_VARIANTS.NEUTRAL,
  hue: null,
  size: BUTTON_SIZES.MEDIUM,
  outline: false,
  icon: null,
  iconPosition: BUTTON_ICON_POSITIONS.LEFT,
  badge: null,
  disabled: false,
  className: null,
  isActive: false,
  children: null,
};

export const Button: MemoExoticComponent<typeof ButtonBase> & {
  VARIANTS?: typeof BUTTON_VARIANTS;
} & { HUES?: typeof BUTTON_HUES } & { SIZES?: typeof BUTTON_SIZES } & {
  ICON_POSITIONS?: typeof BUTTON_ICON_POSITIONS;
} = memo(ButtonBase);

Button.VARIANTS = BUTTON_VARIANTS;
Button.HUES = BUTTON_HUES;
Button.SIZES = BUTTON_SIZES;
Button.ICON_POSITIONS = BUTTON_ICON_POSITIONS;

export { BUTTON_VARIANTS, BUTTON_HUES, BUTTON_SIZES, BUTTON_ICON_POSITIONS };
