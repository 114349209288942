import { css, Theme } from '@emotion/react';

export const cardItem = css`
  display: flex;
  flex-direction: column;
`;

export const card = css`
  margin-top: 10px;
`;

export const assessment = css`
  padding: 10px 20px;
`;
export const assessmentCardBody = css`
  display: flex;
  flex-direction: column;
  gap: 10px;

  padding: 20px;
`;

export const coloredTextSuccess = (theme: Theme) => css`
  color: ${theme.colors.success[500]};
`;
