import { VersionObjectBase } from '@eversity/types/domain';

/**
 * Given the list of versions of a lesson, return the list of major version with their latest minor.
 * List is sorted by major version DESC.
 *
 * @param versions - Versions.
 * @returns Major versions.
 */
export const listMajorVersions = <T extends VersionObjectBase>(
  versions: T[],
): T[] =>
  Object.values(
    versions.reduce<{ [key: number]: T }>((acc, version) => {
      const currentMajorVersion = acc[version.versionMajor];

      if (
        !currentMajorVersion ||
        currentMajorVersion.versionMinor < version.versionMinor
      ) {
        acc[version.versionMajor] = version;
      }

      return acc;
    }, {}),
  ).sort((a, b) => b.versionMajor - a.versionMajor);
