import { memo, Fragment, useMemo, HTMLProps } from 'react';
import PropTypes from 'prop-types';
import { head, last, noop } from 'lodash';

import { TabKey, TabShape } from './types';
import { tabKeyPropTypes, tabPropTypes } from '../../../types';

import { TabGroup } from './TabGroup';
import { Separator } from './Separator';

import * as styles from './Tabs.styles';

export type TabsProps<TTabKey extends TabKey = TabKey> = Omit<
  HTMLProps<HTMLDivElement>,
  'ref' | 'onChange' | 'value'
> & {
  value: TTabKey | null;
  onChange: (key: TTabKey) => void;
  onChangeOrder?: (newOrder: TabShape<TTabKey>[], index: number) => void;
  vertical?: boolean;
  tabs: TabShape<TTabKey>[] | TabShape<TTabKey>[][];
  isDraggable?: boolean;
  isDragDisabled?: boolean;
};

const DEFAULT_TABS = [];

export const TabsBase = <TTabKey extends TabKey = TabKey>({
  value = null,
  onChange = noop,
  onChangeOrder = noop,
  vertical = false,
  tabs = DEFAULT_TABS,
  isDraggable = false,
  isDragDisabled = false,
  ...props
}: TabsProps<TTabKey>) => {
  const tabsGroups = useMemo(
    () =>
      (tabs.length > 0 && !Array.isArray(tabs[0])
        ? [tabs as TabShape<TTabKey>[]]
        : (tabs as TabShape<TTabKey>[][])
      ).filter((tabGroup) => !!tabGroup.length),
    [tabs],
  );

  return (
    <div
      {...props}
      css={vertical ? styles.verticalContainer : styles.container}
    >
      {tabsGroups.map((groupTabs, index) => (
        <Fragment key={groupTabs[0].key}>
          <TabGroup<TTabKey>
            tabs={groupTabs}
            index={index}
            tabGroupsCount={tabsGroups.length}
            value={value}
            onChange={onChange}
            onChangeOrder={onChangeOrder}
            isDraggable={isDraggable}
            isDragDisabled={isDragDisabled}
            vertical={vertical}
          />

          {!vertical && index < tabsGroups.length - 1 && (
            <Separator
              isLeftActive={last(groupTabs).key === value}
              isRightActive={head(tabsGroups[index + 1]).key === value}
            />
          )}
        </Fragment>
      ))}
    </div>
  );
};

TabsBase.displayName = 'Tabs';

TabsBase.propTypes = {
  /** Currently active tab key. */
  value: tabKeyPropTypes,
  /** On change handler, called with the new active tab key. */
  onChange: PropTypes.func,
  /** On drag end change handler, called with the new tabs order. */
  onChangeOrder: PropTypes.func,
  /** Should display tabs vertically. */
  vertical: PropTypes.bool,
  /** Tabs or tabs groups. */
  tabs: PropTypes.oneOfType([
    /** Either pass all tabs directly (they will be considered part of a single group). */
    PropTypes.arrayOf(tabPropTypes),
    /** Or pass tabs divided by groups. Empty groups are ignored. */
    PropTypes.arrayOf(PropTypes.arrayOf(tabPropTypes)),
  ]),
  isDraggable: PropTypes.bool,
  isDragDisabled: PropTypes.bool,
};

export const Tabs = memo(TabsBase) as unknown as typeof TabsBase;
