import { createContext, memo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Pause, PlayOne } from '@icon-park/react';
import { noop } from 'lodash';

import { useBoolState, useMemoizedBundle } from '@eversity/ui/utils';
import { Button } from '@eversity/ui/design-system';

export type AudioResourceItemContextValue = {
  isAudioPlaying: boolean;
  onAudioPlaying: () => void;
  onAudioPaused: () => void;
  onAudioTogglePlayback: () => void;
};

export const AudioResourceItemContext =
  createContext<AudioResourceItemContextValue>({
    isAudioPlaying: false,
    onAudioPlaying: noop,
    onAudioPaused: noop,
    onAudioTogglePlayback: noop,
  });

export type AudioResourceItemContextProviderProps = {
  audioHref: string;
};

export const AudioResourceItemContextProviderBase = ({
  audioHref,
}: AudioResourceItemContextProviderProps) => {
  const audioRef = useRef<HTMLAudioElement>(null);

  const [isAudioPlaying, onAudioPlaying, onAudioPaused] = useBoolState(false);

  const onAudioTogglePlayback = useCallback(() => {
    if (isAudioPlaying) {
      audioRef.current?.pause();
    } else {
      audioRef.current?.play();
    }
  }, [isAudioPlaying]);

  const contextValue = useMemoizedBundle({
    isAudioPlaying,
    onAudioPlaying,
    onAudioPaused,
    onAudioTogglePlayback,
  });

  return (
    <AudioResourceItemContext.Provider value={contextValue}>
      {/* // Can't generate a caption track... */}
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio
        hidden
        ref={audioRef}
        onPlay={onAudioPlaying}
        onPause={onAudioPaused}
      >
        <source
          src={audioHref}
          type="audio/mpeg"
        />
      </audio>

      <Button
        size={Button.SIZES.LARGE}
        outline
        icon={isAudioPlaying ? <Pause /> : <PlayOne />}
        onClick={onAudioTogglePlayback}
      />
    </AudioResourceItemContext.Provider>
  );
};

AudioResourceItemContextProviderBase.displayName =
  'AudioResourceItemContextProvider';

AudioResourceItemContextProviderBase.propTypes = {
  audioHref: PropTypes.string.isRequired,
};

export default memo(AudioResourceItemContextProviderBase);
