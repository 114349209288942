import { orderBy } from 'lodash';
import { SORT } from '@eversity/domain/constants';

const SORT_MAPPING: Record<SORT, boolean | 'asc' | 'desc'> = {
  [SORT.ASC]: 'asc',
  [SORT.DESC]: 'desc',
};

/**
 * Parse a string to pick to key to sort an array on and the direction.
 *
 * @param sort - Key to sort on with the direction.
 * @returns Sort parameters.
 */
export const parseSortParameters = (
  sort: string,
): { sortBy: string; sortDirection: SORT } => {
  let sortDirection = null;

  if (sort?.indexOf('-') === 0) {
    sortDirection = SORT.DESC;
  } else if (sort?.indexOf('+') === 0) {
    sortDirection = SORT.ASC;
  }

  const sortBy = sortDirection ? sort?.substring(1, sort.length) : sort;

  return {
    sortBy,
    sortDirection: sortDirection || SORT.ASC,
  };
};

/**
 * Perform a shallow copy of the data and returns them sorted.
 *
 * @param data - Collection of object to sort.
 * @param sort - Key to sort the collection on, prefixed with sort direction.
 * @returns Sorted collection.
 */
export const sortData = <T extends {}>(data: T[], sort: string): T[] => {
  if (sort) {
    const { sortBy, sortDirection } = parseSortParameters(sort);

    return orderBy(data, [sortBy], [SORT_MAPPING[sortDirection]]) as T[];
  }
  return data;
};
