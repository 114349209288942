import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { Button, ButtonProps } from '../../../general/button/Button';

type PaginationPageButtonProps = Omit<ButtonProps, 'onClick'> & {
  page: number;
  onClick?: (page: number) => void;
  isActive?: boolean;
};

export const PaginationPageButtonBase = ({
  page,
  onClick = noop,
  isActive = false,
  ...props
}: PaginationPageButtonProps) => {
  const onClickProxy = useCallback(() => onClick(page), [onClick, page]);

  return (
    <Button
      {...props}
      size={Button.SIZES.SMALL}
      onClick={onClickProxy}
      outline={!isActive}
      variant={isActive ? Button.VARIANTS.PRIMARY : Button.VARIANTS.NEUTRAL}
    >
      {`${page + 1}`}
    </Button>
  );
};

PaginationPageButtonBase.displayName = 'PaginationPageButton';

PaginationPageButtonBase.propTypes = {
  page: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
};

export const PaginationPageButton = memo(PaginationPageButtonBase);
