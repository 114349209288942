import { memo } from 'react';
import { css, Global } from '@emotion/react';
import { TinyColor } from '@ctrl/tinycolor';

export const ToastGlobalStylesBase = () => (
  <Global
    styles={(theme) => css`
      /**
       * Wrap everything inside body so we have a higher priority than react-toastify's css rules.
       */
      body {
        .Toastify__toast {
          min-height: 60px;
          padding: 10px;
          border-radius: 4px;

          gap: 10px;

          box-shadow:
            0px 1px 2px
              ${new TinyColor(theme.colors.gray[900])
                .setAlpha(0.1)
                .toRgbString()},
            0px 0px 5px 1px
              ${new TinyColor(theme.colors.gray[900])
                .setAlpha(0.1)
                .toRgbString()};
        }

        .Toastify__toast-body {
          margin: 0;
          padding: 0;
          display: flex;
          align-items: center;
        }

        .Toastify__progress-bar {
          height: 3px;
        }

        .Toastify__toast-theme--colored.Toastify__toast--default {
          color: ${theme.colors.gray[700]};
          background-color: ${theme.colors.gray[0]};
        }

        .Toastify__progress-bar-theme--colored.Toastify__progress-bar--default {
          background: linear-gradient(
            to right,
            ${theme.colors.primary[500]},
            ${theme.colors.secondary[500]}
          );
        }

        .Toastify__toast-theme--colored.Toastify__toast--info {
          color: ${theme.colors.gray[0]};
          background-color: ${theme.colors.primary[500]};
        }

        .Toastify__progress-bar-theme--colored.Toastify__progress-bar--info {
          background-color: ${theme.colors.primary[100]};
        }

        .Toastify__toast-theme--colored.Toastify__toast--error {
          color: ${theme.colors.gray[0]};
          background-color: ${theme.colors.danger[500]};
        }

        .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
          background-color: ${theme.colors.danger[100]};
        }

        .Toastify__toast-theme--colored.Toastify__toast--warning {
          color: ${theme.colors.gray[0]};
          background-color: ${theme.colors.warning[500]};
        }

        .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning {
          background-color: ${theme.colors.warning[100]};
        }

        .Toastify__toast-theme--colored.Toastify__toast--success {
          color: ${theme.colors.gray[0]};
          background-color: ${theme.colors.success[500]};
        }

        .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success {
          background-color: ${theme.colors.success[100]};
        }
      }
    `}
  />
);

ToastGlobalStylesBase.displayName = 'ToastGlobalStyles';

ToastGlobalStylesBase.propTypes = {};

export const ToastGlobalStyles = memo(ToastGlobalStylesBase);
