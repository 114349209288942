import { css, Theme } from '@emotion/react';
import { TinyColor } from '@ctrl/tinycolor';

export const box = (theme: Theme) => css`
  flex: none;
  width: 16px;
  height: 16px;
  border: 1px solid ${theme.colors.gray[200]};
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: ${theme.transitions.default()};

  background-color: ${theme.colors.gray[0]};

  &.isChecked {
    background-color: ${theme.colors.primary[500]};
    border-color: ${theme.colors.primary[500]};
  }

  &.isFocused {
    border-color: ${theme.colors.primary[500]};
    box-shadow: 0 0 0 4px
      ${new TinyColor(theme.colors.primary[500]).setAlpha(0.15).toRgbString()};
  }

  &.isDisabled {
    background-color: ${theme.colors.gray[25]};
    border-color: ${theme.colors.gray[200]};
    cursor: not-allowed;
  }
`;
