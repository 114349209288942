import { memo, useMemo, ReactNode } from 'react';
import RcTable, { Column } from 'rc-table';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { css } from '@emotion/react';

import styles from './Table.styles';
import messages from './Table.messages';

import { TABLE_VARIANTS } from './constants';
import { TYPOGRAPHY_VARIANTS } from '../../../config/typography/constants';
import { useToggleSort } from '../../../utils/hooks/useToggleSort';

import { ImpulseSpinner } from '../../general/loading/impulse-spinner/ImpulseSpinner';
import { HeaderCell } from './header-cell/HeaderCell';

const getEmptyText = (
  loading: boolean,
  error?: ReactNode,
  emptyText?: ReactNode | (() => ReactNode),
) => {
  if (error) {
    return <span css={styles.error}>{error}</span>;
  }

  if (loading) {
    return (
      <div
        css={(theme) => css`
          min-height: ${theme.typography[
            TYPOGRAPHY_VARIANTS.BODY_MEDIUM_REGULAR
          ].lineHeight};
          display: flex;
          align-items: center;
        `}
      >
        <ImpulseSpinner size={ImpulseSpinner.SIZES.SMALL} />
      </div>
    );
  }

  return emptyText;
};

// rc-table does not expose TableProps and ColumnType.
type RcTableProps<RecordType> = Parameters<typeof RcTable<RecordType>>[0];

// Add custom props to our own ColumnType.
type RcColumnType<RecordType> = Omit<
  Parameters<typeof Column<RecordType>>[0],
  'children'
> & {
  sortable?: boolean;
};

export type TableProps<RecordType> = Omit<
  RcTableProps<RecordType>,
  'columns'
> & {
  columns?: RcColumnType<RecordType>[];
  isLoading?: boolean;
  error?: ReactNode;
  currentSort?: string;
  setCurrentSort?: (sort: string) => void;
  variant?: TABLE_VARIANTS;
};

const DEFAULT_COLUMNS = [];
const DEFAULT_DATA = [];

export const TableBase = <RecordType = unknown,>({
  className = null,
  columns = DEFAULT_COLUMNS,
  data = DEFAULT_DATA,
  isLoading = false,
  error = null,
  emptyText = <FormattedMessage {...messages.NO_DATA} />,
  currentSort = null,
  setCurrentSort = noop,
  variant = TABLE_VARIANTS.DARK,
  ...props
}: TableProps<RecordType>) => {
  const toggleSort = useToggleSort(currentSort, setCurrentSort);

  const enhancedColumns = useMemo(
    () =>
      columns.map((col) => ({
        ...col,
        title: (
          <HeaderCell
            name={`${col.key}`}
            title={col.title}
            toggleSort={toggleSort}
            sortable={col.sortable}
            sort={currentSort}
            align={col.align}
          />
        ),
      })),
    [columns, currentSort, toggleSort],
  );

  return (
    <RcTable
      {...props}
      columns={enhancedColumns}
      data={isLoading || error ? null : data}
      emptyText={getEmptyText(isLoading, error, emptyText)}
      className={cn(className, variant)}
      css={styles.table}
    />
  );
};

TableBase.displayName = 'Table';

TableBase.propTypes = {
  /** Root element class name. */
  className: PropTypes.string,
  /** Definition of columns. */
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  /** Data. Shapes depends on the columns definition. */
  data: PropTypes.arrayOf(PropTypes.shape({})),
  /** Display a loader. */
  isLoading: PropTypes.bool,
  /** Error message. */
  error: PropTypes.node,
  /** Text to display when there is no data. */
  emptyText: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  /** Current information regarding the sorting of the table. */
  currentSort: PropTypes.string,
  /** Function for mutating the sorting of the table. */
  setCurrentSort: PropTypes.func,
  /** Theme variant. */
  variant: PropTypes.oneOf(Object.values(TABLE_VARIANTS)),
};

// Cast as typeof TableBase to keep the generic type on Table.
export const Table = memo(TableBase) as unknown as typeof TableBase & {
  VARIANTS?: typeof TABLE_VARIANTS;
};

Table.VARIANTS = TABLE_VARIANTS;
