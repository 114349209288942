import { ForwardedRef, forwardRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Tippy, { TippyProps } from '@tippyjs/react';

import { useBoolState } from '@eversity/ui/utils';

const DEFAULT_PLUGINS = [];

export type WithLazyTippyProps = Omit<TippyProps, 'ref'>;

// A tippy component that mounts its content only when visible.
// This is useful if there are state initialization in the tippy content.
export const withLazyTippy = (WrappedTippy: typeof Tippy) => {
  const WithLazyTippy = forwardRef(
    (
      {
        plugins = DEFAULT_PLUGINS,
        render = undefined,
        content = undefined,
        ...props
      }: WithLazyTippyProps,
      ref: ForwardedRef<HTMLElement>,
    ) => {
      const computedProps: WithLazyTippyProps = { ...props };

      const [isMounted, onMount, onUnmount] = useBoolState();

      const lazyPlugin = useMemo(
        () => ({
          fn: () => ({
            onShow: onMount,
            onHidden: onUnmount,
          }),
        }),
        [onMount, onUnmount],
      );

      computedProps.plugins = useMemo(
        () => [lazyPlugin, ...(plugins || [])],
        [lazyPlugin, plugins],
      );

      const renderIfMounted = useCallback(
        (...args: Parameters<WithLazyTippyProps['render']>) =>
          isMounted ? render(...args) : '',
        [isMounted, render],
      );

      if (render) {
        computedProps.render = renderIfMounted;
      } else {
        computedProps.content = isMounted ? content : '';
      }

      return (
        <WrappedTippy
          {...computedProps}
          ref={ref}
        />
      );
    },
  );

  WithLazyTippy.displayName = 'WithLazyTippy';

  WithLazyTippy.propTypes = {
    /** Tippy plugins. Couldn't match shape with vendor type, feel free to refactor. */
    // eslint-disable-next-line react/forbid-prop-types
    plugins: PropTypes.arrayOf(PropTypes.any),
    /** Alternative to content. Returns the tippy content. */
    render: PropTypes.func,
    /** Alternative to render. Content of the tippy. */
    content: PropTypes.element,
  };

  WithLazyTippy.defaultProps = {
    plugins: [],
    render: null,
    content: '',
  };

  return WithLazyTippy;
};
