import { Fragment, memo, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';

import { UseWizard } from '../../../hooks/useWizard';

export type WizardStepChildren =
  | ((params: {
      onGoToNextStep: () => void;
      onGoToPreviousStep: () => void;
      isNextStepDisabled: boolean;
      isPreviousStepDisabled: boolean;
    }) => ReactNode)
  | ReactNode;

export type WizardStepProps = UseWizard[0] & {
  step: string | string[];
  children?: WizardStepChildren;
};

export const WizardStepBase = ({
  step,
  onGoToNextStep,
  onGoToPreviousStep,
  isCurrentStep,
  isNextStepDisabled,
  isPreviousStepDisabled,
  children = null,
}: WizardStepProps) => (
  // Need to use jsx otherwise storybook/eslint does not see that this a react component.
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <Fragment>
    {isCurrentStep(step) &&
      (isFunction(children)
        ? children({
            onGoToNextStep,
            onGoToPreviousStep,
            isNextStepDisabled,
            isPreviousStepDisabled,
          })
        : children)}
  </Fragment>
);

WizardStepBase.displayName = 'WizardStep';

WizardStepBase.propTypes = {
  /** Name of the step. */
  step: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  /**
   * Children rendered if the step is the current step.
   * If children is a function, it is called with callbacks and disabled state for steps buttons.
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /** From useWizard. */
  isCurrentStep: PropTypes.func.isRequired,
  /** From useWizard. */
  onGoToNextStep: PropTypes.func.isRequired,
  /** From useWizard. */
  onGoToPreviousStep: PropTypes.func.isRequired,
  /** From useWizard. */
  isNextStepDisabled: PropTypes.bool.isRequired,
  /** From useWizard. */
  isPreviousStepDisabled: PropTypes.bool.isRequired,
};

export const WizardStep = memo(WizardStepBase);
