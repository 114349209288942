import { cloneElement, memo, ReactElement } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { Picture } from '@icon-park/react';

import * as styles from './CardCover.styles';

export type CardCoverProps = {
  imageSrc?: string;
  icon?: ReactElement;
  height?: number;
};

export const CardCoverBase = ({
  imageSrc = null,
  icon = <Picture />,
  height = 150,
}: CardCoverProps) => {
  const theme = useTheme();

  return (
    <div css={styles.cover(theme, height)}>
      {imageSrc ? (
        <div css={styles.image(imageSrc)} />
      ) : (
        <div css={styles.placeholder}>
          <div css={styles.placeholderImage}>
            {cloneElement(icon, {
              size: 50,
              fill: [theme.colors.primary[200], theme.colors.primary[50]],
            })}
          </div>
        </div>
      )}
    </div>
  );
};

CardCoverBase.displayName = 'CardCover';

CardCoverBase.propTypes = {
  /** Image src. If not provided, will use the icon. */
  imageSrc: PropTypes.string,
  /** Icon to display if no image is provided. */
  icon: PropTypes.node,
  /** Cover height in px. */
  height: PropTypes.number,
};

export const CardCover = memo(CardCoverBase);
