import { marked } from 'marked';
import { httpRegexp } from '../http/constants';
/**
 * Get the first letter of a set of labels.
 *
 * @param labels - All the labels passed as parameters.
 * @returns The first letter of all the labels.
 */
export const getInitials = (...labels: string[]) =>
  labels
    .map((label) => label.charAt(0))
    .join('')
    .toUpperCase();

/**
 * Check if a string is filled with empty spaces.
 *
 * @param str - Input.
 * @returns True if no string or only empty spaces.
 */
export const isEmptyString = (str?: string) => !str?.trim();

/**
 * Remove diacritics from a string (é, è, ê -> e, ç -> c, etc).
 *
 * TODO (927): make str required when everything is in TS.
 *
 * @param str - Input string.
 * @returns String without diacritics.
 */
export const removeDiacritics = (str?: string): string | undefined =>
  str?.normalize('NFD').replace(/\p{Diacritic}/gu, '');

/**
 * Replace string formatting with html formatting.
 *
 * @param description - Event description.
 * @returns
 */
export const formatStringAsHtml = (description: string) =>
  description
    .replace(
      httpRegexp,
      '<a target="_blank" rel="noopener noreferrer" href="$&">$&</a>',
    )
    .split('\n')
    .map((p) => `<p>${p}</p>`)
    .join('');

/**
 * Remove references from a string.
 *
 * @param str - Input string.
 * @returns String without references.
 */
export const removeReferencesFromString = (str: string) =>
  str.replace(/\s*\[\s*\d+\s*\]\s*/g, ' ');

/**
 * Replace markdown tags with html tags.
 *
 * @param str - Input markdown string.
 * @returns - HTML string.
 */
export const replaceMarkdownWithHtml = (str?: string) => {
  if (!str) return '';

  const multilineMarkdown = str.replace(/(?<!\*)\*(?=\s)/g, `\n* `);
  const html = marked(multilineMarkdown) as string;

  // remove last \n
  return html.replace(/\n+$/g, '');
};
