import {
  memo,
  MouseEventHandler,
  MemoExoticComponent,
  ReactNode,
  useMemo,
} from 'react';
import PropTypes, { ReactComponentLike } from 'prop-types';
import { noop } from 'lodash';
import { css, useTheme } from '@emotion/react';
import { TinyColor } from '@ctrl/tinycolor';
import { FormattedMessage } from 'react-intl';
import { Attention, CheckOne, CloseOne, Info } from '@icon-park/react';

import { globalMessages } from '@eversity/ui/intl';

import { VARIANTS as CARD_VARIANTS } from '../../data-display/card/constants';
import { BUTTON_VARIANTS } from '../../general/button/constants';
import { ALERT_DIALOG_VARIANTS } from './constants';

import { Dialog, DialogProps } from '../dialog/Dialog';

const ALERT_DIALOG_VARIANT_MAPPING: Record<
  ALERT_DIALOG_VARIANTS,
  CARD_VARIANTS
> = {
  [ALERT_DIALOG_VARIANTS.INFO]: Dialog.VARIANTS.PRIMARY,
  [ALERT_DIALOG_VARIANTS.DANGER]: Dialog.VARIANTS.DANGER,
  [ALERT_DIALOG_VARIANTS.WARNING]: Dialog.VARIANTS.WARNING,
  [ALERT_DIALOG_VARIANTS.SUCCESS]: Dialog.VARIANTS.SUCCESS,
};

const ALERT_DIALOG_BUTTON_VARIANT_MAPPING: Record<
  ALERT_DIALOG_VARIANTS,
  BUTTON_VARIANTS
> = {
  [ALERT_DIALOG_VARIANTS.INFO]: Dialog.Action.VARIANTS.PRIMARY,
  [ALERT_DIALOG_VARIANTS.DANGER]: Dialog.Action.VARIANTS.DANGER,
  [ALERT_DIALOG_VARIANTS.WARNING]: Dialog.Action.VARIANTS.WARNING,
  [ALERT_DIALOG_VARIANTS.SUCCESS]: Dialog.Action.VARIANTS.SUCCESS,
};

const ALERT_DIALOG_ICON_MAPPING: Record<
  ALERT_DIALOG_VARIANTS,
  ReactComponentLike
> = {
  [ALERT_DIALOG_VARIANTS.INFO]: Info,
  [ALERT_DIALOG_VARIANTS.DANGER]: CloseOne,
  [ALERT_DIALOG_VARIANTS.WARNING]: Attention,
  [ALERT_DIALOG_VARIANTS.SUCCESS]: CheckOne,
};

export type AlertDialogProps = Omit<DialogProps, 'variant'> & {
  variant?: ALERT_DIALOG_VARIANTS;
  onClickConfirm?: MouseEventHandler<HTMLButtonElement>;
  onClickCancel?: MouseEventHandler<HTMLButtonElement>;
  cancelText?: ReactNode;
  confirmText?: ReactNode;
};

const DEFAULT_CANCEL_TEXT = <FormattedMessage {...globalMessages.CANCEL} />;
const DEFAULT_CONFIRM_TEXT = <FormattedMessage {...globalMessages.CONFIRM} />;

export const AlertDialogBase = ({
  variant = ALERT_DIALOG_VARIANTS.INFO,
  children = null,
  onClickConfirm = noop,
  onClickCancel = null,
  onRequestClose = noop,
  disabled = false,
  cancelText = DEFAULT_CANCEL_TEXT,
  confirmText = DEFAULT_CONFIRM_TEXT,
  size = Dialog.SIZES.SMALL,
  ...props
}: AlertDialogProps) => {
  const theme = useTheme();

  const ALERT_DIALOG_VARIANT_COLOR_MAPPING = useMemo(
    () => ({
      [ALERT_DIALOG_VARIANTS.INFO]: theme.colors.primary[500],
      [ALERT_DIALOG_VARIANTS.DANGER]: theme.colors.danger[500],
      [ALERT_DIALOG_VARIANTS.WARNING]: theme.colors.warning[500],
      [ALERT_DIALOG_VARIANTS.SUCCESS]: theme.colors.success[500],
    }),
    [theme],
  );

  const ALERT_DIALOG_ICON_FILL_MAPPING = useMemo(
    () => ({
      [ALERT_DIALOG_VARIANTS.INFO]: [
        theme.colors.primary[500],
        theme.colors.gray[0],
      ],
      [ALERT_DIALOG_VARIANTS.DANGER]: [
        theme.colors.danger[500],
        theme.colors.gray[0],
      ],
      [ALERT_DIALOG_VARIANTS.WARNING]: [
        theme.colors.warning[500],
        theme.colors.gray[0],
      ],
      [ALERT_DIALOG_VARIANTS.SUCCESS]: [
        theme.colors.success[500],
        theme.colors.gray[0],
      ],
    }),
    [theme],
  );

  const IconComponent = ALERT_DIALOG_ICON_MAPPING[variant];

  const color = ALERT_DIALOG_VARIANT_COLOR_MAPPING[variant];

  return (
    <Dialog
      {...props}
      size={size}
      color={color}
      onRequestClose={onRequestClose}
      variant={ALERT_DIALOG_VARIANT_MAPPING[variant]}
    >
      <Dialog.Body>
        <div
          css={css`
            display: flex;
            align-items: flex-start;
          `}
        >
          {/**
           * These divs display the icon. We need to add a box-shadow to the icon, but the viewbox
           * is larger than its content, so we display an empty div with the box-shadow, and render
           * the icon inside with absolute positioning to make it work visually.
           * Feel free to refactor if you have a better solution.
           */}
          <div
            css={css`
              position: relative;
            `}
          >
            <div
              css={css`
                width: 20px;
                height: 20px;
                border-radius: 20px;
                box-shadow: 0 0 0 4px
                  ${new TinyColor(color).setAlpha(0.15).toRgbString()};
              `}
            />

            <div
              css={css`
                position: absolute;
                top: -2px;
                left: -2px;
              `}
            >
              <IconComponent
                size={24}
                fill={ALERT_DIALOG_ICON_FILL_MAPPING[variant]}
              />
            </div>
          </div>
          <div
            css={css`
              margin-left: 20px;
            `}
          >
            {children}
          </div>
        </div>
      </Dialog.Body>
      <Dialog.Actions variant={ALERT_DIALOG_VARIANT_MAPPING[variant]}>
        <Dialog.Action
          onClick={onClickCancel || onRequestClose}
          disabled={disabled}
        >
          {cancelText}
        </Dialog.Action>

        <Dialog.Action
          onClick={onClickConfirm}
          disabled={disabled}
          variant={ALERT_DIALOG_BUTTON_VARIANT_MAPPING[variant]}
        >
          {confirmText}
        </Dialog.Action>
      </Dialog.Actions>
    </Dialog>
  );
};

AlertDialogBase.displayName = 'AlertDialog';

AlertDialogBase.propTypes = {
  /** Type of alert dialog. */
  variant: PropTypes.oneOf(Object.values(ALERT_DIALOG_VARIANTS)),
  /** Override Dialog default size. */
  size: PropTypes.oneOf(Object.values(Dialog.SIZES)),
  /** Disable the action buttons. */
  disabled: PropTypes.bool,
  /** Callback when clicking the confirm button. */
  onClickConfirm: PropTypes.func,
  /** Callback when clicking the cancel button. */
  onClickCancel: PropTypes.func,
  /** Dialog's onRequestClose. */
  onRequestClose: PropTypes.func,
  /** Text of cancel button. */
  cancelText: PropTypes.node,
  /** Text of confirm button. */
  confirmText: PropTypes.node,
  /** Alert text. */
  children: PropTypes.node,
};

export const AlertDialog: MemoExoticComponent<typeof AlertDialogBase> & {
  VARIANTS?: typeof ALERT_DIALOG_VARIANTS;
  SIZES?: typeof Dialog.SIZES;
} = memo(AlertDialogBase);

AlertDialog.VARIANTS = ALERT_DIALOG_VARIANTS;
AlertDialog.SIZES = Dialog.SIZES;
