import PropTypes from 'prop-types';

import { CUSTOM_HTML } from '@eversity/domain/constants';

import { COLOR_GROUPS } from '../config/colors/constants';
import { TYPOGRAPHY_VARIANTS } from '../config/typography/constants';

export const themeColorsPropTypes = PropTypes.shape(
  Object.values(COLOR_GROUPS).reduce((acc, variant) => {
    acc[variant] = PropTypes.objectOf(PropTypes.string).isRequired;
    return acc;
  }, {}),
);

export const typographyStylesPropTypes = PropTypes.shape({
  fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lineHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fontWeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  textDecoration: PropTypes.string,
  textStyle: PropTypes.string,
  letterSpacing: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fontFamily: PropTypes.string,
});

export const typographyPropTypes = PropTypes.shape(
  Object.values(TYPOGRAPHY_VARIANTS).reduce((acc, variant) => {
    acc[variant] = typographyStylesPropTypes.isRequired;
    return acc;
  }, {}),
);

export const themePropTypes = PropTypes.shape({
  colors: themeColorsPropTypes.isRequired,
  contrastColors: themeColorsPropTypes.isRequired,
  typography: typographyPropTypes.isRequired,
  globalStylesComponent: PropTypes.elementType,
});

// This shape is recursive and needs to be declared in multiple steps.
const hastNodeLeaf = {
  // Element or text node.
  type: PropTypes.string.isRequired,
  // HTML tag name if element.
  tagName: PropTypes.string,
  //  Attributes.
  properties: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  ),
  children: undefined,
};

// Node content.
hastNodeLeaf.children = PropTypes.arrayOf(PropTypes.shape(hastNodeLeaf));

// Shape of a HAST node.
export const hastNodePropTypes = PropTypes.shape(hastNodeLeaf);

export const hastLeafPropTypes = PropTypes.exact({
  type: PropTypes.oneOf(['text'] as const).isRequired,
  value: PropTypes.string.isRequired,
});

const hastElement = {
  type: PropTypes.oneOf(['element'] as const).isRequired,
  tagName: PropTypes.string.isRequired,
  properties: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string,
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      ),
    ]),
  ),
  children: undefined,
};

hastElement.children = PropTypes.arrayOf(
  PropTypes.oneOfType([hastLeafPropTypes, PropTypes.exact(hastElement)])
    .isRequired,
).isRequired;

export const hastElementPropTypes = PropTypes.exact(hastElement);

export const carouselSlideHastElementPropTypes = PropTypes.exact({
  type: PropTypes.oneOf(['element'] as const).isRequired,
  tagName: PropTypes.oneOf([CUSTOM_HTML.CAROUSEL_SLIDE] as const).isRequired,
  properties: PropTypes.exact({
    dataAlt: PropTypes.string,
    dataRatio: PropTypes.string.isRequired,
    dataResizedUploadId: PropTypes.string.isRequired,
    dataResizedUploadHref: PropTypes.string.isRequired,
    dataResizedUploadMimeType: PropTypes.string,
    dataResizedUploadFileName: PropTypes.string.isRequired,
    dataResizedUploadSize: PropTypes.string,
    dataOriginalUploadId: PropTypes.string.isRequired,
    dataOriginalUploadHref: PropTypes.string.isRequired,
    dataOriginalUploadMimeType: PropTypes.string,
    dataOriginalUploadFileName: PropTypes.string.isRequired,
    dataOriginalUploadSize: PropTypes.string,
  }).isRequired,
  children: PropTypes.arrayOf(hastElementPropTypes).isRequired,
});

export const hastRootPropTypes = PropTypes.exact({
  type: PropTypes.oneOf(['root'] as const).isRequired,
  data: PropTypes.shape({}),
  children: PropTypes.arrayOf(
    PropTypes.oneOfType([hastLeafPropTypes, hastElementPropTypes]),
  ).isRequired,
});

export const tabKeyPropTypes = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

export const tabPropTypes = PropTypes.shape({
  key: tabKeyPropTypes.isRequired,
  badge: PropTypes.node,
  label: PropTypes.node.isRequired,
});

// An uploaded asset.
export const uploadPropTypes = PropTypes.exact({
  /** Technical unique id. */
  id: PropTypes.string.isRequired,
  /** Filename (as uploaded by user). */
  fileName: PropTypes.string.isRequired,
  /** Mime type. */
  mimeType: PropTypes.string,
  /** Size in bytes. */
  size: PropTypes.number,
  /** Location of the upload. */
  href: PropTypes.string.isRequired,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
});

// An uploaded asset.
export const uploadWithoutHrefPropTypes = PropTypes.exact({
  /** Technical unique id. */
  id: PropTypes.string.isRequired,
  /** Filename (as uploaded by user). */
  fileName: PropTypes.string.isRequired,
  /** Mime type. */
  mimeType: PropTypes.string,
  /** Size in bytes. */
  size: PropTypes.number,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
});

// A file being uploaded (e.g. via file input).
export const fileUploadingPropTypes = PropTypes.shape({
  /** Technical unique id. */
  id: PropTypes.string.isRequired,
  /** File selected by user. */
  file: PropTypes.instanceOf(File),
  /** Error. If defined, progress should be ignored. */
  error: PropTypes.instanceOf(Error),
  /** Upload progress (between 0 and 1). */
  progress: PropTypes.number,
  /** A promise resolving when the file is successfully uploaded, or rejecting on failure. */
  uploadPromise: PropTypes.instanceOf(Promise),
});

// A Select component option value.
export const selectOptionValueShape = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.bool,
]);

const selectOption = {
  label: PropTypes.node.isRequired,
  value: selectOptionValueShape,
  options: undefined,
};

// Which may contain other options (if current option is a group).
selectOption.options = PropTypes.arrayOf(PropTypes.shape(selectOption));

// A Select component option.
export const selectOptionShape = PropTypes.shape(selectOption);
export const selectOptionsShape = PropTypes.arrayOf(selectOptionShape);

// This shape is recursive and needs to be declared in multiple steps.
export const treeNodeKeyPropTypes = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
]);

export const treeNode = {
  key: treeNodeKeyPropTypes.isRequired,
  label: PropTypes.node.isRequired,
  children: undefined,
};

// Node content.
treeNode.children = PropTypes.arrayOf(PropTypes.shape(treeNode));

// Shape of a tree node.
export const treeNodePropTypes = PropTypes.shape(treeNode);

export const fileUploadContextRulesPropTypes = PropTypes.shape({
  maxSize: PropTypes.number,
  mimeTypes: PropTypes.arrayOf(PropTypes.string),
  maxFiles: PropTypes.number,
});

export const fileWithIdPropTypes = PropTypes.exact({
  id: PropTypes.string.isRequired,
  file: PropTypes.instanceOf(File).isRequired,
});
