import { css, Theme } from '@emotion/react';

export const card = css`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const cardBody = css`
  flex: 1 0 auto;
`;

export const container = css`
  display: flex;
  align-items: center;
  gap: 20px;

  height: 100%;
`;

export const swiper = css`
  flex: 1;

  .swiper-slide {
    /* Need to override swiper-js default width computing. */
    width: auto !important;
    flex-basis: 100%;
  }
`;

export const paginationContainer = css`
  justify-content: center;
  padding: 25px;
`;

export const paginationBullet = (theme: Theme) => css`
  height: 10px;
  width: 10px;
  border-radius: 4px;
  background-color: ${theme.colors.gray[100]};

  transition: ${theme.transitions.default()};

  &.isActive {
    background-color: ${theme.colors.gray[500]};
  }
`;
