import { DEFAULT_ICON_CONFIGS, IIconConfig } from '@icon-park/react/es/runtime';

export const ICON_SIZES = {
  SMALL: 14,
  MEDIUM: 18,
  LARGE: 24,
} as const;

export const ICON_CONFIG: IIconConfig = {
  ...DEFAULT_ICON_CONFIGS,
  prefix: 'app-icons',
  theme: 'two-tone',
} as const;
