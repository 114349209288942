import {
  memo,
  MemoExoticComponent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { last, uniqueId } from 'lodash';

import { useMemoizedBundle } from '@eversity/ui/utils';

import { ILayer, LayersContext } from './LayersContext';

// Default z-index of the first layer.
const DEFAULT_MIN_LAYER_Z_INDEX = 100;
// Default z-index interval between layers.
// > 1 so we have room to set z-index of items inside layers (like a Select menu for example).
const DEFAULT_LAYER_Z_INDEX_INTERVAL = 30;

export type LayersProviderProps = {
  minLayerZIndex?: number;
  layerZIndexInterval?: number;
  children?: ReactNode;
};

export const LayersProviderBase = ({
  minLayerZIndex = DEFAULT_MIN_LAYER_Z_INDEX,
  layerZIndexInterval = DEFAULT_LAYER_Z_INDEX_INTERVAL,
  children = null,
}: LayersProviderProps) => {
  const [layers, onChangeLayers] = useState<ILayer[]>([]);

  // Need to access layers when needed but not as a dependency.
  const layersRef = useRef(layers);
  layersRef.current = layers;

  useEffect(() => {
    const closeOnEscape = (event: KeyboardEvent) => {
      // 'Escape' on most browsers, 'Esc' on IE.
      if (event.key === 'Escape' || event.key === 'Esc') {
        const lastLayer = last(layersRef.current);

        if (
          lastLayer?.shouldCloseOnEscape &&
          lastLayer.onRequestClose &&
          !lastLayer.preventClosing
        ) {
          lastLayer.onRequestClose();
        }
      }
    };

    document.addEventListener('keyup', closeOnEscape);
    return () => document.removeEventListener('keyup', closeOnEscape);
  }, []);

  const onAddLayer = useCallback(
    (layer: ILayer) => {
      const id = layer.id || uniqueId('layer');

      onChangeLayers((currentLayers) => [
        ...currentLayers,
        {
          ...layer,
          id,

          // If there are no layers, the list will be [100] so the z-index of the 1st layer is 100.
          // If there are 2 layers, their z-index should be 100 and 130, and the list below will be
          // [100 (min), 130 (100 + 30), 160 (130 + 30)], so the z-index of the 3rd layer is 160.
          zIndex: Math.max(
            ...[
              minLayerZIndex,
              ...currentLayers.map(
                (otherLayer) => otherLayer.zIndex + layerZIndexInterval,
              ),
            ],
          ),
        },
      ]);

      return id;
    },
    [minLayerZIndex, layerZIndexInterval],
  );

  const onUpdateLayer = useCallback(
    (id: string, layer: ILayer) =>
      onChangeLayers((currentLayers) => {
        const index = currentLayers.findIndex((l) => l.id === id);

        if (index >= 0) {
          const cloneLayers = [...currentLayers];
          cloneLayers[index] = {
            ...layer,
            id,
            zIndex: cloneLayers[index].zIndex,
          };

          return cloneLayers;
        }

        return currentLayers;
      }),
    [],
  );

  const onRemoveLayer = useCallback(
    (id: string) =>
      onChangeLayers((currentLayers) =>
        currentLayers.filter((l) => l.id !== id),
      ),
    [],
  );

  const contextValue = useMemoizedBundle({
    layers,
    onAddLayer,
    onUpdateLayer,
    onRemoveLayer,
  });

  return (
    <LayersContext.Provider value={contextValue}>
      {children}
    </LayersContext.Provider>
  );
};

LayersProviderBase.displayName = 'LayersProvider';

LayersProviderBase.propTypes = {
  /** Min z-index of layers. */
  minLayerZIndex: PropTypes.number,
  /** Z-index interval between layers. */
  layerZIndexInterval: PropTypes.number,
  /** Children components. */
  children: PropTypes.node,
};

export const LayersProvider: MemoExoticComponent<typeof LayersProviderBase> & {
  DEFAULT_MIN_LAYER_Z_INDEX?: number;
  DEFAULT_LAYER_Z_INDEX_INTERVAL?: number;
} = memo(LayersProviderBase);

// Default z-index of the first layer.
LayersProvider.DEFAULT_MIN_LAYER_Z_INDEX = DEFAULT_MIN_LAYER_Z_INDEX;
LayersProvider.DEFAULT_LAYER_Z_INDEX_INTERVAL = DEFAULT_LAYER_Z_INDEX_INTERVAL;
