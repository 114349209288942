import { memo, MemoExoticComponent, ReactElement } from 'react';
import PropTypes from 'prop-types';
import { TippyProps } from '@tippyjs/react';

import { Popover } from '../../general/popover/Popover';
import { DropdownList } from './list/DropdownList';
import { DropdownListItem } from './list-item/DropdownListItem';
import { DropdownSubList } from './sub-list/DropdownSubList';

export type DropdownProps = Omit<TippyProps, 'ref'> & {
  menu?: ReactElement;
};

export const DropdownBase = ({
  menu = null,
  children = null,
  ...props
}: DropdownProps) => (
  <Popover
    interactive
    trigger={props.visible !== undefined ? undefined : 'click'}
    placement="bottom-end"
    content={menu}
    {...props}
  >
    {children}
  </Popover>
);

DropdownBase.displayName = 'Dropdown';

DropdownBase.propTypes = {
  /** Dropdown menu. */
  menu: PropTypes.node,
  /** Dropdown trigger. */
  children: PropTypes.node,
};

export const Dropdown: MemoExoticComponent<typeof DropdownBase> & {
  List?: typeof DropdownList;
  ListItem?: typeof DropdownListItem;
  SubList?: typeof DropdownSubList;
} = memo(DropdownBase);

Dropdown.List = DropdownList;
Dropdown.ListItem = DropdownListItem;
Dropdown.SubList = DropdownSubList;
