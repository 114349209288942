import { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { css } from '@emotion/react';

import { Sortable, useSortableList } from '@eversity/ui/design-system';
import { McqQuestion } from '@eversity/types/domain';

import { QuestionItem, QuestionItemProps } from './question-item/QuestionItem';
import { questionPropTypes } from '../../../../types/mcq';

export type QuestionsListProps = {
  questions: McqQuestion[];
  onChangeOrder?: (newOrder: McqQuestion[]) => void;
  onEditQuestion?: QuestionItemProps['onEditQuestion'];
  onRemoveQuestion?: QuestionItemProps['onRemoveQuestion'];
  readOnly?: boolean;
  isLoading?: boolean;
};

const DEFAULT_QUESTIONS = [];

export const QuestionsListBase = ({
  questions = DEFAULT_QUESTIONS,
  onChangeOrder = noop,
  onEditQuestion = noop,
  onRemoveQuestion = noop,
  readOnly = false,
  isLoading = false,
}: QuestionsListProps) => {
  const [draggableQuestions, onChangeDraggableQuestions, onDrop] =
    useSortableList(questions, onChangeOrder);

  return (
    <Sortable
      list={draggableQuestions}
      setList={onChangeDraggableQuestions}
      onEnd={onDrop}
      handle={`.${QuestionItem.DRAGGABLE_CLASSNAME}`}
      disabled={readOnly || isLoading}
      css={css`
        display: flex;
        flex-direction: column;
        gap: 10px;
      `}
    >
      {draggableQuestions.map(({ data: question }) => (
        <QuestionItem
          key={question.id}
          question={question}
          isDraggable={!readOnly && questions.length > 1}
          onEditQuestion={onEditQuestion}
          onRemoveQuestion={onRemoveQuestion}
          readOnly={readOnly}
        />
      ))}
    </Sortable>
  );
};

QuestionsListBase.displayName = 'QuestionsList';

QuestionsListBase.propTypes = {
  questions: PropTypes.arrayOf(questionPropTypes),
  onChangeOrder: PropTypes.func,
  onEditQuestion: PropTypes.func,
  onRemoveQuestion: PropTypes.func,
  readOnly: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export const QuestionsList = memo(QuestionsListBase);
