import { ForwardedRef, forwardRef, memo } from 'react';
import PropTypes from 'prop-types';

import { Embedded, EmbeddedProps } from '../embedded/Embedded';

import * as styles from './LinkedInEmbedded.styles';

export type LinkedInEmbeddedProps = EmbeddedProps;

export const LinkedInEmbeddedBase = forwardRef(
  (
    { src, title, children, ...props }: LinkedInEmbeddedProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => (
    <Embedded
      {...props}
      ref={ref}
      css={styles.linkedInEmbedded}
      src={src}
      title={title}
    >
      {children}
    </Embedded>
  ),
);

LinkedInEmbeddedBase.displayName = 'LinkedInEmbedded';

LinkedInEmbeddedBase.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

LinkedInEmbeddedBase.defaultProps = {
  children: null,
};

export const LinkedInEmbedded = memo(LinkedInEmbeddedBase);
