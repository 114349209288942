import { memo, MemoExoticComponent, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { css, Theme } from '@emotion/react';

import { REFERENCE_TAG_SIZES, REFERENCE_TAG_VARIANTS } from './constants';

import { Typography, TypographyProps } from '../typography/Typography';

const REFERENCE_TAG_SIZE_TYPOGRAPHY_MAPPING = {
  [REFERENCE_TAG_SIZES.SMALL]: Typography.VARIANTS.MONOSPACE_SMALL_BOLD,
  [REFERENCE_TAG_SIZES.MEDIUM]: Typography.VARIANTS.MONOSPACE_MEDIUM_BOLD,
};

export const REFERENCE_TAG_SIZE_BORDER_RADIUS_MAPPING = {
  [REFERENCE_TAG_SIZES.SMALL]: '4px',
  [REFERENCE_TAG_SIZES.MEDIUM]: '8px',
};

const getReferenceTagColors = (theme: Theme, contrast: boolean) => ({
  [REFERENCE_TAG_VARIANTS.PRIMARY]: {
    color: contrast ? theme.colors.gray[0] : theme.colors.primary[500],
    backgroundColor: contrast
      ? theme.colors.primary[500]
      : theme.colors.primary[25],
  },
  [REFERENCE_TAG_VARIANTS.SECONDARY]: {
    color: contrast ? theme.colors.gray[0] : theme.colors.secondary[600],
    backgroundColor: contrast
      ? theme.colors.secondary[400]
      : theme.colors.secondary[25],
  },
  [REFERENCE_TAG_VARIANTS.NEUTRAL]: {
    color: contrast ? theme.colors.gray[0] : theme.colors.gray[500],
    backgroundColor: contrast ? theme.colors.gray[500] : theme.colors.gray[25],
  },
  [REFERENCE_TAG_VARIANTS.TERTIARY]: {
    color: contrast ? theme.colors.gray[0] : theme.colors.tertiary[500],
    backgroundColor: contrast
      ? theme.colors.tertiary[500]
      : theme.colors.tertiary[25],
  },
});

export type ReferenceTagProps = Omit<TypographyProps, 'variant'> & {
  size?: REFERENCE_TAG_SIZES;
  variant?: REFERENCE_TAG_VARIANTS;
  contrast?: boolean;
  children?: ReactNode;
};

export const ReferenceTagBase = ({
  size = REFERENCE_TAG_SIZES.MEDIUM,
  variant = REFERENCE_TAG_VARIANTS.PRIMARY,
  contrast = false,
  children = undefined,
  ...props
}: ReferenceTagProps) => (
  <Typography
    {...props}
    variant={REFERENCE_TAG_SIZE_TYPOGRAPHY_MAPPING[size]}
    css={(theme) => css`
      display: inline-flex;
      padding: 2px 8px;
      border-radius: ${REFERENCE_TAG_SIZE_BORDER_RADIUS_MAPPING[size]};
      color: ${getReferenceTagColors(theme, contrast)[variant].color};
      background-color: ${getReferenceTagColors(theme, contrast)[variant]
        .backgroundColor};
      transition: ${theme.transitions.default()};
    `}
  >
    {children}
  </Typography>
);

ReferenceTagBase.displayName = 'ReferenceTag';

ReferenceTagBase.propTypes = {
  /** Reference tag size. */
  size: PropTypes.oneOf(Object.values(REFERENCE_TAG_SIZES)),
  variant: PropTypes.oneOf(Object.values(REFERENCE_TAG_VARIANTS)),
  /** Content. */
  children: PropTypes.node,
  /** Contrast mode. Only for Primary colors. */
  contrast: PropTypes.bool,
};

export const ReferenceTag: MemoExoticComponent<typeof ReferenceTagBase> & {
  SIZES?: typeof REFERENCE_TAG_SIZES;
} & { VARIANTS?: typeof REFERENCE_TAG_VARIANTS } = memo(ReferenceTagBase);

ReferenceTag.SIZES = REFERENCE_TAG_SIZES;
ReferenceTag.VARIANTS = REFERENCE_TAG_VARIANTS;
