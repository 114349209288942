import {
  ForwardedRef,
  forwardRef,
  HTMLProps,
  memo,
  cloneElement,
  ReactNode,
  ReactElement,
} from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import cn from 'classnames';

import { TYPOGRAPHY_VARIANTS } from '../../../config/typography/constants';
import { removeButtonAppearance } from '../../../utils/style';
import { ICON_SIZES } from '../icon/constants';

export type LinkButtonProps = Omit<
  HTMLProps<HTMLButtonElement>,
  'type' | 'ref'
> & {
  children: ReactNode;
  icon?: ReactElement;
};

export const LinkButtonBase = forwardRef(
  (
    { disabled, children, icon, className, ...props }: LinkButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => (
    <button
      {...props}
      ref={ref}
      type="button"
      className={cn(className, { disabled })}
      css={[
        removeButtonAppearance,
        (theme) => css`
          display: flex;
          align-items: center;
          gap: 20px;
          color: ${theme.colors.primary[500]};
          text-decoration: underline;

          &:disabled {
            color: ${theme.colors.gray[300]};
            cursor: not-allowed;
            text-decoration: none;
          }
        `,
        (theme) => theme.typography[TYPOGRAPHY_VARIANTS.BODY_MEDIUM_REGULAR],
      ]}
      disabled={disabled}
    >
      <span>{children}</span>
      {icon &&
        cloneElement(icon, {
          size: ICON_SIZES.LARGE,
        })}
    </button>
  ),
);

LinkButtonBase.displayName = 'LinkButton';

LinkButtonBase.propTypes = {
  /** Button text. */
  children: PropTypes.node.isRequired,
  /** Icon (right side of the text) from IconPark. */
  icon: PropTypes.element,
  /** Is the button disabled. */
  disabled: PropTypes.bool,
  /** Root element className. */
  className: PropTypes.string,
};

LinkButtonBase.defaultProps = {
  disabled: false,
  icon: null,
  className: null,
};

export const LinkButton = memo(LinkButtonBase);
