import { memo, HTMLProps, MemoExoticComponent } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { useFocus } from '@eversity/ui/utils';
import { AvatarShape } from '@eversity/types/web';

import { AVATAR_SIZES } from '../avatar/constants';
import { avatarShape } from '../../../types/avatar';

import { Avatar } from '../avatar/Avatar';

export type AvatarButtonProps = Omit<
  HTMLProps<HTMLButtonElement>,
  'type' | 'size' | 'ref'
> & {
  user: AvatarShape;
  size?: AVATAR_SIZES;
  isShadowed?: boolean;
};

export const AvatarButtonBase = ({
  user,
  size = Avatar.SIZES.MEDIUM,
  isShadowed = false,
  ...props
}: AvatarButtonProps) => {
  const { isFocused, ...focusProps } = useFocus(props);

  return (
    <button
      {...props}
      {...focusProps}
      type="button"
      css={css`
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        display: block;
      `}
    >
      <Avatar
        user={user}
        size={size}
        isShadowed={isFocused || isShadowed}
      />
    </button>
  );
};

AvatarButtonBase.displayName = 'AvatarButton';

AvatarButtonBase.propTypes = {
  /** User to whom the avatar belongs. */
  user: avatarShape.isRequired,
  /** Avatar size. */
  size: PropTypes.oneOf(Object.values(Avatar.SIZES)),
  /** Display the avatar with a shadow. */
  isShadowed: PropTypes.bool,
};

export const AvatarButton: MemoExoticComponent<typeof AvatarButtonBase> & {
  SIZES?: typeof AVATAR_SIZES;
} = memo(AvatarButtonBase);

AvatarButton.SIZES = Avatar.SIZES;
