import { css, Theme } from '@emotion/react';
import { TinyColor } from '@ctrl/tinycolor';

import { INPUT_SIZES } from './constants';

export const prefixStyle = (theme: Theme) => css`
  background-color: ${theme.colors.gray[25]};
  color: ${theme.colors.gray[700]};

  display: flex;
  align-items: center;

  border: 1px solid ${theme.colors.gray[200]};
  border-right: none;
  border-radius: 4px 0 0 4px;

  &.disabled {
    color: ${theme.colors.gray[300]};
  }

  &.${INPUT_SIZES.SMALL} {
    padding: 0px 7px;
  }

  &.${INPUT_SIZES.MEDIUM} {
    padding: 0px 13px;
  }

  &.${INPUT_SIZES.LARGE} {
    padding: 0 15px;
  }
`;

export const inputStyle = (theme: Theme) => css`
  border-radius: 4px;

  width: 100%;
  outline: none;

  border: 1px solid ${theme.colors.gray[200]};
  color: ${theme.colors.gray[700]};
  background-color: ${theme.colors.gray[0]};

  transition: ${theme.transitions.default()};

  ~ .rightContent,
  ~ .iconLeft,
  ~ .iconRight {
    position: absolute;
  }

  &:focus {
    border-color: ${theme.colors.primary[400]};
    box-shadow: 0 0 0 4px
      ${new TinyColor(theme.colors.primary[500]).setAlpha(0.15).toRgbString()};
  }

  &::placeholder {
    color: ${theme.colors.gray[300]};
  }

  &:disabled {
    color: ${theme.colors.gray[300]};
    background-color: ${theme.colors.gray[25]};
    cursor: not-allowed;
  }

  &.hasError {
    &,
    &:focus {
      border-color: ${theme.colors.danger[400]};
    }

    &:focus {
      box-shadow: 0 0 0 4px
        ${new TinyColor(theme.colors.danger[500]).setAlpha(0.15).toRgbString()};
    }
  }

  &.hasWarning {
    &,
    &:focus {
      border-color: ${theme.colors.warning[400]};
    }

    &:focus {
      box-shadow: 0 0 0 4px
        ${new TinyColor(theme.colors.warning[500]).setAlpha(0.15).toRgbString()};
    }
  }

  &.hasPrefix {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.${INPUT_SIZES.SMALL} {
    padding: 2px 7px;

    &.hasIconLeft {
      padding-left: 29px;
    }

    &.hasIconRight {
      padding-right: 29px;
    }

    ~ .iconLeft {
      top: 5px;
      left: 8px;
    }

    ~ .iconRight {
      top: 5px;
      right: 8px;
    }

    ~ .rightContent {
      top: 50%;
      transform: translateY(-50%);
      right: 8px;
    }
  }

  &.${INPUT_SIZES.MEDIUM} {
    padding: 4.5px 13px;

    &.hasIconLeft {
      padding-left: 39px;
    }

    &.hasIconRight {
      padding-right: 39px;
    }

    ~ .iconLeft {
      top: 7px;
      left: 14px;
    }

    ~ .iconRight {
      top: 7px;
      right: 14px;
    }

    ~ .rightContent {
      top: 50%;
      transform: translateY(-50%);
      right: 14px;
    }
  }

  &.${INPUT_SIZES.LARGE} {
    padding: 7px 15px;

    &.hasIconLeft {
      padding-left: 47px;
    }

    &.hasIconRight {
      padding-right: 47px;
    }

    ~ .iconLeft {
      top: 8px;
      left: 16px;
    }

    ~ .iconRight {
      top: 8px;
      right: 16px;
    }

    ~ .rightContent {
      top: 50%;
      transform: translateY(-50%);
      right: 16px;
    }
  }
`;

export const getIconFills = (
  theme: Theme,
  isDisabled: boolean,
  isFocused: boolean,
) => {
  if (isDisabled) {
    return [theme.colors.gray[300], theme.colors.gray[25]];
  }

  if (isFocused) {
    return [theme.colors.primary[500], theme.colors.primary[50]];
  }

  return [theme.colors.gray[500], theme.colors.gray[50]];
};
