import {
  memo,
  forwardRef,
  ForwardedRef,
  MouseEventHandler,
  ChangeEvent,
} from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Close } from '@icon-park/react';
import { css } from '@emotion/react';

import { Input, InputProps } from '../../input/Input';
import { Button } from '../../../general/button/Button';

const INPUT_BUTTON_SIZES_MAPPING = {
  [Input.SIZES.LARGE]: Button.SIZES.MEDIUM,
  [Input.SIZES.MEDIUM]: Button.SIZES.SMALL,
  [Input.SIZES.SMALL]: Button.SIZES.SMALL,
};

export type CustomInputProps = Omit<InputProps, 'size' | 'onChange'> & {
  inputSize?: InputProps['size'];
  hasValue?: boolean;
  isClearable?: boolean;
  onClear?: MouseEventHandler<HTMLButtonElement>;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const CustomInputBase = forwardRef(
  (
    {
      onClear,
      hasValue,
      inputSize,
      isClearable,
      onChange,
      ...props
    }: CustomInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const onChangeProxy = (
      value: string,
      event: ChangeEvent<HTMLInputElement>,
    ) => {
      onChange(event);
    };
    return (
      <div
        css={css`
          position: relative;
        `}
      >
        <Input
          size={inputSize}
          ref={ref}
          {...props}
          onChange={onChangeProxy}
        />
        {isClearable && hasValue && (
          <Button
            icon={<Close />}
            onClick={onClear}
            size={INPUT_BUTTON_SIZES_MAPPING[inputSize]}
            css={css`
              position: absolute;
              top: 4px;
              right: ${props.iconRight ? '35px' : '15px'};
            `}
          />
        )}
      </div>
    );
  },
);

CustomInputBase.displayName = 'CustomInput';

CustomInputBase.propTypes = {
  onClear: PropTypes.func,
  hasValue: PropTypes.bool,
  inputSize: PropTypes.oneOf(Object.values(Input.SIZES)),
  isClearable: PropTypes.bool,
};

CustomInputBase.defaultProps = {
  onClear: noop,
  onChange: noop,
  hasValue: false,
  inputSize: Input.SIZES.MEDIUM,
  isClearable: false,
};

export default memo(CustomInputBase);
