import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@eversity/ui/design-system';

import {
  gradeMessages,
  globalMessages,
  academicTranscriptMessages,
} from '@eversity/ui/intl';
import { StudentAcademicTranscript } from '@eversity/types/domain';
import { OVERALL_AVERAGE_GRADING_SCALE } from '@eversity/domain/constants';
import { getTranscriptGradedAssignmentsCount } from '@eversity/domain/students/utils';

import * as styles from './AcademicTranscriptFooter.styles';

export const AcademicTranscriptFooterBase = ({
  transcript,
}: {
  transcript: StudentAcademicTranscript;
}) => {
  const overallGradedAssignments = useMemo(
    () => getTranscriptGradedAssignmentsCount(transcript),
    [transcript],
  );

  return (
    <div css={styles.footer}>
      <div css={styles.overallAverage}>
        <Typography
          variant={Typography.VARIANTS.HEADING_3}
          element="span"
        >
          <FormattedMessage {...academicTranscriptMessages.OVERALL_AVERAGE} />
        </Typography>

        <Typography
          variant={Typography.VARIANTS.HEADING_5}
          element="span"
          css={styles.coloredTextPrimary}
        >
          <FormattedMessage
            {...(Number.isFinite(transcript.overallAverage)
              ? gradeMessages.GRADE
              : globalMessages.NOT_AFFECTED)}
            values={{
              grade: transcript.overallAverage,
              gradingScale: OVERALL_AVERAGE_GRADING_SCALE,
              Grade: (chunks) => (
                <Typography
                  variant={Typography.VARIANTS.HEADING_3}
                  element="span"
                >
                  {chunks}
                </Typography>
              ),
              GradingScale: (chunks) => chunks,
            }}
          />
        </Typography>
      </div>

      <Typography variant={Typography.VARIANTS.BODY_SMALL_REGULAR}>
        <FormattedMessage
          {...gradeMessages.GRADED_ASSIGNMENTS_COUNT}
          values={{
            ...overallGradedAssignments,
            b: (chunks) => (
              <Typography variant={Typography.VARIANTS.BODY_MEDIUM_BOLD}>
                {chunks}
              </Typography>
            ),
          }}
        />
      </Typography>
    </div>
  );
};

AcademicTranscriptFooterBase.displayName = 'AcademicTranscriptFooter';

export const AcademicTranscriptFooter = memo(AcademicTranscriptFooterBase);
