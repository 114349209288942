import Cropper from 'cropperjs';

import { Upload } from '@eversity/types/domain';

import { CropperWithPreviewValue } from '../../components/forms/cropper-with-preview/CropperWithPreview';

// Omit head.
type Tail<T extends any[]> = T extends [any, ...infer R] ? R : never;

export const getCropperBlob = (
  cropper: Cropper,
  ...toBlobArgs: Tail<Parameters<HTMLCanvasElement['toBlob']>>
) =>
  new Promise<Blob>((resolve, reject) => {
    cropper
      .getCroppedCanvas()
      .toBlob(
        (blob) =>
          blob ? resolve(blob) : reject(new Error('Could not create the blob')),
        ...toBlobArgs,
      );
  });

export async function getRemoteOrLocalUploadFromCropper(
  { remote, local, cropperRef }: CropperWithPreviewValue,
  uploadFile: (file: Blob) => Promise<Upload>,
) {
  return (
    remote ||
    (local
      ? await uploadFile(
          await getCropperBlob(cropperRef.current.cropper, 'image/png'),
        )
      : null)
  );
}
