import { ForwardedRef, forwardRef, HTMLProps, memo } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import { TABLE_BACKGROUND_COLORS } from '@eversity/domain/constants';

export type RowProps = Omit<HTMLProps<HTMLTableRowElement>, 'ref'> & {
  backgroundColor?: TABLE_BACKGROUND_COLORS;
};

export const RowBase = forwardRef(
  (
    { backgroundColor, ...props }: RowProps,
    ref: ForwardedRef<HTMLTableRowElement>,
  ) => (
    <tr
      {...props}
      ref={ref}
      className={cn(props.className, backgroundColor)}
    />
  ),
);

RowBase.displayName = 'Row';

RowBase.propTypes = {
  /** Root element class name. */
  className: PropTypes.string,
  /** Background color enum. */
  backgroundColor: PropTypes.oneOf(Object.values(TABLE_BACKGROUND_COLORS)),
};

RowBase.defaultProps = {
  className: null,
  backgroundColor: null,
};

export const Row = memo(RowBase);
