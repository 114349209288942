import { ColorInput, mostReadable } from '@ctrl/tinycolor';
import { Color } from '@eversity/types/web';

export function getTextContrastColor(
  backgroundColor: ColorInput,
  themeColors: Record<string, Color>,
) {
  return mostReadable(
    backgroundColor,
    [themeColors.gray[700], themeColors.gray[0]],
    { includeFallbackColors: false },
  ).toHexString();
}

/**
 * Get a color from a list of colors for a given text.
 * Formula does not matter but will always return the same color for the same text.
 *
 * @param text - Text.
 * @param colorList - List of colors to choose from.
 * @returns A color from the list of colors.
 */
export function getDeterministicTextColor<T = string>(
  text: string,
  colorList: T[],
) {
  // Compute the sum of all characters in the text.
  const textCode = text
    .split('')
    .reduce((acc, char) => acc + char.charCodeAt(0), 0);

  return colorList[textCode % colorList.length];
}
