import {
  cloneElement,
  memo,
  MemoExoticComponent,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { Theme, useTheme } from '@emotion/react';
import cn from 'classnames';

import { removeButtonAppearance } from '../../../../utils/style';

import { RadioButtonGroupContext } from '../../radio/contexts/RadioButtonGroup.context';
import { Typography } from '../../../general/typography/Typography';

import * as styles from './RadioButton.styles';
import { RADIO_BUTTON_SIZES, RADIO_BUTTON_VARIANTS } from './constants';

const getIconFills = (theme: Theme, variant: RADIO_BUTTON_VARIANTS) => {
  switch (variant) {
    case RADIO_BUTTON_VARIANTS.PRIMARY:
    default:
      return [theme.colors.primary[400], theme.colors.primary[100]];
    case RADIO_BUTTON_VARIANTS.NEUTRAL:
      return [theme.colors.gray[700], theme.colors.gray[25]];
  }
};

export type RadioButtonProps = {
  value: string;
  icons: ReactElement | ReactElement[];
  label?: ReactNode;
  description: ReactNode;
  size?: RADIO_BUTTON_SIZES;
  variant?: RADIO_BUTTON_VARIANTS;
  isShadowed?: boolean;
};

export const RadioButtonBase = ({
  value,
  icons,
  label = null,
  description,
  size = RADIO_BUTTON_SIZES.MEDIUM,
  variant = RADIO_BUTTON_VARIANTS.PRIMARY,
  isShadowed = true,
}: RadioButtonProps) => {
  const { value: selectedValue, onChange } = useContext(
    RadioButtonGroupContext,
  );

  const theme = useTheme();

  const checked = selectedValue === value;

  const onClick = useCallback(() => {
    onChange(value);
  }, [onChange, value]);

  return (
    <button
      type="button"
      value={value}
      onClick={onClick}
      className={cn(size, variant, { checked })}
      css={[removeButtonAppearance, styles.button]}
    >
      <div
        css={styles.iconsContainer}
        className={cn(variant, { isShadowed })}
      >
        {(Array.isArray(icons) && icons.length
          ? (icons as ReactElement[])
          : [icons as ReactElement]
        ).map((icon, _, array) =>
          cloneElement(icon, {
            key: array.length === 1 ? 'icon' : undefined,
            size: icon.props.size || 50,
            fill: getIconFills(theme, variant),
            strokeWidth: 2,
          }),
        )}
      </div>

      {label && (
        <Typography
          variant={Typography.VARIANTS.HEADING_4}
          css={styles.label}
        >
          {label}
        </Typography>
      )}

      <Typography
        variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}
        css={styles.description}
      >
        {description}
      </Typography>
    </button>
  );
};

RadioButtonBase.displayName = 'RadioButton';

RadioButtonBase.propTypes = {
  value: PropTypes.string.isRequired,
  icons: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  label: PropTypes.node,
  description: PropTypes.node.isRequired,
  size: PropTypes.oneOf(Object.values(RADIO_BUTTON_SIZES)),
  variant: PropTypes.oneOf(Object.values(RADIO_BUTTON_VARIANTS)),
  isShadowed: PropTypes.bool,
};

export const RadioButton: MemoExoticComponent<typeof RadioButtonBase> & {
  SIZES?: typeof RADIO_BUTTON_SIZES;
  VARIANTS?: typeof RADIO_BUTTON_VARIANTS;
} = memo(RadioButtonBase);

RadioButton.SIZES = RADIO_BUTTON_SIZES;
RadioButton.VARIANTS = RADIO_BUTTON_VARIANTS;
