import { memo } from 'react';
import { css } from '@emotion/react';

import { FormattedMessage } from 'react-intl';
import { Typography } from '@eversity/ui/design-system';
import InternalServerErrorSvg from '../../../../assets/images/InternalServerError.svg';

import messages from './InternalServerError.messages';

export const InternalServerErrorBase = () => (
  <div
    css={(theme) => css`
      width: 100%;
      height: 100%;

      display: flex;

      background-color: ${theme.colors.primary[15]};
    `}
  >
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 800px;

        margin: auto;
      `}
    >
      <InternalServerErrorSvg />

      <Typography
        variant={Typography.VARIANTS.HEADING_3}
        css={(theme) => css`
          margin-top: -40px;

          color: ${theme.colors.primary[500]};
        `}
      >
        <FormattedMessage {...messages.INTERNAL_SERVER_ERROR_TITLE} />
      </Typography>

      <Typography variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}>
        <FormattedMessage {...messages.INTERNAL_SERVER_ERROR_DESCRIPTION} />
      </Typography>

      <Typography variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}>
        <FormattedMessage {...messages.RETRY} />
      </Typography>
    </div>
  </div>
);

InternalServerErrorBase.displayName = 'InternalServerError';

export const InternalServerError = memo(InternalServerErrorBase);
