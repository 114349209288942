import PropTypes from 'prop-types';

import { uploadPropTypes } from '@eversity/ui/design-system';
import {
  ASSIGNMENT_ASSESSMENTS,
  ASSIGNMENT_COMMENT_REASON,
  ASSIGNMENT_CORRECTION_KIND,
  ASSIGNMENT_GRADING_TYPES,
  ASSIGNMENT_TYPES,
} from '@eversity/domain/constants';

import { schoolObjectBasePropTypes } from './common';
import { commonUserPropTypes } from './users';
import { mcqAnswerPropTypes } from './mcq';

export const mcqAnswersPropTypes = PropTypes.shape({
  questionId: PropTypes.string,
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      answerId: PropTypes.string,
      isSelected: PropTypes.bool,
    }),
  ),
});

export const correctionPropTypes = PropTypes.shape({
  /** Unique id. */
  id: PropTypes.string,
  /** Feedback if corrected (not HTML, with new lines). */
  feedback: PropTypes.string,
  /** Comment if corrected (not HTML, with new lines). */
  comment: PropTypes.string,
  /** Grade if corrected and assignment gradingType is GRADE. */
  grade: PropTypes.number,
  /** Correction date if corrected. */
  date: PropTypes.string.isRequired,
  /** Assessment if corrected and assignment gradingType is ASSESSMENT. */
  assessment: PropTypes.oneOf(Object.values(ASSIGNMENT_ASSESSMENTS)),
  /** Attachments correction (empty if not corrected). */
  attachments: PropTypes.arrayOf(uploadPropTypes),
  /** Reason because assignment was not evaluated but commented (technical reason, misunderstood) */
  reason: PropTypes.oneOf(Object.values(ASSIGNMENT_COMMENT_REASON)),
  /** Corrector if corrected. */
  corrector: commonUserPropTypes,
  /** Has the user seen the correction. */
  correctionSeen: PropTypes.bool.isRequired,
  /** Kind of correction (EVALUATION, COMMENT or MCQ) */
  correctionKind: PropTypes.oneOf(Object.values(ASSIGNMENT_CORRECTION_KIND))
    .isRequired,
});

export const depositPropTypes = PropTypes.shape({
  /** Unique id. */
  id: PropTypes.string.isRequired,
  /** Set when submitted. */
  submissionDate: PropTypes.string,
  /** Student uploads. */
  attachments: PropTypes.arrayOf(uploadPropTypes).isRequired,
  /** Mcq Answers */
  mcqAnswers: PropTypes.arrayOf(mcqAnswerPropTypes),
  /** Student comment on his submission deposit. */
  studentComment: PropTypes.string,
  /** Submission deposit correction if corrected */
  correction: correctionPropTypes,
});

export const assignmentSubmissionPropTypes = PropTypes.shape({
  /** Unique id. */
  id: PropTypes.string.isRequired,
  /** Assignment. */
  assignment: PropTypes.shape({
    /** Assignment id. */
    id: PropTypes.string.isRequired,
    /** Assignment title. */
    title: PropTypes.string.isRequired,
    /** Assignment code. */
    code: PropTypes.string.isRequired,
    /** Correctors affected to the assignment. */
    correctors: PropTypes.arrayOf(commonUserPropTypes).isRequired,
    /** Assignment type. */
    assignmentType: PropTypes.oneOf(Object.values(ASSIGNMENT_TYPES)).isRequired,
    /** Assignment grading type. */
    gradingType: PropTypes.oneOf(Object.values(ASSIGNMENT_GRADING_TYPES))
      .isRequired,
    /** Assignemnt grading scale (max grade). */
    gradingScale: PropTypes.number,
  }).isRequired,
  /** Assignment version. */
  assignmentVersion: PropTypes.string.isRequired,
  /** Course. */
  course: schoolObjectBasePropTypes.isRequired,
  /** Course class. */
  class: schoolObjectBasePropTypes.isRequired,
  /** Teaching unit. */
  teachingUnit: schoolObjectBasePropTypes.isRequired,
  /** Lesson. */
  lesson: schoolObjectBasePropTypes,
  /** Student. */
  student: commonUserPropTypes.isRequired,
  /** Creation date (initialization on the submission). */
  createdAt: PropTypes.string.isRequired,
  /** Last update. */
  updatedAt: PropTypes.string.isRequired,
  /** Student submission deposits. */
  deposits: PropTypes.arrayOf(depositPropTypes).isRequired,
});
