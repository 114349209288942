import { css, Theme } from '@emotion/react';

export const container = css`
  display: flex;
  align-items: flex-end;
  width: 100%;
`;

export const verticalContainer = css`
  display: flex;
  height: 100%;
`;

export const horizontalTab = (
  theme: Theme,
  hasMultipleTabGroups: boolean,
) => css`
  display: flex;
  align-items: flex-end;
  gap: 20px;
  border-bottom: 1px solid ${theme.colors.gray[100]};
  width: ${!hasMultipleTabGroups ? '100%' : 'auto'};

  list-style-type: none;
`;

export const verticalTab = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  border-right: 1px solid ${theme.colors.gray[100]};

  list-style-type: none;
`;

export const dragStyle = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  cursor: grab;

  padding: 2px;
`;
