import { memo, MemoExoticComponent, MouseEventHandler } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { css, useTheme } from '@emotion/react';
import { useIntl } from 'react-intl';
import {
  Close,
  Drag,
  GoEnd,
  GoStart,
  Pause,
  PlayOne,
  SquareSmall,
} from '@icon-park/react';

import { Button } from '@eversity/ui/design-system';

import messages from './AudioPlayer.messages';

const DRAGGABLE_CLASSNAME = 'draggable';

export type AudioPlayerProps = {
  isPlaying?: boolean;
  isActivated?: boolean;
  isDraggable?: boolean;
  isPreviousDisabled?: boolean;
  isNextDisabled?: boolean;
  onClickPrevious: MouseEventHandler<HTMLButtonElement>;
  onClickNext: MouseEventHandler<HTMLButtonElement>;
  onClickPlay: MouseEventHandler<HTMLButtonElement>;
  onClickPause: MouseEventHandler<HTMLButtonElement>;
  onClickStop: MouseEventHandler<HTMLButtonElement>;
  onClickClose: MouseEventHandler<HTMLButtonElement>;
};

export const AudioPlayerBase = ({
  isPlaying = false,
  isActivated = false,
  isDraggable = false,
  isPreviousDisabled = false,
  isNextDisabled = false,
  onClickPrevious = noop,
  onClickNext = noop,
  onClickPlay = noop,
  onClickPause = noop,
  onClickStop = noop,
  onClickClose = noop,
}: AudioPlayerProps) => {
  const intl = useIntl();
  const theme = useTheme();

  return (
    <div
      css={[
        css`
          display: flex;
          align-items: center;
          padding: 8px;
          background-color: ${theme.colors.gray[0]};
          border-radius: 4px;
        `,
      ]}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 4px;
        `}
      >
        <Button
          disabled={isPreviousDisabled}
          onClick={onClickPrevious}
          icon={<GoStart />}
          aria-label={intl.formatMessage(messages.PREVIOUS)}
          size={Button.SIZES.SMALL}
        />

        {isPlaying ? (
          <Button
            aria-label={intl.formatMessage(messages.PAUSE)}
            size={Button.SIZES.SMALL}
            icon={<Pause />}
            onClick={onClickPause}
          />
        ) : (
          <Button
            aria-label={intl.formatMessage(messages.PLAY)}
            size={Button.SIZES.SMALL}
            icon={<PlayOne />}
            onClick={onClickPlay}
          />
        )}

        <Button
          disabled={isNextDisabled}
          icon={<GoEnd />}
          aria-label={intl.formatMessage(messages.NEXT)}
          size={Button.SIZES.SMALL}
          onClick={onClickNext}
        />
      </div>

      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 4px;
          padding-left: 4px;
          margin-left: 4px;
          border-left: 1px solid ${theme.colors.gray[100]};
        `}
      >
        {isActivated ? (
          <Button
            aria-label={intl.formatMessage(messages.STOP)}
            icon={<SquareSmall />}
            size={Button.SIZES.SMALL}
            onClick={onClickStop}
          />
        ) : (
          <Button
            aria-label={intl.formatMessage(messages.CLOSE)}
            icon={<Close />}
            size={Button.SIZES.SMALL}
            onClick={onClickClose}
          />
        )}

        {isDraggable && (
          <Drag
            fill={[theme.colors.gray[500], theme.colors.gray[500]]}
            className={DRAGGABLE_CLASSNAME}
            css={css`
              cursor: grab;
            `}
          />
        )}
      </div>
    </div>
  );
};

AudioPlayerBase.displayName = 'AudioPlayer';

AudioPlayerBase.propTypes = {
  /** Is the audio currently playing. */
  isPlaying: PropTypes.bool,
  /** Is the audio activated (playback has started, might be paused). */
  isActivated: PropTypes.bool,
  /** Is the player draggable. */
  isDraggable: PropTypes.bool,
  /** Is the previous button disabled. */
  isPreviousDisabled: PropTypes.bool,
  /** Is the next button disabled. */
  isNextDisabled: PropTypes.bool,
  /** Previous button onClick handler. */
  onClickPrevious: PropTypes.func,
  /** Previous button onClick handler. */
  onClickNext: PropTypes.func,
  /** Play button onClick handler. */
  onClickPlay: PropTypes.func,
  /** Pause button onClick handler. */
  onClickPause: PropTypes.func,
  /** Stop button onClick handler. */
  onClickStop: PropTypes.func,
  /** Close button onClick handler. */
  onClickClose: PropTypes.func,
};

export const AudioPlayer: MemoExoticComponent<typeof AudioPlayerBase> & {
  DRAGGABLE_CLASSNAME?: typeof DRAGGABLE_CLASSNAME;
} = memo(AudioPlayerBase);
AudioPlayer.DRAGGABLE_CLASSNAME = DRAGGABLE_CLASSNAME;
