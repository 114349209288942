import { memo } from 'react';
import { createPortal } from 'react-dom';
import {
  CloseButtonProps as ToastifyCloseButtonProps,
  ToastContainer as ToastifyToastContainer,
  ToastContainerProps as ToastifyToastContainerProps,
} from 'react-toastify';

import { TOAST_ROOT_ID } from '../../meta/dialogs-provider/constants';

import { ToastCloseButton } from './close-button/ToastCloseButton';

const closeButton = (props: ToastifyCloseButtonProps) => (
  <ToastCloseButton {...props} />
);

/**
 * This is a wrapper around ToastContainer from react-toastify to set our default props.
 */
export const ToastContainerBase = (props: ToastifyToastContainerProps) => {
  // Init the modal root at the bottom of the body.
  if (!document.getElementById(TOAST_ROOT_ID)) {
    const modalRoot = document.createElement('div');
    modalRoot.setAttribute('id', TOAST_ROOT_ID);
    document.body.appendChild(modalRoot);
  }

  return createPortal(
    <ToastifyToastContainer
      closeButton={closeButton}
      icon={false}
      theme="colored"
      {...props}
    />,
    document.getElementById(TOAST_ROOT_ID),
  );
};

ToastContainerBase.displayName = 'ToastContainer';

ToastContainerBase.propTypes = {};

export const ToastContainer = memo(ToastContainerBase);
