import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { css, useTheme } from '@emotion/react';
import { useIntl } from 'react-intl';
import { Delete, FileSuccess } from '@icon-park/react';

import { Upload } from '@eversity/types/domain';

import { ICON_SIZES } from '../../../general/icon/constants';
import { TYPOGRAPHY_VARIANTS } from '../../../../config/typography/constants';
import { fileWithIdPropTypes, uploadPropTypes } from '../../../../types';
import { FileWithId } from '../types';

import { Button } from '../../../general/button/Button';
import { Typography } from '../../../general/typography/Typography';

import messages from './FileUploaded.messages';

export type FileUploadedProps = {
  file: Upload | FileWithId;
  useFileAsValue?: boolean;
  onRemove?: (fileIdOrFile: string | File) => void;
  disabled?: boolean;
};

export const FileUploadedBase = ({
  file,
  useFileAsValue = false,
  onRemove = noop,
  disabled = false,
}: FileUploadedProps) => {
  const intl = useIntl();
  const theme = useTheme();

  const onRemoveProxy = useCallback(() => onRemove(file.id), [onRemove, file]);

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 7px 10px;

        border-radius: 4px;
        border: 1px solid ${theme.colors.gray[200]};

        background-color: ${theme.colors.gray[0]};
      `}
    >
      <FileSuccess
        size={ICON_SIZES.LARGE}
        fill={[theme.colors.gray[500], theme.colors.gray[25]]}
      />

      <div
        css={css`
          flex: 1;
          color: ${theme.contrastColors.gray[0]};
          overflow-wrap: anywhere;

          display: flex;
          align-items: center;
          min-height: ${parseInt(
            theme.typography[TYPOGRAPHY_VARIANTS.BODY_MEDIUM_REGULAR]
              .lineHeight,
            10,
          ) +
          parseInt(
            theme.typography[TYPOGRAPHY_VARIANTS.BODY_SMALL_BOLD].lineHeight,
            10,
          )}px;
        `}
      >
        <Typography variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}>
          {useFileAsValue
            ? (file as FileWithId).file.name
            : (file as Upload).fileName}
        </Typography>
      </div>

      <Button
        aria-label={intl.formatMessage(messages.REMOVE_FILE)}
        icon={<Delete />}
        onClick={onRemoveProxy}
        disabled={disabled}
      />
    </div>
  );
};

FileUploadedBase.displayName = 'FileUploaded';

FileUploadedBase.propTypes = {
  /** File data. */
  file: PropTypes.oneOfType([uploadPropTypes, fileWithIdPropTypes]).isRequired,
  /** Call with the file id to remove it from the form. */
  onRemove: PropTypes.func,
  /** Is the remove button disabled. */
  disabled: PropTypes.bool,
};

export const FileUploaded = memo(FileUploadedBase);
