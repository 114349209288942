import { memo, MemoExoticComponent, ReactNode } from 'react';
import PropTypes from 'prop-types';

import { ComponentSwitchContext } from './contexts/ComponentSwitch.context';
import { ComponentSwitchItem } from './ComponentSwitchItem';

export type ComponentSwitchProps = {
  value?: string | null;
  children?: ReactNode;
};

export const ComponentSwitchBase = ({
  value = null,
  children = undefined,
}: ComponentSwitchProps) => (
  <ComponentSwitchContext.Provider value={value}>
    {children}
  </ComponentSwitchContext.Provider>
);

ComponentSwitchBase.displayName = 'ComponentSwitch';

ComponentSwitchBase.propTypes = {
  /** Value of the currently active item. */
  value: PropTypes.string,
  /** Children. */
  children: PropTypes.node,
};

export const ComponentSwitch: MemoExoticComponent<
  typeof ComponentSwitchBase
> & { Item?: typeof ComponentSwitchItem } = memo(ComponentSwitchBase);

ComponentSwitch.Item = ComponentSwitchItem;
