import { Fragment, memo, ReactNode, useContext } from 'react';
import PropTypes from 'prop-types';

import { ComponentSwitchContext } from './contexts/ComponentSwitch.context';

export type ComponentSwitchItemProps = {
  value: string;
  children?: ReactNode;
};

export const ComponentSwitchItemBase = ({
  value,
  children = null,
}: ComponentSwitchItemProps) => {
  const currentValue = useContext(ComponentSwitchContext);

  return currentValue === value ? (
    // Need to use JSX to make docgen and eslint detect this as a component.
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <Fragment>{children}</Fragment>
  ) : null;
};

ComponentSwitchItemBase.displayName = 'ComponentSwitchItem';

ComponentSwitchItemBase.propTypes = {
  /** Value to compare to ComponentSwitch value. */
  value: PropTypes.string.isRequired,
  /** Children only displayed if this item is the current item. */
  children: PropTypes.node,
};

export const ComponentSwitchItem = memo(ComponentSwitchItemBase);
