import {
  CreateVirtualClassroomBody,
  GetVirtualClassroomQuery,
  GetVirtualClassroomsQuery,
  UpdateVirtualClassroomBody,
  UpdateVirtualClassroomQuery,
  VirtualClassroomViewFull,
  VirtualClassroomViewMinimal,
  VirtualClassroomViewStudent,
  LessonViewMinimal,
} from '@eversity/types/domain';

import { HttpRepository } from '../httpRepository';

const VIRTUAL_CLASSROOMS_API_URI = '/api/v1/school/virtual-classrooms';
const VIRTUAL_CLASSROOMS_TAGS_API_URI =
  '/api/v1/school/virtual-classrooms-tags';
const VIRTUAL_CLASSROOMS_FILES_API_URI =
  '/api/v1/file/downloads/virtual-classrooms';

const e = encodeURIComponent;

export class VirtualClassroomsRepository extends HttpRepository {
  /**
   * Get the virtual classrooms.
   *
   * @param queryParams - Query parameters.
   * @param queryParams.limit - Limit the number of items in result.
   * @param queryParams.offset - Skip the number of items.
   * @param queryParams.startDate - Search for VC after the startDate.
   * @param queryParams.endDate - Search for VC before the endDate.
   * @param queryParams.q - Search by title.
   * @param queryParams.presenters - Search by presenters id.
   * @param queryParams.tags - Search by tags.
   * @param queryParams.types - Search by virtual classroom types.
   * @param queryParams.sortBy - Sort by type.
   * @returns Result with count of VC hit by query and items paginated.
   */
  async getVirtualClassrooms(queryParams: GetVirtualClassroomsQuery): Promise<{
    count: number;
    virtualClassrooms: VirtualClassroomViewMinimal[];
  }> {
    const { body } = await this.http
      .get(VIRTUAL_CLASSROOMS_API_URI)
      .query(queryParams);

    return body;
  }

  /** Get a virtual classroom info.
   *
   * @param virtualClassroomId - Virtual classroom id.
   * @param query - Query parameters.
   * @param query.view - View to use (MIN or FULL).
   * @returns Virtual classroom object.
   */
  async getVirtualClassroom(
    virtualClassroomId: string,
    { view }: GetVirtualClassroomQuery = {},
  ): Promise<
    | VirtualClassroomViewMinimal
    | VirtualClassroomViewFull
    | VirtualClassroomViewStudent
  > {
    const { body } = await this.http
      .get(`${VIRTUAL_CLASSROOMS_API_URI}/${e(virtualClassroomId)}`)
      .query({ view });

    return body;
  }

  /**
   * Update a virtual classroom.
   *
   * @param virtualClassroomId - Virtual classroom id.
   * @param updates - Fields to update.
   * @returns Updated virtual classroom.
   */
  async updateVirtualClassroom(
    virtualClassroomId: string,
    updates: UpdateVirtualClassroomBody,
    query?: UpdateVirtualClassroomQuery,
  ): Promise<VirtualClassroomViewFull> {
    const { body } = await this.http
      .patch(`${VIRTUAL_CLASSROOMS_API_URI}/${e(virtualClassroomId)}`)
      .query(query)
      .send(updates);

    return body;
  }

  /**
   * Delete a virtual classroom.
   *
   * @param virtualClassroomId - Virtual classroom id.
   */
  async deleteVirtualClassroom(virtualClassroomId: string): Promise<void> {
    await this.http.delete(
      `${VIRTUAL_CLASSROOMS_API_URI}/${e(virtualClassroomId)}`,
    );
  }

  async getVirtualClassroomsTags(): Promise<string[]> {
    const { body } = await this.http.get(VIRTUAL_CLASSROOMS_TAGS_API_URI);

    return body;
  }

  async postVirtualClassroom(
    params: CreateVirtualClassroomBody,
  ): Promise<VirtualClassroomViewFull> {
    const { body } = await this.http
      .post(`${VIRTUAL_CLASSROOMS_API_URI}/`)
      .send(params);

    return body;
  }

  async downloadVirtualClassroomAttachment({
    virtualClassroomId,
    uploadId,
  }: {
    virtualClassroomId: string;
    uploadId: string;
  }): Promise<{ body: Blob; type: string }> {
    const { body, type } = await this.http
      .get(
        `${VIRTUAL_CLASSROOMS_FILES_API_URI}/${e(
          virtualClassroomId,
        )}/attachments/${e(uploadId)}`,
      )
      .responseType('blob');

    return { body, type };
  }

  async addLessonsToVirtualClassroom(
    virtualClassroomId: string,
    lessonId: string,
  ): Promise<LessonViewMinimal> {
    const { body } = await this.http
      .post(`${VIRTUAL_CLASSROOMS_API_URI}/${e(virtualClassroomId)}/lessons`)
      .send({
        lessonId,
      });

    return body;
  }

  async deleteLessonsFromVirtualClassroom(
    virtualClassroomId: string,
    lessonId: string,
  ): Promise<boolean> {
    const { status } = await this.http.delete(
      `${VIRTUAL_CLASSROOMS_API_URI}/${e(virtualClassroomId)}/lessons/${e(
        lessonId,
      )}`,
    );

    return status === 204;
  }
}
