import { memo } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { ImageFiles } from '@icon-park/react';

import * as styles from './LessonThumbnail.styles';

export type LessonThumbnailProps = {
  href?: string;
};

export const LessonThumbnailBase = ({
  href = undefined,
}: LessonThumbnailProps) => {
  const theme = useTheme();

  return href ? (
    <div css={styles.thumbnailImage(href)} />
  ) : (
    <div css={styles.placeholderImage}>
      <ImageFiles
        size={48}
        theme="outline"
        fill={theme.colors.primary[500]}
        strokeWidth={1}
      />
    </div>
  );
};

LessonThumbnailBase.displayName = 'LessonThumbnail';

LessonThumbnailBase.propTypes = {
  href: PropTypes.string,
};

export const LessonThumbnail = memo(LessonThumbnailBase);
