import { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  AlertDialog,
  AlertDialogProps,
  HtmlFormatter,
  Typography,
} from '@eversity/ui/design-system';

import messages from './QuestionItemRemoveAlertDialog.messages';

export type QuestionItemRemoveAlertDialogProps = AlertDialogProps & {
  questionLabel?: string;
};

export const QuestionItemRemoveAlertDialogBase = ({
  questionLabel = null,
  ...props
}: QuestionItemRemoveAlertDialogProps) => (
  <AlertDialog
    variant={AlertDialog.VARIANTS.WARNING}
    {...props}
  >
    <HtmlFormatter>
      <Typography variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}>
        <FormattedMessage {...messages.REMOVE_QUESTION} />
      </Typography>

      <Typography variant={Typography.VARIANTS.HEADING_4}>
        {questionLabel}
      </Typography>
    </HtmlFormatter>
  </AlertDialog>
);

QuestionItemRemoveAlertDialogBase.displayName = 'QuestionItemRemoveAlertDialog';

QuestionItemRemoveAlertDialogBase.propTypes = {
  questionLabel: PropTypes.string,
};

export const QuestionItemRemoveAlertDialog = memo(
  QuestionItemRemoveAlertDialogBase,
);
