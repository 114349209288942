import { Fragment, memo, ReactNode } from 'react';
import PropTypes from 'prop-types';

import { BoolStateValue, useBoolState } from '../../hooks/useBoolState';

export type BoolStateProps = {
  initial?: boolean;
  children?: (boolState: BoolStateValue) => ReactNode | undefined;
};

const defaultChildren = () => null;

export const BoolStateBase = ({
  initial = false,
  children = defaultChildren,
}: BoolStateProps) => {
  const boolState = useBoolState(initial);

  return <Fragment>{children(boolState)}</Fragment>;
};

BoolStateBase.displayName = 'BoolState';

BoolStateBase.propTypes = {
  // Initial state of the boolean. Defaults to false.
  initial: PropTypes.bool,
  children: PropTypes.func,
};

export const BoolState = memo(BoolStateBase);
