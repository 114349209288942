import { memo, Fragment, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Typography } from '../typography/Typography';

export type LineSeparatorProps = {
  label?: ReactNode;
  className?: string;
};

export const LineSeparatorBase = ({
  label = undefined,
  className = undefined,
}: LineSeparatorProps) => (
  <div
    css={css`
      display: flex;
      align-items: center;
      padding: 10px 0;
    `}
    className={className}
  >
    <div
      css={(theme) => css`
        flex: 1;
        height: 1px;
        background-color: ${theme.colors.gray[100]};
      `}
    />

    {!!label && (
      <Fragment>
        <div
          css={css`
            padding: 0 10px;
          `}
        >
          <Typography variant={Typography.VARIANTS.BODY_SMALL_REGULAR}>
            {label}
          </Typography>
        </div>

        <div
          css={(theme) => css`
            flex: 1;
            height: 1px;
            background-color: ${theme.colors.gray[100]};
          `}
        />
      </Fragment>
    )}
  </div>
);

LineSeparatorBase.displayName = 'LineSeparator';

LineSeparatorBase.propTypes = {
  label: PropTypes.node,
  className: PropTypes.string,
};

export const LineSeparator = memo(LineSeparatorBase);
