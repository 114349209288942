import { useCallback, FocusEvent } from 'react';

import { useBoolState } from './useBoolState';

export const useFocus = <TElement>({
  onFocus,
  onBlur,
}: {
  onFocus?: (event: FocusEvent<TElement>) => void;
  onBlur?: (event: FocusEvent<TElement>) => void;
} = {}): {
  isFocused: boolean;
  onFocus: (event: FocusEvent<TElement>) => void;
  onBlur: (event: FocusEvent<TElement>) => void;
} => {
  const [isFocused, onStartFocus, onStopFocus] = useBoolState(false);

  const onFocusProxy = useCallback(
    (event: FocusEvent<TElement>) => {
      onStartFocus();
      onFocus?.(event);
    },
    [onStartFocus, onFocus],
  );

  const onBlurProxy = useCallback(
    (event: FocusEvent<TElement>) => {
      onStopFocus();
      onBlur?.(event);
    },
    [onStopFocus, onBlur],
  );

  return {
    isFocused,
    onFocus: onFocusProxy,
    onBlur: onBlurProxy,
  };
};
