import { memo, forwardRef, useContext, ForwardedRef, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { To } from 'react-router-dom';
import cn from 'classnames';

import { linkPathPropTypes } from '@eversity/ui/utils';

import { Link } from '../../link/Link';
import { Typography } from '../../../general/typography/Typography';

import * as styles from './TableOfContentsSubSectionItem.styles';
import { TableOfContentsSectionContext } from '../section/TableOfContentsSection.context';

export type TableOfContentsSubSectionItemProps = {
  label: ReactNode;
  href?: To;
  isSelected?: boolean;
};

export const TableOfContentsSubSectionItemBase = forwardRef(
  (
    { label, href, isSelected }: TableOfContentsSubSectionItemProps,
    ref: ForwardedRef<HTMLLIElement>,
  ) => {
    const { variant } = useContext(TableOfContentsSectionContext);

    return (
      <li ref={ref}>
        <Link
          to={href}
          isDisabled={!href}
          className={cn(variant, { isSelected })}
          css={styles.link}
        >
          <div css={styles.label}>
            <Typography variant={Typography.VARIANTS.BODY_SMALL_REGULAR}>
              {label}
            </Typography>
          </div>
        </Link>
      </li>
    );
  },
);

TableOfContentsSubSectionItemBase.displayName = 'TableOfContentsSubSectionItem';

TableOfContentsSubSectionItemBase.propTypes = {
  /** Text label. Hidden when collapsed. */
  label: PropTypes.node.isRequired,
  /** Link href. */
  href: linkPathPropTypes,
  /** Is the TableOfContents sub section item currently selected. */
  isSelected: PropTypes.bool,
};

TableOfContentsSubSectionItemBase.defaultProps = {
  href: null,
  isSelected: false,
};

export const TableOfContentsSubSectionItem = memo(
  TableOfContentsSubSectionItemBase,
);
