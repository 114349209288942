import { useContext } from 'react';

import { useLayer } from './useLayer';

import { DIALOG_LAYER_TYPE } from '../../components/meta/dialogs-provider/constants';
import {
  DialogLayer,
  DialogsContext,
} from '../../components/meta/dialogs-provider/DialogsContext';

export const useDialog = ({
  isOpen,
  shouldCloseOnEscape,
  preventClosing,
  onRequestClose,
}: {
  isOpen: boolean;
  shouldCloseOnEscape?: boolean;
  preventClosing?: boolean;
  onRequestClose?: () => void;
}) => {
  const {
    layerId,
    layerIndex,
    isTopMostLayer,
    layerZIndex,
    numberOfLayersAbove,
  } = useLayer<DialogLayer>({
    isOpen,
    shouldCloseOnEscape,
    preventClosing,
    onRequestClose,
    type: DIALOG_LAYER_TYPE,
  });

  const { dialogLayers } = useContext(DialogsContext);

  const dialogLayerIndex = dialogLayers.findIndex(({ id }) => id === layerId);
  const dialogLayersCount = dialogLayers.length;

  return {
    layerId,
    layerIndex,
    isTopMostLayer,
    numberOfLayersAbove,
    layerZIndex,
    dialogLayerIndex,
    dialogLayersCount,
    numberOfDialogLayersAbove:
      dialogLayerIndex >= 0 ? dialogLayers.length - dialogLayerIndex - 1 : 0,
  };
};
