import { css, Theme } from '@emotion/react';

import { VARIANTS } from '../../constants';

export const container = css`
  display: flex;
  gap: 20px;

  &.${VARIANTS.HORIZONTAL} {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  &.${VARIANTS.VERTICAL} {
    min-height: 100px;
    align-items: stretch;
  }
`;

export const progress = css`
  display: flex;
  align-items: center;
  gap: 10px;

  &.${VARIANTS.VERTICAL} {
    flex-direction: column;
  }

  &.${VARIANTS.HORIZONTAL} {
    width: 100%;
  }
`;

export const badge = (theme: Theme) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: none;

  width: 28px;
  height: 28px;

  padding: 6px;

  background-color: ${theme.colors.gray[0]};
  color: ${theme.colors.gray[700]};
  border: 1px solid ${theme.colors.gray[100]};
  border-radius: 8px;

  transition: ${theme.transitions.default()};

  &.isDisabled {
    color: ${theme.colors.gray[400]};
  }

  &.isCurrent {
    color: ${theme.colors.gray[0]};
    background-color: ${theme.colors.primary[500]};

    border: none;

    box-shadow: 0px 0px 0px 3px ${theme.colors.primary[25]};
  }

  &.isDone {
    color: ${theme.colors.gray[0]};
    background-color: ${theme.colors.success[500]};

    border: none;
  }

  &.isIncomplete {
    color: ${theme.colors.gray[0]};
    background-color: ${theme.colors.danger[500]};

    border: none;
  }
`;

export const separator = (theme: Theme) => css`
  flex: 1;

  border-radius: 2px;

  background-color: ${theme.colors.gray[100]};

  background-color: ${theme.colors.gray[100]};

  &.${VARIANTS.HORIZONTAL} {
    height: 2px;

    &.isCurrent:not(.isDone) {
      background: linear-gradient(
        90deg,
        ${theme.colors.primary[500]} 0%,
        ${theme.colors.primary[500]} 50%,
        ${theme.colors.gray[100]} 50%,
        ${theme.colors.gray[100]} 100%
      );
    }
  }

  &.${VARIANTS.VERTICAL} {
    width: 2px;

    &.isCurrent:not(.isDone) {
      background: linear-gradient(
        180deg,
        ${theme.colors.primary[500]} 0%,
        ${theme.colors.primary[500]} 50%,
        ${theme.colors.gray[100]} 50%,
        ${theme.colors.gray[100]} 100%
      );
    }
  }

  &.isDone {
    background-color: ${theme.colors.success[500]};
  }

  &.isIncomplete {
    background-color: ${theme.colors.danger[500]};
  }
`;

export const text = css`
  display: flex;
  flex-direction: column;
`;

export const stepIndex = (theme: Theme) => css`
  text-transform: uppercase;
  color: ${theme.colors.gray[400]};
`;

export const tag = css`
  margin-top: 8px;
`;
