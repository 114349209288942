import { USER_VIEWS } from '@eversity/domain/constants';
import {
  GetStudentsQuery,
  GetUserSessionsQuery,
  Session,
  StudentAcademicTranscript,
  StudentViewFull,
  StudentViewMinimal,
  UpdateStudentBody,
  UserKpis,
} from '@eversity/types/domain';

import { HttpRepository } from '../httpRepository';

const e = encodeURIComponent;

export class StudentsRepository extends HttpRepository {
  async getStudents(
    query?: Omit<GetStudentsQuery, 'view'> & {
      view: USER_VIEWS.FULL | USER_VIEWS.MIN;
    },
  ): Promise<{
    count: number;
    users: StudentViewFull[] | StudentViewMinimal[];
  }> {
    const {
      body: { count, users },
    } = await this.http.get('/api/v1/users/students/').query(query);

    return {
      count,
      users,
    };
  }

  async updateStudent(
    userId: string,
    params: Pick<UpdateStudentBody, 'picture'>,
  ): Promise<StudentViewFull> {
    const { body: user } = await this.http
      .patch(`/api/v1/users/students/${e(userId)}`)
      .send(params);

    return user;
  }

  /**
   * Delete a profile picture from a student.
   *
   * @param userId - Student id.
   * @param pictureId - Picture id.
   * @returns Updated user.
   */
  async deleteProfilePicture(
    userId: string,
    pictureId: string,
  ): Promise<StudentViewFull> {
    const { body: updatedStudent } = await this.http
      .delete(
        `/api/v1/users/students/${e(userId)}/profile-pictures/${pictureId}`,
      )
      .send();

    return updatedStudent;
  }

  /**
   * Fetch the student's academic transcript for a course.
   *
   * @param courseId - Id of the course.
   * @param classId - Id of the class.
   * @returns Transcript data.
   */
  async getCurrentStudentAcademicTranscript(
    courseId: string,
    classId: string,
  ): Promise<StudentAcademicTranscript> {
    const { body: transcript } = await this.http
      .get('/api/v1/users/users/me/academic-transcript')
      .query({
        courseId,
        classId,
      });

    return transcript;
  }

  /**
   * Fetch the student's academic transcript for a course.
   *
   * @param studentId - Id of the student.
   * @param courseId - Id of the course.
   * @param classId - Id of the class.
   * @returns Transcript data.
   */
  async getStudentAcademicTranscript(
    studentId: string,
    courseId: string,
    classId: string,
  ): Promise<StudentAcademicTranscript> {
    const { body: transcript } = await this.http
      .get(`/api/v1/users/students/${e(studentId)}/academic-transcript`)
      .query({
        courseId,
        classId,
      });

    return transcript;
  }

  /**
   * Fetch the student's KPIs for a course.
   *
   * @param studentId - Id of the student.
   * @param courseId - Id of the course.
   * @param classId - Id of the class.
   * @returns KPIs data.
   */
  async getStudentKpis(
    studentId: string,
    courseId: string,
    classId: string,
  ): Promise<UserKpis> {
    const { body } = await this.http
      .get(`/api/v1/users/students/${e(studentId)}/kpis`)
      .query({
        courseId,
        classId,
      });

    return body;
  }

  /**
   * Fetch the student's sessions.
   * @param studentId - Id of the student.
   * @param query - Query params.
   * @returns - Paginated sorted list of sessions.
   */
  async getStudentSessions(
    studentId: string,
    query: GetUserSessionsQuery,
  ): Promise<Session[]> {
    const { body } = await this.http
      .get(`/api/v1/users/students/${e(studentId)}/sessions`)
      .query(query);

    return body;
  }
}
