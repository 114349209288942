import { HTMLProps, memo, MemoExoticComponent } from 'react';
import PropTypes from 'prop-types';

import { VARIANTS } from './WaveCard.constants';
import * as styles from './WaveCard.styles';
import { HUE_GROUPS } from '../../../config/colors/constants';

export type WaveCardProps = Omit<HTMLProps<HTMLDivElement>, 'ref'> & {
  variant?: VARIANTS;
  hue?: HUE_GROUPS;
};

export const WaveCardBase = ({
  variant = VARIANTS.PRIMARY,
  hue = null,
  children = null,
  ...props
}: WaveCardProps) => (
  <div
    css={(theme) => styles.container(theme, variant, hue)}
    {...props}
  >
    <div css={(theme) => styles.waveThree(theme, variant, hue)} />

    <div css={(theme) => styles.waveTwo(theme, variant, hue)} />

    <div css={(theme) => styles.waveOne(theme, variant, hue)} />

    <div css={(theme) => styles.content(theme, variant, hue)}>{children}</div>
  </div>
);

WaveCardBase.displayName = 'WaveCard';

WaveCardBase.propTypes = {
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  hue: PropTypes.oneOf(Object.values(HUE_GROUPS)),
  children: PropTypes.node,
};

export const WaveCard: MemoExoticComponent<typeof WaveCardBase> & {
  VARIANTS?: typeof VARIANTS;
  HUES?: typeof HUE_GROUPS;
} = memo(WaveCardBase);

WaveCard.VARIANTS = VARIANTS;
WaveCard.HUES = HUE_GROUPS;
