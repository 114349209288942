import { ForwardedRef, forwardRef, HTMLProps, memo } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

export type TruncateProps = Omit<HTMLProps<HTMLDivElement>, 'ref'> & {
  isExpanded?: boolean;
  maxLines: number;
  textOverflow?: string;
};

export const TruncateBase = forwardRef(
  (
    { isExpanded, maxLines, textOverflow, ...props }: TruncateProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => (
    <div
      {...props}
      ref={ref}
      css={
        !isExpanded &&
        css`
          display: -webkit-box;
          -webkit-line-clamp: ${maxLines};
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ${textOverflow};
        `
      }
    />
  ),
);

TruncateBase.displayName = 'Truncate';

TruncateBase.propTypes = {
  /** Show ellipsis or not. */
  isExpanded: PropTypes.bool,
  /** Max number of lines to display. */
  maxLines: PropTypes.number.isRequired,
  /** CSS text-overflow. */
  textOverflow: PropTypes.string,
};

TruncateBase.defaultProps = {
  isExpanded: false,
  textOverflow: 'ellipsis',
};

export const Truncate = memo(TruncateBase);
