import { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import prettyBytes from 'pretty-bytes';

export type FormattedFileSizeProps = {
  value: number;
};

export const FormattedFileSizeBase = ({ value }: FormattedFileSizeProps) => (
  <Fragment>{prettyBytes(value)}</Fragment>
);

FormattedFileSizeBase.displayName = 'FormattedFileSize';

FormattedFileSizeBase.propTypes = {
  value: PropTypes.number.isRequired,
};

export const FormattedFileSize = memo(FormattedFileSizeBase);
