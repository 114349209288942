import { memo, MemoExoticComponent, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';

import { UseWizard, useWizard } from '../../hooks/useWizard';

import { WizardContext } from './contexts/Wizard.context';
import { WizardStepWithContext } from './step/WizardStepWithContext';

type WizardProps = {
  steps: string[];
  initialStep?: string;
  children?: (wizardValue: UseWizard) => ReactNode | ReactNode;
};

const defaultSteps: string[] = [];

export const WizardBase = ({
  steps = defaultSteps,
  initialStep = undefined,
  children = undefined,
}: WizardProps) => {
  const wizard = useWizard(steps, initialStep);

  return (
    <WizardContext.Provider value={wizard}>
      {isFunction(children) ? children(wizard) : children}
    </WizardContext.Provider>
  );
};

WizardBase.displayName = 'Wizard';

WizardBase.propTypes = {
  /**
   * List of names of steps in the wizard, in the order of the steps.
   * Make sure that the reference does not change or it will reset the current step.
   */
  steps: PropTypes.arrayOf(PropTypes.string),
  /** Current step name. */
  initialStep: PropTypes.string,
  /** Children. If a function, called with the useWizard return value. */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

export const Wizard: MemoExoticComponent<typeof WizardBase> & {
  Step?: typeof WizardStepWithContext;
} = memo(WizardBase);

Wizard.Step = WizardStepWithContext;
