import { useCallback, useEffect, useRef, useState } from 'react';
import { useBoolState } from './useBoolState';

/**
 * Check if a node needs to be truncated.
 *
 * @param params - Params.
 * @param params.initialState - Initial expanded state. Passed to useState.
 * @param [params.maxLines] - Max number of lines. Skip if maxLines is constant.
 * @returns
 */
export const useTruncate = ({
  initialState = false,
  maxLines,
}: {
  initialState?: boolean;
  maxLines?: number;
} = {}): {
  ref: (node: HTMLElement) => void;
  isExpanded: boolean;
  contentOverflows: boolean;
  onExpand: () => void;
  onCollapse: () => void;
  onToggleExpand: () => void;
} => {
  const [isExpanded, onExpand, onCollapse, onToggleExpand] =
    useBoolState(initialState);
  const [contentOverflows, onSetContentOverflows] = useState(false);
  const nodeRef = useRef<HTMLElement>();

  const onUpdateContentOverflows = useCallback(
    () =>
      onSetContentOverflows(
        !!(
          (nodeRef.current?.offsetHeight || 0) <
          (nodeRef.current?.scrollHeight || 0)
        ),
      ),
    [],
  );

  /**
   * https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
   */
  const ref = useCallback(
    (node: HTMLElement) => {
      nodeRef.current = node;
      onUpdateContentOverflows();
    },
    [onUpdateContentOverflows],
  );

  /** Recompute contentOverflows if maxLines changes. */
  useEffect(
    () => onUpdateContentOverflows(),
    [maxLines, onUpdateContentOverflows],
  );

  return {
    ref,
    isExpanded,
    contentOverflows,
    onExpand,
    onCollapse,
    onToggleExpand,
  };
};
