import { css, Theme } from '@emotion/react';

export const containerStyle = (theme: Theme) => css`
  width: 100%;
  text-decoration: none;

  display: flex;
  align-items: stretch;

  border-radius: 4px 0 0 4px;
  border-right: 2px solid transparent;

  padding: 4px;

  color: ${theme.colors.gray[700]};

  transition: ${theme.transitions.default()};

  &:not(.isDisabled) {
    &:hover,
    &.isSelected {
      background-color: ${theme.colors.primary[25]};
      color: ${theme.colors.primary[500]};
    }
  }

  &.isDisabled {
    color: ${theme.colors.gray[300]};

    &.isSelected {
      background-color: ${theme.colors.gray[25]};
    }
  }

  &.isCollapsed {
    border-radius: 4px;
    border-right: none;
  }

  &.isSelected:not(.isCollapsed) {
    border-right-color: ${theme.colors.primary[500]};

    &.isDisabled {
      border-right-color: ${theme.colors.gray[300]};
    }
  }
`;

export const iconContainerStyle = (theme: Theme) => css`
  padding: 4px;
  border-radius: 4px;

  display: flex;
  align-items: center;

  flex: none;

  transition: ${theme.transitions.default()};

  &.isSelected&.withIcons {
    background-color: ${theme.colors.primary[500]};

    &.isDisabled {
      background-color: ${theme.colors.gray[300]};
    }
  }
`;

export const labelStyle = (theme: Theme) => css`
  /**
   * Margin right should be 10px, but since we shift 4px to the right when selecting,
   * we reserve that space so the text always has the same width
   * and does not wrap only when selected (it makes the entire menu move up and down).
   */
  margin: 4px 14px 4px 10px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  word-break: break-word;

  flex: 1;

  transition: ${theme.transitions.default('margin-left', 'margin-right')};

  &.isSelected {
    /** When selecting, shift to the right and decrease right margin. */
    margin-left: 14px;
    margin-right: 10px;
  }
`;

export const iconBadgeContainerStyle = css`
  position: relative;
`;

export const badgeStyle = css`
  position: absolute;
  left: -10px;
  top: -10px;
`;

export const dot = (theme: Theme) => css`
  position: absolute;
  right: -5px;
  bottom: -5px;
  width: 12px;
  height: 12px;
  border: 2px solid ${theme.colors.gray[0]};
  border-radius: 100%;
  background-color: ${theme.colors.danger[500]};
`;

export const tagsStyle = css`
  display: flex;
  align-items: center;
  gap: 4px;

  margin-top: 2px;
`;

export const moreTagsStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const more = (theme: Theme) => css`
  color: ${theme.colors.primary[500]};
  text-decoration: underline;
`;

export const getIconFills = (
  theme: Theme,
  isDisabled: boolean,
  isSelected: boolean,
) => {
  if (isDisabled) {
    return isSelected
      ? [theme.colors.gray[0], theme.colors.gray[200]]
      : [theme.colors.gray[200], theme.colors.gray[25]];
  }

  return isSelected
    ? [theme.colors.gray[0], theme.colors.primary[400]]
    : [theme.colors.primary[500], theme.colors.primary[50]];
};

export const dragStyle = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  cursor: grab;

  padding: 2px;
`;
