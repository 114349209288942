import {
  Children,
  cloneElement,
  memo,
  MouseEvent,
  MemoExoticComponent,
  ReactElement,
  ReactNode,
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Right, DeathStar, CloseOne, CheckOne } from '@icon-park/react';
import { noop } from 'lodash';
import { Collapse } from 'react-collapse';
import { ClassNames, useTheme } from '@emotion/react';
import { To } from 'react-router-dom';

import { linkPathPropTypes, useMemoizedBundle } from '@eversity/ui/utils';

import { ICON_SIZES } from '../../../general/icon/constants';
import { SECTION_STATES, SECTION_VARIANTS } from './constants';

import { TableOfContentsSectionContext } from './TableOfContentsSection.context';
import { Link } from '../../link/Link';
import { Typography } from '../../../general/typography/Typography';

import * as styles from './TableOfContentsSection.styles';

export type TableOfContentsSectionProps = {
  children?: ReactNode;
  icon?: ReactElement;
  label?: ReactNode;
  href?: To;
  subLabel?: ReactNode;
  isSelected?: boolean;
  state?: SECTION_STATES;
  variant?: SECTION_VARIANTS;
  isOpen?: boolean;
  onToggleOpen?: (event: MouseEvent<HTMLButtonElement>) => void;
};

export const TableOfContentsSectionBase = ({
  children = null,
  icon = <DeathStar />,
  label = null,
  href = null,
  subLabel = null,
  isSelected = false,
  state = SECTION_STATES.NONE,
  variant = SECTION_VARIANTS.PRIMARY,
  isOpen = false,
  onToggleOpen = noop,
}: TableOfContentsSectionProps) => {
  const theme = useTheme();

  const isFinished = state !== SECTION_STATES.NONE;

  const [iconBackgroundColor, ...iconColors] = styles.getIconFills(
    theme,
    variant,
    isFinished,
    isSelected,
  );

  const contextValue = useMemoizedBundle({ variant });

  const canExpand = !!Children.toArray(children).filter(Boolean).length;

  return (
    <TableOfContentsSectionContext.Provider value={contextValue}>
      <li>
        <div
          css={styles.header}
          className={cn(variant, {
            isSelected,
            isFinished,
          })}
        >
          <div
            css={styles.icon(theme, iconBackgroundColor)}
            className={cn({
              isSelected,
              isFinished,
            })}
          >
            <div>
              {cloneElement(icon, {
                size: ICON_SIZES.LARGE,
                fill: iconColors,
              })}

              {isFinished && (
                <div css={styles.iconState}>
                  {state === SECTION_STATES.COMPLETED ? (
                    <CheckOne
                      size={ICON_SIZES.SMALL}
                      fill={[theme.colors.gray[0], theme.colors.primary[500]]}
                    />
                  ) : (
                    <CloseOne
                      size={ICON_SIZES.SMALL}
                      fill={[theme.colors.gray[0], theme.colors.danger[500]]}
                    />
                  )}
                </div>
              )}
            </div>
          </div>

          <Link
            to={href}
            isDisabled={!href}
            css={styles.link}
          >
            <div css={styles.label}>
              {!!subLabel && (
                <Typography variant={Typography.VARIANTS.BUTTON_SMALL_REGULAR}>
                  {subLabel}
                </Typography>
              )}

              <Typography variant={Typography.VARIANTS.BUTTON_LARGE_REGULAR}>
                {label}
              </Typography>
            </div>
          </Link>

          {canExpand && (
            <button
              type="button"
              onClick={onToggleOpen}
              css={styles.arrow}
              className={cn({ isOpen })}
            >
              <Right size={12} />
            </button>
          )}
        </div>

        {canExpand && (
          <ClassNames>
            {({ css }) => (
              <Collapse
                isOpened={isOpen}
                theme={{
                  collapse: css`
                    transition: ${theme.transitions.default('height')};
                  `,
                }}
              >
                <ul css={styles.list}>{children}</ul>
              </Collapse>
            )}
          </ClassNames>
        )}
      </li>
    </TableOfContentsSectionContext.Provider>
  );
};

TableOfContentsSectionBase.displayName = 'TableOfContentsSection';

TableOfContentsSectionBase.propTypes = {
  /** Children (table of content sub section items) */
  children: PropTypes.node,
  /** Left icon */
  icon: PropTypes.node,
  /** Sub section label. */
  label: PropTypes.node,
  /** Link href. */
  href: linkPathPropTypes,
  /** Text display below label. */
  subLabel: PropTypes.node,
  /** Is the TableOfContents sub section item currently selected. */
  isSelected: PropTypes.bool,
  /** Is the section currently open. Always use with onToggleOpen. */
  isOpen: PropTypes.bool,
  /** Toggle open state. Always use with isOpen. */
  onToggleOpen: PropTypes.func,
  /** Current state of the section. */
  state: PropTypes.oneOf(Object.values(SECTION_STATES)),
  /** Color theme variant. */
  variant: PropTypes.oneOf(Object.values(SECTION_VARIANTS)),
};

export const TableOfContentsSection: MemoExoticComponent<
  typeof TableOfContentsSectionBase
> & {
  STATES?: typeof SECTION_STATES;
  VARIANTS?: typeof SECTION_VARIANTS;
} = memo(TableOfContentsSectionBase);

TableOfContentsSection.STATES = SECTION_STATES;
TableOfContentsSection.VARIANTS = SECTION_VARIANTS;
