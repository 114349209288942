import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, Typography } from '@eversity/ui/design-system';
import { useTheme } from '@emotion/react';

import {
  gradeMessages,
  globalMessages,
  academicTranscriptMessages,
  getStudentTranscriptAssessmentMessage,
} from '@eversity/ui/intl';
import { StudentAcademicTranscript } from '@eversity/types/domain';
import { OVERALL_AVERAGE_GRADING_SCALE } from '@eversity/domain/constants';
import { getTranscriptGradedAssignmentsCount } from '@eversity/domain/students/utils';

import messages from './AcademicTranscriptAssessment.messages';
import * as styles from './AcademicTranscriptAssessment.styles';

export const AcademicTranscriptAssessmentBase = ({
  transcript,
}: {
  transcript: StudentAcademicTranscript;
}) => {
  const theme = useTheme();

  const overallGradedAssignments = useMemo(
    () => getTranscriptGradedAssignmentsCount(transcript),
    [transcript],
  );

  return (
    <div css={styles.assessment}>
      <Card
        color={theme.colors.success[500]}
        css={styles.card}
      >
        <div css={styles.assessmentCardBody}>
          <div css={styles.cardItem}>
            <Typography variant={Typography.VARIANTS.BODY_SMALL_REGULAR}>
              <FormattedMessage
                {...academicTranscriptMessages.OVERALL_AVERAGE}
              />
            </Typography>

            <Typography
              variant={Typography.VARIANTS.HEADING_5}
              element="span"
            >
              <FormattedMessage
                {...(Number.isFinite(transcript.overallAverage)
                  ? gradeMessages.GRADE
                  : globalMessages.NOT_AFFECTED)}
                values={{
                  grade: transcript.overallAverage,
                  gradingScale: OVERALL_AVERAGE_GRADING_SCALE,
                  Grade: (chunks) => (
                    <Typography
                      variant={Typography.VARIANTS.HEADING_3}
                      element="span"
                      css={styles.coloredTextSuccess}
                    >
                      {chunks}
                    </Typography>
                  ),
                  GradingScale: (chunks) => chunks,
                }}
              />
            </Typography>
          </div>

          <div css={styles.cardItem}>
            <Typography variant={Typography.VARIANTS.BODY_SMALL_REGULAR}>
              <FormattedMessage {...messages.ASSESSMENT_LABEL} />
            </Typography>

            <Typography variant={Typography.VARIANTS.BODY_MEDIUM_BOLD}>
              <p>
                <FormattedMessage
                  {...messages.ASSIGNMENTS_COMPLETED}
                  values={{
                    value:
                      overallGradedAssignments.count /
                      overallGradedAssignments.total,
                  }}
                />
              </p>
              {Number.isFinite(transcript?.overallAverage) && (
                <p>
                  <FormattedMessage
                    {...getStudentTranscriptAssessmentMessage(
                      transcript.overallAverage,
                    )}
                  />
                </p>
              )}
            </Typography>
          </div>
        </div>
      </Card>
    </div>
  );
};

AcademicTranscriptAssessmentBase.displayName = 'AcademicTranscriptAssessment';

export const AcademicTranscriptAssessment = memo(
  AcademicTranscriptAssessmentBase,
);
