import {
  GetNewsQuery,
  NewsViewFull,
  NewsViewStudent,
  PostNewsBody,
} from '@eversity/types/domain';
import { NEWS_STATES } from '@eversity/domain/constants';

import { HttpRepository } from '../httpRepository';

const BASE_NEWS_URI = '/api/v1/news';

const e = encodeURIComponent;

export class NewsRepository extends HttpRepository {
  /**
   * Get the list of news available to the current user.
   *
   * @returns List of news.
   */
  async getCurrentUserNewsList(): Promise<NewsViewStudent[]> {
    const { body } = await this.http.get(BASE_NEWS_URI);

    return body;
  }

  /**
   * @description This method is used to create a news
   * @param PostNewsBody
   * @returns The created news
   */
  async createNews(params: PostNewsBody): Promise<NewsViewFull> {
    const { body: news } = await this.http.post(BASE_NEWS_URI).send(params);

    return news;
  }

  /**
   * @description This method is used to modify an existant news
   * @param PostNewsBody
   * @returns The updated news
   */
  async updateNews(
    newsId: string,
    params:
      | Pick<
          PostNewsBody,
          'id' | 'title' | 'content' | 'categories' | 'attachments' | 'status'
        >
      | PostNewsBody,
  ): Promise<NewsViewFull> {
    const { body: news } = await this.http
      .patch(`${BASE_NEWS_URI}/${newsId}`)
      .send(params);

    return news;
  }

  /**
   * @description This method is used to modify an existant published news
   * @param PostNewsBody
   * @returns The updated news
   */
  async publishNews(
    newsId: string,
    params: PostNewsBody,
  ): Promise<NewsViewFull> {
    const { body: news } = await this.http
      .post(`${BASE_NEWS_URI}/${newsId}/publish`)
      .send(params);

    return news;
  }

  /**
   * @description This method is used to get a news by ID
   * @param newsId
   * @returns The news corresponding to the ID
   */
  async getNewsById(newsId: string): Promise<NewsViewFull> {
    const { body: news } = await this.http.get(`/api/v1/news/${e(newsId)}`);

    return news;
  }

  /**
   * Fetch news list for backoffice users.
   *
   * @param query - Query.
   * @param query.q - Search (on title, content or categories).
   * @param query.limit - Limit number of results.
   * @param query.offset - Skip results.
   * @param query.sort - Field to sort (field is ASC, -field is DESC).
   * @param query.statuses - News statuses filter.
   * @param query.publicationStartDate - Fetch news after given date
   * @param query.publicationEndDate - Fetch news before given date
   * @param query.isPinned - Fetch pinned news.
   * @param query.isHidden - Fetch hidden news.
   * @param query.isVisibleByEveryone - Fetch news visible by everyone
   * @returns Object with news and count.
   */
  async getNewsList(
    query?: GetNewsQuery,
  ): Promise<{ count: number; news: NewsViewFull[] }> {
    const {
      body: { count, news },
    } = await this.http.get(BASE_NEWS_URI).query(query);

    return {
      count,
      news,
    };
  }

  /**
   * Delete a news.
   *
   * @param newsId - News id.
   * @returns True if the news was deleted.
   */
  async deleteNews(newsId: string): Promise<boolean> {
    const { status } = await this.http.delete(`${BASE_NEWS_URI}/${e(newsId)}`);

    return status === 204;
  }

  /**
   * Get the summary of the news.
   *
   * @returns News summary.
   */
  async getNewsSummary(): Promise<Record<NEWS_STATES, { count: number }>> {
    const { body } = await this.http.get(`${BASE_NEWS_URI}/summary`);

    return body;
  }
}
