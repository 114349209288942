import { ForwardedRef, forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { TinyColor } from '@ctrl/tinycolor';
import Tippy, { TippyProps } from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import { withLazyTippy } from '../tippy/lazy/withLazyTippy';

const LazyTippy = withLazyTippy(Tippy);

export type PopoverProps = Omit<TippyProps, 'ref'>;

export const PopoverBase = forwardRef(
  ({ ...props }: PopoverProps, ref: ForwardedRef<HTMLElement>) => (
    <LazyTippy
      arrow={false}
      trigger={props.visible !== undefined ? undefined : 'click'}
      placement="bottom-start"
      {...props}
      css={(theme) => css`
        background-color: ${theme.colors.gray[0]};
        color: ${theme.colors.gray[700]};

        border-radius: 4px;

        box-shadow:
          0px 2px 4px
            ${new TinyColor(theme.colors.gray[900]).setAlpha(0.1).toRgbString()},
          0px 0px 11px 1px
            ${new TinyColor(theme.colors.gray[900]).setAlpha(0.1).toRgbString()};

        .tippy-content {
          padding: 0;
        }
      `}
      ref={ref}
    />
  ),
);

PopoverBase.displayName = 'Popover';

PopoverBase.propTypes = {
  visible: PropTypes.bool,
};

PopoverBase.defaultProps = {
  visible: undefined,
};

export const Popover = memo(PopoverBase);
