import { createContext } from 'react';

import { DIALOG_LAYER_TYPE } from './constants';
import { ILayer } from '../layers-provider/LayersContext';

export interface DialogLayer extends ILayer {
  type: typeof DIALOG_LAYER_TYPE;
}

export type DialogsContextValue = {
  dialogLayers: DialogLayer[];
};

export const DialogsContext = createContext<DialogsContextValue>({
  dialogLayers: [],
});
