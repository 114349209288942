import { createContext, useContext } from 'react';
import { noop } from 'lodash';
import { Locale } from 'date-fns';

type LocaleContextValue = {
  locale: string;
  setLocale: (newLocale: string) => void;
  messages: Record<string, string>;
  dateFnsLocale: Locale;
};

export const LocaleContext = createContext<LocaleContextValue>({
  locale: 'fr',
  setLocale: noop,
  messages: {},
  dateFnsLocale: {},
});

export const useLocaleContext = () => useContext(LocaleContext);
