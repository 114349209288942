import {
  memo,
  useCallback,
  forwardRef,
  ReactNode,
  ChangeEventHandler,
  ChangeEvent,
  ForwardedRef,
} from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { Typography } from '../../general/typography/Typography';
import {
  CheckboxControl,
  CheckboxControlProps,
} from '../checkbox-control/CheckboxControl';

import * as styles from './Checkbox.styles';

export type CheckboxProps = Omit<CheckboxControlProps, 'onChange'> & {
  description?: ReactNode;
  onChange?: (checked: boolean, event: ChangeEvent<HTMLInputElement>) => void;
};

export const CheckboxBase = forwardRef(
  (
    { description, onChange, className, ...props }: CheckboxProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const onChangeProxy: ChangeEventHandler<HTMLInputElement> = useCallback(
      (event) => onChange(event.target.checked, event),
      [onChange],
    );

    return (
      // Input is nested.
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label
        className={className}
        css={styles.container}
      >
        <div css={styles.box}>
          <CheckboxControl
            {...props}
            ref={ref}
            onChange={onChangeProxy}
          />
        </div>

        {!!description && (
          <Typography variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}>
            {description}
          </Typography>
        )}
      </label>
    );
  },
);

CheckboxBase.displayName = 'Checkbox';

CheckboxBase.propTypes = {
  /** Checkbox label. */
  description: PropTypes.node,
  /** Root element class name. */
  className: PropTypes.string,
  /** Is the input checked. */
  checked: PropTypes.bool,
  /** Is the checkbox partially checked. Takes priority over checked visually. */
  indeterminate: PropTypes.bool,
  /** On change handler. (checked, event) => null. */
  onChange: PropTypes.func,
  /** Is the checkbox disabled. */
  disabled: PropTypes.bool,
};

CheckboxBase.defaultProps = {
  description: null,
  className: null,
  checked: false,
  indeterminate: false,
  onChange: noop,
  disabled: false,
};

export const Checkbox = memo(CheckboxBase);
