import { memo } from 'react';
import { Global, css } from '@emotion/react';

import { COLORS } from './colors';

export default memo(() => (
  <Global
    styles={css`
      @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;1,400;1,600&family=Nunito:wght@300;400;600;700&family=Source+Code+Pro:wght@500;600;700&family=Lily+Script+One&display=swap');

      /* Reset gutters. */
      *,
      *::after,
      *::before {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
      }

      html,
      body,
      #root {
        font-family: Montserrat, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        height: 100%;
        width: 100%;

        color: ${COLORS.gray[700]};
      }

      mark {
        background-color: inherit;
        color: inherit;
      }

      .ReactCollapse--collapse {
        transition: height 250ms;
      }
    `}
  />
));
