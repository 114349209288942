import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { css, useTheme } from '@emotion/react';

import { FormattedMessage } from 'react-intl';
import { Check, Close } from '@icon-park/react';
import {
  Dot,
  Lightbox,
  Table,
  TableProps,
  Typography,
} from '@eversity/ui/design-system';
import { MCQ_ANSWER_STYLES } from '@eversity/domain/constants';
import { Answer } from '@eversity/types/domain';

import { answerPropTypes } from '../../../../../../types/mcq';
import AudioResourceItemContextProvider from '../audio-resource-item/AudioResourceItemContext';
import ImageResourceItem from '../image-resource-item/ImageResourceItem';

import messages from './QuestionAnswersTable.messages';

export type QuestionAnswersTableProps = {
  answers?: Answer[];
  answerStyle?: MCQ_ANSWER_STYLES;
};

const DEFAULT_ANSWERS = [];

export const QuestionAnswersTableBase = ({
  answers = DEFAULT_ANSWERS,
  answerStyle = MCQ_ANSWER_STYLES.TEXT,
}: QuestionAnswersTableProps) => {
  const theme = useTheme();

  const columns = useMemo<TableProps<Answer>['columns']>(
    () =>
      [
        {
          key: 'label',
          title: <FormattedMessage {...messages.ANSWERS_LABEL} />,
          render: (answer: Answer) => (
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: 10px;
              `}
            >
              {answer.isCorrectAnswer ? (
                <Check fill={[theme.colors.success[500]]} />
              ) : (
                <Close fill={[theme.colors.danger[500]]} />
              )}

              {answer.label}
            </div>
          ),
        },
        answerStyle === MCQ_ANSWER_STYLES.TEXT_IMAGE && {
          key: 'images',
          title: <FormattedMessage {...messages.ANSWERS_IMAGES} />,
          render: (answer: Answer) => (
            <Lightbox>
              <ImageResourceItem
                href={answer.media?.href}
                alt={answer.mediaAlt}
              />
            </Lightbox>
          ),
        },
        answerStyle === MCQ_ANSWER_STYLES.TEXT_AUDIO && {
          key: 'audios',
          title: <FormattedMessage {...messages.ANSWERS_AUDIOS} />,
          render: (answer: Answer) => (
            <AudioResourceItemContextProvider audioHref={answer.media?.href} />
          ),
        },
        {
          key: 'messages',
          title: (
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: 8px;
              `}
            >
              <Dot
                css={css`
                  width: 10px;
                  height: 10px;
                  flex: none;

                  background-color: ${theme.colors.danger[500]};

                  border: none;
                `}
              />
              <FormattedMessage {...messages.ERROR_TEXT_LABEL} />
              <span>/</span>
              <Dot
                css={css`
                  width: 10px;
                  height: 10px;
                  flex: none;

                  background-color: ${theme.colors.success[500]};

                  border: none;
                `}
              />
              <FormattedMessage {...messages.SUCCESS_TEXT_LABEL} />
            </div>
          ),
          render: (answer: Answer) => (
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
              `}
            >
              {answer.errorText && (
                <Typography
                  variant={Typography.VARIANTS.BODY_MEDIUM_ITALIC}
                  css={css`
                    color: ${theme.colors.danger[500]};
                  `}
                >
                  {answer.errorText}
                </Typography>
              )}

              {answer.successText && (
                <Typography
                  variant={Typography.VARIANTS.BODY_MEDIUM_ITALIC}
                  css={css`
                    color: ${theme.colors.success[500]};
                  `}
                >
                  {answer.successText}
                </Typography>
              )}
            </div>
          ),
        },
      ].filter(Boolean),
    [theme, answerStyle],
  );

  return (
    <Table<Answer>
      columns={columns}
      data={answers}
      rowKey="id"
    />
  );
};

QuestionAnswersTableBase.displayName = 'QuestionAnswersTable';

QuestionAnswersTableBase.propTypes = {
  answers: PropTypes.arrayOf(answerPropTypes),
  answerStyle: PropTypes.oneOf(Object.values(MCQ_ANSWER_STYLES)),
};

export const QuestionAnswersTable = memo(QuestionAnswersTableBase);
