import { NEWS_CATEGORIES } from '@eversity/domain/constants';
import { defineMessages } from 'react-intl';

export const categoriesMessages = defineMessages<NEWS_CATEGORIES>({
  [NEWS_CATEGORIES.ARTICLES]: {
    defaultMessage: 'Articles',
  },
  [NEWS_CATEGORIES.COACHING]: {
    defaultMessage: 'Coaching',
  },
  [NEWS_CATEGORIES.EXAMS]: {
    defaultMessage: 'Examens',
  },
  [NEWS_CATEGORIES.LESSONS]: {
    defaultMessage: 'Cours',
  },
  [NEWS_CATEGORIES.SCHOOL_LIFE]: {
    defaultMessage: 'Vie scolaire',
  },
});
