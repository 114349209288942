import {
  CreateEmployeeBody,
  CreateEmployeeQuery,
  EmployeeViewFull,
  EmployeeViewMinimal,
  GetEmployeeQuery,
  GetEmployeesQuery,
  UpdateEmployeeBody,
} from '@eversity/types/domain';
import { HttpRepository } from '../httpRepository';

const e = encodeURIComponent;

export class EmployeesRepository extends HttpRepository {
  async getEmployees(query?: GetEmployeesQuery): Promise<{
    users: EmployeeViewFull[] | EmployeeViewMinimal[];
    count: number;
  }> {
    const {
      body: { count, users },
    } = await this.http.get('/api/v1/users/employees/').query(query);

    return {
      count,
      users,
    };
  }

  /**
   * Create employee.
   *
   * @param params - Params.
   * @param params.firstName - First name.
   * @param params.lastName - Last name.
   * @param params.email - Email address.
   * @param params.roles - User roles (USER_ROLES enum).
   * @param query - Query.
   * @param query.view - User view to return (USER_VIEWS enum). Defaults to FULL.
   * @returns Created user employee.
   */
  async createEmployee(
    params: CreateEmployeeBody,
    query?: CreateEmployeeQuery,
  ): Promise<EmployeeViewFull | EmployeeViewMinimal> {
    const { body: user } = await this.http
      .post('/api/v1/users/employees/')
      .query(query)
      .send(params);

    return user;
  }

  /**
   * Update an employee.
   *
   * @param userId - Employee user id.
   * @param params - Params.
   * @returns Updated user employee.
   */
  async updateEmployee(
    userId: string,
    params: UpdateEmployeeBody,
  ): Promise<EmployeeViewFull> {
    const { body: user } = await this.http
      .patch(`/api/v1/users/employees/${e(userId)}`)
      .send(params);

    return user;
  }

  /**
   * Get a single employee.
   *
   * @param userId - Employee id.
   * @param query - Query params.
   * @returns The employee data.
   */
  async getEmployee(
    userId: string,
    query?: GetEmployeeQuery,
  ): Promise<EmployeeViewFull | EmployeeViewMinimal | null> {
    const { body: employee } = await this.http
      .get(`/api/v1/users/employees/${e(userId)}`)
      .query(query);

    return employee;
  }

  async deleteProfilePicture(
    userId: string,
    pictureId: string,
  ): Promise<EmployeeViewFull> {
    const { body: updatedEmployee } = await this.http
      .delete(
        `/api/v1/users/employees/${e(userId)}/profile-pictures/${e(pictureId)}`,
      )
      .send();

    return updatedEmployee;
  }
}
