import { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { css } from '@emotion/react';

import { Notification } from '@eversity/types/domain';

import { notificationPropTypes } from '../../../types/notifications';

import {
  NotificationListItem,
  NotificationListItemProps,
} from './item/NotificationListItem';
import { NotificationListItemSkeleton } from './item/skeleton/NotificationListItemSkeleton';

export type NotificationsListProps = {
  notifications: Notification[];
  rendererMapping: NotificationListItemProps['rendererMapping'];
  onMarkNotificationAsRead?: NotificationListItemProps['onMarkAsRead'];
  onMarkNotificationAsUnread?: NotificationListItemProps['onMarkAsUnread'];
  onClose?: NotificationListItemProps['onCloseNotifications'];
  isLoading?: boolean;
};

const DEFAULT_NOTIFICATIONS = [];

export const NotificationsListBase = ({
  notifications = DEFAULT_NOTIFICATIONS,
  rendererMapping,
  onMarkNotificationAsRead = noop,
  onMarkNotificationAsUnread = noop,
  isLoading = false,
  onClose = noop,
}: NotificationsListProps) => (
  <ul
    css={(theme) => css`
      > li {
        list-style-type: none;
        &:not(:first-of-type) {
          border-top: 1px solid ${theme.colors.gray[100]};
        }
      }
    `}
  >
    {notifications.map((notification) => (
      <li key={notification.id}>
        <NotificationListItem
          notification={notification}
          rendererMapping={rendererMapping}
          onMarkAsRead={onMarkNotificationAsRead}
          onMarkAsUnread={onMarkNotificationAsUnread}
          onCloseNotifications={onClose}
        />
      </li>
    ))}

    {!!isLoading && (
      <Fragment>
        <li>
          <NotificationListItemSkeleton />
        </li>
        <li>
          <NotificationListItemSkeleton />
        </li>
      </Fragment>
    )}
  </ul>
);

NotificationsListBase.displayName = 'NotificationsList';

NotificationsListBase.propTypes = {
  /** List of notifications to display. This component does not handle the empty state.   */
  notifications: PropTypes.arrayOf(notificationPropTypes),
  /** Mapping from notification.notificationType to a component rendering the notification. */
  rendererMapping: PropTypes.objectOf(PropTypes.elementType).isRequired,
  /** Mark a notification as read. Called with the notification id. */
  onMarkNotificationAsRead: PropTypes.func,
  /** Mark a notificatrion as unread. Called with the notification id. */
  onMarkNotificationAsUnread: PropTypes.func,
  /** Show skeletons if loading. */
  isLoading: PropTypes.bool,
  /** Closes the list of notifications. */
  onClose: PropTypes.func,
};

export const NotificationsList = memo(NotificationsListBase);
