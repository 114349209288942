import { Theme } from '@emotion/react';

export enum SELECT_SIZES {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export type MakeCustomThemeProps = {
  theme: Theme;
  hasError: boolean;
  hasWarning: boolean;
  size: SELECT_SIZES;
};

export type MakeCustomStylesProps = {
  theme: Theme;
  hasError: boolean;
  hasWarning: boolean;
  size: SELECT_SIZES;
  isInLayer: boolean;
  layerZIndex?: number;
};

export type CommonSelectProps = {
  size?: SELECT_SIZES;
  disabled?: boolean;
  hasError?: boolean;
  hasWarning?: boolean;
};
