import { memo, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Theme, ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { ToastContainerProps } from 'react-toastify';
import { IconProvider } from '@icon-park/react/es/runtime';
import 'react-toastify/dist/ReactToastify.css';

import { DEFAULT_THEME } from '../../../themes/default';
import { themePropTypes } from '../../../types';
import { ICON_CONFIG } from '../../general/icon/constants';

import { LayersProvider } from '../layers-provider/LayersProvider';
import { DialogsProvider } from '../dialogs-provider/DialogsProvider';
import { ToastContainer } from '../../feedback/toast/ToastContainer';
import { ToastGlobalStyles } from '../../feedback/toast/ToastGlobalStyles';
import { IconGlobalStyles } from '../../general/icon/IconGlobalStyles';

export type DesignSystemProviderProps = {
  theme?: Theme;
  rootElementSelector?: string;
  minLayerZIndex?: number;
  layerZIndexInterval?: number;
  children?: ReactNode;
  toastContainerProps?: ToastContainerProps;
};

export const DesignSystemProviderBase = ({
  theme = DEFAULT_THEME,
  rootElementSelector = '#root',
  toastContainerProps = null,
  minLayerZIndex = undefined,
  layerZIndexInterval = undefined,
  children = null,
}: DesignSystemProviderProps) => {
  const { globalStylesComponent: ThemeGlobalStyles } = theme;

  return (
    <EmotionThemeProvider theme={theme}>
      <LayersProvider
        minLayerZIndex={minLayerZIndex}
        layerZIndexInterval={layerZIndexInterval}
      >
        <DialogsProvider rootElementSelector={rootElementSelector}>
          <IconProvider value={ICON_CONFIG}>
            {ThemeGlobalStyles && <ThemeGlobalStyles />}
            <IconGlobalStyles />
            <ToastGlobalStyles />

            {children}

            <ToastContainer {...toastContainerProps} />
          </IconProvider>
        </DialogsProvider>
      </LayersProvider>
    </EmotionThemeProvider>
  );
};

DesignSystemProviderBase.displayName = 'DesignSystemProvider';

DesignSystemProviderBase.propTypes = {
  /** Current theme. */
  theme: themePropTypes,
  /** CSS selector of the root element. */
  rootElementSelector: PropTypes.string,
  /** See react-toastify ToastContainer props. */
  toastContainerProps: PropTypes.shape({}),
  /** Min z-index of layers. */
  minLayerZIndex: PropTypes.number,
  /** Z-index interval between layers. */
  layerZIndexInterval: PropTypes.number,
  /** App. */
  children: PropTypes.node,
};

export const DesignSystemProvider = memo(DesignSystemProviderBase);
