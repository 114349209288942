import { ForwardedRef, forwardRef, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { css, useTheme } from '@emotion/react';
import Tippy, { TippyProps } from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { tippyDelayPropTypes } from '@eversity/ui/utils';

import { Typography } from '../typography/Typography';

import { withLazyTippy } from '../tippy/lazy/withLazyTippy';

const SHOW_DELAY_MS = 150;
const HIDE_DELAY_MS = 100;

const LazyTippy = withLazyTippy(Tippy);

export type TooltipProps = Omit<TippyProps, 'ref'> & {
  lazy?: boolean;
  light?: boolean;
  withDelay?: boolean;
  delay?: number | [number, number];
};

export const TooltipBase = forwardRef(
  (
    {
      content,
      render,
      duration,
      lazy,
      light,
      withDelay,
      ...props
    }: TooltipProps,
    ref: ForwardedRef<HTMLElement>,
  ) => {
    const theme = useTheme();

    // If render is defined, wrap the render function to render a Typography component.
    const renderProxy = useMemo(
      () =>
        render
          ? (...args: Parameters<TooltipProps['render']>) => (
              <Typography variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}>
                {render(...args)}
              </Typography>
            )
          : null,
      [render],
    );

    // If content is defined, wrap it in a Typography component.
    const contentProxy = content ? (
      <Typography variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}>
        {content}
      </Typography>
    ) : null;

    const TippyComponent = lazy ? LazyTippy : Tippy;

    return (
      <TippyComponent
        {...props}
        content={contentProxy}
        render={renderProxy}
        ref={ref}
        duration={
          Number.isFinite(duration)
            ? duration
            : theme.transitions.default.duration
        }
        delay={withDelay ? [SHOW_DELAY_MS, HIDE_DELAY_MS] : props.delay}
        css={css`
          background-color: ${light
            ? theme.colors.gray[25]
            : theme.colors.gray[700]};
          color: ${light
            ? theme.contrastColors.gray[25]
            : theme.contrastColors.gray[700]};
          border: ${light ? `1px solid ${theme.colors.gray[50]}` : 'none'};

          .tippy-content {
            padding: 8px;
            text-align: center;
          }

          .tippy-arrow {
            color: ${light ? theme.colors.gray[50] : theme.colors.gray[700]};
          }
        `}
      />
    );
  },
);

TooltipBase.displayName = 'Tooltip';

TooltipBase.propTypes = {
  /** Content (if static). */
  content: PropTypes.node,
  /** Content (if function). */
  render: PropTypes.func,
  /**
   * Duration of the enter/leave transition in ms.
   * Defaults to theme default transition duration.
   */
  duration: PropTypes.number,
  /** Only render content when visible. Use it when the content has lifecycle logic. */
  lazy: PropTypes.bool,
  /** Display tooltip with light background. */
  light: PropTypes.bool,
  /** Apply default delay. */
  withDelay: PropTypes.bool,
  /** Use Tippy's delay prop when withDelay is false. */
  delay: tippyDelayPropTypes,
};

TooltipBase.defaultProps = {
  content: null,
  render: null,
  duration: null,
  lazy: false,
  light: false,
  withDelay: true,
  delay: null,
};

export const Tooltip = memo(TooltipBase);
