import { memo, MemoExoticComponent, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { css } from '@emotion/react';

import { VARIANTS } from './RadioGroup.constants';

import { RadioGroupContextProvider } from '../radio/contexts/RadioGroup.context';
import { Radio } from './radio/Radio';

export type RadioGroupProps = {
  value?: string;
  onChange?: (newValue: string) => void;
  children?: ReactNode;
  variant?: VARIANTS;
};

export const RadioGroupBase = ({
  value = null,
  onChange = noop,
  children = null,
  variant = VARIANTS.HORIZONTAL,
  ...props
}: RadioGroupProps) => {
  const isVertical = variant === VARIANTS.HORIZONTAL;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: ${isVertical ? 'row' : 'column'};
        align-items: baseline;
        flex-wrap: wrap;
        gap: 10px;
      `}
    >
      <RadioGroupContextProvider
        {...props}
        value={value}
        onChange={onChange}
      >
        {children}
      </RadioGroupContextProvider>
    </div>
  );
};

RadioGroupBase.displayName = 'RadioGroup';

RadioGroupBase.propTypes = {
  /** Selected value. */
  value: PropTypes.string,
  /** On change function. */
  onChange: PropTypes.func,
  /** Content. */
  children: PropTypes.node,
  /** Way the group of radios is displayed. */
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
};

export const RadioGroup: MemoExoticComponent<typeof RadioGroupBase> & {
  Radio?: typeof Radio;
  VARIANTS?: typeof VARIANTS;
} = memo(RadioGroupBase);

RadioGroup.Radio = Radio;
RadioGroup.VARIANTS = VARIANTS;
