import { Locale } from 'date-fns';

import dayjs from '@eversity/services/dayjs';
import { getBrowserLocale, setHtmlLangAttribute } from '@eversity/utils/web';

import { getStoredLocale, storeLocale } from './localStorage';

export const availableLocales = ['fr', 'en'];

export const DEFAULT_APP_LOCALE = 'fr';

export const getDefaultLocale = () => {
  const storedLocale = getStoredLocale();

  if (storedLocale) {
    return storedLocale;
  }

  const browserLocale = getBrowserLocale();

  if (availableLocales.includes(browserLocale)) {
    return browserLocale;
  }

  return DEFAULT_APP_LOCALE;
};

export const importLocale = async (
  locale: string,
): Promise<{ messages: Record<string, string>; dateFnsLocale: Locale }> => {
  const { default: bundle } = await import(`../../intl/locales/${locale}`);
  return bundle;
};

export const setAppLocale = (locale: string) => {
  dayjs.locale(locale);
  setHtmlLangAttribute(locale);
  storeLocale(locale);
};
