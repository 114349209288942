import { Fragment, memo, MemoExoticComponent, ReactNode, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { parseDuration, PARSE_DURATION_BASE_UNITS } from '@eversity/utils/misc';

import { VARIANTS } from './constants';

import messages from './FormattedDuration.messages';

export type FormattedDurationProps = {
  value?: number;
  variant?: VARIANTS;
  unit?: PARSE_DURATION_BASE_UNITS;
  render?: (timeValues: ReturnType<typeof parseDuration>) => ReactNode;
};

export const FormattedDurationBase = ({
  value = 0,
  variant = VARIANTS.SHORT,
  unit = PARSE_DURATION_BASE_UNITS.MINUTE,
  render = undefined,
}: FormattedDurationProps) => {
  const timeValues = useMemo(() => parseDuration(value, unit), [value, unit]);

  return !render ? (
    <FormattedMessage
      {...messages[variant]}
      values={timeValues}
    />
  ) : (
    <Fragment>{render(timeValues)}</Fragment>
  );
};

FormattedDurationBase.displayName = 'FormatDuration';

FormattedDurationBase.propTypes = {
  /** Give the duration in minutes. */
  value: PropTypes.number,
  /** Variant. */
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  /** Base unit time */
  unit: PropTypes.oneOf(Object.values(parseDuration.BASE_UNITS)),
  /** Render function */
  render: PropTypes.func,
};

export const FormattedDuration: MemoExoticComponent<
  typeof FormattedDurationBase
> & {
  VARIANTS?: typeof VARIANTS;
  UNITS?: typeof parseDuration.BASE_UNITS;
} = memo(FormattedDurationBase);
FormattedDuration.VARIANTS = VARIANTS;
FormattedDuration.UNITS = parseDuration.BASE_UNITS;
