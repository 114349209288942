import { css, Theme } from '@emotion/react';

import WaveOne from '../../../../assets/images/WaveOne.raw.svg';
import WaveTwo from '../../../../assets/images/WaveTwo.raw.svg';
import WaveThree from '../../../../assets/images/WaveThree.raw.svg';

import { HUE_GROUPS } from '../../../config/colors/constants';
import { VARIANTS } from './WaveCard.constants';

export const getColors = (theme: Theme, variant: VARIANTS, hue: HUE_GROUPS) => {
  if (hue) {
    return {
      background: theme.hues[hue][25],
      waveOne: theme.hues[hue][200],
      waveTwo: theme.hues[hue][100],
      waveThree: theme.hues[hue][50],
      content: theme.contrastColors[hue][0],
      opacity: 0.3,
    };
  }

  switch (variant) {
    case VARIANTS.PRIMARY_DARK:
      return {
        background: theme.colors.primary[500],
        waveOne: theme.colors.primary[200],
        waveTwo: theme.colors.primary[300],
        waveThree: theme.colors.primary[400],
        content: theme.colors.gray[0],
        opacity: 0.3,
      };
    case VARIANTS.PRIMARY_LIGHT:
      return {
        background: theme.colors.gray[0],
        waveOne: theme.colors.primary[25],
        waveTwo: theme.colors.primary[50],
        waveThree: theme.colors.primary[100],
        content: theme.colors.gray[900],
        opacity: 0.2,
      };
    case VARIANTS.WARNING:
      return {
        background: theme.colors.warning[25],
        waveOne: theme.colors.warning[200],
        waveTwo: theme.colors.warning[100],
        waveThree: theme.colors.warning[50],
        content: theme.colors.gray[700],
        opacity: 0.3,
      };
    case VARIANTS.DANGER:
      return {
        background: theme.colors.danger[25],
        waveOne: theme.colors.danger[200],
        waveTwo: theme.colors.danger[100],
        waveThree: theme.colors.danger[50],
        content: theme.colors.gray[700],
        opacity: 0.3,
      };
    case VARIANTS.SUCCESS:
      return {
        background: theme.colors.success[25],
        waveOne: theme.colors.success[200],
        waveTwo: theme.colors.success[100],
        waveThree: theme.colors.success[50],
        content: theme.colors.gray[700],
        opacity: 0.3,
      };
    case VARIANTS.PRIMARY:
    default:
      return {
        background: theme.colors.primary[25],
        waveOne: theme.colors.primary[200],
        waveTwo: theme.colors.primary[100],
        waveThree: theme.colors.primary[50],
        content: theme.colors.gray[700],
        opacity: 0.3,
      };
  }
};

export const container = (
  theme: Theme,
  variant: VARIANTS,
  hue: HUE_GROUPS,
) => css`
  position: relative;
  width: 100%;
  padding: 11px 17px;
  min-height: 40px;
  border-radius: 8px;
  background-color: ${getColors(theme, variant, hue).background};
  overflow: hidden;
  transition: ${theme.transitions.default()};
  z-index: 0;
`;

export const waveThree = (
  theme: Theme,
  variant: VARIANTS,
  hue: HUE_GROUPS,
) => css`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 400px;
  height: 80px;
  background-color: ${getColors(theme, variant, hue).waveThree};
  mask-image: url(${WaveThree});
  background-repeat: no-repeat;
  mask-size: 100% 100%;
  opacity: ${getColors(theme, variant, hue).opacity};
  z-index: -3;
`;

export const waveTwo = (
  theme: Theme,
  variant: VARIANTS,
  hue: HUE_GROUPS,
) => css`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 380px;
  height: 70px;
  background-color: ${getColors(theme, variant, hue).waveTwo};
  mask-image: url(${WaveTwo});
  background-repeat: no-repeat;
  mask-size: 100% 100%;
  opacity: ${getColors(theme, variant, hue).opacity};
  z-index: -2;
`;

export const waveOne = (
  theme: Theme,
  variant: VARIANTS,
  hue: HUE_GROUPS,
) => css`
  position: absolute;
  bottom: -1px;
  right: 0;
  width: 300px;
  height: 41px;
  background-color: ${getColors(theme, variant, hue).waveOne};
  mask-image: url(${WaveOne});
  background-repeat: no-repeat;
  mask-size: 100% 100%;
  opacity: ${getColors(theme, variant, hue).opacity};
  z-index: -1;
`;

export const content = (
  theme: Theme,
  variant: VARIANTS,
  hue: HUE_GROUPS,
) => css`
  width: 100%;
  height: 100%;
  color: ${getColors(theme, variant, hue).content};
  z-index: 0;
`;
