import { ReactComponentLike } from 'prop-types';

export enum TYPOGRAPHY_VARIANTS {
  BRAND = 'brand',
  HEADING_1 = 'heading1',
  HEADING_2 = 'heading2',
  HEADING_3 = 'heading3',
  HEADING_4 = 'heading4',
  HEADING_5 = 'heading5',
  BODY_SMALL_REGULAR = 'bodySmallRegular',
  BODY_SMALL_ITALIC = 'bodySmallItalic',
  BODY_SMALL_BOLD = 'bodySmallBold',
  BODY_SMALL_BOLD_ITALIC = 'bodySmallBoldItalic',
  BODY_EXTRA_SMALL_REGULAR = 'bodyExtraSmallRegular',
  BODY_EXTRA_SMALL_ITALIC = 'bodyExtraSmallItalic',
  BODY_EXTRA_SMALL_BOLD = 'bodyExtraSmallBold',
  BODY_EXTRA_SMALL_BOLD_ITALIC = 'bodyExtraSmallBoldItalic',
  BODY_MEDIUM_REGULAR = 'bodyMediumRegular',
  BODY_MEDIUM_ITALIC = 'bodyMediumItalic',
  BODY_MEDIUM_BOLD = 'bodyMediumBold',
  BODY_MEDIUM_BOLD_ITALIC = 'bodyMediumBoldItalic',
  BODY_LARGE_REGULAR = 'bodyLargeRegular',
  BODY_LARGE_ITALIC = 'bodyLargeItalic',
  BODY_LARGE_BOLD = 'bodyLargeBold',
  BODY_LARGE_BOLD_ITALIC = 'bodyLargeBoldItalic',
  BUTTON_SMALL_REGULAR = 'buttonSmallRegular',
  BUTTON_SMALL_BOLD = 'buttonSmallBold',
  BUTTON_MEDIUM_REGULAR = 'buttonMediumRegular',
  BUTTON_MEDIUM_BOLD = 'buttonMediumBold',
  BUTTON_LARGE_REGULAR = 'buttonLargeRegular',
  BUTTON_LARGE_BOLD = 'buttonLargeBold',
  MONOSPACE_SMALL_REGULAR = 'monospaceSmallRegular',
  MONOSPACE_SMALL_BOLD = 'monospaceSmallBold',
  MONOSPACE_MEDIUM_REGULAR = 'monospaceMediumRegular',
  MONOSPACE_MEDIUM_BOLD = 'monospaceMediumBold',
  PDF_FOOTER = 'pdfFooter',
}

export const TYPOGRAPHY_DEFAULT_ELEMENTS: Record<
  TYPOGRAPHY_VARIANTS,
  ReactComponentLike
> = {
  [TYPOGRAPHY_VARIANTS.BRAND]: 'span',
  [TYPOGRAPHY_VARIANTS.HEADING_1]: 'h1',
  [TYPOGRAPHY_VARIANTS.HEADING_2]: 'h2',
  [TYPOGRAPHY_VARIANTS.HEADING_3]: 'h3',
  [TYPOGRAPHY_VARIANTS.HEADING_4]: 'h4',
  [TYPOGRAPHY_VARIANTS.HEADING_5]: 'h5',
  [TYPOGRAPHY_VARIANTS.BODY_SMALL_REGULAR]: 'span',
  [TYPOGRAPHY_VARIANTS.BODY_SMALL_ITALIC]: 'span',
  [TYPOGRAPHY_VARIANTS.BODY_SMALL_BOLD]: 'span',
  [TYPOGRAPHY_VARIANTS.BODY_SMALL_BOLD_ITALIC]: 'span',
  [TYPOGRAPHY_VARIANTS.BODY_EXTRA_SMALL_REGULAR]: 'span',
  [TYPOGRAPHY_VARIANTS.BODY_EXTRA_SMALL_ITALIC]: 'span',
  [TYPOGRAPHY_VARIANTS.BODY_EXTRA_SMALL_BOLD]: 'span',
  [TYPOGRAPHY_VARIANTS.BODY_EXTRA_SMALL_BOLD_ITALIC]: 'span',
  [TYPOGRAPHY_VARIANTS.BODY_MEDIUM_REGULAR]: 'span',
  [TYPOGRAPHY_VARIANTS.BODY_MEDIUM_ITALIC]: 'span',
  [TYPOGRAPHY_VARIANTS.BODY_MEDIUM_BOLD]: 'span',
  [TYPOGRAPHY_VARIANTS.BODY_MEDIUM_BOLD_ITALIC]: 'span',
  [TYPOGRAPHY_VARIANTS.BODY_LARGE_REGULAR]: 'span',
  [TYPOGRAPHY_VARIANTS.BODY_LARGE_ITALIC]: 'span',
  [TYPOGRAPHY_VARIANTS.BODY_LARGE_BOLD]: 'span',
  [TYPOGRAPHY_VARIANTS.BODY_LARGE_BOLD_ITALIC]: 'span',
  [TYPOGRAPHY_VARIANTS.BUTTON_SMALL_REGULAR]: 'span',
  [TYPOGRAPHY_VARIANTS.BUTTON_SMALL_BOLD]: 'span',
  [TYPOGRAPHY_VARIANTS.BUTTON_MEDIUM_REGULAR]: 'span',
  [TYPOGRAPHY_VARIANTS.BUTTON_MEDIUM_BOLD]: 'span',
  [TYPOGRAPHY_VARIANTS.BUTTON_LARGE_REGULAR]: 'span',
  [TYPOGRAPHY_VARIANTS.BUTTON_LARGE_BOLD]: 'span',
  [TYPOGRAPHY_VARIANTS.MONOSPACE_SMALL_REGULAR]: 'span',
  [TYPOGRAPHY_VARIANTS.MONOSPACE_SMALL_BOLD]: 'span',
  [TYPOGRAPHY_VARIANTS.MONOSPACE_MEDIUM_REGULAR]: 'span',
  [TYPOGRAPHY_VARIANTS.MONOSPACE_MEDIUM_BOLD]: 'span',
  [TYPOGRAPHY_VARIANTS.PDF_FOOTER]: 'span',
};
