import {
  memo,
  forwardRef,
  ReactNode,
  ForwardedRef,
  MemoExoticComponent,
} from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { TableOfContentsSection } from './section/TableOfContentsSection';
import { TableOfContentsSubSection } from './sub-section/TableOfContentsSubSection';
import { TableOfContentsSubSectionItem } from './sub-section-item/TableOfContentsSubSectionItem';

export type TableOfContentsProps = {
  children?: ReactNode;
};

export const TableOfContentsBase = forwardRef(
  ({ children }: TableOfContentsProps, ref: ForwardedRef<HTMLUListElement>) => (
    <ul
      ref={ref}
      css={css`
        list-style-type: none;
        display: flex;
        flex-direction: column;
        gap: 10px;
      `}
    >
      {children}
    </ul>
  ),
);

TableOfContentsBase.displayName = 'TableOfContents';

TableOfContentsBase.propTypes = {
  children: PropTypes.node,
};

TableOfContentsBase.defaultProps = {
  children: null,
};

export const TableOfContents: MemoExoticComponent<
  typeof TableOfContentsBase
> & {
  Section?: typeof TableOfContentsSection;
  SubSection?: typeof TableOfContentsSubSection;
  SubSectionItem?: typeof TableOfContentsSubSectionItem;
} = memo(TableOfContentsBase);

TableOfContents.Section = TableOfContentsSection;
TableOfContents.SubSection = TableOfContentsSubSection;
TableOfContents.SubSectionItem = TableOfContentsSubSectionItem;
