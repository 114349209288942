import {
  cloneElement,
  memo,
  Fragment,
  useContext,
  ReactElement,
  ReactNode,
  MemoExoticComponent,
} from 'react';
import { Location } from 'react-router-dom';
import { useTheme, css } from '@emotion/react';
import { Drag } from '@icon-park/react';
import cn from 'classnames';

import { Badge } from '../../../data-display/badge/Badge';
import { Tooltip } from '../../../general/tooltip/Tooltip';

import { ICON_SIZES } from '../../../general/icon/constants';
import { MENU_ITEM_DRAGGABLE_CLASSNAME } from '../constants';
import { REFERENCE_TAG_VARIANTS } from '../../../general/reference-tag/constants';

import { MenuContext } from '../Menu.context';
import { Typography } from '../../../general/typography/Typography';
import { ReferenceTag } from '../../../general/reference-tag/ReferenceTag';

import * as styles from './MenuItem.styles';

export type MenuSubItemShape = {
  key: string;
  href: string;
  label: ReactNode;
  isActive: (location: Location) => boolean;
};

export type MenuItemProps = {
  icons?: ReactElement | ReactElement[];
  label: ReactNode;
  badge?: ReactElement;
  subtitle?: ReactNode;
  reference?: ReactNode;
  tags?: {
    variant?: REFERENCE_TAG_VARIANTS;
    label: ReactNode;
  }[];
  isSelected?: boolean;
  isDisabled?: boolean;
  isDraggable?: boolean;
  hasActivity?: boolean;
};

const DEFAULT_TAGS = [];

export const MenuItemBase = ({
  icons = null,
  label,
  badge = null,
  subtitle = null,
  reference = null,
  tags = DEFAULT_TAGS,
  isSelected = false,
  isDisabled = false,
  isDraggable = false,
  hasActivity = false,
}: MenuItemProps) => {
  const theme = useTheme();
  const { isCollapsed } = useContext(MenuContext);
  return (
    <Fragment>
      <div
        className={cn({ isSelected, isDisabled, withIcons: !!icons })}
        css={styles.iconContainerStyle}
      >
        <div css={styles.iconBadgeContainerStyle}>
          {!!badge && (
            <div css={styles.badgeStyle}>
              {cloneElement(badge, { size: Badge.SIZES.MEDIUM })}
            </div>
          )}

          {!!icons && (
            <div
              css={css`
                display: flex;
                flex-direction: row;
                gap: 7px;
              `}
            >
              {(Array.isArray(icons) && icons.length
                ? (icons as ReactElement[])
                : [icons as ReactElement].filter(Boolean)
              ).map((icon, _, array) =>
                cloneElement(icon, {
                  // Override key if only one icon is passed.
                  key: array.length === 1 ? 'icon' : undefined,
                  size: ICON_SIZES.LARGE,
                  fill: styles.getIconFills(theme, isDisabled, isSelected),
                }),
              )}
            </div>
          )}

          {hasActivity && <div css={styles.dot} />}
        </div>
      </div>

      {!isCollapsed && (
        <Fragment>
          <div
            className={cn({ isSelected })}
            css={styles.labelStyle}
          >
            {!!subtitle && (
              <Typography variant={Typography.VARIANTS.BUTTON_SMALL_BOLD}>
                {subtitle}
              </Typography>
            )}

            <Typography
              variant={Typography.VARIANTS.BUTTON_MEDIUM_BOLD}
              css={css`
                width: 100%;
              `}
            >
              {label}
            </Typography>

            {(!!reference || !!tags.length) && (
              <div css={styles.tagsStyle}>
                {!!reference && (
                  <ReferenceTag size={ReferenceTag.SIZES.SMALL}>
                    {reference}
                  </ReferenceTag>
                )}

                {!!tags.length &&
                  tags.slice(0, 2).map(({ variant, label: tagLabel }) => (
                    <ReferenceTag
                      key={`${tagLabel}`}
                      variant={variant}
                      size={ReferenceTag.SIZES.SMALL}
                    >
                      {tagLabel}
                    </ReferenceTag>
                  ))}

                {
                  // TODO (926): tags slice value is arbitrary and will overflow if text is too
                  // long. Ideally we should check if the div overflows and adapt accordingly.
                  tags.length > 2 && (
                    <Tooltip
                      content={
                        <div css={styles.moreTagsStyle}>
                          {tags.slice(2).map(({ variant, label: tagLabel }) => (
                            <ReferenceTag
                              key={`${tagLabel}`}
                              variant={variant}
                              size={ReferenceTag.SIZES.SMALL}
                            >
                              {tagLabel}
                            </ReferenceTag>
                          ))}
                        </div>
                      }
                    >
                      <Typography
                        variant={Typography.VARIANTS.BODY_SMALL_REGULAR}
                        css={styles.more}
                      >
                        {`+${tags.slice(2).length}`}
                      </Typography>
                    </Tooltip>
                  )
                }
              </div>
            )}
          </div>

          {isDraggable && (
            <div
              css={styles.dragStyle}
              className={MENU_ITEM_DRAGGABLE_CLASSNAME}
            >
              <Drag
                size={ICON_SIZES.MEDIUM}
                fill={[theme.colors.gray[300]]}
              />
            </div>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

MenuItemBase.displayName = 'MenuItem';

export const MenuItem: MemoExoticComponent<typeof MenuItemBase> & {
  Tag?: typeof ReferenceTag;
} = memo(MenuItemBase);

MenuItem.Tag = ReferenceTag;
