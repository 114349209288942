import { css } from '@emotion/react';
import { Dialog, DialogProps } from '@eversity/ui/design-system';
import { memo } from 'react';
import ReactPlayer from 'react-player/lazy';
import propTypes from 'prop-types';
import { noop } from 'lodash';

export type VideoResourceItemDialogProps = {
  isOpen?: DialogProps['isOpen'];
  onRequestClose?: DialogProps['onRequestClose'];
  videoHref: string;
};

export const VideoResourceItemDialogBase = ({
  isOpen = false,
  onRequestClose = noop,
  videoHref,
}: VideoResourceItemDialogProps) => (
  <Dialog
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    size={Dialog.SIZES.LARGE}
  >
    <div
      css={css`
        width: 800px;
        height: 500px;
        padding-top: 40px;
        padding-left: 5px;
      `}
    >
      <ReactPlayer
        width={790}
        height={445}
        url={videoHref}
        controls
      />
    </div>
  </Dialog>
);

VideoResourceItemDialogBase.displayName = 'VideoResourceItemDialog';

VideoResourceItemDialogBase.propTypes = {
  isOpen: propTypes.bool,
  onRequestClose: propTypes.func,
  videoHref: propTypes.string.isRequired,
};

export default memo(VideoResourceItemDialogBase);
