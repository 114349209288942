import { memo, useCallback, Fragment, MemoExoticComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { css, useTheme } from '@emotion/react';
import { Collapse } from 'react-collapse';
import { FormattedMessage } from 'react-intl';
import { Delete, Drag, Edit, Right } from '@icon-park/react';

import {
  Button,
  ICON_SIZES,
  Lightbox,
  ReferenceTag,
  removeButtonAppearance,
  Typography,
} from '@eversity/ui/design-system';
import { useBoolState } from '@eversity/ui/utils';
import { McqQuestion } from '@eversity/types/domain';
import { MCQ_MEDIA_RESOURCES_TYPES } from '@eversity/domain/constants';
import { mcqMessages } from '@eversity/ui/intl';

import { QuestionAnswersTable } from './question-answers-table/QuestionAnswersTable';
import { QuestionItemRemoveAlertDialog } from './remove-dialog/QuestionItemRemoveAlertDialog';
import { questionPropTypes } from '../../../../../types/mcq';
import ImageResourceItem from './image-resource-item/ImageResourceItem';
import AudioResourceItemContextProvider from './audio-resource-item/AudioResourceItemContext';
import VideoResourceItem from './video-resource-item/VideoResourceItem';

const DRAGGABLE_CLASSNAME = 'draggable';

export type QuestionItemProps = {
  question: McqQuestion;
  onEditQuestion?: (question: McqQuestion) => void;
  onRemoveQuestion?: (question: McqQuestion) => void;
  isDraggable?: boolean;
  readOnly?: boolean;
};

export const QuestionItemBase = ({
  question,
  onEditQuestion = noop,
  onRemoveQuestion = noop,
  isDraggable = false,
  readOnly = false,
}: QuestionItemProps) => {
  const theme = useTheme();
  const [isExpanded, , , onToggleExpanded] = useBoolState(false);
  const [
    isRemoveAlertDialogOpen,
    onOpenRemoveAlertDialog,
    onCloseRemoveAlertDialog,
  ] = useBoolState(false);

  const onEdit = useCallback(
    () => onEditQuestion(question),
    [question, onEditQuestion],
  );

  const onRemove = useCallback(
    () => onRemoveQuestion(question),
    [question, onRemoveQuestion],
  );

  return (
    <Fragment>
      <div
        css={css`
          display: flex;
          flex-direction: column;

          width: 100%;

          border: 1px solid ${theme.colors.gray[100]};
          border-radius: 4px;
          background-color: ${theme.colors.gray[0]};

          .ReactCollapse--collapse {
            transition: ${theme.transitions.default()};
          }
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: flex-start;

            padding: 10px;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 20px;
            `}
          >
            {isDraggable && (
              <div
                css={css`
                  cursor: grab;
                `}
              >
                <Drag
                  size={ICON_SIZES.MEDIUM}
                  fill={[theme.colors.gray[300]]}
                  className={DRAGGABLE_CLASSNAME}
                  css={css`
                    cursor: grab;
                  `}
                />
              </div>
            )}

            <button
              type="button"
              css={css`
                ${removeButtonAppearance};

                transform: rotate(${isExpanded ? 90 : 0}deg);

                transition: ${theme.transitions.default('transform')};
              `}
              onClick={onToggleExpanded}
            >
              <Right
                size={ICON_SIZES.LARGE}
                fill={[theme.colors.gray[500]]}
              />
            </button>

            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
              `}
            >
              <Typography variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}>
                {question.label}
              </Typography>

              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 5px;
                `}
              >
                <ReferenceTag size={ReferenceTag.SIZES.SMALL}>
                  <FormattedMessage {...mcqMessages[question.answerType]} />
                </ReferenceTag>

                {Number.isFinite(question.points) && (
                  <Typography variant={Typography.VARIANTS.BODY_SMALL_ITALIC}>
                    <FormattedMessage
                      {...mcqMessages.POINTS_VALUE}
                      values={{
                        value: question.points,
                        b: (chunks) => chunks,
                      }}
                    />
                  </Typography>
                )}
              </div>
            </div>
          </div>

          <div
            css={css`
              display: flex;
              flex-direction: row;
              margin-left: auto;
            `}
          >
            <div>
              {!!question.resources.length && (
                <div
                  css={css`
                    display: flex;
                    flex-direction: row;
                    gap: 20px;
                  `}
                >
                  <Lightbox>
                    {question.resources.map((resource) => {
                      switch (resource.type) {
                        case MCQ_MEDIA_RESOURCES_TYPES.IMAGE:
                          return (
                            <ImageResourceItem
                              href={resource.params.upload.href}
                              alt={resource.params.alt}
                              key={resource.id}
                            />
                          );
                        case MCQ_MEDIA_RESOURCES_TYPES.AUDIO:
                          return (
                            <AudioResourceItemContextProvider
                              audioHref={resource.params.upload.href}
                              key={resource.id}
                            />
                          );
                        case MCQ_MEDIA_RESOURCES_TYPES.VIDEO:
                          return (
                            <VideoResourceItem
                              videoHref={resource.params.href}
                              key={resource.id}
                            />
                          );
                        default:
                          return null;
                      }
                    })}
                  </Lightbox>
                  {!readOnly && (
                    <div
                      css={css`
                        border: 1px solid ${theme.colors.gray[100]};
                        margin-right: 10px;
                      `}
                    />
                  )}
                </div>
              )}
            </div>
            {!readOnly && (
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 10px;
                `}
              >
                <Button
                  size={Button.SIZES.SMALL}
                  icon={<Edit />}
                  onClick={onEdit}
                />

                <Button
                  size={Button.SIZES.SMALL}
                  icon={<Delete />}
                  onClick={onOpenRemoveAlertDialog}
                />
              </div>
            )}
          </div>
        </div>

        <Collapse isOpened={isExpanded}>
          <div
            css={css`
              padding: 10px;

              background-color: ${theme.colors.primary[15]};
              border-radius: 0 0 4px 4px;

              border-top: 1px solid ${theme.colors.gray[100]};
            `}
          >
            <QuestionAnswersTable
              answerStyle={question.answerStyle}
              answers={question.answers}
            />
          </div>
        </Collapse>
      </div>

      <QuestionItemRemoveAlertDialog
        isOpen={isRemoveAlertDialogOpen}
        onRequestClose={onCloseRemoveAlertDialog}
        onClickConfirm={onRemove}
        questionLabel={question.label}
      />
    </Fragment>
  );
};

QuestionItemBase.displayName = 'QuestionItem';

QuestionItemBase.propTypes = {
  question: questionPropTypes.isRequired,
  onEditQuestion: PropTypes.func,
  onRemoveQuestion: PropTypes.func,
  isDraggable: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export const QuestionItem: MemoExoticComponent<typeof QuestionItemBase> & {
  DRAGGABLE_CLASSNAME?: typeof DRAGGABLE_CLASSNAME;
} = memo(QuestionItemBase);

QuestionItem.DRAGGABLE_CLASSNAME = DRAGGABLE_CLASSNAME;
