import { TinyColor } from '@ctrl/tinycolor';
import { css, keyframes, Theme } from '@emotion/react';
import { DRAWER_POSITIONS, DRAWER_SIZES } from './constants';

const drawerEntranceRight = keyframes`
  0% {
    opacity: 0;
    right: -5vw;
  }

  100% {
    opacity: 1;
    right: 0;
  }
`;

const drawerEntranceLeft = keyframes`
  0% {
    opacity: 0;
    left: -5vw;
  }

  100% {
    opacity: 1;
    left: 0;
  }
`;

const drawerExitRight = keyframes`
  0% {
    opacity: 1;
    right: 0;
  }

  100% {
    opacity: 0;
    right: -5vw;
  }
`;

const drawerExitLeft = keyframes`
  0% {
    opacity: 1;
    left: 0;
  }

  100% {
    opacity: 0;
    left: -5vw;
  }
`;

// From animate.css
// https://github.com/animate-css/animate.css/blob/main/source/attention_seekers/headShake.css
const headShake = keyframes`
  0% {
    transform: translateX(0);
  }

  13% {
    transform: translateX(-12px) rotateY(-9deg);
  }

  37% {
    transform: translateX(10px) rotateY(7deg);
  }

  63% {
    transform: translateX(-6px) rotateY(-5deg);
  }

  87% {
    transform: translateX(4px) rotateY(3deg);
  }

  100% {
    transform: translateX(0);
  }
`;

export const drawerStyle = (theme: Theme) => css`
  animation-fill-mode: forwards;
  animation-duration: ${theme.transitions.default.duration}ms;
  animation-timing-function: ${theme.transitions.default.easing};

  position: fixed;

  width: 100%;
  height: 100%;

  overflow: auto;

  border: none;

  box-shadow:
    0px 2px 4px
      ${new TinyColor(theme.colors.gray[900]).setAlpha(0.1).toRgbString()},
    0px 0px 11px 1px
      ${new TinyColor(theme.colors.gray[900]).setAlpha(0.1).toRgbString()};

  &.isOpen {
    &.${DRAWER_POSITIONS.RIGHT} {
      animation-name: ${drawerEntranceRight};
    }

    &.${DRAWER_POSITIONS.LEFT} {
      animation-name: ${drawerEntranceLeft};
    }
  }

  &.triedToClickAway {
    animation-name: ${headShake};
  }

  &.isClosing {
    &.${DRAWER_POSITIONS.RIGHT} {
      animation-name: ${drawerExitRight};
    }

    &.${DRAWER_POSITIONS.LEFT} {
      animation-name: ${drawerExitLeft};
    }
  }

  &.${DRAWER_SIZES.SMALL} {
    width: clamp(300px, 80vw, 450px);
  }

  &.${DRAWER_SIZES.MEDIUM} {
    width: clamp(300px, 80vw, 600px);
  }

  &.${DRAWER_SIZES.LARGE} {
    width: clamp(300px, 80vw, 800px);
  }

  &.${DRAWER_POSITIONS.RIGHT} {
    right: 0;
  }

  &.${DRAWER_POSITIONS.LEFT} {
    left: 0;
  }
`;
