import { memo, ReactElement, useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { More } from '@icon-park/react';

import { useHover } from '@eversity/ui/utils';

import { Button, ButtonProps } from '../../../general/button/Button';

export type PaginationEllipsisButtonProps = Omit<ButtonProps, 'onClick'> & {
  page: number;
  hoverIcon?: ReactElement;
  onClick?: (page: number) => void;
};

export const PaginationEllipsisButtonBase = ({
  page,
  hoverIcon,
  onClick = noop,
  ...props
}: PaginationEllipsisButtonProps) => {
  const onClickProxy = useCallback(() => onClick(page), [onClick, page]);

  const { isHovered, ...hoverProps } = useHover(props);

  return (
    <Button
      {...props}
      {...hoverProps}
      size={Button.SIZES.SMALL}
      onClick={onClickProxy}
      variant={Button.VARIANTS.NEUTRAL}
      icon={isHovered ? hoverIcon : <More />}
    />
  );
};

PaginationEllipsisButtonBase.displayName = 'PaginationEllipsisButton';

PaginationEllipsisButtonBase.propTypes = {
  page: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  hoverIcon: PropTypes.node.isRequired,
};

export const PaginationEllipsisButton = memo(PaginationEllipsisButtonBase);
