import { css, keyframes, Theme } from '@emotion/react';
import { IMPULSE_SPINNER_VARIANTS } from './constants';

export const impulseSpinner = (
  theme: Theme,
  variant: IMPULSE_SPINNER_VARIANTS,
) => keyframes`
  0% {
    transform: scale(1);
    animation-timing-function: cubic-bezier(1, 0, 0.7, 1);
  }

  40% {
    background: ${
      variant === IMPULSE_SPINNER_VARIANTS.PRIMARY
        ? theme.colors.primary[500]
        : theme.colors.secondary[500]
    };

    transform: scale(1.5);
    animation-timing-function: cubic-bezier(0.3, 0, 0, 1);
  }

  72% {
    background: ${theme.colors.gray[100]};
    transform: scale(1);
    animation-timing-function: linear;
  }

  100% {
    transform: scale(1);
  }
`;

export default {
  container: (theme: Theme, size: number) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    width: ${size * 5}px;
    height: ${size}px;
  `,

  ball: (
    theme: Theme,
    size: number,
    variant: IMPULSE_SPINNER_VARIANTS,
    index: number,
  ) => css`
    position: absolute;
    top: 0;
    border-radius: 50%;
    background-color: ${theme.colors.gray[100]};
    animation: ${impulseSpinner(theme, variant)} 1.25s linear
      ${(1.25 * (index - 1)) / 10}s infinite;

    left: ${(index - 1) * size * 2}px;

    width: ${size}px;
    height: ${size}px;
  `,
};
