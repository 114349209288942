import escapeStringRegexp from 'escape-string-regexp';
import { removeDiacritics } from '../strings';

type FilterItem<T> = (
  filterRegExp: RegExp,
  ...args: Parameters<Parameters<Array<T>['filter']>[0]>
) => boolean;

/**
 * Sanitize a search text.
 * Remove accents, trims whitespaces and sets to lower case.
 *
 * @param searchText - Search text.
 * @param options - Options.
 * @returns Search text sanitized.
 */
export const sanitizeSearchText = (
  searchText: string,
  options?: { keepDiacritics?: boolean },
) => {
  if (options?.keepDiacritics) {
    return searchText.toLowerCase().trim();
  }

  return removeDiacritics(searchText).toLowerCase().trim();
};

/**
 * Generate a new RegExp to test text against a search text.
 *
 * @param searchText - Search text.
 * @returns A RegExp.
 */
export const getFilterTextRegExp = (searchText: string) => {
  const sanitizedSearchText = sanitizeSearchText(searchText);
  return new RegExp(escapeStringRegexp(sanitizedSearchText), 'i');
};

/**
 * Filter an array via a searchText.
 *
 * @param searchText - Search text.
 * @param array - Array.
 * @param filterItem - Filter item predicate.
 * @returns Filtered array (immutable).
 */
export const filterArrayWithSearchText = <T>(
  searchText: string,
  array: T[],
  filterItem: FilterItem<T>,
) => {
  if (!searchText) {
    return array || [];
  }

  const filterRegExp = getFilterTextRegExp(searchText);

  return (array || []).filter((...args) => filterItem(filterRegExp, ...args));
};
