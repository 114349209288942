import { css, Theme } from '@emotion/react';

import { SECTION_VARIANTS } from '../section/constants';

export const label = css`
  padding: 4px 8px;
`;

export const link = (theme: Theme) => css`
  text-decoration: none;

  display: flex;
  align-items: center;

  border-radius: 8px 0 0 8px;
  border-right: 2px solid transparent;

  color: ${theme.colors.gray[700]};
  padding-left: 20px;
  margin-left: 48px;

  transition: ${theme.transitions.default()};

  &.${SECTION_VARIANTS.PRIMARY}, &.${SECTION_VARIANTS.NEUTRAL} {
    &:hover,
    &.isSelected {
      color: ${theme.colors.primary[500]};
    }

    &.isSelected {
      background-color: ${theme.colors.primary[25]};
    }
  }

  &.${SECTION_VARIANTS.FADED} {
    color: ${theme.colors.gray[500]};
  }
`;
