import { Theme } from '@emotion/react';
import { last } from 'lodash';

import { getSubmissionStatus } from '@eversity/domain/assignment-submissions/utils';
import { SUBMISSION_STATUSES } from '@eversity/domain/constants';
import { AssignmentSubmission } from '@eversity/types/domain';
import dayjs from '@eversity/services/dayjs';

/**
 * Define the color to display for a submission based on urgency to correct it.
 *
 * @param submission - Assignment submission.
 * @param theme - Theme of eversity.
 * @returns - Color.
 */
export const getSubmissionColorFromTheme = (
  submission: AssignmentSubmission,
  theme: Theme,
) => {
  const status = getSubmissionStatus(submission.deposits);

  if (status === SUBMISSION_STATUSES.CORRECTED) {
    return theme.colors.gray[200];
  }

  const lastDeposit = last(submission.deposits);
  const submissionDaysFromNow = dayjs().diff(
    dayjs(lastDeposit.submissionDate),
    'day',
    true,
  );

  if (submissionDaysFromNow < 1) {
    return theme.colors.success[500];
  }

  if (submissionDaysFromNow < 7) {
    return theme.colors.warning[500];
  }

  return theme.colors.danger[500];
};
