import {
  AddTeachingUnitLessonBody,
  CourseClassViewMinimal,
  CourseViewFull,
  CourseViewMinimal,
  CourseViewStudent,
  CreateClassAssignmentBody,
  CreateCourseBody,
  CreateCourseClassBody,
  CreateCourseExamsPageBody,
  CreateCourseInternshipsPageBody,
  CreateCourseWorkStudyPageBody,
  CreateTeachingUnitBody,
  CreateTeachingUnitLessonUserBody,
  CreateTeachingUnitUserBody,
  DeleteTeachingUnitLessonUserBody,
  DeleteTeachingUnitUserBody,
  GetCourseQuery,
  GetCoursesQuery,
  TeachingUnit,
  TeachingUnitLessonAssignment,
  TeachingUnitLessonUser,
  TeachingUnitUser,
  UpdateCourseBody,
  UpdateCourseClassBody,
  UpdateCourseExamsPageBody,
  UpdateCourseInternshipsPageBody,
  UpdateCourseQuery,
  UpdateCourseWorkStudyPageBody,
  UpdateTeachingUnitBody,
  UpdateTeachingUnitLessonBody,
  UpsertTeachingUnitLessonAssignmentBody,
} from '@eversity/types/domain';

import { HttpRepository } from '../httpRepository';

const e = encodeURIComponent;

export class CoursesRepository extends HttpRepository {
  /**
   * Fetch courses.
   *
   * @param query - Query.
   * @returns Courses matching the query.
   */
  async getCourses(
    query?: GetCoursesQuery,
  ): Promise<CourseViewFull[] | CourseViewMinimal[]> {
    const { body: courses } = await this.http
      .get('/api/v1/school/courses')
      .query(query);

    return courses;
  }

  /**
   * Fetch course.
   *
   * @param courseId - Course id.
   * @param query - Query.
   * @param query.view - View to fetch (COURSE_VIEWS enum).
   * @returns {object} - Course.
   */
  async getCourse(
    courseId: string,
    query?: GetCourseQuery,
  ): Promise<CourseViewFull | CourseViewMinimal | CourseViewStudent> {
    const { body: course } = await this.http
      .get(`/api/v1/school/courses/${e(courseId)}`)
      .query(query);

    return course;
  }

  /**
   * Fetch teaching unit within a course.
   *
   * @param {string} courseId - Course id.
   * @param {string} teachingUnitId - Teaching unit id.
   * @returns {object} - Teaching unit.
   */
  async getTeachingUnit(
    courseId: string,
    teachingUnitId: string,
  ): Promise<TeachingUnit> {
    const { body: teachingUnit } = await this.http.get(
      `/api/v1/school/courses/${e(courseId)}/teaching-units/${e(
        teachingUnitId,
      )}`,
    );

    return teachingUnit;
  }

  /**
   * Create a course.
   *
   * @param params - Params.
   * @param params.title - Course title.
   * @param params.code - Course code (with prefix).
   * @param params.diplomaType - Course diploma type (COURSE_DIPLOMA_TYPES enum value).
   * @returns Created course.
   */
  async createCourse(params: CreateCourseBody): Promise<CourseViewFull> {
    const { body: course } = await this.http
      .post('/api/v1/school/courses')
      .send(params);

    return course;
  }

  /**
   * Update a course.
   *
   * @param courseId - Course id.
   * @param params - Params.
   * @param params.description - Course title.
   * @param query - Query.
   * @param query.view - Course view to return.
   * @returns Created course.
   */
  async updateCourse(
    courseId: string,
    params: UpdateCourseBody,
    query?: UpdateCourseQuery,
  ): Promise<CourseViewFull | CourseViewMinimal> {
    const { body: course } = await this.http
      .patch(`/api/v1/school/courses/${e(courseId)}`)
      .send(params)
      .query(query);

    return course;
  }

  /**
   * Create a new teaching unit in a course.
   *
   * @param courseId - Course id.
   * @param params - Teaching unit params.
   * @param params.code - Teaching unit code.
   * @param params.title - Teaching unit title.
   * @param params.coefficient - Teaching unit coefficient.
   * @returns Created teaching unit.
   */
  async createTeachingUnit(
    courseId: string,
    params: CreateTeachingUnitBody,
  ): Promise<TeachingUnit> {
    const { body: teachingUnit } = await this.http
      .post(`/api/v1/school/courses/${e(courseId)}/teaching-units`)
      .send(params);

    return teachingUnit;
  }

  /**
   * Patch a teaching unit in a course.
   *
   * @param courseId - Course id.
   * @param teachingUnitId - Teaching unit id.
   * @param params - Teaching unit params.
   * @param params.code - Teaching unit code.
   * @param params.title - Teaching unit title.
   * @param params.coefficient - Teaching unit coefficient.
   * @param params.description - Teaching unit description.
   * @returns Updated teaching unit.
   */
  async editTeachingUnit(
    courseId: string,
    teachingUnitId: string,
    params: UpdateTeachingUnitBody,
  ): Promise<TeachingUnit> {
    const { body: teachingUnit } = await this.http
      .patch(
        `/api/v1/school/courses/${e(courseId)}/teaching-units/${e(
          teachingUnitId,
        )}`,
      )
      .send(params);

    return teachingUnit;
  }

  /**
   * Add a lesson to a teaching unit.
   *
   * @param courseId - Course id.
   * @param teachingUnitId - Teaching unit id.
   * @param params - Lesson params.
   * @param params.lessonId - Lesson id.
   * @param params.availableAfterDays - Number of days before being available.
   * @param params.isOptional - Is lesson optional.
   * @returns Updated teaching unit.
   */
  async addTeachingUnitLesson(
    courseId: string,
    teachingUnitId: string,
    params: AddTeachingUnitLessonBody,
  ): Promise<TeachingUnit> {
    const { body: teachingUnit } = await this.http
      .post(
        `/api/v1/school/courses/${e(courseId)}/teaching-units/${e(
          teachingUnitId,
        )}/lessons`,
      )
      .send(params);

    return teachingUnit;
  }

  /**
   * Patch a teaching unit in a course.
   *
   * @param courseId - Course id.
   * @param teachingUnit - Teaching unit id.
   * @param lessonId - Lesson id.
   * @param params - Teaching unit params.
   * @param params.availableAfterDays - Lesson availability in this teaching unit.
   * @param params.isOptional - Is this lesson optional.
   * @param params.description - Teaching unit description.
   * @returns Updated teaching unit.
   */
  async editTeachingUnitLesson(
    courseId: string,
    teachingUnitId: string,
    lessonId: string,
    params: UpdateTeachingUnitLessonBody,
  ): Promise<TeachingUnit> {
    const { body: teachingUnit } = await this.http
      .patch(
        `/api/v1/school/courses/${e(courseId)}/teaching-units/${e(
          teachingUnitId,
        )}/lessons/${e(lessonId)}`,
      )
      .send(params);

    return teachingUnit;
  }

  /**
   * Create a new class in a course.
   *
   * @param courseId - Course id.
   * @param params - Params
   * @param params.title - Class title.
   * @param params.code - Class code (prefixed).
   * @param params.lessons - Class lessons.
   * @param params.lessons[].lesson - Lesson id.
   * @param params.lessons[].teachingUnit - Teaching unit id.
   * @param params.lessons[].vMajor - Major version number of the lesson.
   * @param params.assignments - Exams.
   * @param params.assignments[].assignment - Assignment id.
   * @param params.assignments[].teachingUnit - Teaching unit id.
   * @param params.assignments[].coefficient - Exam coeff in the teaching unit.
   * @returns Course class.
   */
  async createCourseClass(
    courseId: string,
    params: CreateCourseClassBody,
  ): Promise<CourseClassViewMinimal> {
    const { body: courseClass } = await this.http
      .post(`/api/v1/school/courses/${e(courseId)}/classes`)
      .send(params);

    return courseClass;
  }

  /**
   * Update a class in a course.
   *
   * @param courseId - Course id.
   * @param courseClassId - Course class id.
   * @param params - Params
   * @param params.title - Class title.
   * @param params.code - Class code (prefixed).
   * @param params.lessons - Class lessons.
   * @param params.lessons[].lesson - Lesson id.
   * @param params.lessons[].teachingUnit - Teaching unit id.
   * @param params.lessons[].vMajor - Major version number of the lesson.
   * @param params.assignments - Exams.
   * @param params.assignments[].assignment - Assignment id.
   * @param params.assignments[].teachingUnit - Teaching unit id.
   * @param params.assignments[].coefficient - Exam coeff in the teaching unit.
   * @returns Course class.
   */
  async updateCourseClass(
    courseId: string,
    courseClassId: string,
    params: UpdateCourseClassBody,
  ): Promise<CourseClassViewMinimal> {
    const { body: courseClass } = await this.http
      .patch(
        `/api/v1/school/courses/${e(courseId)}/classes/${e(courseClassId)}`,
      )
      .send(params);

    return courseClass;
  }

  /**
   * Add a teacher on a lesson.
   *
   * @param courseId - Course id.
   * @param teachingUnitId - Teaching unit id.
   * @param lessonId - Lesson id.
   * @param params - Params.
   * @param params.userId - User id.
   * @param params.role - Role of the user.
   * @returns Added user.
   */
  async addTeacherOnLesson(
    courseId: string,
    teachingUnitId: string,
    lessonId: string,
    params: CreateTeachingUnitLessonUserBody,
  ): Promise<TeachingUnitLessonUser> {
    const { body: userAdded } = await this.http
      .post(
        `/api/v1/school/courses/${e(courseId)}/teaching-units/${e(
          teachingUnitId,
        )}/lessons/${e(lessonId)}/users`,
      )
      .send(params);

    return userAdded;
  }

  /**
   * Delete a teacher from lesson.
   *
   * @param courseId - Course id.
   * @param teachingUnitId - Teaching unit id.
   * @param lessonId - Lesson id.
   * @param params - Params.
   * @param params.userId - User id.
   * @param params.role - Role of the user.
   * @returns True if status is 204.
   */
  async deleteTeacherFromLesson(
    courseId: string,
    teachingUnitId: string,
    lessonId: string,
    params: DeleteTeachingUnitLessonUserBody,
  ): Promise<boolean> {
    const { status } = await this.http
      .delete(
        `/api/v1/school/courses/${e(courseId)}/teaching-units/${e(
          teachingUnitId,
        )}/lessons/${e(lessonId)}/users`,
      )
      .send(params);

    return status === 204;
  }

  /**
   * Add a user to a teaching unit within a course.
   *
   * @param courseId - Cours id.
   * @param teachingUnitId - Course teaching unit id.
   * @param params - Params.
   * @param params.userId - User id to add.
   * @param params.role - User role.
   * @returns Added user.
   */
  async addUserToTeachingUnit(
    courseId: string,
    teachingUnitId: string,
    params: CreateTeachingUnitUserBody,
  ): Promise<TeachingUnitUser> {
    const { body: user } = await this.http
      .post(
        `/api/v1/school/courses/${e(courseId)}/teaching-units/${e(
          teachingUnitId,
        )}/users`,
      )
      .send(params);

    return user;
  }

  /**
   * Remove a user from a teaching unit within a course.
   *
   * @param courseId - Cours id.
   * @param teachingUnitId - Course teaching unit id.
   * @returns Deletion success.
   */
  async removeUserFromTeachingUnit(
    courseId: string,
    teachingUnitId: string,
    params: DeleteTeachingUnitUserBody,
  ): Promise<boolean> {
    const { status } = await this.http
      .delete(
        `/api/v1/school/courses/${e(courseId)}/teaching-units/${e(
          teachingUnitId,
        )}/users`,
      )
      .send(params);

    return status === 204;
  }

  /**
   * Upsert the parameters of a assignment of a lesson in a teaching unit.
   *
   * @param courseId - Course id.
   * @param teachingUnitId - Teaching unit id.
   * @param lessonId - Lesson id.
   * @param assignmentId - Assignment id.
   * @param params - Params.
   * @param params.coefficient - Coefficient of this assignment.
   * @returns The updated assignment params.
   */
  async upsertTeachingUnitLessonAssignment(
    courseId: string,
    teachingUnitId: string,
    lessonId: string,
    assignmentId: string,
    params: UpsertTeachingUnitLessonAssignmentBody,
  ): Promise<TeachingUnitLessonAssignment> {
    const { body: assignment } = await this.http
      .patch(
        `/api/v1/school/courses/${e(courseId)}/teaching-units/${e(
          teachingUnitId,
        )}/lessons/${e(lessonId)}/assignments/${e(assignmentId)}`,
      )
      .send(params);

    return assignment;
  }

  /**
   * Add an exam to a class.
   *
   * @param courseId - Course id.
   * @param classId - Course class id.
   * @param params - Params.
   * @param params.teachingUnit - Teaching unit id.
   * @param params.assignment - Assignment id.
   * @param params.coeffcient - Coefficient.
   * @returns Class assignment.
   */
  async addAssignmentToClass(
    courseId: string,
    classId: string,
    params: CreateClassAssignmentBody,
  ): Promise<CourseClassViewMinimal['assignments'][0]> {
    const { body: classAssignment } = await this.http
      .post(
        `/api/v1/school/courses/${e(courseId)}/classes/${e(
          classId,
        )}/assignments`,
      )
      .send(params);

    return classAssignment;
  }

  /**
   * Fetch class within a course.
   *
   * @param courseId - Course id.
   * @param classId - Class id.
   * @param query - Query.
   * @returns Class.
   */
  async getCourseClass(
    courseId: string,
    classId: string,
  ): Promise<CourseClassViewMinimal> {
    const { body: classData } = await this.http.get(
      `/api/v1/school/courses/${e(courseId)}/classes/${e(classId)}/`,
    );

    return classData;
  }

  /**
   * Create a new page in the course's internships information.
   * @param courseId - Course id.
   * @param params - Page parameters.
   * @param params.title - Page title.
   * @param params.content - Page HTML content.
   * @param params.shouldNotify - Should notify student of the new page.
   * @returns - Updated course object.
   */
  async createCourseInternshipsPage(
    courseId: string,
    { title, content, shouldNotify }: CreateCourseInternshipsPageBody,
  ): Promise<CourseViewFull> {
    const { body: updatedCourse } = await this.http
      .post(`/api/v1/school/courses/${e(courseId)}/internships-information`)
      .send({
        title,
        content,
        shouldNotify,
      });

    return updatedCourse;
  }

  /**
   * Update a page in the course's internships information.
   * @param courseId - Course id.
   * @param pageId - Page id.
   * @param params - Page parameters.
   * @param params.title - Page title.
   * @param params.content - Page HTML content.
   * @param params.shouldNotify - Should notify students that the page has been updated.
   * @returns Updated course object.
   */
  async updateCourseInternshipsPage(
    courseId: string,
    pageId: string,
    { title, content, shouldNotify }: UpdateCourseInternshipsPageBody,
  ): Promise<CourseViewFull> {
    const { body: updatedCourse } = await this.http
      .patch(
        `/api/v1/school/courses/${e(courseId)}/internships-information/${e(
          pageId,
        )}`,
      )
      .send({
        title,
        content,
        shouldNotify,
      });

    return updatedCourse;
  }

  /**
   * Delete a page in the course's internships information.
   *
   * @param courseId - Course id.
   * @param pageId - Page id.
   * @returns Updated course.
   */
  async deleteCourseInternshipsPage(
    courseId: string,
    pageId: string,
  ): Promise<CourseViewFull> {
    const { body: updatedCourse } = await this.http.delete(
      `/api/v1/school/courses/${e(courseId)}/internships-information/${e(
        pageId,
      )}`,
    );

    return updatedCourse;
  }

  /**
   * Create a new page in the course's exams information.
   * @param courseId - Course id.
   * @param params - Page parameters.
   * @param params.title - Page title.
   * @param params.content - Page HTML content.
   * @param params.shouldNotify - Should notify student of the new page.
   * @returns - Updated course object.
   */
  async createCourseExamsPage(
    courseId: string,
    { title, content, shouldNotify }: CreateCourseExamsPageBody,
  ): Promise<CourseViewFull> {
    const { body: updatedCourse } = await this.http
      .post(`/api/v1/school/courses/${e(courseId)}/exams-information`)
      .send({
        title,
        content,
        shouldNotify,
      });

    return updatedCourse;
  }

  /**
   * Update a page in the course's exams information.
   * @param courseId - Course id.
   * @param pageId - Page id.
   * @param params - Page parameters.
   * @param params.title - Page title.
   * @param params.content - Page HTML content.
   * @param params.shouldNotify - Should notify students that the page has been updated.
   * @returns - Updated course object.
   */
  async updateCourseExamsPage(
    courseId: string,
    pageId: string,
    { title, content, shouldNotify }: UpdateCourseExamsPageBody,
  ): Promise<CourseViewFull> {
    const { body: updatedCourse } = await this.http
      .patch(
        `/api/v1/school/courses/${e(courseId)}/exams-information/${e(pageId)}`,
      )
      .send({
        title,
        content,
        shouldNotify,
      });

    return updatedCourse;
  }

  /**
   * Delete a page in the course's exams information.
   *
   * @param courseId - Course id.
   * @param pageId - Page id.
   * @returns Updated course.
   */
  async deleteCourseExamsPage(
    courseId: string,
    pageId: string,
  ): Promise<CourseViewFull> {
    const { body: updatedCourse } = await this.http.delete(
      `/api/v1/school/courses/${e(courseId)}/exams-information/${e(pageId)}`,
    );

    return updatedCourse;
  }

  /**
   * Create a new page in the course's work study information.
   * @param courseId - Course id.
   * @param params - Page parameters.
   * @param params.title - Page title.
   * @param params.content - Page HTML content.
   * @param params.shouldNotify - Should notify student of the new page.
   * @returns - Updated course object.
   */
  async createCourseWorkStudyPage(
    courseId: string,
    { title, content, shouldNotify }: CreateCourseWorkStudyPageBody,
  ): Promise<CourseViewFull> {
    const { body: updatedCourse } = await this.http
      .post(`/api/v1/school/courses/${e(courseId)}/work-study-information`)
      .send({
        title,
        content,
        shouldNotify,
      });

    return updatedCourse;
  }

  /**
   * Update a page in the course's work study information.
   * @param courseId - Course id.
   * @param pageId - Page id.
   * @param params - Page parameters.
   * @param params.title - Page title.
   * @param params.content - Page HTML content.
   * @param params.shouldNotify - Should notify students that the page has been updated.
   * @returns - Updated course object.
   */
  async updateCourseWorkStudyPage(
    courseId: string,
    pageId: string,
    { title, content, shouldNotify }: UpdateCourseWorkStudyPageBody,
  ): Promise<CourseViewFull> {
    const { body: updatedCourse } = await this.http
      .patch(
        `/api/v1/school/courses/${e(courseId)}/work-study-information/${e(
          pageId,
        )}`,
      )
      .send({
        title,
        content,
        shouldNotify,
      });

    return updatedCourse;
  }

  /**
   * Delete a page in the course's work study information.
   *
   * @param courseId - Course id.
   * @param pageId - Page id.
   * @returns Updated course.
   */
  async deleteCourseWorkStudyPage(
    courseId: string,
    pageId: string,
  ): Promise<CourseViewFull> {
    const { body: updatedCourse } = await this.http.delete(
      `/api/v1/school/courses/${e(courseId)}/work-study-information/${e(
        pageId,
      )}`,
    );

    return updatedCourse;
  }

  /**
   * Delete a teaching unit.
   *
   * @param courseId - Course id.
   * @param teachingUnitId - Course teaching unit id.
   * @returns Deletion success.
   */
  async deleteTeachingUnit(
    courseId: string,
    teachingUnitId: string,
  ): Promise<boolean> {
    const { status } = await this.http.delete(
      `/api/v1/school/courses/${e(courseId)}/teaching-units/${e(
        teachingUnitId,
      )}`,
    );
    return status === 204;
  }

  /**
   * Delete a lesson from a teaching unit in a course.
   *
   * @param courseId - Course id.
   * @param teachingUnit - Teaching unit id.
   * @param lessonId - Lesson id.
   * @returns Updated teaching unit.
   */
  async deleteTeachingUnitLesson(
    courseId: string,
    teachingUnitId: string,
    lessonId: string,
  ): Promise<TeachingUnit> {
    const { body: teachingUnit } = await this.http.delete(
      `/api/v1/school/courses/${e(courseId)}/teaching-units/${e(
        teachingUnitId,
      )}/lessons/${e(lessonId)}`,
    );

    return teachingUnit;
  }

  /**
   * Delete a class within a course.
   *
   * @param courseId - Course id.
   * @param classId - Course class id.
   * @returns Deletion success.
   */
  async deleteCourseClass(courseId: string, classId: string): Promise<boolean> {
    const { status } = await this.http.delete(
      `/api/v1/school/courses/${e(courseId)}/classes/${e(classId)}`,
    );
    return status === 204;
  }

  /**
   * Delete a course
   *
   * @param courseId - Course id.
   * @returns True if successfully deleted.
   */
  async deleteCourse(courseId: string): Promise<boolean> {
    const { status } = await this.http.delete(
      `/api/v1/school/courses/${e(courseId)}`,
    );
    return status === 204;
  }
}
