import { memo, ReactNode, useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Down, Up } from '@icon-park/react';
import { useTheme } from '@emotion/react';
import cn from 'classnames';

import { SORT } from '@eversity/domain/constants';

import styles from './HeaderCell.styles';

import { ICON_SIZES } from '../../../general/icon/constants';
import { Typography } from '../../../general/typography/Typography';
import { parseSortParameters } from '../../../../utils/arrays';

export type HeaderCellProps = {
  name: string;
  title?: ReactNode;
  toggleSort?: (name: string) => void;
  sortable?: boolean;
  sort?: string;
  align?: string;
};

export const HeaderCellBase = ({
  name,
  title = null,
  toggleSort = noop,
  sortable = false,
  sort = null,
  align = null,
}: HeaderCellProps) => {
  const theme = useTheme();
  const onToggleSort = useCallback(() => toggleSort(name), [toggleSort, name]);
  const { sortBy, sortDirection } = parseSortParameters(sort);

  const isSorted = sortBy === name;

  return (
    <div
      css={styles.header}
      className={cn({ alignRight: align === 'right' })}
    >
      <Typography variant={Typography.VARIANTS.BODY_MEDIUM_BOLD}>
        {title}
      </Typography>

      {sortable && (
        <button
          type="button"
          css={styles.sort}
          onClick={onToggleSort}
        >
          <Up
            size={ICON_SIZES.SMALL}
            fill={
              isSorted && sortDirection === SORT.DESC
                ? theme.colors.primary[500]
                : theme.colors.gray[300]
            }
          />
          <Down
            size={ICON_SIZES.SMALL}
            fill={
              isSorted && sortDirection === SORT.ASC
                ? theme.colors.primary[500]
                : theme.colors.gray[300]
            }
          />
        </button>
      )}
    </div>
  );
};

HeaderCellBase.displayName = 'HeaderCell';

HeaderCellBase.propTypes = {
  /** Title of the Column. */
  title: PropTypes.node,
  /** Key name of the column. */
  name: PropTypes.string.isRequired,
  /** Event that triggers toggling the sort. */
  toggleSort: PropTypes.func,
  /** Use sortings. */
  sortable: PropTypes.bool,
  /** Current sorting direction. */
  sort: PropTypes.string,
  /** Specify how cell content is aligned (left/right, not specified = left). */
  align: PropTypes.string,
};

export const HeaderCell = memo(HeaderCellBase);
