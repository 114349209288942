import { Theme } from '@emotion/react';

import { COLORS, CONTRAST_COLORS, HUES } from './colors';
import { TYPOGRAPHY } from './typography';
import { defaultTransition } from './transitions';
import ThemeGlobalStyles from './styles';

export const DEFAULT_THEME: Theme = {
  colors: COLORS,
  contrastColors: CONTRAST_COLORS,
  hues: HUES,
  typography: TYPOGRAPHY,

  globalStylesComponent: ThemeGlobalStyles,

  transitions: {
    default: defaultTransition,
  },
};
