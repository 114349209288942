import { HTMLProps, memo } from 'react';

import LogoSvg from './logo.svg';

export type LogoProps = Omit<HTMLProps<SVGElement>, 'ref'>;

const LogoBase = (props: LogoProps) => <LogoSvg {...props} />;

LogoBase.displayName = 'Logo';

export const Logo = memo(LogoBase);
