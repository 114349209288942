import dayjs from 'dayjs';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormatPlugin from 'dayjs/plugin/localizedFormat';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
import minMaxPlugin from 'dayjs/plugin/minMax';
import durationPlugin from 'dayjs/plugin/duration';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekday from 'dayjs/plugin/weekday';
import advancedFormat from 'dayjs/plugin/advancedFormat';

// Dayjs offers opt-in plugins to enhance its API.
// https://day.js.org/docs/en/plugin/plugin

dayjs.extend(localizedFormatPlugin);
dayjs.extend(durationPlugin);
dayjs.extend(relativeTimePlugin);
dayjs.extend(customParseFormat);
dayjs.extend(minMaxPlugin);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(weekOfYear);
dayjs.extend(weekday);
dayjs.extend(advancedFormat);

export default dayjs;
