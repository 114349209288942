import { memo, ReactElement } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Collapse } from 'react-collapse';

import { AccordionHeader } from './AccordionHeader';
import { AccordionItem } from './AccordionItem';
import { itemBoxStyle } from './Accordion.styles';

import { accordionItemShape } from '../../../types/accordion';

type AccordionItemProps = {
  id: string;
  title: string;
  icon?: ReactElement;
  withDot?: boolean;
};

export type AccordionProps = {
  title: string;
  subtitle?: string;
  items?: AccordionItemProps[];
  icon?: ReactElement;
  isOpen?: boolean;
  onClickHeader?: () => void;
  selectedItem?: string;
  onClickItem?: (id: string, item: AccordionItemProps) => void;
};

const DEFAULT_ITEMS = [];

export const AccordionBase = ({
  title,
  subtitle = undefined,
  items = DEFAULT_ITEMS,
  icon = undefined,
  isOpen = false,
  onClickHeader = noop,
  selectedItem = undefined,
  onClickItem = noop,
}: AccordionProps) => {
  const withDotOnHeader = items.some((item) => item.withDot);

  return (
    <div css={itemBoxStyle}>
      <AccordionHeader
        title={title}
        subtitle={subtitle}
        icon={icon}
        isOpen={isOpen}
        onClick={onClickHeader}
        withDot={withDotOnHeader}
      />
      <Collapse isOpened={isOpen}>
        {items.map((item) => (
          <AccordionItem
            key={item.id}
            title={item.title}
            icon={item.icon}
            isSelected={selectedItem === item.id}
            onClick={() => onClickItem(item.id, item)}
            withDot={item.withDot}
          />
        ))}
      </Collapse>
    </div>
  );
};

AccordionBase.displayName = 'Accordion';

AccordionBase.propTypes = {
  /** Title of the group of items. */
  title: PropTypes.node.isRequired,
  /** Subtitle of the group of items. */
  subtitle: PropTypes.node,
  /** List of items to display. */
  items: PropTypes.arrayOf(accordionItemShape),
  /** Icon displayed on the left of the header. */
  icon: PropTypes.node,
  /** Define if the list of item is currently displayed. */
  isOpen: PropTypes.bool,
  /** Id of currently selected item. */
  selectedItem: PropTypes.string,
  /** Callback triggered on the click of the header. */
  onClickHeader: PropTypes.func,
  /** Callback triggered on the click of the item. */
  onClickItem: PropTypes.func,
};

export const Accordion = memo(AccordionBase);
