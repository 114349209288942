import {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  HTMLProps,
  memo,
  MemoExoticComponent,
  ReactNode,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import cn from 'classnames';

import { useFocus } from '@eversity/ui/utils';

import {
  SWITCH_INPUT_SIDES,
  SWITCH_SIZES,
  SWITCH_SIZE_TYPOGRAPHY_MAPPING,
} from './constants';

import { Typography } from '../../general/typography/Typography';

import * as styles from './Switch.styles';

export type SwitchProps = Omit<
  HTMLProps<HTMLInputElement>,
  'onChange' | 'size' | 'ref'
> & {
  description?: ReactNode;
  size?: SWITCH_SIZES;
  side?: SWITCH_INPUT_SIDES;
  onChange?: (checked: boolean, event: ChangeEvent<HTMLInputElement>) => void;
};

export const SwitchBase = forwardRef(
  (
    { description, size, onChange, className, side, ...props }: SwitchProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const { isFocused, ...focusProps } = useFocus(props);

    // Extract the value from the event so we don't have to override onChange everywhere.
    // Pass event as 2nd parameter if needed in some obscure case.
    const onChangeProxy = useCallback(
      (event: ChangeEvent<HTMLInputElement>) =>
        onChange(event.target.checked, event),
      [onChange],
    );

    return (
      <styles.SwitchContainer
        className={cn(className, size, side, {
          focused: isFocused,
          disabled: props.disabled,
          checked: props.checked,
        })}
      >
        <input
          {...props}
          {...focusProps}
          ref={ref}
          type="checkbox"
          onChange={onChangeProxy}
          css={styles.input}
        />

        <styles.ControlContainer>
          <styles.InnerControl>
            <styles.Control />
          </styles.InnerControl>
        </styles.ControlContainer>

        {!!description && (
          <Typography variant={SWITCH_SIZE_TYPOGRAPHY_MAPPING[size]}>
            {description}
          </Typography>
        )}
      </styles.SwitchContainer>
    );
  },
);

SwitchBase.displayName = 'Switch';

SwitchBase.propTypes = {
  /** Switch description text. */
  description: PropTypes.node,
  /** Input size. */
  size: PropTypes.oneOf(Object.values(SWITCH_SIZES)),
  /** On change handler. (checked, event) => null. */
  onChange: PropTypes.func,
  /** Is the input disabled. */
  disabled: PropTypes.bool,
  /** Is the input checked. */
  checked: PropTypes.bool,
  /** Class name. */
  className: PropTypes.string,
  /** Side displaying the switch relative to the description. */
  side: PropTypes.oneOf(Object.values(SWITCH_INPUT_SIDES)),
};

SwitchBase.defaultProps = {
  description: null,
  size: SWITCH_SIZES.MEDIUM,
  onChange: noop,
  disabled: false,
  checked: false,
  className: null,
  side: SWITCH_INPUT_SIDES.LEFT,
};

export const Switch: MemoExoticComponent<typeof SwitchBase> & {
  SIZES?: typeof SWITCH_SIZES;
  SIDES?: typeof SWITCH_INPUT_SIDES;
} = memo(SwitchBase);

Switch.SIZES = SWITCH_SIZES;
Switch.SIDES = SWITCH_INPUT_SIDES;
