import {
  AiInteraction,
  GetAiInteractionQuery,
  GetUserAiInteractionQuery,
} from '@eversity/types/domain';

import { HttpRepository } from '../httpRepository';

const BASE_INTERACTION_URI = '/api/v1/ai/interactions';

export class AiInteractionsRepository extends HttpRepository {
  /**
   * Get all students AI interactions.
   *
   * @param query - Query.
   * @param query.limit - Limit number of results.
   * @param query.offset - Skip results.
   * @param query.startDate - Filter on AI interaction after this date.
   * @param query.endDate - Filter on AI interaction before this date.
   * @returns Object with count and AI interactions list.
   */
  async getAiInteractions(
    query?: GetAiInteractionQuery,
  ): Promise<{ count: number; aiInteractions: AiInteraction[] }> {
    const {
      body: { count, aiInteractions },
    } = await this.http.get(BASE_INTERACTION_URI).query(query);

    return {
      count,
      aiInteractions,
    };
  }

  async getUserAiInteractions(
    query?: GetUserAiInteractionQuery,
  ): Promise<AiInteraction[]> {
    const { body: aiInteractions } = await this.http
      .get('/api/v1/users/users/me/interactions')
      .query(query);

    return aiInteractions;
  }

  /**
   * Ask a question to the AI
   *
   * @param text - the AI question
   * @returns the AI answer and his AI interaction object
   */
  async askAi(params: { text: string }): Promise<AiInteraction> {
    const { body: aiInteraction } = await this.http
      .post(`${BASE_INTERACTION_URI}/search`)
      .send(params);
    return aiInteraction;
  }

  /**
   *
   * @param interactionId - the AI interaction Id
   * @param isPositive - student feedback on the AI answer
   * @returns AI interaction
   */
  async postAiInteractionfeedback(
    interactionId: string,
    params: { isPositive: boolean },
  ): Promise<AiInteraction> {
    const { body: aiInteraction } = await this.http
      .post(`${BASE_INTERACTION_URI}/${interactionId}/feedback`)
      .send(params);

    return aiInteraction;
  }
}
