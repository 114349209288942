import { createContext } from 'react';
import { noop } from 'lodash';

import { UseWizard } from '../../../hooks/useWizard';

export const WizardContext = createContext<UseWizard>([
  {
    isCurrentStep: () => false,
    onGoToNextStep: noop,
    onGoToPreviousStep: noop,
    isNextStepDisabled: true,
    isPreviousStepDisabled: true,
  },
  undefined,
  noop,
]);
