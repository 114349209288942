import { Fragment, memo, MemoExoticComponent } from 'react';
import PropTypes from 'prop-types';
import { Theme, useTheme } from '@emotion/react';
import { DocDetail, ListSuccess, Plus } from '@icon-park/react';

import { MCQ_PAGE_TYPES } from '@eversity/domain/constants';
import { ICON_SIZES } from '@eversity/ui/design-system';

enum SIZES {
  MEDIUM = 'MEDIUM',
  VERY_LARGE = 'VERY_LARGE',
}

enum VARIANTS {
  PRIMARY = 'PRIMARY',
  NEUTRAL = 'NEUTRAL',
}

const getIconFills = (theme: Theme, variant: VARIANTS) => {
  switch (variant) {
    case VARIANTS.PRIMARY:
      return [theme.colors.primary[500], theme.colors.primary[50]];
    case VARIANTS.NEUTRAL:
    default:
      return [theme.colors.gray[700], theme.colors.gray[50]];
  }
};

const getIconSize = (size: SIZES) => {
  switch (size) {
    case SIZES.VERY_LARGE:
      return 50;
    case SIZES.MEDIUM:
    default:
      return ICON_SIZES.MEDIUM;
  }
};

const getPlusIconSize = (size: SIZES) => {
  switch (size) {
    case SIZES.VERY_LARGE:
      return ICON_SIZES.LARGE;
    case SIZES.MEDIUM:
    default:
      return ICON_SIZES.SMALL;
  }
};

export type McqPageTypeIconsProps = {
  pageType?: MCQ_PAGE_TYPES;
  size?: SIZES;
  variant?: VARIANTS;
};

export const McqPageTypeIconsBase = ({
  pageType = MCQ_PAGE_TYPES.MCQ,
  size = SIZES.MEDIUM,
  variant = VARIANTS.NEUTRAL,
}: McqPageTypeIconsProps) => {
  const theme = useTheme();

  return (
    <Fragment>
      {pageType !== MCQ_PAGE_TYPES.MCQ && (
        <DocDetail
          size={getIconSize(size)}
          fill={getIconFills(theme, variant)}
          strokeWidth={2}
        />
      )}
      {pageType === MCQ_PAGE_TYPES.MCQ_CONTENT && (
        <Plus
          size={getPlusIconSize(size)}
          fill={getIconFills(theme, variant)}
          strokeWidth={2}
        />
      )}
      {pageType !== MCQ_PAGE_TYPES.CONTENT && (
        <ListSuccess
          size={getIconSize(size)}
          fill={getIconFills(theme, variant)}
          strokeWidth={2}
        />
      )}
    </Fragment>
  );
};

McqPageTypeIconsBase.displayName = 'McqPageTypeIcons';

McqPageTypeIconsBase.propTypes = {
  pageType: PropTypes.oneOf(Object.values(MCQ_PAGE_TYPES)),
  size: PropTypes.oneOf(Object.values(SIZES)),
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
};

export const McqPageTypeIcons: MemoExoticComponent<
  typeof McqPageTypeIconsBase
> & {
  VARIANTS?: typeof VARIANTS;
  SIZES?: typeof SIZES;
} = memo(McqPageTypeIconsBase);

McqPageTypeIcons.VARIANTS = VARIANTS;
McqPageTypeIcons.SIZES = SIZES;
