import {
  cloneElement,
  memo,
  MemoExoticComponent,
  ReactElement,
  ReactNode,
} from 'react';
import PropTypes from 'prop-types';
import { css, useTheme } from '@emotion/react';
import { isFunction } from 'lodash';

import { useMemoizedBundle } from '@eversity/ui/utils';

import { EMPTY_STATE_SIZES } from './constants';
import { TYPOGRAPHY_VARIANTS } from '../../../config/typography/constants';

import { HtmlFormatter } from '../html-formatter/HtmlFormatter';
import { Typography } from '../../general/typography/Typography';
import { EmptyStateContext } from './EmptyStateContext';
import {
  EMPTY_STATE_SIZE_TYPOGRAPHY_MAPPING,
  Paragraph,
  TypographyMapping,
} from './paragraph/Paragraph';

const EMPTY_STATE_SIZE_TITLE_TYPOGRAPHY_MAPPING: Record<
  EMPTY_STATE_SIZES,
  TYPOGRAPHY_VARIANTS
> = {
  [EMPTY_STATE_SIZES.SMALL]: Typography.VARIANTS.HEADING_4,
  [EMPTY_STATE_SIZES.MEDIUM]: Typography.VARIANTS.HEADING_3,
  [EMPTY_STATE_SIZES.LARGE]: Typography.VARIANTS.HEADING_2,
};

const EMPTY_STATE_SIZE_ICON_SIZE_MAPPING: Record<EMPTY_STATE_SIZES, number> = {
  [EMPTY_STATE_SIZES.SMALL]: 50,
  [EMPTY_STATE_SIZES.MEDIUM]: 80,
  [EMPTY_STATE_SIZES.LARGE]: 120,
};

const EMPTY_STATE_SIZE_ICON_PADDING_MAPPING: Record<EMPTY_STATE_SIZES, number> =
  {
    [EMPTY_STATE_SIZES.SMALL]: 20,
    [EMPTY_STATE_SIZES.MEDIUM]: 30,
    [EMPTY_STATE_SIZES.LARGE]: 50,
  };

const EMPTY_STATE_SIZE_ICON_STROKE_WIDTH_MAPPING: Record<
  EMPTY_STATE_SIZES,
  number
> = {
  [EMPTY_STATE_SIZES.SMALL]: 2,
  [EMPTY_STATE_SIZES.MEDIUM]: 1.5,
  [EMPTY_STATE_SIZES.LARGE]: 1,
};

export type EmptyStateProps = {
  title: ReactNode;
  icon?: ReactElement;
  size?: EMPTY_STATE_SIZES;
  children?:
    | ReactNode
    | ((props: { typography: TypographyMapping }) => ReactNode);
};

export const EmptyStateBase = ({
  title,
  icon = null,
  size = EMPTY_STATE_SIZES.MEDIUM,
  children = null,
}: EmptyStateProps) => {
  const theme = useTheme();

  const contextValue = useMemoizedBundle({
    size,
  });

  return (
    <EmptyStateContext.Provider value={contextValue}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;

          color: ${theme.colors.primary[700]};
        `}
      >
        {icon && (
          <div
            css={css`
              padding: ${EMPTY_STATE_SIZE_ICON_PADDING_MAPPING[size]}px;
              border-radius: 100%;
              background-color: ${theme.colors.primary[25]};
            `}
          >
            {cloneElement(icon, {
              size: EMPTY_STATE_SIZE_ICON_SIZE_MAPPING[size],
              fill: [theme.colors.primary[400], theme.colors.primary[50]],
              strokeWidth: EMPTY_STATE_SIZE_ICON_STROKE_WIDTH_MAPPING[size],
            })}
          </div>
        )}

        <HtmlFormatter
          css={css`
            margin-top: 36px;
            text-align: center;
          `}
        >
          <Typography
            variant={EMPTY_STATE_SIZE_TITLE_TYPOGRAPHY_MAPPING[size]}
            css={css`
              color: ${theme.colors.primary[500]};
            `}
          >
            {title}
          </Typography>

          {isFunction(children)
            ? children({
                typography: EMPTY_STATE_SIZE_TYPOGRAPHY_MAPPING[size],
              })
            : children}
        </HtmlFormatter>
      </div>
    </EmptyStateContext.Provider>
  );
};

EmptyStateBase.displayName = 'EmptyState';

EmptyStateBase.propTypes = {
  /** Icon from @icon-park/react. */
  icon: PropTypes.node,
  /** Empty state size to scale all components. */
  size: PropTypes.oneOf(Object.values(EMPTY_STATE_SIZES)),
  /** Title text. */
  title: PropTypes.node.isRequired,
  /** Content. Do not wrap inside a div (or other), it will be wrapped by HtmlFormatter. */
  children: PropTypes.oneOfType([
    PropTypes.node,
    // ({ typography: object }) => React.Node.
    PropTypes.func,
  ]),
};

export const EmptyState: MemoExoticComponent<typeof EmptyStateBase> & {
  SIZES?: typeof EMPTY_STATE_SIZES;
  Paragraph?: typeof Paragraph;
} = memo(EmptyStateBase);

EmptyState.SIZES = EMPTY_STATE_SIZES;
EmptyState.Paragraph = Paragraph;

/**
 * Note to self: we will use new svgs in the future. In order not to break existing empty states,
 * just add another prop (picture) and render that instead of the icon.
 */
