import { css, Theme } from '@emotion/react';

import { VARIANTS } from '../constants';

import WaveOne from '../../../../../assets/images/WaveOne.raw.svg';
import WaveTwo from '../../../../../assets/images/WaveTwo.raw.svg';
import WaveThree from '../../../../../assets/images/WaveThree.raw.svg';

export const getColors = (theme: Theme, variant: VARIANTS) => {
  switch (variant) {
    case VARIANTS.WARNING:
      return {
        background: theme.colors.warning[25],
        waveOne: theme.colors.warning[200],
        waveTwo: theme.colors.warning[100],
        waveThree: theme.colors.warning[50],
        content: theme.colors.gray[700],
      };
    case VARIANTS.DANGER:
      return {
        background: theme.colors.danger[25],
        waveOne: theme.colors.danger[200],
        waveTwo: theme.colors.danger[100],
        waveThree: theme.colors.danger[50],
        content: theme.colors.gray[700],
      };
    case VARIANTS.SUCCESS:
      return {
        background: theme.colors.success[25],
        waveOne: theme.colors.success[200],
        waveTwo: theme.colors.success[100],
        waveThree: theme.colors.success[50],
        content: theme.colors.gray[700],
      };
    case VARIANTS.NEUTRAL:
      return {
        background: theme.colors.gray[0],
        waveOne: theme.colors.gray[0],
        waveTwo: theme.colors.gray[0],
        waveThree: theme.colors.gray[0],
        content: theme.colors.gray[0],
      };
    case VARIANTS.PRIMARY:
    default:
      return {
        background: theme.colors.primary[25],
        waveOne: theme.colors.primary[200],
        waveTwo: theme.colors.primary[100],
        waveThree: theme.colors.primary[50],
        content: theme.colors.gray[700],
      };
  }
};

export const container = (theme: Theme, variant: VARIANTS) => css`
  position: relative;
  border-top: 1px solid ${theme.colors.gray[100]};
  padding: 10px;
  background-color: ${getColors(theme, variant).background};

  border-radius: 0 0 4px 4px;

  display: flex;
  align-items: flex-start;
  gap: 10px;

  z-index: 0;
  overflow: hidden;
`;

export const waveThree = (theme: Theme, variant: VARIANTS) => css`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 400px;
  height: 60px;
  background-color: ${getColors(theme, variant).waveThree};
  mask-image: url(${WaveThree});
  background-repeat: no-repeat;
  mask-size: 100% 100%;
  opacity: 0.3;
  z-index: -3;
`;

export const waveTwo = (theme: Theme, variant: VARIANTS) => css`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 380px;
  height: 50px;
  background-color: ${getColors(theme, variant).waveTwo};
  mask-image: url(${WaveTwo});
  background-repeat: no-repeat;
  mask-size: 100% 100%;
  opacity: 0.3;
  z-index: -2;
`;

export const waveOne = (theme: Theme, variant: VARIANTS) => css`
  position: absolute;
  bottom: -1px;
  right: 0;
  width: 300px;
  height: 21px;
  background-color: ${getColors(theme, variant).waveOne};
  mask-image: url(${WaveOne});
  background-repeat: no-repeat;
  mask-size: 100% 100%;
  opacity: 0.3;
  z-index: -1;
`;
