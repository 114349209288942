import { HTMLProps, memo, MemoExoticComponent } from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { RadioButtonGroupContextProvider } from '../radio/contexts/RadioButtonGroup.context';
import { RadioButton } from './radio-button/RadioButton';

export type RadioButtonGroupProps = Omit<
  HTMLProps<HTMLDivElement>,
  'ref' | 'value' | 'onChange'
> & {
  value?: string;
  onChange?: (newValue: string) => void;
};

export const RadioButtonGroupBase = ({
  value = null,
  onChange = noop,
  children = null,
  ...props
}: RadioButtonGroupProps) => (
  <RadioButtonGroupContextProvider
    value={value}
    onChange={onChange}
  >
    <div
      {...props}
      css={css`
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
      `}
    >
      {children}
    </div>
  </RadioButtonGroupContextProvider>
);

RadioButtonGroupBase.displayName = 'RadioButtonGroup';

RadioButtonGroupBase.propTypes = {
  /** Selected value. */
  value: PropTypes.string,
  /** On change function. */
  onChange: PropTypes.func,
  /** Content. */
  children: PropTypes.node,
};

export const RadioButtonGroup: MemoExoticComponent<
  typeof RadioButtonGroupBase
> & {
  RadioButton?: typeof RadioButton;
} = memo(RadioButtonGroupBase);

RadioButtonGroup.RadioButton = RadioButton;
