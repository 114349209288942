import { useCallback, useEffect, MouseEvent } from 'react';

import { useBoolState } from './useBoolState';

/**
 * A hook to determine if an element is considered active (css-wise).
 *
 * @param [props] - Props.
 * @param [props.onMouseDown] - Event handler to wrap.
 * @returns Active state and event handlers.
 */
export const useActive = <TElement>({
  onMouseDown,
}: {
  onMouseDown?: (event: MouseEvent<TElement>) => void;
} = {}): {
  isActive: boolean;
  onMouseDown: (event: MouseEvent<TElement>) => void;
} => {
  const [isActive, onSetActive, onSetInactive] = useBoolState(false);

  const onMouseDownProxy = useCallback(
    (event: MouseEvent<TElement>) => {
      onSetActive();
      onMouseDown?.(event);
    },
    [onSetActive, onMouseDown],
  );

  /** The css :active pseudo-selector is removed when releasing the mouse button anywhere. */
  useEffect(() => {
    document?.addEventListener('mouseup', onSetInactive);
    return () => document?.removeEventListener('mouseup', onSetInactive);
  }, [onSetInactive]);

  return {
    isActive,
    onMouseDown: onMouseDownProxy,
  };
};
