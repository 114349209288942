import { computeSequenceNumbers } from '@eversity/domain/lessons/utils';
import {
  LessonEditableVersionViewFull,
  LessonEditableVersionViewFullNumberedTitled,
  LessonEditableVersionViewInternal,
  LessonVersionViewFull,
  LessonVersionViewFullNumberedTitled,
  LessonVersionViewMinimal,
  LessonVersionWithSequencesViewMinimal,
  LessonVersionWithSequencesViewMinimalNumberedTitled,
  LessonViewFull,
  LessonViewFullNumberedTitled,
  LessonViewInternal,
  LessonViewMinimal,
} from '@eversity/types/domain';

// TODO map this from the backend so we can remove these mappers.

export const mapVersion = (
  version:
    | LessonVersionViewMinimal
    | LessonVersionWithSequencesViewMinimal
    | LessonEditableVersionViewInternal
    | LessonVersionViewFull
    | LessonEditableVersionViewFull,
):
  | LessonVersionViewMinimal
  | LessonVersionWithSequencesViewMinimalNumberedTitled
  | LessonEditableVersionViewInternal
  | LessonVersionViewFullNumberedTitled
  | LessonEditableVersionViewFullNumberedTitled =>
  version && 'sequences' in version
    ? {
        ...version,
        sequences: computeSequenceNumbers<
          | LessonVersionViewFull['sequences'][0]
          | LessonEditableVersionViewFull['sequences'][0]
          | LessonVersionWithSequencesViewMinimal['sequences'][0]
        >(version.sequences)?.map((sequence) => ({
          ...sequence,
          title: sequence.outline[0].title,
          outline: sequence.outline[0].children,
        })),
      }
    : version;

export const mapLesson = (
  lesson: LessonViewFull | LessonViewMinimal | LessonViewInternal,
): LessonViewFullNumberedTitled | LessonViewMinimal | LessonViewInternal =>
  'versions' in lesson || 'editableVersions' in lesson
    ? {
        ...lesson,
        versions: (lesson as LessonViewFull | LessonViewInternal).versions?.map(
          (version: LessonVersionViewFull | LessonVersionViewMinimal) =>
            mapVersion(version),
        ) as LessonVersionViewFullNumberedTitled[] | LessonVersionViewMinimal[],
        editableVersions: (
          lesson as LessonViewFull | LessonViewInternal
        ).editableVersions?.map(
          (
            version:
              | LessonEditableVersionViewFull
              | LessonEditableVersionViewInternal,
          ) => mapVersion(version),
        ) as
          | LessonEditableVersionViewFullNumberedTitled[]
          | LessonEditableVersionViewInternal[],
      }
    : lesson;
