import { TinyColor } from '@ctrl/tinycolor';
import { css, Theme } from '@emotion/react';

import { RADIO_BUTTON_SIZES, RADIO_BUTTON_VARIANTS } from './constants';

export const button = (theme: Theme) => css`
  transition: ${theme.transitions.default()};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &.${RADIO_BUTTON_SIZES.SMALL} {
    width: 250px;
    padding: 20px;
  }

  &.${RADIO_BUTTON_SIZES.MEDIUM} {
    width: 300px;
    padding: 40px;
  }

  border-radius: 4px;

  &.${RADIO_BUTTON_VARIANTS.PRIMARY} {
    border: 1px solid ${theme.colors.gray[300]};
    &.checked {
      box-shadow: 0 0 0 4px
        ${new TinyColor(theme.colors.primary[500]).setAlpha(0.15).toRgbString()};
      border-color: ${theme.colors.primary[400]};
    }
  }
  &.${RADIO_BUTTON_VARIANTS.NEUTRAL} {
    border: 1px solid ${theme.colors.gray[100]};
    &.checked {
      box-shadow: 0 0 0 4px
        ${new TinyColor(theme.colors.gray[500]).setAlpha(0.15).toRgbString()};
      border-color: ${theme.colors.gray[400]};
    }
  }
`;

export const iconsContainer = (theme: Theme) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;
  border-radius: 90px;

  &.isShadowed {
    &.${RADIO_BUTTON_VARIANTS.PRIMARY} {
      background-color: ${theme.colors.primary[25]};
    }
    &.${RADIO_BUTTON_VARIANTS.NEUTRAL} {
      background-color: ${theme.colors.gray[25]};
    }
  }
`;

export const label = css`
  margin-top: 30px;
  width: 100%;
  margin-bottom: 10px;
`;

export const description = (theme: Theme) => css`
  padding-top: 10px;
  border-top: 1px solid ${theme.colors.gray[100]};
`;
