import { useState, useCallback } from 'react';

export type BoolStateValue = [boolean, () => void, () => void, () => void];

export const useBoolState = (
  initialState: boolean | (() => boolean) = false,
): BoolStateValue => {
  const [isState, setIsState] = useState(initialState);

  const onTrue = useCallback(() => {
    setIsState(true);
  }, []);

  const onFalse = useCallback(() => {
    setIsState(false);
  }, []);

  const onToggle = useCallback(() => {
    setIsState((currentState) => !currentState);
  }, []);

  return [isState, onTrue, onFalse, onToggle];
};
