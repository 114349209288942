import { css, Theme } from '@emotion/react';
import { ICON_HUES } from './constants';

export const container = (theme: Theme) => css`
  position: relative;
  padding: 10px 60px 10px 20px;

  display: flex;
  align-items: stretch;

  background-color: ${theme.colors.gray[0]};

  &:not(.isSeen) {
    background-color: ${theme.colors.primary[15]};
  }

  &.isRead {
    color: ${theme.colors.gray[500]};

    .markAsRead {
      opacity: 0;
    }

    &:hover {
      .markAsRead {
        opacity: 1;
      }
    }
  }
`;

export const notification = (theme: Theme) => css`
  display: flex;
  align-items: center;
  gap: 10px;

  transition: ${theme.transitions.default()};

  color: ${theme.colors.gray[700]};
`;

export const iconContainer = (theme: Theme) => css`
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  border-radius: 8px;

  transition: ${theme.transitions.default()};

  opacity: 1;

  &.isRead {
    opacity: 0.5;
  }

  &.${ICON_HUES.BLUE} {
    background-color: ${theme.hues.blue[50]};
  }

  &.${ICON_HUES.ORANGE} {
    background-color: ${theme.hues.orange[50]};
  }

  &.${ICON_HUES.YELLOW} {
    background-color: ${theme.hues.yellow[50]};
  }

  &.${ICON_HUES.GREEN} {
    background-color: ${theme.hues.green[50]};
  }

  &.${ICON_HUES.RED} {
    background-color: ${theme.hues.red[50]};
  }

  &.${ICON_HUES.PURPLE} {
    background-color: ${theme.hues.purple[50]};
  }
`;

export const avatar = (theme: Theme) => css`
  flex: none;
  transition: ${theme.transitions.default()};

  opacity: 1;

  &.isRead {
    opacity: 0.5;
  }
`;

export const contentContainer = css`
  display: flex;
  flex-direction: column;
  gap: 4px;

  flex: 1;

  overflow-wrap: anywhere;
`;

export const date = (theme: Theme) => css`
  &:not(.isRead) {
    color: ${theme.colors.primary[500]};
  }
`;

export const markAsRead = (theme: Theme) => css`
  padding: 10px;
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
  flex: none;

  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);

  &:not(.isRead) {
    &::after {
      background-color: ${theme.colors.primary[500]};
    }
  }

  &::after {
    content: '';
    display: block;
    height: 10px;
    width: 10px;
    border-radius: 10px;
    border: 2px solid ${theme.colors.primary[500]};
  }
`;

export const link = css`
  flex: 1;
`;
