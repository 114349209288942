import { defineMessages } from 'react-intl';
import { VIRTUAL_CLASSROOM_TYPES } from '@eversity/domain/constants';

export const virtualClassroomTypeMessages =
  defineMessages<VIRTUAL_CLASSROOM_TYPES>({
    [VIRTUAL_CLASSROOM_TYPES.THEMATIC]: {
      defaultMessage: 'Thématique',
    },
    [VIRTUAL_CLASSROOM_TYPES.METHODOLOGY]: {
      defaultMessage: 'Méthodologie',
    },
    [VIRTUAL_CLASSROOM_TYPES.PRACTICE]: {
      defaultMessage: 'Pratique',
    },
    [VIRTUAL_CLASSROOM_TYPES.INTENSIVE_REVIEWS]: {
      defaultMessage: 'Révisions intensives',
    },
    [VIRTUAL_CLASSROOM_TYPES.MOCK_ORAL]: {
      defaultMessage: 'Oral blanc',
    },
    [VIRTUAL_CLASSROOM_TYPES.INTERNSHIP]: {
      defaultMessage: 'Stage',
    },
    [VIRTUAL_CLASSROOM_TYPES.ENTERTAINMENT]: {
      defaultMessage: 'Animation',
    },
    [VIRTUAL_CLASSROOM_TYPES.COACHING]: {
      defaultMessage: 'Coaching',
    },
    [VIRTUAL_CLASSROOM_TYPES.TUTORIAL]: {
      defaultMessage: 'Tutoriel',
    },
    [VIRTUAL_CLASSROOM_TYPES.EXAM]: {
      defaultMessage: 'Examens',
    },
    [VIRTUAL_CLASSROOM_TYPES.PRIVATE]: {
      defaultMessage: 'Évènement privé',
    },
  });

export const virtualClassroomMessages = defineMessages({
  VIRTUAL_CLASSROOMS_TAG_LABEL: {
    defaultMessage: 'Classe virtuelle',
  },
});
