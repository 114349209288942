import { noop } from 'lodash';
import { createContext } from 'react';

type DropDownListContextValue = {
  selectedItem: string;
  onClickItem: (item: string | null) => void;
  checkedItems?: string[];
  onChangeCheckedItems?: (
    newCheckedItems: string[] | ((currentCheckedItems: string[]) => string[]),
  ) => void;
};

export const DropdownListContext = createContext<DropDownListContextValue>({
  selectedItem: null,
  onClickItem: noop,
  checkedItems: [],
  onChangeCheckedItems: noop,
});
