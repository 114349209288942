import { defineMessages } from 'react-intl';

import { COURSE_DIPLOMA_TYPES } from '@eversity/domain/constants';

export const diplomaTypesMessages = defineMessages<COURSE_DIPLOMA_TYPES>({
  [COURSE_DIPLOMA_TYPES.ADVANCED_TECHNICIAN_CERTIFICATE]: {
    defaultMessage: 'BTS',
  },

  [COURSE_DIPLOMA_TYPES.PROFESSIONAL_DIPLOMA]: {
    defaultMessage: 'Titre Professionnel',
  },

  [COURSE_DIPLOMA_TYPES.LICENSE]: {
    defaultMessage: 'Licence',
  },

  [COURSE_DIPLOMA_TYPES.BACHELOR]: {
    defaultMessage: 'Bachelor Européen',
  },

  [COURSE_DIPLOMA_TYPES.MASTER]: {
    defaultMessage: 'Mastère Européen',
  },

  [COURSE_DIPLOMA_TYPES.SKILLS_TRAINING]: {
    defaultMessage: 'Formation qualifiante',
  },

  [COURSE_DIPLOMA_TYPES.CQP]: {
    defaultMessage: 'CQP',
  },
});
