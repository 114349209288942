import { memo, useCallback } from 'react';
import { css, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import cn from 'classnames';

import { Delete, MoreOne, Refresh } from '@icon-park/react';
import { FormattedMessage } from 'react-intl';

import { Upload } from '@eversity/types/domain';
import { Button, Dropdown, uploadPropTypes } from '@eversity/ui/design-system';

import { imageStyle } from './ProfilePictureItem.styles';
import messages from './ProfilePictureItem.messages';

export type ProfilePictureItemProps = {
  picture: Upload;
  isSelected?: boolean;
  onChangeSelected?: (pictureId: string) => void;
  onDelete?: (pictureId: string) => void;
};

export const ProfilePictureItemBase = ({
  picture,
  isSelected = false,
  onChangeSelected = noop,
  onDelete = noop,
}: ProfilePictureItemProps) => {
  const theme = useTheme();

  const proxyOnChangeSelected = useCallback(
    () => onChangeSelected(picture.id),
    [onChangeSelected, picture],
  );

  const proxyOnDelete = useCallback(
    () => onDelete(picture.id),
    [onDelete, picture],
  );

  return (
    <div
      css={css`
        position: relative;
        width: 125px;
        height: 125px;
        border-radius: 4px;

        transition: ${theme.transitions.default()};
      `}
    >
      <div
        css={imageStyle(theme, picture.href)}
        className={cn(isSelected && 'SELECTED')}
      >
        <Dropdown
          menu={
            <Dropdown.List
              css={css`
                all: none;
                width: 257px;
              `}
            >
              <Dropdown.ListItem
                icon={<Refresh />}
                label={<FormattedMessage {...messages.DEFINE_PROFIL_PICTURE} />}
                onClick={proxyOnChangeSelected}
              />
              <Dropdown.ListItem
                icon={<Delete />}
                label={<FormattedMessage {...messages.DELETE_PROFIL_PICTURE} />}
                onClick={proxyOnDelete}
              />
            </Dropdown.List>
          }
        >
          <Button
            css={css`
              position: absolute;
              top: 8px;
              right: 8px;
            `}
            outline
            icon={<MoreOne />}
            size={Button.SIZES.SMALL}
          />
        </Dropdown>
      </div>
    </div>
  );
};

ProfilePictureItemBase.displayName = 'ProfilePictureItem';

ProfilePictureItemBase.propTypes = {
  /** Picture to display */
  picture: uploadPropTypes.isRequired,
  /** Define if the picture is currently selected in a set of pictures. */
  isSelected: PropTypes.bool,
  /** Callback triggered on the click of the picture. */
  onChangeSelected: PropTypes.func,
  /** Callback triggered on the click of the Delete entry. */
  onDelete: PropTypes.func,
};

export default memo(ProfilePictureItemBase);
