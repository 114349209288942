import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop, times } from 'lodash';
import { css } from '@emotion/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DoubleLeft, DoubleRight, Left, Right } from '@icon-park/react';

import { Typography } from '../../general/typography/Typography';
import { Button } from '../../general/button/Button';
import { PaginationPageButton } from './buttons/PaginationPageButton';
import { PaginationEllipsisButton } from './buttons/PaginationEllipsisButton';

import messages from './Pagination.messages';

const PAGE_NEIGHBOURS_COUNT = 3;

export type PaginationProps = {
  pageSize: number;
  totalCount?: number;
  currentPage?: number;
  onChangePage?: (page: number) => void;
  hidePageRange?: boolean;
  disabled?: boolean;
};

export const PaginationBase = ({
  pageSize,
  totalCount = 0,
  currentPage = 0,
  onChangePage = noop,
  hidePageRange = false,
  disabled = false,
}: PaginationProps) => {
  /** The total number of pages. */
  const pageCount = pageSize ? Math.ceil(totalCount / pageSize) : 0;

  const intl = useIntl();

  const onGoPrev = useCallback(
    () => onChangePage(currentPage - 1),
    [onChangePage, currentPage],
  );

  const onGoNext = useCallback(
    () => onChangePage(currentPage + 1),
    [onChangePage, currentPage],
  );

  const minPage = Math.max(1, currentPage - PAGE_NEIGHBOURS_COUNT);
  const maxPage = Math.min(pageCount, currentPage + PAGE_NEIGHBOURS_COUNT + 1);

  const firstItemOfPageIndex = currentPage * pageSize;
  const lastItemOfPageIndex = Math.min(
    (currentPage + 1) * pageSize,
    totalCount,
  );

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 10px 40px;
      `}
    >
      {totalCount > 0 && !hidePageRange && (
        <Typography
          variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}
          css={(theme) => css`
            color: ${theme.colors.gray[400]};
          `}
        >
          <FormattedMessage
            {...messages.TOTAL_ITEMS}
            values={{
              first: firstItemOfPageIndex + 1,
              last: lastItemOfPageIndex,
              total: totalCount,
            }}
          />
        </Typography>
      )}

      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 10px;
        `}
      >
        {/* Previous page. */}
        <Button
          outline
          size={Button.SIZES.SMALL}
          variant={Button.VARIANTS.NEUTRAL}
          onClick={onGoPrev}
          icon={<Left />}
          disabled={disabled || currentPage <= 0}
          aria-label={intl.formatMessage(messages.PREV_ARIA_LABEL)}
        />

        {/* First page. */}
        <PaginationPageButton
          disabled={disabled}
          onClick={onChangePage}
          page={0}
          isActive={currentPage === 0}
        />

        {/* Ellipsis to first page. */}
        {pageCount > 1 && currentPage >= PAGE_NEIGHBOURS_COUNT + 2 && (
          <PaginationEllipsisButton
            disabled={disabled}
            onClick={onChangePage}
            page={Math.max(0, currentPage - (PAGE_NEIGHBOURS_COUNT + 2))}
            hoverIcon={<DoubleLeft />}
          />
        )}

        {/* Middle pages and some pages on the side. */}
        {pageCount > 1 &&
          times(maxPage - minPage).map((_, offset) => (
            <PaginationPageButton
              // eslint-disable-next-line react/no-array-index-key
              key={offset}
              disabled={disabled}
              onClick={onChangePage}
              page={minPage + offset}
              isActive={currentPage === minPage + offset}
            />
          ))}

        {/* Ellipsis to last page. */}
        {currentPage < pageCount - (PAGE_NEIGHBOURS_COUNT + 2) && (
          <PaginationEllipsisButton
            disabled={disabled}
            onClick={onChangePage}
            page={Math.min(
              pageCount - 1,
              currentPage + PAGE_NEIGHBOURS_COUNT + 2,
            )}
            hoverIcon={<DoubleRight />}
          />
        )}

        {/* Last page. */}
        {currentPage < pageCount - (PAGE_NEIGHBOURS_COUNT + 1) && (
          <PaginationPageButton
            disabled={disabled}
            onClick={onChangePage}
            page={pageCount - 1}
          />
        )}

        {/* Next page. */}
        <Button
          outline
          size={Button.SIZES.SMALL}
          variant={Button.VARIANTS.NEUTRAL}
          onClick={onGoNext}
          icon={<Right />}
          disabled={disabled || currentPage + 1 >= pageCount}
          aria-label={intl.formatMessage(messages.NEXT_ARIA_LABEL)}
        />
      </div>
    </div>
  );
};

PaginationBase.displayName = 'Pagination';

PaginationBase.propTypes = {
  /** Total number of results. */
  totalCount: PropTypes.number,
  /** Max number of items in a single page. */
  pageSize: PropTypes.number.isRequired,
  /** Current page (0-based index). */
  currentPage: PropTypes.number,
  /** Current page onChange handler. */
  onChangePage: PropTypes.func,
  /** Should hide the "X-Y of Z results". */
  hidePageRange: PropTypes.bool,
  /** Disable all buttons. */
  disabled: PropTypes.bool,
};

export const Pagination = memo(PaginationBase);
