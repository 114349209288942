import { ReactNode, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { LocaleContext, useMemoizedBundle } from '@eversity/ui/utils';

import {
  getDefaultLocale,
  setAppLocale,
  importLocale,
} from '../../../../services/intl';

export type LocaleProviderProps = {
  children?: ReactNode;
};

export const LocaleProvider = ({ children = null }: LocaleProviderProps) => {
  const [locale, setLocale] = useState(() => getDefaultLocale());
  const [localeBundle, setLocaleBundle] = useState({
    messages: {},
    dateFnsLocale: null,
  });

  useEffect(() => {
    (async () => {
      const bundle = await importLocale(locale);
      setAppLocale(locale);
      setLocaleBundle(bundle);
    })();
  }, [locale]);

  const bundle = useMemoizedBundle({
    locale,
    setLocale,
    messages: localeBundle.messages,
    dateFnsLocale: localeBundle.dateFnsLocale,
  });

  return (
    <LocaleContext.Provider value={bundle}>{children}</LocaleContext.Provider>
  );
};

LocaleProvider.displayName = 'LocaleProvider';

LocaleProvider.propTypes = {
  children: PropTypes.node,
};
