import {
  memo,
  useContext,
  forwardRef,
  ForwardedRef,
  MouseEvent,
  ReactElement,
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { noop } from 'lodash';

import { MenuItem, MenuItemProps } from './MenuItem';
import { containerStyle } from './MenuItem.styles';
import { MenuContext } from '../Menu.context';
import { Tooltip } from '../../../general/tooltip/Tooltip';
import { removeButtonAppearance } from '../../../../utils/style';

export type MenuItemButtonProps = MenuItemProps & {
  disableTooltip?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  children?: ReactElement;
};

export const MenuItemButtonBase = forwardRef(
  (
    {
      label,
      onClick,
      isSelected,
      isDisabled,
      disableTooltip,
      children,
      ...props
    }: MenuItemButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const { isCollapsed } = useContext(MenuContext);

    const itemContent = (
      <li>
        <button
          ref={ref}
          type="button"
          onClick={onClick}
          disabled={isDisabled}
          className={cn({ isSelected, isCollapsed, isDisabled })}
          css={[removeButtonAppearance, containerStyle]}
        >
          <MenuItem
            {...props}
            label={label}
            isSelected={isSelected}
            isDisabled={isDisabled}
          />
        </button>
        {children}
      </li>
    );

    return isCollapsed && !disableTooltip ? (
      <Tooltip
        content={label}
        placement="right"
      >
        {itemContent}
      </Tooltip>
    ) : (
      itemContent
    );
  },
);

MenuItemButtonBase.displayName = 'MenuItemButton';

MenuItemButtonBase.propTypes = {
  /** Link href. */
  onClick: PropTypes.func,
  /** Text label. Hidden when collapsed. */
  label: PropTypes.node.isRequired,
  /** Is the menu item currently selected. */
  isSelected: PropTypes.bool,
  /** Is menu item disabled. */
  isDisabled: PropTypes.bool,
  /** Disable the tooltip on hover when the menu is collapsed. */
  disableTooltip: PropTypes.bool,
};

MenuItemButtonBase.defaultProps = {
  onClick: noop,
  isSelected: false,
  isDisabled: false,
  disableTooltip: false,
  children: null,
};

export const MenuItemButton = memo(MenuItemButtonBase);
