import {
  cloneElement,
  ForwardedRef,
  forwardRef,
  Fragment,
  memo,
  MouseEvent,
  ReactElement,
  ReactNode,
} from 'react';
import PropTypes from 'prop-types';
import { isFunction, noop } from 'lodash';
import { useTheme, css } from '@emotion/react';
import cn from 'classnames';

import { removeButtonAppearance } from '../../../../../utils/style';
import { ICON_SIZES } from '../../../../general/icon/constants';
import { Typography } from '../../../../general/typography/Typography';
import { CheckboxControl } from '../../../../forms/checkbox-control/CheckboxControl';

import * as styles from './ItemRenderer.styles';

export type ItemRendererProps = {
  icon?: ReactElement;
  label?: ReactNode;
  render?: (params: {
    isSelected: boolean;
    isDisabled: boolean;
    isChecked: boolean;
  }) => ReactNode;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  isSelected?: boolean;
  isDisabled?: boolean;
  isActive?: boolean;
  isCheckable?: boolean;
  isChecked?: boolean;
  children?: ReactNode;
};

export const ItemRendererBase = forwardRef(
  (
    {
      icon,
      label,
      render,
      onClick,
      isSelected,
      isDisabled,
      isActive,
      isChecked,
      isCheckable,
      children,
    }: ItemRendererProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const theme = useTheme();

    return (
      <button
        ref={ref}
        type="button"
        disabled={isDisabled}
        onClick={onClick}
        className={cn({ isSelected, isActive })}
        css={[removeButtonAppearance, styles.containerStyle]}
      >
        {isFunction(render) ? (
          render({ isSelected, isDisabled, isChecked })
        ) : (
          <Fragment>
            {!!isCheckable && (
              <CheckboxControl
                checked={isChecked}
                readOnly
              />
            )}

            {!!icon &&
              cloneElement(icon, {
                size: ICON_SIZES.MEDIUM,
                fill: styles.getIconFills(theme, isDisabled, isSelected),
              })}

            <Typography
              variant={Typography.VARIANTS.BUTTON_MEDIUM_REGULAR}
              css={css`
                flex: 1;
                word-break: break-word;
              `}
            >
              {label}
            </Typography>
          </Fragment>
        )}

        {children}
      </button>
    );
  },
);

ItemRendererBase.displayName = 'ItemRenderer';

ItemRendererBase.propTypes = {
  /** Icon from @icon-park/react. */
  icon: PropTypes.element,
  /** Text label. */
  label: PropTypes.node,
  /** Called when clicking the item. */
  onClick: PropTypes.func,
  /**
   * Override default inner renderer.
   * If this is specified, icon and label are ignored.
   * Signature: ({ isSelected, isDisabled }) => React.Node.
   */
  render: PropTypes.func,
  /** Is the item disabled. */
  isDisabled: PropTypes.bool,
  /** Is the item active (i.e. if the sub menu is open). */
  isActive: PropTypes.bool,
  /** Is the item selected. */
  isSelected: PropTypes.bool,
  /** Is the item checkable. */
  isCheckable: PropTypes.bool,
  /** Is the item checked (only if isCheckable is true). */
  isChecked: PropTypes.bool,
  /** More content to the right of the item (like an arrow for SubList). */
  children: PropTypes.node,
};

ItemRendererBase.defaultProps = {
  icon: null,
  label: null,
  onClick: noop,
  render: null,
  isDisabled: false,
  isActive: false,
  isSelected: false,
  isCheckable: false,
  isChecked: false,
  children: null,
};

export const ItemRenderer = memo(ItemRendererBase);
