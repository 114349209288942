import { ForwardedRef, forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import {
  ItemInterface,
  ReactSortable,
  ReactSortableProps,
} from 'react-sortablejs';
import { ClassNames, useTheme } from '@emotion/react';

export const SortableBase = forwardRef(
  (
    props: ReactSortableProps<ItemInterface>,
    ref: ForwardedRef<ReactSortable<ItemInterface>>,
  ) => {
    const theme = useTheme();

    return (
      <ClassNames>
        {({ css, cx }) => (
          <ReactSortable
            {...props}
            ref={ref}
            animation={
              props.animation === undefined
                ? theme.transitions.default.duration
                : props.animation
            }
            easing={
              props.easing === undefined
                ? theme.transitions.default.easing
                : props.easing
            }
            // Hide the item being dragged inside the list (otherwise it is duplicated).
            ghostClass={cx(
              props.ghostClass,
              css`
                opacity: 0;
              `,
            )}
            // The dragged item opacity cannot be overriden with the HTML5 dnd API.
            // So force sortable not to use it and set the opacity to 1.
            forceFallback
            fallbackClass={cx(
              props.fallbackClass,
              css`
                opacity: 1 !important;
              `,
            )}
          />
        )}
      </ClassNames>
    );
  },
);

SortableBase.displayName = 'Sortable';

SortableBase.propTypes = {
  animation: PropTypes.number,
  easing: PropTypes.string,
  ghostClass: PropTypes.string,
  fallbackClass: PropTypes.string,
};

SortableBase.defaultProps = {
  animation: undefined,
  easing: undefined,
  ghostClass: null,
  fallbackClass: null,
};

export const Sortable = memo(SortableBase);
