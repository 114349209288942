import { ForwardedRef, forwardRef, HTMLProps, memo } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import cn from 'classnames';

import { useFocus } from '@eversity/ui/utils';

import * as styles from './RadioControl.styles';

export type RadioControlProps = Omit<HTMLProps<HTMLInputElement>, 'ref'>;

export const RadioControlBase = forwardRef(
  (
    { checked, disabled, ...props }: RadioControlProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const { isFocused, ...focusProps } = useFocus(props);

    return (
      <div
        css={styles.box}
        className={cn({
          isChecked: checked,
          isDisabled: disabled,
          isFocused,
        })}
      >
        <input
          {...props}
          {...focusProps}
          ref={ref}
          type="radio"
          disabled={disabled}
          checked={checked}
          css={css`
            position: fixed;
            top: -1000px;
            left: -1000px;
          `}
        />

        {checked && (
          <div
            css={styles.checked}
            className={cn({ isDisabled: disabled })}
          />
        )}
      </div>
    );
  },
);

RadioControlBase.displayName = 'RadioControl';

RadioControlBase.propTypes = {
  /** Is the input checked. */
  checked: PropTypes.bool,
  /** Is the input disabled. */
  disabled: PropTypes.bool,
};

RadioControlBase.defaultProps = {
  checked: false,
  disabled: false,
};

export const RadioControl = memo(RadioControlBase);
