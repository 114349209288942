import { useCallback, MouseEvent } from 'react';

import { useBoolState } from './useBoolState';

export const useHover = <TElement>({
  onMouseEnter,
  onMouseLeave,
}: {
  onMouseEnter?: (event: MouseEvent<TElement>) => void;
  onMouseLeave?: (event: MouseEvent<TElement>) => void;
} = {}): {
  isHovered: boolean;
  onMouseEnter: (event: MouseEvent<TElement>) => void;
  onMouseLeave: (event: MouseEvent<TElement>) => void;
} => {
  const [isHovered, onStartHovering, onStopHovering] = useBoolState(false);

  const onMouseEnterProxy = useCallback(
    (event: MouseEvent<TElement>) => {
      onStartHovering();
      onMouseEnter?.(event);
    },
    [onStartHovering, onMouseEnter],
  );

  const onMouseLeaveProxy = useCallback(
    (event: MouseEvent<TElement>) => {
      onStopHovering();
      onMouseLeave?.(event);
    },
    [onStopHovering, onMouseLeave],
  );

  return {
    isHovered,
    onMouseEnter: onMouseEnterProxy,
    onMouseLeave: onMouseLeaveProxy,
  };
};
