import React, { memo, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { toast, IconProps } from 'react-toastify';

import { globalMessages } from '@eversity/ui/intl';
import { Toast } from '../Toast';

export type UnexpectedErrorToastProps = {
  errorMessage?: ReactNode;
  toastProps?: IconProps;
};

export const UnexpectedErrorToastBase = ({
  errorMessage = null,
  toastProps,
}: UnexpectedErrorToastProps) => (
  <Toast toastProps={toastProps}>
    {
      // Check errorMessage type to prevent crashes when using with body.more.
      // Keep the errorMessage propType as node because it is still a dev error to pass an array.
      typeof errorMessage === 'string' || React.isValidElement(errorMessage) ? (
        <FormattedMessage
          {...globalMessages.UNEXPECTED_ERROR_MESSAGE}
          values={{ message: errorMessage }}
        />
      ) : (
        <FormattedMessage {...globalMessages.UNEXPECTED_ERROR} />
      )
    }
  </Toast>
);

UnexpectedErrorToastBase.displayName = 'UnexpectedErrorToast';

UnexpectedErrorToastBase.propTypes = {
  /** Error message or code. */
  errorMessage: PropTypes.node,
  /** Injected automatically by react-toastify. */
  toastProps: PropTypes.shape({
    /** Type of toast (default, info, success, error, warning). */
    type: PropTypes.oneOf(Object.values(toast.TYPE)).isRequired,
  }).isRequired,
};

export const UnexpectedErrorToast = memo(UnexpectedErrorToastBase);
