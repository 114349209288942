import { memo, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { useMemoizedBundle } from '@eversity/ui/utils';

import { DropdownListContext } from './DropdownListContext';
import { ListRenderer } from '../renderers/list/ListRenderer';

export type DropdownListProps = {
  withItemSeparator?: boolean;
  selectedItem?: string;
  onClickItem?: (item: string | null) => void;
  checkedItems?: string[];
  onChangeCheckedItems?: (
    newCheckedItems: string[] | ((currentCheckedItems: string[]) => string[]),
  ) => void;
  children?: ReactNode;
  className?: string;
};

const DEFAULT_CHECKED_KEYS = [];

export const DropdownListBase = ({
  withItemSeparator = false,
  selectedItem = null,
  onClickItem = noop,
  checkedItems = DEFAULT_CHECKED_KEYS,
  onChangeCheckedItems = noop,
  children = null,
  className = undefined,
}: DropdownListProps) => {
  const contextValue = useMemoizedBundle({
    selectedItem,
    onClickItem,
    checkedItems,
    onChangeCheckedItems,
  });

  return (
    <DropdownListContext.Provider value={contextValue}>
      <ListRenderer
        className={className}
        withItemSeparator={withItemSeparator}
      >
        {children}
      </ListRenderer>
    </DropdownListContext.Provider>
  );
};

DropdownListBase.displayName = 'DropdownList';

DropdownListBase.propTypes = {
  /** Value of currently selected list item. */
  selectedItem: PropTypes.string,
  /** Called with the new item value when clicking an item. */
  onClickItem: PropTypes.func,
  /** Keys of checked items. */
  checkedItems: PropTypes.arrayOf(PropTypes.string),
  /** Checked keys onChange handler. */
  onChangeCheckedItems: PropTypes.func,
  /** List items and sublists. */
  children: PropTypes.node,
  /** Class name. */
  className: PropTypes.string,
};

export const DropdownList = memo(DropdownListBase);
