import {
  forwardRef,
  memo,
  useContext,
  ReactNode,
  HTMLProps,
  ForwardedRef,
} from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';

import { RadioGroupContext } from '../../radio/contexts/RadioGroup.context';
import { Typography } from '../../../general/typography/Typography';
import { RadioControl } from '../../radio-control/RadioControl';

import styles from './Radio.styles';

export type RadioProps = Omit<HTMLProps<HTMLInputElement>, 'ref' | 'label'> & {
  label: ReactNode;
};

export const RadioBase = forwardRef(
  (
    { value, label, disabled = false, ...props }: RadioProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const theme = useTheme();
    const { value: selectedValue, onChange } = useContext(RadioGroupContext);

    const checked = selectedValue === value;

    return (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label css={styles.container(theme, disabled)}>
        <div css={styles.radio}>
          <RadioControl
            {...props}
            ref={ref}
            value={value}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
          />
        </div>

        <Typography
          variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}
          css={styles.label}
        >
          {label}
        </Typography>
      </label>
    );
  },
);

RadioBase.displayName = 'Radio';

RadioBase.propTypes = {
  /** Value of the radio. */
  value: PropTypes.string.isRequired,
  /** Label of the radio. */
  label: PropTypes.node.isRequired,
  /** Define if the radio can be selected. */
  disabled: PropTypes.bool,
};

RadioBase.defaultProps = {
  disabled: false,
};

export const Radio = memo(RadioBase);
