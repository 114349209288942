import {
  cloneElement,
  memo,
  MemoExoticComponent,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { useIntl } from 'react-intl';
import { Theme, useTheme } from '@emotion/react';
import cn from 'classnames';
import { To, useNavigate } from 'react-router-dom';

import {
  Avatar,
  avatarShape,
  removeButtonAppearance,
  Tooltip,
  Typography,
} from '@eversity/ui/design-system';
import {
  FormattedRelativeTimeFromNow,
  linkPathPropTypes,
} from '@eversity/ui/utils';
import { openLinkInNewTab } from '@eversity/utils/web';
import { AvatarShape } from '@eversity/types/web';

import { ICON_HUES } from './constants';

import { NotificationListItemFormattedMessage } from '../formatted-message/NotificationListItemFormattedMessage';

import * as styles from './NotificationListItemRenderer.styles';
import messages from './NotificationListItemRenderer.messages';

const ICON_HUES_MAPPING = (
  theme: Theme,
): Record<ICON_HUES, [string, string]> => ({
  [ICON_HUES.BLUE]: [theme.hues.blue[500], theme.hues.blue[25]],
  [ICON_HUES.ORANGE]: [theme.hues.orange[500], theme.hues.orange[25]],
  [ICON_HUES.RED]: [theme.hues.red[500], theme.hues.red[25]],
  [ICON_HUES.YELLOW]: [theme.hues.yellow[500], theme.hues.yellow[25]],
  [ICON_HUES.GREEN]: [theme.hues.green[500], theme.hues.green[25]],
  [ICON_HUES.PURPLE]: [theme.hues.purple[500], theme.hues.purple[25]],
});

const now = new Date().toISOString();

export type NotificationListItemRendererProps = {
  user?: AvatarShape;
  content?: ReactNode;
  isSeen?: boolean;
  isRead?: boolean;
  createdAt?: string;
  icon?: ReactElement;
  iconHue?: ICON_HUES;
  href?: To;
  onClick?: () => void;
  isExternalHref?: boolean;
  onMarkAsRead?: () => void;
  onMarkAsUnread?: () => void;
  onCloseNotifications?: () => void;
};

export const NotificationListItemRendererBase = ({
  user = null,
  content = null,
  isSeen = false,
  isRead = false,
  createdAt = now,
  icon,
  iconHue = ICON_HUES.BLUE,
  href = null,
  onClick = null,
  isExternalHref = false,
  onMarkAsRead = noop,
  onMarkAsUnread = noop,
  onCloseNotifications = noop,
}: NotificationListItemRendererProps) => {
  const theme = useTheme();
  const intl = useIntl();
  const navigate = useNavigate();

  const iconColors = useMemo(
    () => ICON_HUES_MAPPING(theme)[iconHue],
    [theme, iconHue],
  );

  const onGoToLink = useCallback(() => {
    if (!isRead) {
      onMarkAsRead();
    }

    if (href) {
      if (isExternalHref) {
        openLinkInNewTab(href as string);
      } else {
        navigate(href);
      }
    } else {
      onClick();
    }

    onCloseNotifications();
  }, [
    navigate,
    href,
    isExternalHref,
    isRead,
    onMarkAsRead,
    onCloseNotifications,
    onClick,
  ]);

  return (
    <div
      css={styles.container}
      className={cn({ isRead, isSeen })}
    >
      <button
        type="button"
        onClick={onGoToLink}
        disabled={!href && !onClick}
        css={[removeButtonAppearance, styles.link]}
        aria-label={intl.formatMessage(messages.GO_TO_LINK)}
      >
        <div css={styles.notification}>
          <div
            aria-hidden
            css={styles.iconContainer}
            className={cn(iconHue, { isRead })}
          >
            {cloneElement(icon, {
              size: 24,
              fill: iconColors,
            })}
          </div>

          {!!user && (
            <Avatar
              user={user}
              size={Avatar.SIZES.LARGE}
              className={cn({ isRead })}
              css={styles.avatar}
            />
          )}

          <div css={styles.contentContainer}>
            <Typography variant={Typography.VARIANTS.BODY_SMALL_REGULAR}>
              {content}
            </Typography>

            <div
              css={styles.date}
              className={cn({ isRead })}
            >
              <Typography
                variant={
                  isRead
                    ? Typography.VARIANTS.BODY_SMALL_REGULAR
                    : Typography.VARIANTS.BODY_SMALL_BOLD
                }
              >
                <FormattedRelativeTimeFromNow
                  date={createdAt}
                  limitRelative={Infinity}
                />
              </Typography>
            </div>
          </div>
        </div>
      </button>

      <Tooltip
        content={intl.formatMessage(
          isRead ? messages.MARK_AS_UNREAD : messages.MARK_AS_READ,
        )}
      >
        <button
          type="button"
          css={styles.markAsRead}
          className={cn('markAsRead', { isRead })}
          onClick={isRead ? onMarkAsUnread : onMarkAsRead}
          aria-label={intl.formatMessage(
            isRead ? messages.MARK_AS_UNREAD : messages.MARK_AS_READ,
          )}
        />
      </Tooltip>
    </div>
  );
};

NotificationListItemRendererBase.displayName = 'NotificationListItemRenderer';

NotificationListItemRendererBase.propTypes = {
  /** User displayed on the notification. */
  user: avatarShape,
  /** Notification text. */
  content: PropTypes.node,
  /** Is the notification seen. */
  isSeen: PropTypes.bool,
  /** Is the notification read. */
  isRead: PropTypes.bool,
  /** Notification creation date. */
  createdAt: PropTypes.string,
  /** Icon from @icon-park. */
  icon: PropTypes.element.isRequired,
  /** Color of the icon. */
  iconHue: PropTypes.oneOf(Object.values(ICON_HUES)),
  /** Redirection if any. */
  href: linkPathPropTypes,
  /** Trigger onclick if any. */
  onClick: PropTypes.func,
  /** Is the href external to the app (e.g. Google Meets url). */
  isExternalHref: PropTypes.bool,
  /** Callback when marking as read. */
  onMarkAsRead: PropTypes.func,
  /** Callback when marking as unread. */
  onMarkAsUnread: PropTypes.func,
  /** Closes the list of notifications. */
  onCloseNotifications: PropTypes.func,
};

export const NotificationListItemRenderer: MemoExoticComponent<
  typeof NotificationListItemRendererBase
> & {
  ICON_HUES?: typeof ICON_HUES;
  FormattedMessage?: typeof NotificationListItemFormattedMessage;
} = memo(NotificationListItemRendererBase);

NotificationListItemRenderer.ICON_HUES = ICON_HUES;
NotificationListItemRenderer.FormattedMessage =
  NotificationListItemFormattedMessage;
