import {
  ForwardedRef,
  forwardRef,
  memo,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { noop, without } from 'lodash';
import { css } from '@emotion/react';

import { DropdownListContext } from '../list/DropdownListContext';
import { ItemRenderer } from '../renderers/item/ItemRenderer';

export type DropdownListItemProps = {
  value?: string;
  icon?: ReactElement;
  label?: ReactNode;
  render?: (params: { isSelected: boolean; isDisabled: boolean }) => ReactNode;
  onClick?: (value?: string) => void;
  isDisabled?: boolean;
  isCheckable?: boolean;
};

export const DropdownListItemBase = forwardRef(
  (
    {
      value,
      icon,
      label,
      render,
      onClick,
      isDisabled,
      isCheckable,
    }: DropdownListItemProps,
    ref: ForwardedRef<HTMLLIElement>,
  ) => {
    const { selectedItem, onClickItem, checkedItems, onChangeCheckedItems } =
      useContext(DropdownListContext);

    const isSelected = value !== null && selectedItem === value;
    const isChecked = isCheckable && checkedItems?.includes(value);

    const onClickProxy = useCallback(() => {
      onClick(value);
      onClickItem(value);

      if (isCheckable) {
        onChangeCheckedItems((currentCheckedItems) =>
          currentCheckedItems?.includes(value)
            ? without(currentCheckedItems, value)
            : [...currentCheckedItems, value],
        );
      }
    }, [onClickItem, value, onClick, isCheckable, onChangeCheckedItems]);

    return (
      <li
        ref={ref}
        css={css`
          display: flex;
        `}
      >
        <ItemRenderer
          icon={icon}
          label={label}
          render={render}
          onClick={onClickProxy}
          isSelected={isSelected}
          isDisabled={isDisabled}
          isCheckable={isCheckable}
          isChecked={isChecked}
        />
      </li>
    );
  },
);

DropdownListItemBase.displayName = 'DropdownListItem';

DropdownListItemBase.propTypes = {
  /** Value of the item, used to determine if the item is selected. */
  value: PropTypes.string,
  /** Icon from @icon-park/react. */
  icon: PropTypes.element,
  /** Text label. */
  label: PropTypes.node,
  /**
   * Override default inner renderer.
   * If this is specified, icon and label are ignored.
   * Signature: ({ isSelected, isDisabled }) => React.Node.
   */
  render: PropTypes.func,
  /** Is the item disabled. */
  isDisabled: PropTypes.bool,
  /** Is the item checkable. */
  isCheckable: PropTypes.bool,
  /** Specific behaviour for this item. */
  onClick: PropTypes.func,
};

DropdownListItemBase.defaultProps = {
  value: null,
  icon: null,
  label: null,
  render: null,
  isDisabled: false,
  isCheckable: false,
  onClick: noop,
};

export const DropdownListItem = memo(DropdownListItemBase);
