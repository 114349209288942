import { memo, Fragment } from 'react';
import PropTypes from 'prop-types';

import { useRelativeTimeFromNow } from '../../hooks/useRelativeTimeFromNow';

export type FormattedRelativeTimeFromNowProps = {
  date: string;
  limitRelative?: number;
  absoluteFormat?: string;
};

export const FormattedRelativeTimeFromNowBase = ({
  date,
  // Default to a week.
  limitRelative = 7 * 24 * 60 * 60,
  absoluteFormat = 'LL',
}: FormattedRelativeTimeFromNowProps) => {
  const { formattedDate } = useRelativeTimeFromNow(date, {
    limitRelative,
    absoluteFormat,
  });

  return (
    // Need to use JSX to make docgen and eslint detect this as a component.
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <Fragment>{formattedDate}</Fragment>
  );
};

FormattedRelativeTimeFromNowBase.displayName = 'FormattedRelativeTimeFromNow';

FormattedRelativeTimeFromNowBase.propTypes = {
  /** Reference date to compare to now. */
  date: PropTypes.string.isRequired,
  /** Max number of seconds between the date and now before rendering absolute date. */
  limitRelative: PropTypes.number,
  /** Dayjs format when rendering absolute date. */
  absoluteFormat: PropTypes.string,
};

export const FormattedRelativeTimeFromNow = memo(
  FormattedRelativeTimeFromNowBase,
);
