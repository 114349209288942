import { memo, MemoExoticComponent, useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { useIntl } from 'react-intl';
import { Gallery, GalleryProps, Item } from 'react-photoswipe-gallery';
import 'photoswipe/dist/photoswipe.css';

import messages from './Lightbox.messages';

export type LightboxProps = Omit<GalleryProps, 'options'>;

export const LightboxBase = ({ children = null, ...props }: LightboxProps) => {
  const intl = useIntl();
  const theme = useTheme();

  const options = useMemo(
    () => ({
      zoom: true,
      zoomTitle: intl.formatMessage(messages.ZOOM_BUTTON),
      arrowPrevTitle: intl.formatMessage(messages.PREVIOUS),
      arrowNextTitle: intl.formatMessage(messages.NEXT),
      closeTitle: intl.formatMessage(messages.CLOSE_BUTTON),
      showAnimationDuration: theme.transitions.default.duration,
      hideAnimationDuration: theme.transitions.default.duration,
    }),
    [intl, theme],
  );

  return (
    <Gallery
      {...props}
      options={options}
    >
      {children}
    </Gallery>
  );
};

LightboxBase.displayName = 'Lightbox';

export const Lightbox: MemoExoticComponent<typeof LightboxBase> & {
  Item?: typeof Item;
} = memo(LightboxBase);

Lightbox.Item = Item;
