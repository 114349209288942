import { memo } from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  FormattedMessageTypography,
  FormattedMessageTypographyProps,
} from '@eversity/ui/design-system';

export type NotificationListItemFormattedMessageProps =
  FormattedMessageTypographyProps;

export const NotificationListItemFormattedMessageBase = ({
  typographies = null,
  ...props
}: NotificationListItemFormattedMessageProps) => (
  <FormattedMessageTypography
    {...props}
    typographies={{ b: Typography.VARIANTS.BODY_SMALL_BOLD, ...typographies }}
  />
);

NotificationListItemFormattedMessageBase.displayName =
  'NotificationListItemFormattedMessage';

NotificationListItemFormattedMessageBase.propTypes = {
  typographies: PropTypes.shape({}),
};

export const NotificationListItemFormattedMessage = memo(
  NotificationListItemFormattedMessageBase,
);
