import {
  memo,
  useEffect,
  useCallback,
  forwardRef,
  ForwardedRef,
  MemoExoticComponent,
} from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { useIntl } from 'react-intl';
import { Search } from '@icon-park/react';

import { useForwardedRef } from '@eversity/ui/utils';

import { Input, InputProps } from '../input/Input';

import messages from './SearchInput.messages';

export type SearchInputProps = Omit<
  InputProps,
  'onChange' | 'onBlur' | 'iconRight'
> & {
  onChange?: (value: string) => void;
  onCancelSearch?: () => void;
};

export const SearchInputBase = forwardRef(
  (
    {
      value,
      onChange,
      onCancelSearch,
      placeholder,
      ...props
    }: SearchInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const intl = useIntl();

    const inputRef = useForwardedRef(ref);

    useEffect(() => {
      inputRef.current.focus();
    }, [inputRef]);

    const onBlur = useCallback(() => {
      if (!value) {
        onCancelSearch();
      }
    }, [value, onCancelSearch]);

    return (
      <Input
        {...props}
        ref={inputRef}
        value={value}
        onChange={onChange}
        iconRight={<Search />}
        placeholder={
          placeholder || intl.formatMessage(messages.DEFAULT_SEARCH_PLACEHOLDER)
        }
        onBlur={onBlur}
      />
    );
  },
);

SearchInputBase.displayName = 'SearchInput';

SearchInputBase.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onCancelSearch: PropTypes.func,
  placeholder: PropTypes.string,
};

SearchInputBase.defaultProps = {
  value: null,
  onChange: noop,
  onCancelSearch: noop,
  placeholder: null,
};

export const SearchInput: MemoExoticComponent<typeof SearchInputBase> & {
  SIZES?: typeof Input.SIZES;
  MAX_LENGTH_SIZE_MAPPING?: typeof Input.MAX_LENGTH_SIZE_MAPPING;
} = memo(SearchInputBase);

SearchInput.SIZES = Input.SIZES;
SearchInput.MAX_LENGTH_SIZE_MAPPING = Input.MAX_LENGTH_SIZE_MAPPING;
