import PropTypes from 'prop-types';
import { memo } from 'react';

import dayjs from '@eversity/services/dayjs';

import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';

import messages from './FormattedDateTimeInterval.messages';

export type FormattedDateTimeIntervalProps = {
  startDate: string;
  endDate: string;
};

const displayDate = (date: string) => {
  switch (true) {
    case dayjs(date).isToday():
      return <FormattedMessage {...messages.TODAY} />;
    case dayjs(date).isTomorrow():
      return <FormattedMessage {...messages.TOMORROW} />;
    default:
      return (
        <FormattedDate
          value={dayjs(date).toISOString()}
          year="numeric"
          month="long"
          day="numeric"
          weekday="long"
        />
      );
  }
};

export const FormattedDateTimeIntervalBase = ({
  startDate,
  endDate,
}: FormattedDateTimeIntervalProps) => (
  <FormattedMessage
    {...messages.FORMAT_INTERVAL}
    values={{
      date: displayDate(startDate),
      start: <FormattedTime value={startDate} />,
      end: <FormattedTime value={endDate} />,
    }}
  />
);

FormattedDateTimeIntervalBase.displayName = 'FormattedInterval';

FormattedDateTimeIntervalBase.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

export const FormattedDateTimeInterval = memo(FormattedDateTimeIntervalBase);
