import { memo, ReactNode, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { css, Global } from '@emotion/react';

import { useMemoizedBundle } from '@eversity/ui/utils';

import { DIALOG_LAYER_TYPE, MODAL_ROOT_ID } from './constants';

import { DialogLayer, DialogsContext } from './DialogsContext';
import { LayersContext } from '../layers-provider/LayersContext';

export type DialogsProviderProps = {
  rootElementSelector: string;
  children?: ReactNode;
};

export const DialogsProviderBase = ({
  rootElementSelector,
  children = null,
}: DialogsProviderProps) => {
  const { layers } = useContext(LayersContext);

  const dialogLayers = useMemo(
    () =>
      layers.filter(({ type }) => type === DIALOG_LAYER_TYPE) as DialogLayer[],
    [layers],
  );

  const contextValue = useMemoizedBundle({
    dialogLayers,
  });

  // Init the modal root at the bottom of the body.
  if (!document.getElementById(MODAL_ROOT_ID)) {
    const modalRoot = document.createElement('div');
    modalRoot.setAttribute('id', MODAL_ROOT_ID);
    document.body.appendChild(modalRoot);
  }

  return (
    <DialogsContext.Provider value={contextValue}>
      <Global
        styles={css`
          ${rootElementSelector} {
            filter: ${dialogLayers.length > 0
              ? `blur(${14 * dialogLayers.length}px)`
              : 'none'};
            overflow: ${dialogLayers.length > 0 ? 'hidden' : 'initial'};
          }
        `}
      />

      {children}
    </DialogsContext.Provider>
  );
};

DialogsProviderBase.displayName = 'DialogsProvider';

DialogsProviderBase.propTypes = {
  /** CSS selector of the root element. */
  rootElementSelector: PropTypes.string.isRequired,
  /** App. */
  children: PropTypes.node,
};

export const DialogsProvider = memo(DialogsProviderBase);
