import { Fragment, memo, ReactNode, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { noop, uniqueId } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import {
  DropzoneOptions,
  DropzoneState,
  ErrorCode as DropzoneErrorCode,
  useDropzone,
} from 'react-dropzone';

import { Upload } from '@eversity/types/domain';
import { FileUploadingShape } from '@eversity/types/web';

import {
  fileUploadingPropTypes,
  fileWithIdPropTypes,
  uploadPropTypes,
} from '../../../types';
import { FILE_ERRORS_PRIORITY } from './constants';
import { FileWithId } from './types';

import { Typography } from '../../general/typography/Typography';
import { Toast } from '../../feedback/toast/Toast';

import messages, { fileRejectMessages } from './FileUpload.messages';

export type FileUploadContainerProps = Omit<
  DropzoneOptions,
  'accept' | 'onDropAccepted' | 'onDropRejected' | 'multiple'
> & {
  value?: Upload | Upload[] | FileWithId | FileWithId[];
  onChange?: (newValue: Upload | Upload[] | FileWithId | FileWithId[]) => void;
  accept?: string;
  filesUploading?: FileUploadingShape[];
  onUploadFile?: (file: File) => FileUploadingShape | void;
  useFileAsValue?: boolean;
  children?: (
    props: Pick<
      DropzoneState,
      | 'getRootProps'
      | 'getInputProps'
      | 'isDragActive'
      | 'isDragAccept'
      | 'isDragReject'
    > & {
      isDropzoneDisabled: boolean;
      filesUploading: FileUploadingShape[];
      arrayValue: Upload[] | FileWithId[];
    },
  ) => ReactNode | undefined;
};

const DEFAULT_FILES_UPLOADING = [];
const DEFAULT_CHILDREN = () => null;

export const FileUploadContainerBase = ({
  value = null,
  onChange = noop,
  accept = null,
  maxFiles = Infinity,
  filesUploading = DEFAULT_FILES_UPLOADING,
  onUploadFile = null,
  useFileAsValue = false,
  children = DEFAULT_CHILDREN,
  disabled = false,
  ...props
}: FileUploadContainerProps) => {
  // Convert the value to an array to make it easier to use in the component.
  const arrayValue = useMemo(
    () => (Array.isArray(value) ? value : [value].filter(Boolean)),
    [value],
  ) as FileWithId[] | Upload[];

  // Compute the number of uploading files that are still uploading.
  const validUploadingFiles = useMemo(
    () => filesUploading.filter(({ error }) => !error),
    [filesUploading],
  );

  // Number of files that can be dropped at once.
  // Since an file input cannot be controlled, we need to compute it manually.
  // Take the maxFiles count and subtract the current files uploaded and uploading.
  const availableFilesCount = Math.max(
    maxFiles - validUploadingFiles.length - arrayValue.length,
    0,
  );

  const isDropzoneDisabled = !!disabled || availableFilesCount === 0;

  // Since react-dropzone 13 the accept prop has a different shape.
  // If the accept prop is still a string with a comma-separated list of mime types,
  // convert the prop to the new format.
  // "image/jpeg,image/png" -> { 'image/jpeg': [], 'image/png': [] }
  const reactDropzoneAccept = useMemo(
    () =>
      typeof accept === 'string'
        ? accept
            .split(',')
            .map((mimeType) => mimeType.trim())
            .reduce(
              (acc, mimeType) => ({
                ...acc,
                [mimeType]: [],
              }),
              {},
            )
        : accept,
    [accept],
  );

  // When the user drops files, upload them then add them to the value.
  // If useFileAsValue is true, pass the files directly.
  const onDropAccepted: DropzoneOptions['onDropAccepted'] = useCallback(
    async (files) => {
      if (useFileAsValue) {
        onChange(
          maxFiles > 1
            ? [
                ...(value as FileWithId[]),
                ...files.map((file) => ({
                  file,
                  id: uniqueId('file'),
                })),
              ]
            : files[0] && { file: files[0], id: uniqueId('file') },
        );
      } else {
        await Promise.all(
          files.map(async (file) => {
            try {
              const fileUploading = onUploadFile(file);

              if (fileUploading) {
                const uploadedAsset = await fileUploading.uploadPromise;

                // If the input accepts multiple files, add it to the list.
                // Max number of files being uploaded should already be handled by availableFilesCount
                // If the input only accept 1 file, set the new value (we assume that value is null).
                onChange(
                  maxFiles > 1
                    ? [
                        ...((value as Upload[]) || ([] as Upload[])),
                        uploadedAsset,
                      ]
                    : uploadedAsset,
                );
              }
            } catch (err) {
              // The error is added to the object in filesUploading, and we don't need to handle it.
              // NB: maybe add a onUploadFailure if we need to handle it in the parent component.
            }
          }),
        );
      }
    },
    [maxFiles, onChange, onUploadFile, value, useFileAsValue],
  );

  // For each rejected file, show the file and the list of reasons why it was rejected.
  const onDropRejected: DropzoneOptions['onDropRejected'] = useCallback(
    (rejects) => {
      rejects.forEach(({ file, errors }) => {
        // Sort errors by custom priority DESC.
        const errorsByPriority = [...errors].sort(
          (a, b) =>
            FILE_ERRORS_PRIORITY.indexOf(a.code as DropzoneErrorCode) -
            FILE_ERRORS_PRIORITY.indexOf(b.code as DropzoneErrorCode),
        );

        toast.error(
          <Toast>
            <FormattedMessage
              {...messages.FILE_REJECTED}
              values={{
                fileName: file.name,
                error: (
                  <FormattedMessage
                    {...fileRejectMessages[errorsByPriority[0].code]}
                  />
                ),
                b: (chunks) => (
                  <Typography variant={Typography.VARIANTS.BODY_SMALL_BOLD}>
                    {chunks}
                  </Typography>
                ),
              }}
            />
          </Toast>,
          // Hide after 10s.
          { autoClose: 10000 },
        );
      });
    },
    [],
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    ...props,
    onDropAccepted,
    onDropRejected,
    accept: reactDropzoneAccept,

    // If we pass maxFiles 0, react-dropzone considers that there is no limit,
    // so we just disable the input if the user already dropped too many files.
    disabled: isDropzoneDisabled,
    // Same reasoning, but we use Infinity instead of 0 so we map it to dropzone.
    // For dropzone it is the max number of files to drop at once, for us it's the max number of
    // files attached to the field.
    maxFiles: maxFiles === Infinity ? 0 : availableFilesCount,
    // Allow to drop multiple files if the available slots count is > 1.
    multiple: availableFilesCount > 1,
  });

  return (
    <Fragment>
      {children({
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        isDropzoneDisabled,
        filesUploading,
        arrayValue,
      })}
    </Fragment>
  );
};

FileUploadContainerBase.displayName = 'FileUpload';

FileUploadContainerBase.propTypes = {
  /**
   * Current value of the file upload.
   * These are all successfully uploaded assets.
   * If maxFiles is > 1, this is a list of files, if it is === 1, it is a single file.
   */
  value: PropTypes.oneOfType([
    uploadPropTypes,
    PropTypes.arrayOf(uploadPropTypes),
    fileWithIdPropTypes,
    PropTypes.arrayOf(fileWithIdPropTypes),
  ]),
  /** Set the new value. Depends on the value of maxFiles. */
  onChange: PropTypes.func,
  /** Max number of files that can be uploaded. */
  maxFiles: PropTypes.number,
  /** List of files being uploaded, or with a failure. */
  filesUploading: PropTypes.arrayOf(fileUploadingPropTypes),
  /** Callback with a single file to upload. */
  onUploadFile: PropTypes.func,
  /** Is the field disabled. Prevents drops / removals. */
  disabled: PropTypes.bool,
  /** List of accepted mime-types, separated by commas. */
  accept: PropTypes.string,
  /** Max size of each file, in bytes. */
  maxSize: PropTypes.number,
  /** Render presentation. */
  children: PropTypes.func,
};

export const FileUploadContainer = memo(FileUploadContainerBase);
