import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDebounce } from 'react-use';

import { SequentialAsyncThrottle } from '@eversity/utils/misc';

export const useInputAutoSave = <T, U = unknown>(
  savedContent: T,
  onSaveContent: (value?: T) => U | Promise<U>,
  { enabled = true } = {},
): [
  value: T,
  onChangeValue: Dispatch<SetStateAction<T>>,
  onResetValue: () => void,
] => {
  const [inputValue, onChangeInputValue] = useState(() => savedContent);

  // Store the current saved content in the component to be able to access it when needed.
  // This prevents caching issues with the onSaveIfChanges closure.
  const contentRef = useRef<T>();
  contentRef.current = savedContent;

  // Create a new instance of sequential async throttle for this component.
  // It allows to always wait for the API to respond before sending pending saves.
  const onSaveContentThrottled = useMemo(
    () => new SequentialAsyncThrottle(),
    [],
  );

  // Override the save method to only save if the new content is different than the currently saved
  // content.
  const onSaveIfChanges = useCallback(
    (newContent?: T) =>
      // If the new content is different, save it, otherwise ignore the call.
      newContent !== contentRef.current
        ? onSaveContent(newContent)
        : Promise.resolve(null),
    [onSaveContent],
  );

  // When the input value stops changing for more than 1s, save the new content.
  const [, cancel] = useDebounce(
    () => {
      if (enabled) {
        onSaveContentThrottled.run(onSaveIfChanges, inputValue);
      }
    },
    1000,
    [enabled, inputValue, onSaveIfChanges],
  );

  // Stop pending calls on unmount.
  useEffect(() => () => cancel(), [cancel]);

  // Use this callback to reset field value.
  const resetValue = useCallback(() => {
    onChangeInputValue(savedContent);
  }, [savedContent]);

  return [inputValue, onChangeInputValue, resetValue];
};
