import { reverseRecord } from '@eversity/types/misc';

export enum TEACHER_APPOINTMENT_REASONS {
  LESSON_QUESTION = 'LESSON_QUESTION',
  ASSIGNMENT_CORRECTION = 'ASSIGNMENT_CORRECTION',
  CAREER_MONITORING = 'CAREER_MONITORING',
  INTERNSHIP_QUESTION = 'INTERNSHIP_QUESTION',
  INTERNSHIP_MONITORING = 'INTERNSHIP_MONITORING',
  EXAM_PREPARATION = 'EXAM_PREPARATION',
  OTHER = 'OTHER',
}

export enum COACH_APPOINTMENT_REASONS {
  METHODOLOGY = 'METHODOLOGY',
  ORGANIZATION = 'ORGANIZATION',
  LOW_MOTIVATION = 'LOW_MOTIVATION',
  OTHER = 'OTHER',
}

export enum APPOINTMENT_TYPES {
  COACHING = 'COACHING',
  EDUCATIONAL = 'EDUCATIONAL',
  ONBOARDING = 'ONBOARDING',
}

export const CALENDLY_APPOINTMENT_TYPES: Record<APPOINTMENT_TYPES, string> = {
  [APPOINTMENT_TYPES.COACHING]: 'rdvcoaching',
  [APPOINTMENT_TYPES.EDUCATIONAL]: 'suivipeda',
  [APPOINTMENT_TYPES.ONBOARDING]: 'ep',
};

export const APPOINTMENT_CALENDLY_TYPES = reverseRecord(
  CALENDLY_APPOINTMENT_TYPES,
);

export const DAYS_BEFORE_FORCING_FIRST_MEETING = 7;
export const MINUTES_BETWEEN_FORCING_FIRST_MEETING = 5;

export const CALENDLY_DIDASKILL_SOURCE = 'didaskill';
