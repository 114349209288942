export enum IMPULSE_SPINNER_SIZES {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum IMPULSE_SPINNER_VARIANTS {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}
