import { uniqBy } from 'lodash';

import { StudentAcademicTranscript } from '@eversity/types/domain';
import dayjs from '@eversity/services/dayjs';
import { NB_DAYS_BEFORE_INACTIVITY } from '@eversity/domain/constants';

export const getNumberOfDaysSinceAccessStarted = (
  accessStartDate: string | Date,
  date?: string | Date | number,
) =>
  dayjs(accessStartDate).isValid()
    ? dayjs(date).diff(dayjs(accessStartDate).startOf('day'), 'day')
    : null;

export const makeChartDataRelative = (
  kpis: { daysSinceRegistration: number; value: number }[],
) =>
  uniqBy(kpis, (kpi) => kpi.daysSinceRegistration)
    .sort((a, b) => b.daysSinceRegistration - a.daysSinceRegistration)
    .map(({ daysSinceRegistration, value }) => ({
      x: daysSinceRegistration,
      y: Math.round(value * 100) / 100,
    }));

export const makeChartDataWithDate = (
  kpis: { daysSinceRegistration: number; value: number }[],
  minDate: string,
) =>
  makeChartDataRelative(kpis).map(({ x, y }) => ({
    x: dayjs(minDate).add(x, 'days').toDate().getTime(),
    y,
  }));

export const getTranscriptTeachingUnitGradedAssignmentsCount = (
  teachingUnit: StudentAcademicTranscript['course']['teachingUnits'][0],
) => {
  const assignments = [
    ...teachingUnit.assignments,
    ...(teachingUnit.lessons || []).flatMap(
      ({ assignments: lessonAssignments }) => lessonAssignments,
    ),
  ];

  return {
    count: assignments.filter(({ submission }) => submission?.correctionDate)
      .length,
    total: assignments.length,
  };
};

export const getTranscriptGradedAssignmentsCount = (
  transcript?: StudentAcademicTranscript,
) =>
  (transcript?.course.teachingUnits || []).reduce<{
    count: number;
    total: number;
  }>(
    (acc, teachingUnit) => {
      const { count, total } =
        getTranscriptTeachingUnitGradedAssignmentsCount(teachingUnit);

      return {
        count: acc.count + count,
        total: acc.total + total,
      };
    },
    { count: 0, total: 0 },
  );

/**
 * this method checks that a student logged in less than 28 days ago. It takes the last login date as a parameter
 * @param lastActiveTimestamp
 * @returns {boolean}
 */
export const isStudentActive = (lastActiveTimestamp: string): boolean => {
  const inputDate = dayjs(lastActiveTimestamp);
  const twentyEightDaysAgo = dayjs().subtract(NB_DAYS_BEFORE_INACTIVITY, 'day');

  return (
    inputDate.isSameOrBefore(dayjs()) && inputDate.isAfter(twentyEightDaysAgo)
  );
};
