import { ForwardedRef, forwardRef, HTMLProps, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { downloadHref } from '@eversity/utils/web';
import { Upload } from '@eversity/types/domain';

import { TYPOGRAPHY_VARIANTS } from '../../../../../config/typography/constants';
import { uploadPropTypes } from '../../../../../types';

import { FileDownload } from '../../../file-download/FileDownload';

export type AttachmentProps = Omit<HTMLProps<HTMLDivElement>, 'ref'> & {
  upload: Upload;
  onRemove?: (file: Upload) => void;
};

export const AttachmentBase = forwardRef(
  (
    { upload, children, onRemove, ...props }: AttachmentProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const onDownload = useCallback(
      async ({ href, fileName }) => downloadHref(href, fileName),
      [],
    );

    return (
      <div
        {...props}
        ref={ref}
        css={(theme) =>
          css(theme.typography[TYPOGRAPHY_VARIANTS.BODY_MEDIUM_REGULAR])
        }
      >
        <FileDownload<Upload>
          file={upload}
          onDownload={onDownload}
          onRemove={onRemove}
        />

        {children}
      </div>
    );
  },
);

AttachmentBase.displayName = 'Attachment';

AttachmentBase.propTypes = {
  upload: uploadPropTypes.isRequired,
  children: PropTypes.node,
  onRemove: PropTypes.func,
};

AttachmentBase.defaultProps = {
  children: null,
  onRemove: undefined,
};

export const Attachment = memo(AttachmentBase);
