import { TinyColor } from '@ctrl/tinycolor';
import { css, Theme } from '@emotion/react';

import {
  TEXT_AREA_SIZES as SIZES,
  TEXT_AREA_TYPOGRAPHY_VARIANT_MAPPINGS as TYPOGRAPHY_VARIANT_MAPPINGS,
  TEXT_AREA_MONOSPACE_TYPOGRAPHY_VARIANT_MAPPINGS as MONOSPACE_TYPOGRAPHY_VARIANT_MAPPINGS,
  TEXT_AREA_VERTICAL_PADDING_MAPPINGS as VERTICAL_PADDING_MAPPINGS,
  TEXT_AREA_SIZES,
} from './constants';

export const container = css`
  display: flex;
  flex-direction: column;
`;

export const textArea = (theme: Theme) => css`
  border-radius: 4px;

  width: 100%;
  /* Prevent resizing above 100%. */
  max-width: 100%;
  outline: none;

  /** Disabled horizontal resizing. */
  resize: vertical;

  border: 1px solid ${theme.colors.gray[200]};
  color: ${theme.colors.gray[700]};
  background-color: ${theme.colors.gray[0]};

  transition: ${theme.transitions.default()};

  &:focus {
    border-color: ${theme.colors.primary[400]};
    box-shadow: 0 0 0 4px
      ${new TinyColor(theme.colors.primary[500]).setAlpha(0.15).toRgbString()};
  }

  &::placeholder {
    color: ${theme.colors.gray[300]};
  }

  &:disabled {
    color: ${theme.colors.gray[300]};
    background-color: ${theme.colors.gray[25]};
    cursor: not-allowed;
  }

  &.hasError {
    &,
    &:focus {
      border-color: ${theme.colors.danger[400]};
    }

    &:focus {
      box-shadow: 0 0 0 4px
        ${new TinyColor(theme.colors.danger[500]).setAlpha(0.15).toRgbString()};
    }
  }

  &.hasWarning {
    &,
    &:focus {
      border-color: ${theme.colors.warning[400]};
    }

    &:focus {
      box-shadow: 0 0 0 4px
        ${new TinyColor(theme.colors.warning[500]).setAlpha(0.15).toRgbString()};
    }
  }

  &.${SIZES.SMALL} {
    ${theme.typography[TYPOGRAPHY_VARIANT_MAPPINGS[SIZES.SMALL]]};

    padding: ${VERTICAL_PADDING_MAPPINGS[SIZES.SMALL]}px 7px;

    &.monospace {
      ${theme.typography[MONOSPACE_TYPOGRAPHY_VARIANT_MAPPINGS[SIZES.SMALL]]};
    }
  }

  &.${SIZES.MEDIUM} {
    ${theme.typography[TYPOGRAPHY_VARIANT_MAPPINGS[SIZES.MEDIUM]]};

    padding: ${VERTICAL_PADDING_MAPPINGS[SIZES.MEDIUM]}px 9px;

    &.monospace {
      ${theme.typography[MONOSPACE_TYPOGRAPHY_VARIANT_MAPPINGS[SIZES.MEDIUM]]};
    }
  }

  &.${SIZES.LARGE} {
    ${theme.typography[TYPOGRAPHY_VARIANT_MAPPINGS[SIZES.LARGE]]};

    padding: ${VERTICAL_PADDING_MAPPINGS[SIZES.LARGE]}px 11px;

    &.monospace {
      ${theme.typography[MONOSPACE_TYPOGRAPHY_VARIANT_MAPPINGS[SIZES.LARGE]]};
    }
  }
`;

export const minLines =
  (linesNb: number, size: TEXT_AREA_SIZES, monospace: boolean) =>
  (theme: Theme) => {
    const typography =
      theme.typography[
        monospace
          ? MONOSPACE_TYPOGRAPHY_VARIANT_MAPPINGS[size]
          : TYPOGRAPHY_VARIANT_MAPPINGS[size]
      ];

    const lineHeightPx = parseInt(typography.lineHeight, 10);
    const padding = VERTICAL_PADDING_MAPPINGS[size];

    // (Lines * line height) + (padding top&bottom) + (border top&bottom).
    const minHeight = lineHeightPx * linesNb + padding * 2 + 2;

    return css`
      min-height: ${minHeight}px;
    `;
  };

export const maxLength = css`
  align-self: flex-end;
`;
