import { gmail_v1 } from 'googleapis';

import {
  ASSIGNMENT_QUESTION_VIEWS,
  ASSIGNMENT_STATUSES,
  LEGAL_DOCUMENT_TYPES,
  USER_VIEWS,
} from '@eversity/domain/constants';
import {
  AssignmentSubmission,
  EmployeeViewFull,
  GetStudentVirtualClassroomsQuery,
  McqAnswer,
  StudentCalendarEvent,
  StudentViewFull,
  UpdateStudentSubmissionCorrectionSeenBody,
  UpdateStudentSubmissionSubmitBody,
  UpdateStudentSubmissionUpdateBody,
  McqPageViewForm,
  McqPageViewCorrection,
  GetStudentAssignmentsQuery,
  VirtualClassroomViewMinimal,
  StudentViewPersonal,
  GetCurrentUserQuery,
  UpdateCurrentUserBody,
  UpdateCurrentUserCourseBody,
  WelcomePackStatus,
  StudentAssignment,
  PaymentLinkCrm,
  ExamRegistrationLinkCrm,
} from '@eversity/types/domain';

import { HttpRepository } from '../httpRepository';

const e = encodeURIComponent;

const BASE_USERS_URI = '/api/v1/users/users';

export class UsersRepository extends HttpRepository {
  /**
   * Get connected user info.
   *
   * @param options - Options.
   * @param options.view - User view.
   * @returns User object.
   */
  async getCurrentUser({
    view = USER_VIEWS.FULL,
  }: GetCurrentUserQuery = {}): Promise<
    StudentViewFull | StudentViewPersonal | EmployeeViewFull
  > {
    const { body: user } = await this.http
      .get(`${BASE_USERS_URI}/me`)
      .query({ view });

    return user;
  }

  /**
   * Update the current user.
   *
   * @param params - Object with user values.
   * @param params.personalEmail - The personal email of the user.
   * @param params.picture - The picture id.
   * @returns The updated user data.
   */
  async updateSelfUser({
    personalEmail,
    picture,
  }: UpdateCurrentUserBody): Promise<StudentViewFull | EmployeeViewFull> {
    const { body: user } = await this.http.patch(`${BASE_USERS_URI}/me`).send({
      personalEmail,
      picture,
    });

    return user;
  }

  /**
   * Fetch the school certificate blob.
   *
   * @param courseId - Course Id.
   * @param classId - Class id.
   * @returns The blob of the pdf file.
   */
  async getSchoolCertificate(courseId: string, classId: string): Promise<Blob> {
    const { body } = await this.http
      .get(`${BASE_USERS_URI}/me/documents/school-certificate`)
      .query({ courseId, classId })
      .responseType('blob');

    return body;
  }

  /**
   * Get user's future calendar events.
   *
   * @param query - Query parameters.
   * @param query.limit - Max number of events.
   * @param query.timeMin - Min date of events (iso datetime).
   * @param query.timeMax - Max date of events (iso datetime).
   * @returns A list of events.
   */
  async getUserCalendarEvents({
    limit,
    timeMin,
    timeMax,
  }: {
    limit?: number;
    timeMin?: string;
    timeMax?: string;
  }): Promise<StudentCalendarEvent[]> {
    const { body } = await this.http
      .get(`${BASE_USERS_URI}/me/calendar`)
      .query({ limit, timeMin, timeMax });

    return body;
  }

  /**
   * Get the metadata of the user's mail box.
   *
   * @param labelId - Identify the label used to query Gmail.
   * @returns An object with the metadata of the mail box.
   */
  async getUserMails(labelId: string): Promise<gmail_v1.Schema$Label> {
    const { body } = await this.http
      .get(`${BASE_USERS_URI}/me/mails`)
      .query({ labelId });

    return body;
  }

  /**
   * Get student assignments with submissions.$
   *
   * @param courseId - Course id.
   * @param classId - Class id.
   * @param query - Query.
   * @returns List of assignments.
   */
  async getUserAssignments(
    courseId: string,
    classId: string,
    query: GetStudentAssignmentsQuery,
  ): Promise<StudentAssignment[]> {
    const { body } = await this.http
      .get(`${BASE_USERS_URI}/me/assignments`)
      .query({
        courseId,
        classId,
        ...query,
      });

    return body;
  }

  /**
   * Get an assignment of a student and its submission if it exists.
   *
   * @param assignmentId - Assignment id.
   * @param courseId - Assignment id.
   * @param classId - Class id.
   * @param teachingUnitId - Teaching unit id.
   * @param lessonId - Lesson id.
   * @returns Assignment.
   */
  async getUserAssignment(
    assignmentId: string,
    courseId: string,
    classId: string,
    teachingUnitId: string,
    lessonId: string | null,
  ): Promise<StudentAssignment> {
    const { body } = await this.http
      .get(`${BASE_USERS_URI}/me/assignments/${e(assignmentId)}`)
      .query({
        courseId,
        classId,
        teachingUnitId,
        lessonId,
      });

    return body;
  }

  /**
   * Get count of assignments in each status.
   *
   * @param courseId - Course id.
   * @param classId - Class id.
   * @returns Count of assignments.
   */
  async getUserAssignmentsSummary(
    courseId: string,
    classId: string,
  ): Promise<Record<ASSIGNMENT_STATUSES, { count: number }>> {
    const { body } = await this.http
      .get(`${BASE_USERS_URI}/me/assignments-summary`)
      .query({
        courseId,
        classId,
      });

    return body;
  }

  /**
   * Update a student's submission.
   *
   * @param submissionId - Submission id.
   * @param params - Data to update.
   * @returns Updated submission.
   */
  async patchUserAssignmentSubmission(
    submissionId: string,
    depositId: string,
    params:
      | UpdateStudentSubmissionSubmitBody
      | UpdateStudentSubmissionCorrectionSeenBody
      | UpdateStudentSubmissionUpdateBody,
  ): Promise<AssignmentSubmission> {
    const { body } = await this.http
      .patch(
        `${BASE_USERS_URI}/me/assignment-submissions/${e(
          submissionId,
        )}/deposits/${e(depositId)}`,
      )
      .send(params);

    return body;
  }

  /**
   * Get assignment questions.
   *
   * @param assignmentId - Id of the assignment.
   * @param courseId - Id of the course.
   * @param classId - Id of the class.
   * @param teachingUnitId - Id of the teachingUnit.
   * @param lessonId - Id of the lesson.
   * @param view - View.
   * @returns The list of questions.
   */
  async getAssignmentQuestions(
    assignmentId: string,
    courseId: string,
    classId: string,
    teachingUnitId: string,
    lessonId: string | null,
    view?: ASSIGNMENT_QUESTION_VIEWS,
  ): Promise<McqPageViewForm[] | McqPageViewCorrection[]> {
    const { body } = await this.http
      .get(`${BASE_USERS_URI}/me/assignments/${e(assignmentId)}/questions`)
      .query({
        courseId,
        classId,
        teachingUnitId,
        lessonId,
        view,
      });

    return body;
  }

  /**
   * Create a student's submission.
   *
   * @param assignmentId - Assignment id.
   * @param courseId - Course id.
   * @param classId - Class id.
   * @param teachingUnitId - Teaching unit id.
   * @param lessonId - Lesson id.
   * @param params - Params to post.
   * @param params.mcqAnswers - Answers to MCQ.
   * @returns Student's submission.
   */
  async createAssignmentSubmission(
    assignmentId: string,
    courseId: string,
    classId: string,
    teachingUnitId: string,
    lessonId?: string | null,
    {
      mcqAnswers = undefined,
    }: {
      mcqAnswers?: McqAnswer[];
    } = {},
  ): Promise<AssignmentSubmission> {
    const { body } = await this.http
      .post(
        `${BASE_USERS_URI}/me/assignments/${e(
          assignmentId,
        )}/assignment-submissions`,
      )
      .send({
        courseId,
        classId,
        teachingUnitId,
        lessonId,
        mcqAnswers,
      });

    return body;
  }

  /**
   * Student signin to a virtual classroom, accepting or denying image rights.
   *
   * @param virtualClassroomId Virtual classroom id.
   * @param acceptedImageRights - Image rights waiver status.
   * @returns Success status.
   */
  async signInToVirtualClassroom(
    virtualClassroomId: string,
    acceptedImageRights: boolean,
  ): Promise<boolean> {
    const { status } = await this.http
      .post(
        `${BASE_USERS_URI}/me/virtual-classrooms/${e(
          virtualClassroomId,
        )}/signin`,
      )
      .send({
        acceptedImageRights,
      });

    return status === 204;
  }

  /**
   * Update the student course information.
   *
   * @param courseId - Course id.
   * @param classId - Class id.
   * @param updates - Course updates.
   * @param updates.needsOnboardingMeeting - Set onboarding meeting status.
   * @param updates.firstConnection - Set first connection date on course.
   * @returns Updated student object.
   */
  async updateStudentCourse(
    courseId: string,
    classId: string,
    { needsOnboardingMeeting, firstConnection }: UpdateCurrentUserCourseBody,
  ): Promise<StudentViewFull> {
    const { body } = await this.http
      .patch(`${BASE_USERS_URI}/me/courses/${e(courseId)}/${e(classId)}`)
      .send({
        needsOnboardingMeeting,
        firstConnection,
      });

    return body;
  }

  /**
   * Accept a legal document.
   *
   * @param legalDocumentType - LEGAL_DOCUMENT_TYPES enum.
   * @returns Updated student object.
   */
  async acceptLegalDocument(
    legalDocumentType: LEGAL_DOCUMENT_TYPES,
  ): Promise<StudentViewFull> {
    const { body } = await this.http.put(
      `${BASE_USERS_URI}/me/legal-documents/${e(legalDocumentType)}`,
    );

    return body;
  }

  async deleteProfilePicture(
    pictureId: string,
  ): Promise<StudentViewFull | EmployeeViewFull> {
    const { body: updatedUser } = await this.http.delete(
      `${BASE_USERS_URI}/me/profile-pictures/${e(pictureId)}`,
    );

    return updatedUser;
  }

  /**
   * Get order status of a welcome pack within a course.
   *
   * @param courseId - Course id.
   * @param classId - Class id.
   * @returns Welcome pack with order and status details.
   */
  async getWelcomePackStatus(
    courseId: string,
    classId: string,
  ): Promise<WelcomePackStatus> {
    const { body } = await this.http.get(
      `${BASE_USERS_URI}/me/courses/${e(courseId)}/${e(classId)}/welcome-pack`,
    );

    return body;
  }

  /**
   * Order a welcome pack within a course.
   *
   * @param courseId - Course id.
   * @param classId - Class id.
   * @param params - Params.
   * @param params.welcomePackCode - User's welcome pack code.
   * @param params.address - Shipping addres.
   * @param params.relay - Relay id.
   * @returns Order.
   */
  async orderWelcomePack(
    courseId: string,
    classId: string,
    {
      welcomePackCode,
      address,
      relay,
    }: {
      welcomePackCode: string;
      address: string;
      relay: string;
    },
  ) {
    const { body } = await this.http
      .post(
        `${BASE_USERS_URI}/me/courses/${e(courseId)}/${e(
          classId,
        )}/welcome-pack`,
      )
      .send({
        welcomePackCode,
        address,
        relay,
      });

    return body;
  }

  /**
   * Get the list of virtual classrooms.
   *
   * @param query - Query.
   * @returns List of virtual classrooms.
   */
  async getVirtualClassrooms(query: GetStudentVirtualClassroomsQuery): Promise<{
    count: number;
    virtualClassrooms: VirtualClassroomViewMinimal[];
  }> {
    const { body } = await this.http
      .get(`${BASE_USERS_URI}/me/virtual-classrooms`)
      .query(query);

    return body;
  }

  /**
   * Add a view to a virtual classroom replay.
   *
   * @param virtualClassroomId - Id of the virtual classroom.
   * @returns True if the call has been successful.
   */
  async postVirtualClassroomReplayView(
    virtualClassroomId: string,
  ): Promise<boolean> {
    const { status } = await this.http.post(
      `${BASE_USERS_URI}/me/virtual-classrooms/${e(
        virtualClassroomId,
      )}/replay-views`,
    );

    return status === 200;
  }

  /**
   * Fetch current user payment link from CRM.
   *
   * @param courseId - Id of the course.
   * @param classId - Id of the class.
   * @returns Payment link.
   */
  async getPaymentLink(
    courseId: string,
    classId: string,
  ): Promise<PaymentLinkCrm> {
    const { body } = await this.http
      .get(`${BASE_USERS_URI}/me/payment-link`)
      .query({
        courseId,
        classId,
      });

    return body;
  }

  /**
   * Fetch current user exam registration link from CRM.
   *
   * @param courseId - Id of the course.
   * @param classId - Id of the class.
   * @returns Exam registration link.
   */
  async getExamRegistrationLink(
    courseId: string,
    classId: string,
  ): Promise<ExamRegistrationLinkCrm> {
    const { body } = await this.http
      .get(`${BASE_USERS_URI}/me/exam-registration-link`)
      .query({
        courseId,
        classId,
      });

    return body;
  }

  /**
   * Create a new deposit in assignment submission.
   *
   * @param assignmentId - Assignment id.
   * @param submissionId - Submission id.
   * @returns Student's submission.
   */
  async createDeposit(
    assignmentId: string,
    submissionId: string,
  ): Promise<AssignmentSubmission> {
    const { body } = await this.http.post(
      `${BASE_USERS_URI}/me/assignments/${e(
        assignmentId,
      )}/assignment-submissions/${e(submissionId)}/deposits`,
    );

    return body;
  }

  /**
   * Send student picture to get student card.
   *
   * @param courseId - Course id.
   * @param classId - Class id.
   * @param params - Params.
   * @param params.file - Student picture.
   */
  async postStudentCardPicture(
    courseId: string,
    classId: string,
    { file }: { file: Blob },
  ) {
    const { status } = await this.http
      .post(
        `${BASE_USERS_URI}/me/courses/${e(courseId)}/${e(
          classId,
        )}/student-card`,
      )
      // @ts-ignore -- Conflict between node Buffer and web Buffer, but only in typings.
      .attach('file', file)
      .set('enctype', 'multipart/form-data');

    return status;
  }

  /**
   * Fetch the student card download url.
   *
   * @param courseId - Course Id.
   * @param classId - Class id.
   * @returns The CRM url to download student card.
   */
  async getStudentCardLink(courseId: string, classId: string) {
    const { body } = await this.http.get(
      `${BASE_USERS_URI}/me/courses/${e(courseId)}/${e(
        classId,
      )}/student-card/link`,
    );

    return body;
  }
}
