import {
  RadioGroupContextProvider,
  RadioGroupContextProviderProps,
} from '@eversity/ui/design-system';

import { McqAnswerRadio } from '../mcq-answer/mcq-answer-radio/McqAnswerRadio';

export const McqAnswerRadioGroupBase = (
  props: RadioGroupContextProviderProps,
) => <RadioGroupContextProvider {...props} />;

McqAnswerRadioGroupBase.displayName = 'McqAnswerRadioGroup';

// Wrap RadioGroupContext so we can set McqAnswerRadio as a property.
export const McqAnswerRadioGroup: typeof McqAnswerRadioGroupBase & {
  Radio?: typeof McqAnswerRadio;
} = McqAnswerRadioGroupBase;

McqAnswerRadioGroup.Radio = McqAnswerRadio;
