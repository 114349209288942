import { memo, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { css, Theme, useTheme } from '@emotion/react';
import { CheckSmall, CloseSmall } from '@icon-park/react';
import cn from 'classnames';

import { AudioPlayer, Typography } from '@eversity/ui/design-system';
import { MCQ_ANSWER_STYLES } from '@eversity/domain/constants';

import * as styles from './McqAnswer.styles';

const getIcon = (isSelected: boolean, isCorrectAnswer: boolean) => {
  switch (true) {
    case !!isCorrectAnswer:
      return CheckSmall;
    case !!isSelected && !isCorrectAnswer:
      return CloseSmall;
    default:
      return null;
  }
};

export const getIconFill = (
  isSuccess: boolean,
  isError: boolean,
  theme: Theme,
) => {
  if (isError) {
    return theme.colors.danger[500];
  }

  if (isSuccess) {
    return theme.colors.success[500];
  }

  return null;
};

const renderMedia = (
  media: { href: string; alt?: string },
  style: MCQ_ANSWER_STYLES,
) => {
  switch (style) {
    case MCQ_ANSWER_STYLES.TEXT_IMAGE:
      return (
        <img
          css={css`
            max-width: 100%;
            max-height: 100%;
          `}
          src={media.href}
          alt={media.alt}
        />
      );
    case MCQ_ANSWER_STYLES.TEXT_AUDIO:
      return <AudioPlayer src={media.href} />;
    default:
      return null;
  }
};

export type McqAnswerProps = {
  label: ReactNode;
  answerStyle?: MCQ_ANSWER_STYLES;
  media?: {
    href: string;
    alt?: string;
  };
  isSelected?: boolean;
  isFocused?: boolean;
  isCorrected?: boolean;
  isCorrectAnswer?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  isDisabled?: boolean;
  feedbackText?: ReactNode;
  children?: ReactNode;
};

export const McqAnswerBase = ({
  label,
  answerStyle = MCQ_ANSWER_STYLES.TEXT,
  media = null,
  isSelected = false,
  isFocused = false,
  isCorrected = false,
  isCorrectAnswer = false,
  isSuccess = false,
  isError = false,
  isDisabled = false,
  feedbackText = null,
  children = null,
}: McqAnswerProps) => {
  const theme = useTheme();
  const Icon = isCorrected && getIcon(isSelected, isCorrectAnswer);

  return (
    <div
      css={styles.container}
      className={cn({
        isFocused,
        isSelected,
        isCorrected,
        isSuccess,
        isError,
        isDisabled,
        hasIcon: !!Icon,
      })}
    >
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control -- Control in children. */}
      <label>
        {children}

        {!!Icon && (
          <Icon
            size="24"
            fill={getIconFill(isSuccess, isError, theme)}
          />
        )}

        <div className={cn({ media: answerStyle !== MCQ_ANSWER_STYLES.TEXT })}>
          {!!label && (
            <Typography variant={Typography.VARIANTS.BUTTON_LARGE_BOLD}>
              {label}
            </Typography>
          )}

          {renderMedia(media, answerStyle)}
        </div>
      </label>

      {!!isCorrected && !!feedbackText && (
        <Typography
          variant={Typography.VARIANTS.BODY_MEDIUM_ITALIC}
          className="feedbackText"
        >
          {feedbackText}
        </Typography>
      )}
    </div>
  );
};

McqAnswerBase.displayName = 'McqAnswer';

McqAnswerBase.propTypes = {
  label: PropTypes.node.isRequired,
  answerStyle: PropTypes.oneOf(Object.values(MCQ_ANSWER_STYLES)),
  media: PropTypes.shape({
    href: PropTypes.string,
    alt: PropTypes.string,
  }),
  isSelected: PropTypes.bool,
  isFocused: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isError: PropTypes.bool,
  isCorrected: PropTypes.bool,
  isCorrectAnswer: PropTypes.bool,
  isDisabled: PropTypes.bool,
  feedbackText: PropTypes.node,
  children: PropTypes.node,
};

export const McqAnswer = memo(McqAnswerBase);
