import { memo, ReactNode, useContext } from 'react';
import PropTypes from 'prop-types';

import { MCQ_ANSWER_STYLES } from '@eversity/domain/constants';
import { useFocus } from '@eversity/ui/utils';
import {
  CheckboxGroupContext,
  CheckboxControl,
  CheckboxControlProps,
} from '@eversity/ui/design-system';

import { McqAnswer, McqAnswerProps } from '../McqAnswer';

export type McqAnswerCheckboxProps = Omit<
  CheckboxControlProps,
  'onChange' | 'checked' | 'value' | 'media'
> & {
  value: string;
  label: McqAnswerProps['label'];
  answerStyle?: McqAnswerProps['answerStyle'];
  media?: McqAnswerProps['media'];
  isCorrected?: boolean;
  isCorrectAnswer?: boolean;
  successText?: ReactNode;
  errorText?: ReactNode;
};

export const McqAnswerCheckboxBase = ({
  label,
  value,
  answerStyle = MCQ_ANSWER_STYLES.TEXT,
  media = null,
  isCorrected = false,
  isCorrectAnswer = false,
  disabled = false,
  successText = null,
  errorText = null,
  ...props
}: McqAnswerCheckboxProps) => {
  const { isFocused, ...focusProps } = useFocus();

  const { value: selectedValue, onChange } = useContext(CheckboxGroupContext);

  const isChecked = !!selectedValue?.includes(value);
  const isDisabled = disabled || isCorrected;

  const isSuccess = !!(isCorrected && isCorrectAnswer && isChecked);

  const isError = !!(
    isCorrected &&
    ((!isCorrectAnswer && isChecked) || (isCorrectAnswer && !isChecked))
  );

  const feedbackText = isCorrected && isCorrectAnswer ? successText : errorText;

  return (
    <McqAnswer
      label={label}
      answerStyle={answerStyle}
      media={media}
      isSelected={isChecked}
      isFocused={isFocused}
      isCorrected={isCorrected}
      isCorrectAnswer={isCorrectAnswer}
      isDisabled={isDisabled}
      isSuccess={isSuccess}
      isError={isError}
      feedbackText={feedbackText}
    >
      <CheckboxControl
        {...props}
        {...focusProps}
        value={value}
        onChange={onChange}
        disabled={isDisabled}
        checked={isChecked}
      />
    </McqAnswer>
  );
};

McqAnswerCheckboxBase.displayName = 'McqAnswerCheckbox';

McqAnswerCheckboxBase.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  answerStyle: PropTypes.oneOf(Object.values(MCQ_ANSWER_STYLES)),
  media: PropTypes.shape({
    href: PropTypes.string,
    alt: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  isCorrected: PropTypes.bool,
  isCorrectAnswer: PropTypes.bool,
  successText: PropTypes.node,
  errorText: PropTypes.node,
};

export const McqAnswerCheckbox = memo(McqAnswerCheckboxBase);
