import { memo, forwardRef, ForwardedRef, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { To } from 'react-router-dom';
import cn from 'classnames';

import { linkPathPropTypes } from '@eversity/ui/utils';

import { Link } from '../../link/Link';
import { Typography } from '../../../general/typography/Typography';

export enum ELEMENT_VARIANTS {
  SECTION = 'SECTION',
  SUB_SECTION = 'SUB_SECTION',
  ITEM = 'ITEM',
}

export type SimpletableTableOfContentsElementProps = {
  label: ReactNode;
  children?: ReactNode;
  href?: To;
  isSelected?: boolean;
  variant?: ELEMENT_VARIANTS;
};

export const SimpleTableOfContentsElementBase = forwardRef(
  (
    {
      children,
      label,
      href,
      isSelected,
      variant,
    }: SimpletableTableOfContentsElementProps,
    ref: ForwardedRef<HTMLLIElement>,
  ) => (
    <li ref={ref}>
      <Link
        className={cn(variant, isSelected ? 'SELECTED' : null)}
        css={(theme) => css`
          position: relative;
          display: flex;
          text-decoration: none;
          color: inherit;
          padding: 4px 20px;
          border-left: 1px solid transparent;

          transition: ${theme.transitions.default()};

          &.SUB_SECTION {
            padding-left: 40px;
          }
          &.ITEM {
            padding-left: 60px;
          }
          &.SELECTED {
            border-left: 1px solid ${theme.colors.primary[500]};
            color: ${theme.colors.primary[500]};
          }
        `}
        isDisabled={!href}
        to={href}
      >
        <Typography variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}>
          {label}
        </Typography>
      </Link>
      {children && (
        <ul
          css={css`
            list-style-type: none;
            display: flex;
            flex-direction: column;
            gap: 4px;
          `}
        >
          {children}
        </ul>
      )}
    </li>
  ),
);

SimpleTableOfContentsElementBase.displayName = 'SimpleTableOfContentsElement';

SimpleTableOfContentsElementBase.propTypes = {
  /** Children. */
  children: PropTypes.node,
  /** Element label. */
  label: PropTypes.string.isRequired,
  /** Link href. */
  href: linkPathPropTypes,
  /** Is the element currently selected. */
  isSelected: PropTypes.bool,
  /** Element variant. */
  variant: PropTypes.oneOf(Object.values(ELEMENT_VARIANTS)),
};

SimpleTableOfContentsElementBase.defaultProps = {
  children: null,
  isSelected: false,
  href: null,
  variant: ELEMENT_VARIANTS.SECTION,
};

export const SimpleTableOfContentsElement = memo(
  SimpleTableOfContentsElementBase,
);
