import { Theme } from '@emotion/react';
import { toast } from 'react-toastify';

type ToastType = (typeof toast.TYPE)[keyof typeof toast.TYPE];

export const getIconFills = (theme: Theme, type: ToastType) => {
  switch (type) {
    case toast.TYPE.INFO:
      return [theme.colors.gray[0], theme.colors.primary[400]];
    case toast.TYPE.ERROR:
      return [theme.colors.gray[0], theme.colors.danger[400]];
    case toast.TYPE.WARNING:
      return [theme.colors.gray[0], theme.colors.warning[400]];
    case toast.TYPE.SUCCESS:
      return [theme.colors.gray[0], theme.colors.success[400]];
    case toast.TYPE.DEFAULT:
    default:
      return [theme.colors.gray[500], theme.colors.gray[50]];
  }
};
