import { css, Theme } from '@emotion/react';

export const cardStyle = (theme: Theme) => css`
  background-color: ${theme.colors.gray[0]};

  border-radius: 4px;

  border: 1px solid ${theme.colors.gray[100]};
`;

export const cardBorderStyle = (
  theme: Theme,
  borderColor: string,
  progress: number,
) => css`
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    background-image: linear-gradient(
      to right,
      ${borderColor} 0%,
      ${borderColor} ${progress * 100}%,
      ${theme.colors.gray[50]} ${progress * 100}%,
      ${theme.colors.gray[50]} 100%
    );

    border-radius: 4px 4px 0 0;
  }
`;
