import { memo, MemoExoticComponent, ReactNode, useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTheme } from '@emotion/react';
import { To } from 'react-router-dom';

import { linkPathPropTypes } from '@eversity/ui/utils';

import { SUB_SECTION_PROGRESS_STATE } from './constants';

import { TableOfContentsSectionContext } from '../section/TableOfContentsSection.context';
import { Link } from '../../link/Link';
import { Typography } from '../../../general/typography/Typography';
import { LinearProgress } from '../../../data-display/linear-progress/LinearProgress';

import * as styles from './TableOfContentsSubSection.styles';

export type TableOfContentsSubSectionProps = {
  href?: To;
  children?: ReactNode;
  label?: ReactNode;
  subLabel?: ReactNode;
  progress?: number;
  progressState?: SUB_SECTION_PROGRESS_STATE;
  isSelected?: boolean;
};

export const TableOfContentsSubSectionBase = ({
  children = null,
  label = null,
  href = null,
  subLabel = null,
  progress = null,
  progressState = SUB_SECTION_PROGRESS_STATE.NONE,
  isSelected = false,
}: TableOfContentsSubSectionProps) => {
  const theme = useTheme();

  const { variant } = useContext(TableOfContentsSectionContext);

  return (
    <li css={styles.container}>
      <Link
        to={href}
        className={cn(variant, { isSelected })}
        css={styles.link}
        isDisabled={!href}
      >
        <div css={styles.label}>
          <Typography variant={Typography.VARIANTS.BUTTON_MEDIUM_REGULAR}>
            {label}
          </Typography>

          {!!subLabel && (
            <Typography variant={Typography.VARIANTS.BODY_SMALL_REGULAR}>
              {subLabel}
            </Typography>
          )}

          {progressState !== SUB_SECTION_PROGRESS_STATE.NONE && !subLabel && (
            <LinearProgress
              css={styles.progress}
              progress={progress}
              backgroundColor={
                progressState === SUB_SECTION_PROGRESS_STATE.IN_PROGRESS
                  ? theme.colors.primary[25]
                  : theme.colors.danger[400]
              }
              progressColor={
                progressState === SUB_SECTION_PROGRESS_STATE.IN_PROGRESS
                  ? theme.colors.primary[400]
                  : theme.colors.success[500]
              }
            />
          )}
        </div>
      </Link>

      <ul>{children}</ul>
    </li>
  );
};

TableOfContentsSubSectionBase.displayName = 'TableOfContentsSubSection';

TableOfContentsSubSectionBase.propTypes = {
  /** Children (table of content sub section items) */
  children: PropTypes.node,
  /** Sub section label. */
  label: PropTypes.node,
  /** Link href. */
  href: linkPathPropTypes,
  /** Text display below label. */
  subLabel: PropTypes.node,
  /** Value of progress bar. */
  progress: PropTypes.number,
  /** State of the progress. */
  progressState: PropTypes.oneOf(Object.values(SUB_SECTION_PROGRESS_STATE)),
  /** Is the TableOfContents sub section item currently selected. */
  isSelected: PropTypes.bool,
};

export const TableOfContentsSubSection: MemoExoticComponent<
  typeof TableOfContentsSubSectionBase
> & {
  PROGRESS_STATE?: typeof SUB_SECTION_PROGRESS_STATE;
} = memo(TableOfContentsSubSectionBase);

TableOfContentsSubSection.PROGRESS_STATE = SUB_SECTION_PROGRESS_STATE;
