import { VersionObjectBase } from '@eversity/types/domain';

/**
 * Given the list of versions of a lesson and a major version number, return a reference to its last
 * minor version.
 *
 * @param versions - Versions.
 * @param versionMajor - Major version whose last minor version to find.
 * @returns Last minor version or undefined.
 */
export const findLastMinorVersion = (
  versions: VersionObjectBase[],
  versionMajor?: number,
): VersionObjectBase | undefined =>
  Number.isFinite(versionMajor)
    ? versions
        .filter((version) => version.versionMajor === versionMajor)
        .sort((a, b) => b.versionMinor - a.versionMinor)[0]
    : undefined;
