import { ForwardedRef, forwardRef, HTMLProps, memo } from 'react';
import PropTypes from 'prop-types';

import { useBoolState } from '@eversity/ui/utils';

import { ImpulseSpinner } from '../../../../general/loading/impulse-spinner/ImpulseSpinner';

import * as styles from './Embedded.styles';

export type EmbeddedProps = Omit<HTMLProps<HTMLDivElement>, 'title' | 'ref'> & {
  src: string;
  title: string;
};

export const EmbeddedBase = forwardRef(
  (
    { src, title, children, ...props }: EmbeddedProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const [isLoading, , onStopLoading] = useBoolState(true);

    return (
      <div
        {...props}
        ref={ref}
        css={styles.embedded}
      >
        <iframe
          src={src}
          title={title}
          frameBorder={0}
          allowFullScreen
          onLoad={onStopLoading}
        />

        {!!isLoading && (
          <div css={styles.loaderContainer}>
            <ImpulseSpinner />
          </div>
        )}

        {children}
      </div>
    );
  },
);

EmbeddedBase.displayName = 'Embedded';

EmbeddedBase.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

EmbeddedBase.defaultProps = {
  children: null,
};

export const Embedded = memo(EmbeddedBase);
