import { HTMLProps, memo } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

type LinearProgressProps = Omit<HTMLProps<HTMLDivElement>, 'ref'> & {
  progress?: number;
  backgroundColor?: string;
  progressColor?: string;
};

export const LinearProgressBase = ({
  progress = 0,
  backgroundColor = null,
  progressColor = null,
  ...props
}: LinearProgressProps) => {
  const normalizedProgress = Math.min(Math.max(0, progress), 1);

  return (
    <div
      {...props}
      css={(theme) => css`
        min-height: 1px;
        width: 100%;
        position: relative;
        background-color: ${backgroundColor || theme.colors.gray[50]};
      `}
    >
      <div
        css={(theme) => css`
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;

          width: ${normalizedProgress * 100}%;

          background-color: ${progressColor || theme.colors.primary[500]};

          transition: ${theme.transitions.default('width')};
        `}
      />
    </div>
  );
};

LinearProgressBase.displayName = 'LinearProgress';

LinearProgressBase.propTypes = {
  /** Progress between 0 and 1. */
  progress: PropTypes.number,
  /** Color of the background. Defaults to gray.50. */
  backgroundColor: PropTypes.string,
  /** Color of the progress bar. Defaults to primary.500. */
  progressColor: PropTypes.string,
};

export const LinearProgress = memo(LinearProgressBase);
