import { createContext, ReactNode, useContext } from 'react';
import PropTypes from 'prop-types';
import { ResponseError } from 'superagent';

import { USER_ROLES } from '@eversity/domain/constants';
import { useMemoizedBundle } from '@eversity/ui/utils';
import { EmployeeViewFull } from '@eversity/types/domain';

import { useUser } from '../hooks/queries/user';

export type AuthContextValue = {
  user: EmployeeViewFull | undefined;
  isLoading: boolean;
  error?: Error | ResponseError;
  isAdmin: boolean;
  isEditor: boolean;
};

const AuthContext = createContext<AuthContextValue>({
  user: null,
  isLoading: false,
  error: null,
  isAdmin: false,
  isEditor: false,
});

export type AuthProviderProps = {
  children?: ReactNode;
};

const AuthProvider = ({ children = null }: AuthProviderProps) => {
  const {
    data: user,
    isLoading,
    error,
  } = useUser({
    throwOnError: false,
  });

  const isAdmin = user?.roles?.includes(USER_ROLES.ADMIN);
  const isEditor = user?.roles?.includes(USER_ROLES.EDITOR);

  const contextValue = useMemoizedBundle<AuthContextValue>({
    user,
    isLoading,
    error,
    isAdmin,
    isEditor,
  });

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

export { AuthProvider, useAuth };
