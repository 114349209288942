import {
  ComponentType,
  ForwardedRef,
  forwardRef,
  HTMLProps,
  memo,
  MemoExoticComponent,
  ReactElement,
} from 'react';
import {
  Root as HastRoot,
  Element as HastElement,
  Text as HastText,
} from 'hast';

import { TAGS_TYPOGRAPHY_MAPPING } from './constants';

import { HtmlFormatter } from '../html-formatter/HtmlFormatter';
import { HastRendererDefaultRenderElement } from './HastRendererDefaultRenderElement';
import { HastRendererContext } from './HastRenderer.context';

export { getThemeTextColor, getThemeTableBackgroundColor } from './constants';

export type HastRendererRenderElement = ComponentType<{
  node: HastElement | HastText;
  depth: number;
  path: number[];
  renderChildren: () => ReactElement[] | null;
}>;

const recursiveRenderElement = (
  node: HastElement | HastText,
  index: number,
  renderElement: HastRendererRenderElement,
  depth: number,
  path: number[],
) => {
  const RenderElement = renderElement;

  return (
    <RenderElement
      node={node}
      key={
        'properties' in node ? (node.properties.id as string) || index : index
      }
      depth={depth}
      path={path}
      renderChildren={() =>
        'children' in node
          ? node.children.map((child, childIndex) =>
              recursiveRenderElement(
                child as HastText,
                childIndex,
                renderElement,
                depth + 1,
                [...path, childIndex],
              ),
            )
          : null
      }
    />
  );
};

export type HastRendererProps = Omit<HTMLProps<HTMLDivElement>, 'ref'> & {
  hast: HastRoot;
  renderElement?: HastRendererRenderElement;
  omitHtmlFormatter?: boolean;
};

export const HastRendererBase = forwardRef(
  (
    { hast, renderElement, omitHtmlFormatter, ...props }: HastRendererProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const renderedHast = hast.children.map((child, index) =>
      recursiveRenderElement(child as HastElement, index, renderElement, 0, [
        index,
      ]),
    );

    return (
      <HastRendererContext.Provider value={hast}>
        {omitHtmlFormatter ? (
          renderedHast
        ) : (
          <HtmlFormatter
            {...props}
            ref={ref}
          >
            {renderedHast}
          </HtmlFormatter>
        )}
      </HastRendererContext.Provider>
    );
  },
);

HastRendererBase.displayName = 'HastRenderer';

HastRendererBase.defaultProps = {
  renderElement: HastRendererDefaultRenderElement,
  omitHtmlFormatter: false,
};

export const HastRenderer: MemoExoticComponent<typeof HastRendererBase> & {
  DefaultRenderElement?: typeof HastRendererDefaultRenderElement;
  TAGS_TYPOGRAPHY_MAPPING?: typeof TAGS_TYPOGRAPHY_MAPPING;
} = memo(HastRendererBase);

HastRenderer.DefaultRenderElement = HastRendererDefaultRenderElement;
HastRenderer.TAGS_TYPOGRAPHY_MAPPING = TAGS_TYPOGRAPHY_MAPPING;
