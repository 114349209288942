import { ForwardedRef, forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  CloseButtonProps as ReactToastifyCloseButtonProps,
  toast,
} from 'react-toastify';
import { Close } from '@icon-park/react';
import { css } from '@emotion/react';

import { BUTTON_VARIANTS } from '../../../general/button/constants';

import { Button } from '../../../general/button/Button';

import messages from './ToastCloseButton.messages';

type ToastType = (typeof toast.TYPE)[keyof typeof toast.TYPE];

const TOAST_TYPE_VARIANT_MAPPING: Record<ToastType, BUTTON_VARIANTS> = {
  default: Button.VARIANTS.NEUTRAL,
  info: Button.VARIANTS.PRIMARY,
  error: Button.VARIANTS.DANGER,
  warning: Button.VARIANTS.WARNING,
  success: Button.VARIANTS.SUCCESS,
};

export type CloseButtonProps = ReactToastifyCloseButtonProps;

export const ToastCloseButtonBase = forwardRef(
  (
    { closeToast, type }: CloseButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const intl = useIntl();

    return (
      <Button
        ref={ref}
        size={Button.SIZES.SMALL}
        variant={TOAST_TYPE_VARIANT_MAPPING[type]}
        icon={<Close />}
        onClick={closeToast}
        aria-label={intl.formatMessage(messages.CLOSE_TOAST)}
        css={css`
          align-self: flex-start;
        `}
      />
    );
  },
);

ToastCloseButtonBase.displayName = 'ToastCloseButton';

ToastCloseButtonBase.propTypes = {
  /** Injected by react-toastify. */
  closeToast: PropTypes.func.isRequired,
  /** Injected by react-toastify. */
  type: PropTypes.oneOf(Object.values(toast.TYPE)).isRequired,
};

ToastCloseButtonBase.defaultProps = {};

export const ToastCloseButton = memo(ToastCloseButtonBase);
