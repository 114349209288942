import {
  Children,
  ForwardedRef,
  forwardRef,
  Fragment,
  memo,
  ReactNode,
} from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { LineSeparator } from '../../../../general/line-separator/LineSeparator';

export type ListRendererProps = {
  withItemSeparator?: boolean;
  children?: ReactNode;
  className?: string;
};

export const ListRendererBase = forwardRef(
  (
    { children, className, withItemSeparator }: ListRendererProps,
    ref: ForwardedRef<HTMLUListElement>,
  ) => (
    <ul
      ref={ref}
      className={className}
      css={css`
        list-style-type: none;
        display: flex;
        flex-direction: column;
        width: 200px;
        max-height: 300px;
        overflow-y: auto;

        & > *:first-of-type {
          button {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
          }
        }

        & > *:last-of-type {
          button {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }
      `}
    >
      {Children.map(children, (child, index) => (
        <Fragment>
          {!!index &&
            index !== Children.count(children) &&
            withItemSeparator && (
              <LineSeparator
                css={css`
                  padding: 0px;
                  margin: 0px;
                `}
              />
            )}
          {child}
        </Fragment>
      ))}
    </ul>
  ),
);

ListRendererBase.displayName = 'ListRenderer';

ListRendererBase.propTypes = {
  /** Items and SubLists. */
  children: PropTypes.node,
  /** Class name. */
  className: PropTypes.string,
  /** Separator. */
  withItemSeparator: PropTypes.bool,
};

ListRendererBase.defaultProps = {
  children: null,
  className: null,
  withItemSeparator: null,
};

export const ListRenderer = memo(ListRendererBase);
