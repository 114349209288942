import { css, Theme } from '@emotion/react';

export const title = css`
  padding-bottom: 10px;
`;

export const separator = css`
  display: flex;
  align-items: center;
  padding-top: 10px;
`;

export const lineSeparator = (theme: Theme) => css`
  flex: 1;
  height: 1px;
  background-color: ${theme.colors.gray[100]};
`;

export const separatorText = css`
  padding: 0 10px;
`;

export const picturesContainer = () => css`
  display: flex;
  gap: 20px;
`;

export const historyPicture = css`
  position: relative;
`;
