import { memo, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { css, useTheme } from '@emotion/react';
import { SignalStrength } from '@icon-park/react';

import { VIRTUAL_CLASSROOM_PICTURE_ASPECT_RATIO } from '@eversity/domain/constants';

export type VirtualClassroomPictureProps = {
  picture?: { href: string } | null;
  children?: ReactNode;
  width?: number | string;
};

export const VirtualClassroomPictureBase = ({
  picture = null,
  width = 235,
  children = null,
}: VirtualClassroomPictureProps) => {
  const theme = useTheme();

  return (
    <div
      css={[
        css`
          aspect-ratio: ${VIRTUAL_CLASSROOM_PICTURE_ASPECT_RATIO};
          width: ${Number.isFinite(width) ? `${width}px` : width};
          background-color: ${theme.colors.primary[15]};
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid ${theme.colors.gray[100]};
          border-radius: 4px;
          position: relative;
        `,
        picture &&
          css`
            background-image: url(${picture.href});
            background-position: center;
            background-size: cover;
          `,
      ]}
    >
      {!picture && (
        <SignalStrength
          size={48}
          strokeWidth={2}
          fill={[theme.colors.primary[500], theme.colors.primary[50]]}
        />
      )}

      {children}
    </div>
  );
};

VirtualClassroomPictureBase.displayName = 'VirtualClassroomPicture';

VirtualClassroomPictureBase.propTypes = {
  picture: PropTypes.shape({
    href: PropTypes.string.isRequired,
  }),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
};

export const VirtualClassroomPicture = memo(VirtualClassroomPictureBase);
