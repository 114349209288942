import { Fragment, memo, MemoExoticComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { AvatarShape } from '@eversity/types/web';
import { getInitials } from '@eversity/utils/misc';

import { AVATAR_SIZES } from './constants';
import { Typography } from '../../general/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from '../../../config/typography/constants';

import { useUserThemeColor } from '../../../utils/hooks/useUserThemeColor';
import { avatarShape } from '../../../types/avatar';
import * as styles from './Avatar.styles';

const TYPOGRAPHY_SIZE_MAPPING: Record<AVATAR_SIZES, TYPOGRAPHY_VARIANTS> = {
  [AVATAR_SIZES.SMALL]: Typography.VARIANTS.BODY_SMALL_BOLD,
  [AVATAR_SIZES.MEDIUM]: Typography.VARIANTS.BODY_MEDIUM_BOLD,
  [AVATAR_SIZES.LARGE]: Typography.VARIANTS.BODY_LARGE_BOLD,
  [AVATAR_SIZES.OVERSIZED]: Typography.VARIANTS.HEADING_2,
};

export type AvatarProps = {
  user: AvatarShape;
  size?: AVATAR_SIZES;
  isShadowed?: boolean;
  className?: string;
};

export const AvatarBase = ({
  user,
  size = AVATAR_SIZES.MEDIUM,
  isShadowed = false,
  className = null,
}: AvatarProps) => {
  const themeColor = useUserThemeColor(user);

  const initials = getInitials(user.firstName, user.lastName);

  const pictureHref = user.picture?.href;

  return (
    <span
      css={styles.containerStyle(themeColor)}
      className={cn(className, size, { isShadowed })}
    >
      {pictureHref ? (
        <Fragment>
          {isShadowed && (
            /* This is the shadow overlay behind the picture. */
            /* Container. */
            <div css={styles.profilePictureShadowContainerStyle}>
              {/* Picture + blur. */}
              <div css={styles.profilePictureShadowStyle(pictureHref)} />

              {/* Transparent white overlay. */}
              <div css={styles.profilePictureShadowOverlayStyle} />
            </div>
          )}

          {/* This is the picture. */}
          <div css={styles.profilePictureStyle(pictureHref)} />
        </Fragment>
      ) : (
        <span
          css={styles.defaultAvatarStyle(themeColor)}
          className={size}
        >
          <Typography
            variant={TYPOGRAPHY_SIZE_MAPPING[size]}
            element="span"
          >
            {initials}
          </Typography>
        </span>
      )}
    </span>
  );
};

AvatarBase.defaultName = 'Avatar';

AvatarBase.propTypes = {
  /** User to whom the avatar belongs. */
  user: avatarShape.isRequired,
  /** Avatar size. */
  size: PropTypes.oneOf(Object.values(AVATAR_SIZES)),
  /** With a box-shadow effect. */
  isShadowed: PropTypes.bool,
  /** Style applied to the component. */
  className: PropTypes.string,
};

export const Avatar: MemoExoticComponent<typeof AvatarBase> & {
  SIZES?: typeof AVATAR_SIZES;
} = memo(AvatarBase);

Avatar.SIZES = AVATAR_SIZES;
