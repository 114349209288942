import { css, Theme } from '@emotion/react';

import { VARIANTS } from '../constants';
import {
  removeButtonAppearance,
  removeLinkAppearance,
} from '../../../../utils/style';

export const container = (theme: Theme, variant: VARIANTS) => css`
  ${removeLinkAppearance};
  ${removeButtonAppearance};

  height: 100%;
  width: ${variant === VARIANTS.HORIZONTAL ? '100%' : 'auto'};

  color: ${theme.colors.gray[700]};

  transition: ${theme.transitions.default()};

  &.isDisabled {
    cursor: default;
  }
`;
