import { defineMessages, MessageDescriptor } from 'react-intl';

import { STUDENT_PLANS } from '@eversity/domain/constants';

export const studentPlanMessages = defineMessages<STUDENT_PLANS>({
  [STUDENT_PLANS.INITIAL]: { defaultMessage: 'Initial' },
  [STUDENT_PLANS.MPE]: { defaultMessage: 'MPE' },
  [STUDENT_PLANS.TP_HDF]: { defaultMessage: 'TP HDF' },
  [STUDENT_PLANS.APPRENTISSAGE]: { defaultMessage: 'Apprentissage' },
  [STUDENT_PLANS.APPRENTI_SANS_ENTREPRISE]: {
    defaultMessage: 'Apprenti sans entreprise',
  },
  [STUDENT_PLANS.CONTRAT_PROFESSIONNALISATION]: {
    defaultMessage: 'Contrat professionnalisation',
  },
  [STUDENT_PLANS.PERIODE_PROFESSIONNALISATION]: {
    defaultMessage: 'Période professionnalisation',
  },
  [STUDENT_PLANS.CPF]: { defaultMessage: 'CPF' },
  [STUDENT_PLANS.PLAN_FORMATION]: { defaultMessage: 'Plan formation' },
  [STUDENT_PLANS.PLAN_SAUVEGARDE_EMPLOI]: {
    defaultMessage: 'Plan sauvegarde emploi',
  },
  [STUDENT_PLANS.CONVENTION_RECLASSEMENT_PERSONNALISE]: {
    defaultMessage: 'Convention reclassement personnalisé',
  },
  [STUDENT_PLANS.CIF]: { defaultMessage: 'CIF' },
  [STUDENT_PLANS.CONTRAT_SECURISATION_PROFESSIONNEL]: {
    defaultMessage: 'Contrat sécurisation professionnelle',
  },
  [STUDENT_PLANS.CONGE_FORMATION_PROFESSIONNEL]: {
    defaultMessage: 'Congé formation professionelle',
  },
  [STUDENT_PLANS.PLAN_LICENCIEMENT]: { defaultMessage: 'Plan licenciement' },
  [STUDENT_PLANS.CONTRAT_TRANSITION_PROFESSIONNELLE]: {
    defaultMessage: 'Contrat transition professionnelle',
  },
  [STUDENT_PLANS.CONGE_RECLASSEMENT]: { defaultMessage: 'Congé reclassement' },
  [STUDENT_PLANS.AIF]: { defaultMessage: 'AIF' },
  [STUDENT_PLANS.MISSION_LOCALE]: { defaultMessage: 'Mission locale' },
  [STUDENT_PLANS.REGION]: { defaultMessage: 'Région' },
  [STUDENT_PLANS.FINANCEMENT_ENTREPRISE]: {
    defaultMessage: 'Financement entreprise',
  },
  [STUDENT_PLANS.PLAN_DEPART_VOLONTAIRE]: {
    defaultMessage: 'Plan départ volontaire',
  },
});

export const studentTranscriptAssessmentMessages = defineMessages({
  ASSESSMENT_LOWEST: {
    defaultMessage:
      'Le niveau est assez faible. Il est nécessaire de revoir les notions de cours non acquises !',
  },

  ASSESSMENT_LOW: {
    defaultMessage:
      'Un niveau assez juste. Persévérez dans le travail et les résultats augmenteront.',
  },

  ASSESSMENT_MEDIUM: {
    defaultMessage:
      'Ensemble correct, vous pouvez améliorer vos notes. Revoyez les notions qui ne sont pas maitrisées.',
  },

  ASSESSMENT_MEDIUM_HIGH: {
    defaultMessage: 'Résultats satisfaisants. Il faut continuer ainsi !',
  },

  ASSESSMENT_HIGH: {
    defaultMessage:
      'Travail de qualité. Continuez dans cette voie pour réussir !',
  },

  ASSESSMENT_HIGHEST: {
    defaultMessage:
      'Étudiant ayant de très bons résultats. La moyenne générale est très bonne. Continuez ainsi !',
  },
});

export function getStudentTranscriptAssessmentMessage(
  average: number,
): MessageDescriptor {
  switch (true) {
    default:
    case average < 8:
      return studentTranscriptAssessmentMessages.ASSESSMENT_LOWEST;
    case average < 10:
      return studentTranscriptAssessmentMessages.ASSESSMENT_LOW;
    case average < 12:
      return studentTranscriptAssessmentMessages.ASSESSMENT_MEDIUM;
    case average < 14:
      return studentTranscriptAssessmentMessages.ASSESSMENT_MEDIUM_HIGH;
    case average < 16:
      return studentTranscriptAssessmentMessages.ASSESSMENT_HIGH;
    case average >= 16:
      return studentTranscriptAssessmentMessages.ASSESSMENT_HIGHEST;
  }
}
