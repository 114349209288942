import { memo } from 'react';
import PropTypes from 'prop-types';
import { CloseSmall } from '@icon-park/react';
import { components, GroupBase, MultiValueRemoveProps } from 'react-select';

export const MultiValueRemoveBase = <
  TOption,
  IsMulti extends boolean,
  TGroup extends GroupBase<TOption>,
>(
  props: MultiValueRemoveProps<TOption, IsMulti, TGroup>,
) =>
  props.selectProps.isDisabled ? null : (
    <components.MultiValueRemove {...props}>
      <CloseSmall />
    </components.MultiValueRemove>
  );

MultiValueRemoveBase.displayName = 'MultiValueRemove';

MultiValueRemoveBase.propTypes = {
  selectProps: PropTypes.shape({
    isDisabled: PropTypes.bool.isRequired,
  }).isRequired,
};

export default memo(MultiValueRemoveBase);
