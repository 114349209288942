import {
  ForwardedRef,
  forwardRef,
  memo,
  MemoExoticComponent,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useUpdate } from 'react-use';
import { useIntl } from 'react-intl';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { Left, Right } from '@icon-park/react';
import { useTheme } from '@emotion/react';
import 'swiper/swiper-bundle.css';

import { globalMessages } from '@eversity/ui/intl';
import { ICON_SIZES } from '../../general/icon/constants';

import * as styles from './CardsCarousel.styles';

const PEEK_TRANSLATE_PX = 50;

type SwiperClass = Parameters<SwiperProps['onSwiper']>[0];

export type CardsCarouselProps = Omit<
  SwiperProps,
  'slidesPerView' | 'spaceBetween' | 'speed' | 'onSwiper' | 'onProgress' | 'ref'
> & {
  header?: ReactNode;
  children?: ReactNode;
};

export const CardsCarouselBase = forwardRef(
  (
    { header, children, ...props }: CardsCarouselProps,
    ref: ForwardedRef<SwiperClass>,
  ) => {
    const theme = useTheme();
    const intl = useIntl();
    const update = useUpdate();
    const [swiper, setSwiper] = useState<SwiperClass>(null);

    // Pass swiper as ref for this component.
    useImperativeHandle(ref, () => swiper, [swiper]);

    const onClickPrev = useCallback(() => {
      swiper?.slidePrev(theme.transitions.default.duration);
    }, [swiper, theme]);

    const onClickNext = useCallback(() => {
      swiper?.slideNext(theme.transitions.default.duration);
    }, [swiper, theme]);

    const hasPrev = !swiper?.isBeginning;
    const hasNext = !swiper?.isEnd;

    const onPeekNext = useCallback(
      () =>
        swiper?.translateTo(
          swiper.translate - PEEK_TRANSLATE_PX,
          theme.transitions.default.duration,
          true,
          false,
        ),
      [swiper, theme],
    );

    const onPeekPrev = useCallback(
      () =>
        swiper?.translateTo(
          swiper.translate + PEEK_TRANSLATE_PX,
          theme.transitions.default.duration,
          true,
          false,
        ),
      [swiper, theme],
    );

    return (
      <div css={styles.container}>
        <div>{header}</div>

        <div
          css={styles.swiperContainer}
          className={cn({ hasPrev, hasNext })}
        >
          <Swiper
            {...props}
            css={styles.swiper}
            slidesPerView="auto"
            spaceBetween={20}
            speed={theme.transitions.default.duration}
            onSwiper={setSwiper}
            // Component does not rerender when changing slides, so force updates when it moves
            // because we need to enable/disable the prev/next buttons.
            onProgress={update}
          >
            {children}
          </Swiper>

          {hasPrev && (
            <button
              type="button"
              css={styles.prevButton}
              onMouseEnter={onPeekPrev}
              onMouseLeave={onPeekNext}
              onClick={onClickPrev}
              aria-label={intl.formatMessage(globalMessages.PREVIOUS)}
            >
              <div>
                <Left
                  size={ICON_SIZES.LARGE}
                  fill={theme.colors.gray[0]}
                />
              </div>
            </button>
          )}

          {hasNext && (
            <button
              type="button"
              css={styles.nextButton}
              onMouseEnter={onPeekNext}
              onMouseLeave={onPeekPrev}
              onClick={onClickNext}
              aria-label={intl.formatMessage(globalMessages.NEXT)}
            >
              <div>
                <Right
                  size={ICON_SIZES.LARGE}
                  fill={theme.colors.gray[0]}
                />
              </div>
            </button>
          )}
        </div>
      </div>
    );
  },
);

CardsCarouselBase.displayName = 'CardsCarousel';

CardsCarouselBase.propTypes = {
  /** Header of the carousel. */
  header: PropTypes.node,
  /** List of CardsCarousel.Slide. */
  children: PropTypes.node,
};

CardsCarouselBase.defaultProps = {
  header: null,
  children: null,
};

export const CardsCarousel: MemoExoticComponent<typeof CardsCarouselBase> & {
  Slide?: typeof SwiperSlide;
} = memo(CardsCarouselBase);

CardsCarousel.Slide = SwiperSlide;
