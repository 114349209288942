import { memo, MutableRefObject, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Lightbox, removeButtonAppearance } from '@eversity/ui/design-system';

export type ImageResourceItemProps = {
  href: string;
  alt: string;
};

export const ImageResourceItemBase = ({
  href,
  alt,
}: ImageResourceItemProps) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [width, setWidth] = useState(1024);
  const [height, setHeight] = useState(1024);

  useEffect(() => {
    if (imgRef?.current) {
      setWidth(imgRef.current.naturalWidth);
      setHeight(imgRef.current.naturalHeight);
    }
  }, [imgRef]);

  return (
    <Lightbox.Item
      original={href}
      alt={alt}
      width={width}
      height={height}
    >
      {({ ref, open }) => (
        <button
          type="button"
          onClick={open}
          css={css`
            ${removeButtonAppearance};
            width: 40px;
            height: 40px;
          `}
        >
          <img
            css={css`
              width: 100%;
              height: 100%;
              border-radius: 8px;
            `}
            ref={ref as MutableRefObject<HTMLImageElement>}
            src={href}
            alt={alt}
          />
          <img
            css={css`
              display: none;
            `}
            ref={imgRef}
            src={href}
            alt={alt}
          />
        </button>
      )}
    </Lightbox.Item>
  );
};

ImageResourceItemBase.displayName = 'ImageItem';

ImageResourceItemBase.propTypes = {
  href: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default memo(ImageResourceItemBase);
