import { defineMessages } from 'react-intl';

export default defineMessages({
  TOTAL_ITEMS: {
    defaultMessage: '{first, number}-{last, number} sur {total, number}',
  },

  PREV_ARIA_LABEL: {
    defaultMessage: 'Page précédente',
  },

  NEXT_ARIA_LABEL: {
    defaultMessage: 'Page suivante',
  },

  PAGE_ARIA_LABEL: {
    defaultMessage: 'Aller à la page {page, number}',
  },
});
