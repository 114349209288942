import {
  cloneElement,
  HTMLProps,
  memo,
  MemoExoticComponent,
  ReactElement,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { css, Theme, useTheme } from '@emotion/react';

enum RADIAL_PROGRESS_VARIANTS {
  PRIMARY = 'primary',
}

const RADIAL_PROGRESS_STROKE_COLORS = (
  theme: Theme,
): Record<RADIAL_PROGRESS_VARIANTS, string> => ({
  [RADIAL_PROGRESS_VARIANTS.PRIMARY]: theme.colors.primary[500],
});

const RADIAL_PROGRESS_ICON_FILLS = (
  theme: Theme,
): Record<RADIAL_PROGRESS_VARIANTS, [string, string]> => ({
  [RADIAL_PROGRESS_VARIANTS.PRIMARY]: [
    theme.colors.primary[500],
    theme.colors.primary[50],
  ],
});

enum RADIAL_PROGRESS_SIZES {
  SMALL = 'small',
}

const RADIAL_PROGRESS_CIRCLE_SIZE_MAPPING: Record<
  RADIAL_PROGRESS_SIZES,
  number
> = {
  [RADIAL_PROGRESS_SIZES.SMALL]: 26,
};

const RADIAL_PROGRESS_ICON_SIZE_MAPPING: Record<RADIAL_PROGRESS_SIZES, number> =
  {
    [RADIAL_PROGRESS_SIZES.SMALL]: 14,
  };

// Width of the circle stroke in px.
const STROKE_WIDTH = 1;

export type RadialProgressProps = Omit<
  HTMLProps<HTMLDivElement>,
  'size' | 'ref'
> & {
  progress?: number;
  size?: RADIAL_PROGRESS_SIZES;
  variant?: RADIAL_PROGRESS_VARIANTS;
  icon?: ReactElement;
};

export const RadialProgressBase = ({
  progress = 0,
  size = RADIAL_PROGRESS_SIZES.SMALL,
  variant = RADIAL_PROGRESS_VARIANTS.PRIMARY,
  icon = null,
  ...props
}: RadialProgressProps) => {
  const theme = useTheme();

  const iconFills = useMemo(() => RADIAL_PROGRESS_ICON_FILLS(theme), [theme]);

  const strokeColor = RADIAL_PROGRESS_STROKE_COLORS(theme)[variant];

  const circleSize = RADIAL_PROGRESS_CIRCLE_SIZE_MAPPING[size];

  return (
    <div
      {...props}
      css={css`
        position: relative;
        width: ${circleSize}px;
        height: ${circleSize}px;
      `}
    >
      <svg
        viewBox={`0 0 ${circleSize} ${circleSize}`}
        css={css`
          transform: rotate(-90deg);
          width: 100%;
          height: 100%;
        `}
      >
        <circle
          fill="none"
          stroke={strokeColor}
          cx={circleSize / 2}
          cy={circleSize / 2}
          r={(circleSize - STROKE_WIDTH) / 2}
          strokeWidth={STROKE_WIDTH}
          strokeLinecap="round"
          pathLength={100}
          css={css`
            transition: ${theme.transitions.default()};
            stroke-dasharray: 100;
            stroke-dashoffset: ${100 - progress * 100};
          `}
        />
      </svg>

      {!!icon && (
        <div
          css={css`
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
          `}
        >
          {cloneElement(icon, {
            size: RADIAL_PROGRESS_ICON_SIZE_MAPPING[size],
            fill: iconFills[variant],
          })}
        </div>
      )}
    </div>
  );
};

RadialProgressBase.displayName = 'RadialProgress';

RadialProgressBase.propTypes = {
  /** Progress between 0 and 1. */
  progress: PropTypes.number,
  /** Color variant of the progress bar and icon. */
  variant: PropTypes.oneOf(Object.values(RADIAL_PROGRESS_VARIANTS)),
  /** Size of the circle and icon. */
  size: PropTypes.oneOf(Object.values(RADIAL_PROGRESS_SIZES)),
  /** Icon inside the radial progress. */
  icon: PropTypes.node,
};

export const RadialProgress: MemoExoticComponent<typeof RadialProgressBase> & {
  SIZES?: typeof RADIAL_PROGRESS_SIZES;
  VARIANTS?: typeof RADIAL_PROGRESS_VARIANTS;
} = memo(RadialProgressBase);

RadialProgress.SIZES = RADIAL_PROGRESS_SIZES;
RadialProgress.VARIANTS = RADIAL_PROGRESS_VARIANTS;
