import { css, Theme } from '@emotion/react';

export const cover = (theme: Theme, height: number) => css`
  width: 100%;
  height: ${height}px;

  transition: ${theme.transitions.default()};

  border-bottom: 1px solid ${theme.colors.gray[100]};

  overflow: hidden;
`;

export const image = (imageSrc: string) => css`
  width: 100%;
  height: 100%;

  background-image: url(${imageSrc});
  background-size: cover;
`;

export const placeholder = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  background-color: ${theme.colors.primary[15]};
`;

export const placeholderImage = (theme: Theme) => css`
  padding: 20px;

  width: 90px;
  height: 90px;

  background-color: ${theme.colors.primary[25]};

  border-radius: 50%;
`;
