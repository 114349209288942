import { css, Theme } from '@emotion/react';

export const coloredText = (theme: Theme) => css`
  color: ${theme.colors.primary[500]};
`;

export const originalGrade = (theme: Theme) => css`
  color: ${theme.colors.gray[700]};
`;

export const assignmentItem = (theme: Theme) => css`
  display: flex;

  padding-left: 40px;

  &::before {
    content: '\\2022';
    margin-right: 10px;
  }

  &.greyed {
    color: ${theme.colors.gray[300]};
  }
`;

export const assignmentCoefficient = (theme: Theme) => css`
  color: ${theme.colors.primary[500]};

  margin-left: 10px;

  &.greyed {
    color: ${theme.colors.gray[300]};
  }
`;

export const gradeSeparator = (theme: Theme) => css`
  flex: 1;

  margin: 5px 10px;

  border-bottom: 1px dashed ${theme.colors.gray[100]};
`;

export const gradeContainer = css`
  display: flex;
  align-items: center;
  gap: 10px;
`;
