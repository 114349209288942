import {
  ASSIGNMENT_ASSESSMENTS,
  ASSIGNMENT_GRADING_TYPES,
  COURSE_DIPLOMA_TYPES,
} from '@eversity/domain/constants';
import PropTypes from 'prop-types';

export const academicTranscriptAssignmentPropTypes = PropTypes.shape({
  /** Coefficient of the assignment in the teaching unit. */
  coefficient: PropTypes.number.isRequired,
  /** Assignment metadata. */
  assignment: PropTypes.shape({
    /** Assignment title. */
    title: PropTypes.string.isRequired,
    /** Assignment code. */
    code: PropTypes.string.isRequired,
    /** Assignment grading type. */
    gradingType: PropTypes.oneOf(Object.values(ASSIGNMENT_GRADING_TYPES))
      .isRequired,
    /** Assignment grading scale if gradingType is GRADE. */
    gradingScale: PropTypes.number,
  }).isRequired,
  /** Assignment submission. */
  submission: PropTypes.shape({
    /** grade if assignment.gradingType is GRADE. */
    grade: PropTypes.number,
    /** Assessment if assignment.gradingType is ASSESSMENT. */
    assessment: PropTypes.oneOf(Object.values(ASSIGNMENT_ASSESSMENTS)),
    /** Correction by corrector (or automatically) date. */
    correctionDate: PropTypes.string,
    /** Submission by student date. */
    submissionDate: PropTypes.string,
  }),
});

/** Academic transcript for a student on one of his courses. */
export const academicTranscriptPropTypes = PropTypes.shape({
  /** Student registration date. */
  registrationDate: PropTypes.string.isRequired,
  /** Start date of the academic transcript. */
  fromDate: PropTypes.string.isRequired,
  /** End date of the academic transcript. */
  toDate: PropTypes.string.isRequired,
  /** Overall average across all teaching units (/20). Null if no grades. */
  overallAverage: PropTypes.number,
  /** Student. */
  user: PropTypes.shape({
    /** Student first name. */
    firstName: PropTypes.string.isRequired,
    /** Student last name. */
    lastName: PropTypes.string.isRequired,
    /** CRM id. */
    studentRef: PropTypes.string.isRequired,
  }),
  /** Course and assignments/grades. */
  course: PropTypes.shape({
    /** Course title. */
    title: PropTypes.string.isRequired,
    /** Course code. */
    code: PropTypes.string.isRequired,
    /** Course diploma type. */
    diplomaType: PropTypes.oneOf(Object.values(COURSE_DIPLOMA_TYPES))
      .isRequired,
    /** Teaching units and their assignments/grades. */
    teachingUnits: PropTypes.arrayOf(
      PropTypes.shape({
        /** Teaching unit title. */
        title: PropTypes.string.isRequired,
        /** Teaching unit code. */
        code: PropTypes.string.isRequired,
        /** Coefficient of the teaching unit in the course. */
        coefficient: PropTypes.number.isRequired,
        /** Overall average of all assignments in the teaching unit (/20). Null if no grades. */
        overallAverage: PropTypes.number,
        /** Teaching unit assignments (exams). */
        assignments: PropTypes.arrayOf(academicTranscriptAssignmentPropTypes)
          .isRequired,
        /** Teaching unit lessons and their assignments. */
        lessons: PropTypes.arrayOf(
          PropTypes.shape({
            /** Lesson metadata. */
            lesson: PropTypes.shape({
              /** Lesson title. */
              title: PropTypes.string.isRequired,
              /** Lesson code. */
              code: PropTypes.string.isRequired,
            }).isRequired,
            /** Lesson assignments and grades. */
            assignments: PropTypes.arrayOf(
              academicTranscriptAssignmentPropTypes,
            ).isRequired,
          }),
        ).isRequired,
      }),
    ).isRequired,
  }).isRequired,
});
