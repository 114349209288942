import { css, Theme } from '@emotion/react';
import { LESSON_CARD_THUMBNAIL_ASPECT_RATIO } from '@eversity/domain/constants';

export const thumbnailImage = (imageHref: string) => css`
  position: relative;

  width: 100%;
  aspect-ratio: ${LESSON_CARD_THUMBNAIL_ASPECT_RATIO};

  border-radius: 8px;

  background-image: url(${imageHref});
  background-size: cover;
  background-position: center;
`;

export const placeholderImage = (theme: Theme) => css`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  aspect-ratio: ${LESSON_CARD_THUMBNAIL_ASPECT_RATIO};

  background-color: ${theme.colors.primary[15]};

  border-radius: 8px;
`;
