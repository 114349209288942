import { defineMessages } from 'react-intl';

import { MCQ_ANSWER_TYPES, MCQ_PAGE_TYPES } from '@eversity/domain/constants';

export const mcqMessages = defineMessages({
  [MCQ_ANSWER_TYPES.SINGLE]: {
    defaultMessage: 'QCU',
  },
  [MCQ_ANSWER_TYPES.MULTIPLE]: {
    defaultMessage: 'QCM',
  },
  POINTS_VALUE: {
    defaultMessage:
      '<b>{value}</b> {value, plural, one {point} other {points}}',
  },
  PAGE_NUMBER: {
    defaultMessage: 'Page {value}',
  },
  NUMBER_OF_QUESTIONS: {
    defaultMessage:
      '<b>{value}</b> {value, plural, one {question} other {questions}}',
  },
  CONTENT: {
    defaultMessage: 'Énoncé',
  },
  QUESTIONS: {
    defaultMessage: 'Questions',
  },
});

export const mcqPageTypeMessages = defineMessages<MCQ_PAGE_TYPES>({
  [MCQ_PAGE_TYPES.CONTENT]: {
    defaultMessage: 'Énoncé uniquement',
  },
  [MCQ_PAGE_TYPES.MCQ]: {
    defaultMessage: 'Questions uniquement',
  },
  [MCQ_PAGE_TYPES.MCQ_CONTENT]: {
    defaultMessage: 'Énoncé + questions',
  },
});
