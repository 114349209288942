import { createContext, FormEvent, memo, ReactNode, useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { useMemoizedBundle } from '@eversity/ui/utils';

type RadioGroupContextValue = {
  value: string;
  onChange: (event: FormEvent<HTMLInputElement>) => void;
};

export const RadioGroupContext = createContext<RadioGroupContextValue>({
  value: null,
  onChange: noop,
});

export type RadioGroupContextProviderProps = {
  value?: string;
  onChange?: (newValue: string) => void;
  children?: ReactNode;
};

export const RadioGroupContextProviderBase = ({
  value = null,
  onChange = noop,
  children = null,
}: RadioGroupContextProviderProps) => {
  const onChangeProxy = useCallback(
    (event: FormEvent<HTMLInputElement>) =>
      onChange((event.target as HTMLInputElement).value),
    [onChange],
  );

  const contextValue = useMemoizedBundle({
    value,
    onChange: onChangeProxy,
  });

  return (
    <RadioGroupContext.Provider value={contextValue}>
      {children}
    </RadioGroupContext.Provider>
  );
};

RadioGroupContextProviderBase.displayName = 'RadioGroupContextProvider';

RadioGroupContextProviderBase.propTypes = {
  /** Selected value. */
  value: PropTypes.string,
  /** On change function. */
  onChange: PropTypes.func,
  /** Content. */
  children: PropTypes.node,
};

export const RadioGroupContextProvider = memo(RadioGroupContextProviderBase);
