import {
  ElementType,
  ForwardedRef,
  forwardRef,
  Fragment,
  memo,
  ReactNode,
} from 'react';
import PropTypes from 'prop-types';
import { css, useTheme } from '@emotion/react';
import { Element as HastElement, Text as HastText } from 'hast';

import { CUSTOM_HTML, TEXT_COLORS } from '@eversity/domain/constants';
import {
  AttachmentHastElement,
  AudioHastElement,
  CarouselHastElement,
  LinkedInEmbeddedHastElement,
  TableCellHastElement,
  TableHastElement,
  TableRowHastElement,
  VideoHastElement,
} from '@eversity/types/domain';

import { hastElementPropTypes, hastLeafPropTypes } from '../../../types';
import { TAGS_TYPOGRAPHY_MAPPING, getThemeTextColor } from './constants';

import { Carousel } from './custom-elements/carousel/Carousel';
import { Video } from './custom-elements/video/Video';
import { LinkedInEmbedded } from './custom-elements/linked-in-embedded/LinkedInEmbedded';
import { TextLink } from '../../navigation/text-link/TextLink';
import { Row } from './custom-elements/table/Row';
import { Cell } from './custom-elements/table/Cell';
import { Attachment } from './custom-elements/attachment/Attachment';
import { Audio } from './custom-elements/audio/Audio';

export type HastRendererDefaultRenderElementProps = {
  node: HastElement | HastText;
  path: number[];
  depth: number;
  renderChildren: () => ReactNode;
};

export const HastRendererDefaultRenderElementBase = forwardRef(
  (
    { node, path, renderChildren }: HastRendererDefaultRenderElementProps,
    ref: ForwardedRef<HTMLElement>,
  ) => {
    const theme = useTheme();

    switch (node.type) {
      case 'text':
        return (
          // eslint-disable-next-line react/jsx-no-useless-fragment -- To return a Element type.
          <Fragment>{node.value}</Fragment>
        );

      case 'element': {
        const { tagName = 'div', properties: attributes = {} } = node;

        const elementProps = {
          css: [css(theme.typography[TAGS_TYPOGRAPHY_MAPPING[tagName]])].filter(
            Boolean,
          ),
        };

        switch (tagName) {
          case CUSTOM_HTML.VIDEO: {
            const { src, caption, ...otherAttributes } =
              attributes as VideoHastElement['properties'];

            return (
              <Video
                className="eversity-video"
                {...elementProps}
                {...otherAttributes}
                src={src}
                caption={caption}
                ref={ref}
              />
            );
          }

          case CUSTOM_HTML.LINKEDIN_EMBEDDED: {
            const { src, title, ...otherAttributes } =
              attributes as LinkedInEmbeddedHastElement['properties'];

            return (
              <LinkedInEmbedded
                {...elementProps}
                {...otherAttributes}
                src={src}
                title={title}
                ref={ref as ForwardedRef<HTMLDivElement>}
              />
            );
          }

          case CUSTOM_HTML.CAROUSEL: {
            const { caption, dataSelectedSize, ...otherAttributes } =
              attributes as CarouselHastElement['properties'];

            return (
              <Carousel
                className="eversity-carousel"
                {...elementProps}
                {...otherAttributes}
                caption={caption}
                selectedSize={dataSelectedSize}
                slideNodes={node.children as CarouselHastElement['children']}
              />
            );
          }

          case CUSTOM_HTML.ATTACHMENT: {
            const {
              dataFileName,
              dataSize,
              dataHref,
              dataUploadId,
              ...otherAttributes
            } = attributes as AttachmentHastElement['properties'];

            return (
              <Attachment
                className="eversity-attachment"
                {...elementProps}
                {...otherAttributes}
                ref={ref as ForwardedRef<HTMLDivElement>}
                upload={{
                  id: dataUploadId,
                  fileName: dataFileName,
                  href: dataHref,
                  size: parseInt(dataSize, 10),
                }}
              />
            );
          }

          case CUSTOM_HTML.AUDIO: {
            const {
              dataUploadId,
              dataHref,
              dataFileName,
              dataSize,
              dataCaption,
              ...otherAttributes
            } = attributes as AudioHastElement['properties'];

            return (
              <Audio
                className="eversity-audio"
                {...elementProps}
                {...otherAttributes}
                ref={ref as ForwardedRef<HTMLAudioElement>}
                href={dataHref}
                caption={dataCaption}
              />
            );
          }

          case 'blockquote': {
            // Need to wrap the content with another element to apply the padding.
            return (
              <blockquote
                {...elementProps}
                {...attributes}
                ref={ref as ForwardedRef<HTMLQuoteElement>}
              >
                <p>{renderChildren()}</p>
              </blockquote>
            );
          }

          case 'a': {
            return (
              <TextLink
                {...elementProps}
                {...attributes}
                isExternal
                ref={ref as ForwardedRef<HTMLAnchorElement>}
              >
                {renderChildren()}
              </TextLink>
            );
          }

          case 'tr': {
            const { dataBackgroundColor, ...otherAttributes } =
              attributes as TableRowHastElement['properties'];

            return (
              <Row
                {...elementProps}
                {...otherAttributes}
                backgroundColor={dataBackgroundColor}
                ref={ref as ForwardedRef<HTMLTableRowElement>}
              >
                {renderChildren()}
              </Row>
            );
          }

          case 'th':
          case 'td': {
            const { dataBackgroundColor, ...otherAttributes } =
              attributes as TableCellHastElement['properties'];

            return (
              <Cell
                {...elementProps}
                {...otherAttributes}
                tagName={tagName}
                backgroundColor={dataBackgroundColor}
                path={path}
                ref={ref as ForwardedRef<HTMLTableCellElement>}
              >
                {renderChildren()}
              </Cell>
            );
          }

          case 'table': {
            // Omit dataColRatios.
            const { dataColRatios, ...otherAttributes } =
              attributes as TableHastElement['properties'];

            return (
              <table
                {...elementProps}
                {...otherAttributes}
                ref={ref as ForwardedRef<HTMLTableElement>}
              >
                {renderChildren()}
              </table>
            );
          }

          default: {
            const Component = tagName as ElementType;
            const { dataColor, ...otherAttributes } = attributes;

            if (dataColor) {
              elementProps.css.push(
                css({
                  color: getThemeTextColor(dataColor as TEXT_COLORS, theme),
                }),
              );
            }

            return (
              <Component
                {...elementProps}
                {...otherAttributes}
                ref={ref as ForwardedRef<HTMLElement>}
              >
                {renderChildren()}
              </Component>
            );
          }
        }
      }

      default:
        return null;
    }
  },
);

HastRendererDefaultRenderElementBase.displayName =
  'HastRendererDefaultRenderElement';

HastRendererDefaultRenderElementBase.propTypes = {
  /** Node to render. */
  node: PropTypes.oneOfType([hastElementPropTypes, hastLeafPropTypes])
    .isRequired,
  /** Path to the node in the hast. */
  path: PropTypes.arrayOf(PropTypes.number).isRequired,
  /** Depth in the tree (nodes at root have a depth of `0`). */
  depth: PropTypes.number.isRequired,
  /** A function to render children. Ignore it when the node handles its children manually. */
  renderChildren: PropTypes.func.isRequired,
};

HastRendererDefaultRenderElementBase.defaultProps = {};

export const HastRendererDefaultRenderElement = memo(
  HastRendererDefaultRenderElementBase,
);
