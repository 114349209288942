import { css } from '@emotion/react';
import ReactPlayer from 'react-player/lazy';

import {
  DEFAULT_VIDEO_ASPECT_RATIO,
  MCQ_MEDIA_RESOURCES_TYPES,
} from '@eversity/domain/constants';
import { AudioPlayer } from '@eversity/ui/design-system';
import { McqResource } from '@eversity/types/domain';

export const renderResources = (
  resource: McqResource,
  { width }: { width: number },
) => {
  switch (resource.type) {
    case MCQ_MEDIA_RESOURCES_TYPES.IMAGE:
      return (
        <img
          css={css`
            width: ${width}px;
            border-radius: 8px;
          `}
          src={resource.params.upload.href}
          alt={resource.params.alt}
        />
      );
    case MCQ_MEDIA_RESOURCES_TYPES.AUDIO:
      return <AudioPlayer src={resource.params.upload.href} />;
    case MCQ_MEDIA_RESOURCES_TYPES.VIDEO:
      return (
        <ReactPlayer
          width={width}
          height={width / DEFAULT_VIDEO_ASPECT_RATIO}
          url={resource.params.href}
          controls
        />
      );
    default:
      return null;
  }
};
