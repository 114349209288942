import { css, Theme } from '@emotion/react';
import { TinyColor } from '@ctrl/tinycolor';

export const iconContainer = css`
  position: relative;
`;

export const iconPlaceholder = (color: string) => css`
  width: 32px;
  height: 32px;
  border-radius: 32px;
  box-shadow: 0 0 0 4px ${new TinyColor(color).setAlpha(0.15).toRgbString()};
`;

export const customIconContainer = (color: string) => (theme: Theme) => css`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;
  border-radius: 100%;
  background-color: ${theme.colors.gray[0]};

  border: 2px solid ${color};
`;

export const icon = css`
  position: absolute;
  top: -2px;
  left: -2px;
`;
