import { useCallback, useMemo } from 'react';
import { isFunction } from 'lodash';
import {
  UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

/**
 * Equivalent to useState, synced with localStorage.
 * When updating the value, all components using useLocalStorage with the same key are updated.
 * The synchronization is done with useQuery but could be done another way (just need an Observer).
 *
 * Use setState(null) or setState(undefined) to remove the key from localStorage.
 *
 * @param localStorageKey - Local storage key.
 * @param options - Options.
 * @param options.serialize - Serialize the value in local storage.
 * @param options.deserialize - Deserialize the value from local storage.
 * @returns State value and setState.
 */
export const useLocalStorage = <T = unknown>(
  localStorageKey: string,
  {
    serialize = JSON.stringify,
    deserialize = (value: string | null) =>
      value !== null ? JSON.parse(value) : null,
  }: {
    serialize?: (value: T) => string;
    deserialize?: (value: string | null) => T | null;
  } = {},
): [
  value: T | null,
  onChange: UseMutateFunction<
    T,
    unknown,
    T | ((currentValue: T | null) => T),
    unknown
  >,
] => {
  const queryClient = useQueryClient();

  const queryKey = useMemo(
    () => [`localStorage-${localStorageKey}`],
    [localStorageKey],
  );

  const getValueInLocalStorage = useCallback(
    () => deserialize(localStorage.getItem(localStorageKey) || null),
    [deserialize, localStorageKey],
  );

  const setValueInLocalStorage = useCallback(
    async (newValue: T | ((currentValue: T | null) => T)) => {
      // Emulate setState behavior : if value is a function, call it with the current value.
      const valueToSave = isFunction(newValue)
        ? newValue(getValueInLocalStorage())
        : newValue;

      // If there is a value to save, save it, otherwise remove it from localStorage.
      if (valueToSave !== null && valueToSave !== undefined) {
        // Serialize and save in localStorage.
        localStorage.setItem(localStorageKey, serialize(valueToSave));
      } else {
        localStorage.removeItem(localStorageKey);
      }

      return valueToSave;
    },
    [serialize, localStorageKey, getValueInLocalStorage],
  );

  const { data: value } = useQuery({
    queryKey,
    queryFn: getValueInLocalStorage,
    initialData: getValueInLocalStorage(),
  });

  const { mutate: setValue } = useMutation({
    mutationFn: setValueInLocalStorage,
    onSuccess: (savedValue) => {
      queryClient.setQueryData(queryKey, () => savedValue);
    },
  });

  return [value ?? null, setValue];
};
