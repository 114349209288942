import { memo } from 'react';
import PropTypes from 'prop-types';

import { Tag, TagProps, useTagVariant } from '@eversity/ui/design-system';

export type VirtualClassroomTagProps = Omit<
  TagProps,
  'label' | 'variant' | 'light'
> & {
  label: string;
};

export const VirtualClassroomTagBase = ({
  label,
  ...props
}: VirtualClassroomTagProps) => {
  const variant = useTagVariant(label, {
    exclude: [Tag.VARIANTS.PRIMARY],
  });

  return (
    <Tag
      {...props}
      label={label}
      variant={variant}
      light
    />
  );
};

VirtualClassroomTagBase.displayName = 'VirtualClassroomTag';

VirtualClassroomTagBase.propTypes = {
  label: PropTypes.string.isRequired,
};

export const VirtualClassroomTag = memo(VirtualClassroomTagBase);
